export const addUserFormInputOne = [
  {
    label: "Add Name",
    type: "name",
  },
  {
    label: "Add Email",
    type: "email",
  },
  {
    label: "Add Phone Number",
    type: "phoneNo",
  },
  {
    label: "Add Employee ID",
    type: "empid",
  },
  {
    label: "Add Designation",
    type: "designation",
  },
];

export const addUserFormInputTwo = [
  {
    label: "Select Report",
    type: "reportAccess",
  },
  {
    label: "Select State",
    type: "state",
  },
  {
    label: "Select District",
    type: "district",
  },
];

export const userRolesForSuperAdmin = [
  {
    role: "Admin",
    id: 1,
  },
  {
    role: "User",
    id: 2,
  },
];

export const userRolesForAdmin = [
  {
    role: "User",
    id: 2,
  },
];

export const formLabelOne = [
  "NAME OF USER",
  "E-MAIL ID",
  "PHONE NUMBER",
  "EMPLOYEE ID",
  "DESIGNATION",
  "ROLE",
];

export const formLabelTwo = [
  "REPORT ACCESS",
  "STATE",
  "DISTRICT",
  "CREDIT HUB",
];
