import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Paper from "@material-ui/core/Paper";
import styles from "./styles.module.css";

const CssTextField = styled(TextField)({
  "& .MuiInputLabel-outlined": {
    fontSize: "11px",
    paddingTop: "3px",
    fontWeight: "600",
  },
});

const CustomPaper = (props) => {
  return <Paper elevation={4} {...props} />;
};

export default function Missingreportselectpicker({
  selecterOnChange,
  ...props
}) {
  console.log(props.selecterOptions, "===== selecterOptions");
  const [selectedValue, setSelectedValue] = useState({});
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name || "",
  });

  function getSelectedItem() {
    const item = props.selectPickeCloneNames?.find((opt) => {
      if (opt.level == props.currentLevel) {
        return opt;
      } else {
        return null;
      }
    });
    return item || {};
  }
  console.log(getSelectedItem(), "getSelectedItem");

  return (
    <div
      style={{
        marginBottom: "20px",
        width:
          props.selectedCurrentLevel !== props.currentLevel ? "100%" : "auto",
      }}
    >
      <div className={styles.formInputWrapper}>
        <div className={styles.inputLabels}>
          <p>
            {props.currentLevel}
            <span>
              {props.storeDataFromDistrictLevel.includes(props.currentLevel) &&
                props.selectedCurrentLevel === props.currentLevel &&
                "*"}
            </span>
          </p>
        </div>
        <div className={styles.inputFields}>
          <input
            type="text"
            value={
              props.storeDataFromDistrictLevel.length === 0
                ? ""
                : getSelectedItem().name
            }
            style={
              props.storeDataFromDistrictLevel.includes(props.currentLevel) &&
              props.selectedCurrentLevel === props.currentLevel
                ? { display: "none" }
                : { display: "block" }
            }
            disabled={
              props.selectedCurrentLevel !== props.currentLevel ? true : false
            }
          />
        </div>
      </div>
    </div>
  );
}
