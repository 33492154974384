import React, { useState, useEffect } from "react";
// plugin
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Close from "../../assets/images/common/close.svg";

// style
// import 'bootstrap/dist/css/bootstrap.min.css';
import styles from "./styles.module.css";
import Selectoptions from "./Selectoptions.jsx";
// Action
import { _getStorageValue } from "../../common/localStorage";
import { ACCESS_TOKEN, USER_ID } from "../../common/constants";
import {
  getRegions,
  getDynamicRegions,
} from "../../layouts-components/filter-component/filterfun";
import { missingReportApi } from "../../layouts/archive-report-layout/layout-component/archivefun";
// lodash debouncing import
import { debounce } from "lodash";

export default function Missingreport(props) {
  // to set the next level access.
  const [nextLevelAccess, setNextLevelAccess] = useState(true);

  // to set the current level
  const [currentlevel, setCurrentlevel] = useState("");
  // to access the current level
  const [currentLevelAccess, setCurrentLevelAccess] = useState(false);
  const [filterDropDownComponents, setFilterDropDownComponents] = useState([]);
  const [filter, setFilters] = useState([]);
  // show success message
  const [showNotifySuccessMessage, setShowNotifySuccessMessage] =
    useState(false);
  // to disable/enable submit button
  const [disableButton, setDisableButton] = useState(false);
  // to show/hide missing text.
  const [showMissingText, setShowMissingText] = useState(true);
  // to show/hide text feilds.
  const [showTextBox, setShowTextBox] = useState(false);
  // to store the dropdown names.
  const [storeDropDownNames, setStoreDropDownNames] = useState([]);
  // to store the input text values.
  const [storeTextFieldInput, setStoreTextFieldInput] = useState("");
  // store selected input fields values.
  const [storeSelectedTextFieldValues, setStoreSelectedTextFieldValues] =
    useState("");
  // to store the comments.
  const [storeComments, setStoreComments] = useState("");
  const [storeBasedOnCurrentLevelAccess, setStoreBasedOnCurrentLevelAccess] =
    useState([]);
  const [storeBasedOnSelectChange, setStoreBasedOnSelectChange] = useState("");
  // to store the last current level if data is null.
  const [storeLastIndexFilterData, setStoreLastIndexFilterData] = useState([]);

  //fetch page permission to pass it as a query parameter in region API
  const getDefaultRadioButtonSelection =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];
  let pageName = "";
  getDefaultRadioButtonSelection.forEach((items) => {
    if (items.default_page === true) {
      pageName = items.page_name;
    }
  });

  useEffect(() => {
    const storeStateRegionId = "27841bce-248f-4342-a78d-b6d817dceb24";
    _getStorageValue(USER_ID).then((user_id) => {
      if (props.dropDownComponents.length === 0) {
        getRegions(
          storeStateRegionId,
          pageName,
          missingReportsuccessRegionCallBack,
          failureCallBack,
        );
      }
    });
  }, []);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (props.dropDownComponents.length !== 0) {
        setCurrentlevel(props.selectedCurrentLevel);
        setFilters(props.filter);
        setFilterDropDownComponents(props.dropDownComponents);
        // setStoreDropDownNames(props.selectPickeCloneNames);
      }
    }, 1 * 1000);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timer1);
    };
  }, [props.dropDownComponents]);

  const missingReportsuccessRegionCallBack = (response, getTheCurrentLevel) => {
    let copiedDropDownComponents = [...filterDropDownComponents];
    let copiedFilters = [...filter];

    if (response !== null) {
      setNextLevelAccess(response.nextLevelAccess);
      if (
        response.currentLevelAccess &&
        !storeBasedOnCurrentLevelAccess.includes(response.currentLevel)
      ) {
        const storeNewArray = [
          ...storeBasedOnCurrentLevelAccess,
          response.currentLevel,
        ];
        setStoreBasedOnCurrentLevelAccess(storeNewArray);
      }
      setCurrentLevelAccess(response.currentLevelAccess);
      setCurrentlevel(response.currentLevel);
    }

    if (response !== null && copiedFilters.includes(response.currentLevel)) {
      const index = filter.indexOf(response.currentLevel);
      const newDropDownComponents = filterDropDownComponents.slice(0, index);
      const dropDownFilterComponents = [...newDropDownComponents];
      setFilterDropDownComponents(dropDownFilterComponents);
      setDisableButton(false);
      setShowTextBox(false);

      const newFilter = filter.slice(0, index);
      const newCopiedFilter = [...newFilter];
      setFilters(newCopiedFilter);

      copiedFilters = [...newCopiedFilter];
      copiedDropDownComponents = [...dropDownFilterComponents];
    }
    if (response !== null) {
      setFilters([...copiedFilters, response.currentLevel]);
      setFilterDropDownComponents([...copiedDropDownComponents, response]);
    }
    if (response === null) {
      const index = filter.indexOf(getTheCurrentLevel);

      const newDropDownComponents1 = filterDropDownComponents.slice(
        0,
        index + 1,
      );
      const newFilter1 = filter.slice(0, index + 1);

      if (storeBasedOnCurrentLevelAccess.includes(getTheCurrentLevel)) {
        const indexOfStoreBasedOnCurrentLevelAccess =
          storeBasedOnCurrentLevelAccess.indexOf(getTheCurrentLevel);
        const newStoreBasedOnCurrentLevelAccess =
          storeBasedOnCurrentLevelAccess.slice(
            0,
            indexOfStoreBasedOnCurrentLevelAccess + 1,
          );
        setStoreLastIndexFilterData(newStoreBasedOnCurrentLevelAccess);
        setStoreBasedOnCurrentLevelAccess([
          ...newStoreBasedOnCurrentLevelAccess,
        ]);
      }

      setFilters([...newFilter1]);
      setFilterDropDownComponents([...newDropDownComponents1]);
      setCurrentlevel(getTheCurrentLevel);
      setDisableButton(true);
    }
  };

  const failureCallBack = (response) => {
    console.log(response);
  };

  const onSelectDropdownChange = (value, getCurrentLevel) => {
    const getDefaultPage = JSON.parse(localStorage.getItem("default page"));
    const defaultValue = getDefaultPage.filter((item) => item.default_page)[0]
      ?.page_name;
    if (getCurrentLevel === "State") {
      setStoreBasedOnCurrentLevelAccess([]);
    }

    setShowMissingText(getCurrentLevel);
    setStoreTextFieldInput("");
    if (
      !props.selectPickerNames
        .map((item) => item.level)
        .includes(getCurrentLevel) ||
      !storeDropDownNames.map((item) => item.level).includes(getCurrentLevel)
    ) {
      props.setSelectPickerNames([
        ...props.selectPickerNames,
        { ...value, level: getCurrentLevel },
      ]);
      setStoreDropDownNames([
        ...storeDropDownNames,
        { ...value, level: getCurrentLevel },
      ]);
    } else {
      const index = props.selectPickerNames.findIndex(
        (item) => item.level === getCurrentLevel,
      );
      const index1 = storeDropDownNames.findIndex(
        (item) => item.level === getCurrentLevel,
      );
      const cloneSelectPickerNames = [...props.selectPickerNames];
      const cloneDropDownNames = [...storeDropDownNames];
      cloneSelectPickerNames.splice(index, 1, {
        ...value,
        level: getCurrentLevel,
      });
      cloneDropDownNames.splice(index1, 1, {
        ...value,
        level: getCurrentLevel,
      });
      const sliceTheClonedArr = cloneSelectPickerNames.slice(0, index + 1);
      const sliceTheClonedDropDownArr = cloneDropDownNames.slice(0, index1 + 1);
      props.setSelectPickerNames(sliceTheClonedArr);
      setStoreDropDownNames(sliceTheClonedDropDownArr);
    }
    setDisableButton(false);
    // extract the local storage value for the radio button selection.
    const getStoreRadioButtonSelection = localStorage.getItem(
      "storeRadioButtonSelection",
    );
    _getStorageValue(USER_ID).then(() => {
      getDynamicRegions(
        value.id,
        getStoreRadioButtonSelection === null
          ? defaultValue || storeRadioButtonValue
          : getStoreRadioButtonSelection,
        getCurrentLevel,
        missingReportsuccessRegionCallBack,
        failureCallBack,
      );
    });

    setStoreBasedOnSelectChange({
      level: getCurrentLevel,
      ...value,
    });

    // if (nextLevelAccess === false) {
    //     setShowMissingText(false);
    // }
  };

  useEffect(() => {
    if (
      props.basedOnCurrentLevelAccess.length !== 0 ||
      props.filterDropdownsCurrentLevelAccess !== false
    ) {
      setStoreBasedOnCurrentLevelAccess(props.basedOnCurrentLevelAccess);
    }
  }, [props.basedOnCurrentLevelAccess]);

  useEffect(() => {
    if (
      storeBasedOnCurrentLevelAccess.includes(storeBasedOnSelectChange.level)
    ) {
      setDisableButton(true);
      props.setShowDisabledMissingSubmitButton(true);
    } else if (
      props.storeLastIndexFilterData.includes(props.selectedCurrentLevel) ||
      storeLastIndexFilterData.includes(currentlevel)
    ) {
      setDisableButton(true);
      props.setShowDisabledMissingSubmitButton(true);
    } else {
      setDisableButton(false);
      props.setShowDisabledMissingSubmitButton(false);
    }
  }, [storeBasedOnCurrentLevelAccess, storeBasedOnSelectChange]);

  const missingModalClose = () => {
    props.missingModalClosePopUp();
    if (props.storeDataFromDistrictLevel.length === 0) {
      const storeStateRegionId = "27841bce-248f-4342-a78d-b6d817dceb24";
      _getStorageValue(USER_ID).then((user_id) => {
        getRegions(
          storeStateRegionId,
          pageName,
          missingReportsuccessRegionCallBack,
          failureCallBack,
        );
      });
    }

    if (
      props.storeLastIndexFilterData.includes(props.selectedCurrentLevel) ||
      storeLastIndexFilterData.includes(currentlevel)
    ) {
      setDisableButton(true);
      props.setShowDisabledMissingSubmitButton(true);
    } else {
      setDisableButton(false);
      props.setShowDisabledMissingSubmitButton(false);
      setStoreLastIndexFilterData([]);
      props.setStoreLastIndexFilterData([]);
    }
  };

  // to handle the missing text.
  const handleMissingText = (currentlevel) => {
    setShowMissingText(false);
    setShowTextBox(currentlevel);
    setDisableButton(false);
    props.setShowDisabledMissingSubmitButton(false);
  };

  // for closing the missing textboxes.
  const handleInputClose = () => {
    setShowTextBox(false);
    setShowMissingText(true);
    setDisableButton(false);
  };

  // to capture the input text values.
  const handleTextInput = (value) => {
    if (props.storeDataFromDistrictLevel.length === 0) {
      setStoreTextFieldInput(value);
    } else {
      setStoreSelectedTextFieldValues(value);
    }
    // regex to remove the spaces
    if (value.replace(/\s+/g, "").length == 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  // to capture the input text values if the dropdown values are auto filled.
  const handleSelectedTextInput = (value) => {
    setStoreSelectedTextFieldValues(value);
    // regex to remove the spaces
    if (value.replace(/\s+/g, "").length == 0) {
      props.setShowDisabledMissingSubmitButton(false);
    } else {
      props.setShowDisabledMissingSubmitButton(true);
    }
  };

  // function to call the missing data api.
  const handleFormSubmit = debounce(() => {
    var storeDropDownValues = {};
    filterDropDownComponents.forEach((value) => {
      const item =
        props.storeDataFromDistrictLevel.length === 0 &&
        storeDropDownNames.find(
          (value1) => value1.level === value.currentLevel,
        );
      if (value.currentLevel === currentlevel && storeTextFieldInput !== "") {
        storeDropDownValues[`"${currentlevel}"`] = storeTextFieldInput;
        if (storeComments !== "") {
          storeDropDownValues["Comment"] = storeComments;
        }
      } else {
        if (item !== undefined) {
          storeDropDownValues[`"${item.level}"`] = item.name;
        }
      }
    });

    var storeSelectedDropDownValues = {};
    filterDropDownComponents.forEach((value) => {
      const item =
        props.storeDataFromDistrictLevel.length !== 0 &&
        props.selectPickeCloneNames.find(
          (value1) => value1.level === value.currentLevel,
        );
      if (item !== undefined) {
        storeSelectedDropDownValues[`"${item.level}"`] = item.name;
      } else {
        if (
          value.currentLevel === props.selectedCurrentLevel &&
          storeSelectedTextFieldValues !== ""
        ) {
          storeSelectedDropDownValues[`"${props.selectedCurrentLevel}"`] =
            storeSelectedTextFieldValues;
        }
        if (
          value.currentLevel === props.selectedCurrentLevel &&
          storeComments !== ""
        ) {
          storeSelectedDropDownValues["Comment"] = storeComments;
        }
      }
    });

    document.getElementById("modalContent").scrollIntoView({
      behavior: "smooth",
    });
    _getStorageValue(USER_ID).then(() => {
      missingReportApi(
        props.storeDataFromDistrictLevel.length !== 0
          ? storeSelectedDropDownValues
          : storeDropDownValues,
        successMissingReportResponse,
        failMissingReportResponse,
      );
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }, 100);

  const successMissingReportResponse = (response) => {
    setShowNotifySuccessMessage(true);
  };

  const failMissingReportResponse = (response) => {
    console.log(response);
  };

  // clear all functionality.
  const handleClearAll = () => {
    setDisableButton(false);
    // props.setStoreBasedOnCurrentLevelAccess([]);
    const storeStateRegionId = "27841bce-248f-4342-a78d-b6d817dceb24";
    _getStorageValue(USER_ID).then((user_id) => {
      getRegions(
        storeStateRegionId,
        pageName,
        missingReportsuccessRegionCallBack,
        failureCallBack,
      );
    });
    props.resetDropDowns();
  };

  // function to handle the comments
  function handleComment(value) {
    setStoreComments(value);
  }

  const customStyles = {
    content: {
      position: "absolute",
      top: "40%",
      left: "38%",
      right: "90%",
      bottom: "0",
      marginRight: "-70%",
      marginLeft: "10%",
      borderRadius: "10px",
      transform: "translate(-40%, -40%)",
      width: "550px",
      height: "600px",
      padding: "15px 0 10px 0",
    },
    overlay: {
      zIndex: 2000,
      background: "transparent",
    },
  };

  // function to enable/disable the submit button.

  function addActiveClass() {
    if (disableButton || props.disableMissingDataSubmitButton) {
      return styles.enableButton;
    } else {
      return styles.disableButton;
    }
  }

  return (
    <div>
      <Modal
        isOpen={props.modalIsOpen}
        style={customStyles}
        transparent={true}
        ariaHideApp={false}
      >
        <div className={styles.modalContent} id="modalContent">
          {showNotifySuccessMessage && (
            <p className={styles.submitMessage}>
              Successfully notified the missing data
            </p>
          )}
          <div className={styles.headerWrapper}>
            <h1>NOTIFY MISSING DATA</h1>
            <div>
              <img
                src={Close}
                style={{ cursor: "pointer" }}
                alt="Your image"
                onClick={missingModalClose}
              />
            </div>
          </div>
          <div className={styles.inputElementWrapper}>
            <div className={styles.colWrapper}>
              <Selectoptions
                dropDownComponents={props.dropDownComponents}
                onSelectDropdownChange={onSelectDropdownChange}
                filter={filter}
                filterDropDownComponents={filterDropDownComponents}
                showFilterDropDowns={props.showFilterDropDowns}
                selectPickeCloneNames={props.selectPickeCloneNames}
                onSelectChange={props.onSelectChange}
                showTextBox={showTextBox}
                showMissingText={showMissingText}
                currentlevel={currentlevel}
                handleMissingText={handleMissingText}
                handleInputClose={handleInputClose}
                selectedMissingText={props.selectedMissingText}
                selectedCurrentLevel={props.selectedCurrentLevel}
                handleSelectedMissingText={props.handleSelectedMissingText}
                showSelectedTextBoxes={props.showSelectedTextBoxes}
                handleSelectedInputClose={props.handleSelectedInputClose}
                handleTextInput={handleTextInput}
                storeDropDownNames={storeDropDownNames}
                handleSelectedTextInput={handleSelectedTextInput}
                storeBasedOnCurrentLevelAccess={storeBasedOnCurrentLevelAccess}
                storeDataFromDistrictLevel={props.storeDataFromDistrictLevel}
              />
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              variant="contained"
              className={addActiveClass()}
              onClick={
                disableButton || props.disableMissingDataSubmitButton
                  ? handleFormSubmit
                  : null
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
