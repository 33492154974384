import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import state from "../src/reducer/index";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "2054943c-4687-4418-bf35-131260c76c52",
  clientToken: "pub7a413432889650faec04ead300160397",
  // site refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "ap1.datadoghq.com",
  service: "sage-dashboard",
  env: "sage-production",
  // Specify a version number to identify the deployed version of your application in Datadog
  version: "5.23.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

Sentry.init({
  dsn: "https://193fe44a6444dd7d5bfe0bac3ddf1a40@o4506252999000064.ingest.sentry.io/4506258056282112",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/sage.satsure\.co/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.05, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={state}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
