export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const USER_PAGE_PERMISSION = "USER_PAGE_PERMISSION";

export const FORGOTPASSWORD_REQUEST = "FORGOTPASSWORD_REQUEST";
export const FORGOTPASSWORD_FAILURE = "FORGOTPASSWORD_FAILURE";
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const DISABLE_SELECTPICKER = "DISABLE_SELECTPICKER";
export const FILTER_TABLE_COMPONENT = "FILTER_TABLE_COMPONENT";

export const NOTIFICATION_HEADER_SELECTED = "NOTIFICATION_HEADER_SELECTED";
export const NOTIFICATION_EXPANDED = "NOTIFICATION_EXPANDED";

export const DEFAULT_RADIO_BUTTON_SELECTION = "DEFAULT_RADIO_BUTTON_SELECTION";

export const DEFAULT_PAGE = "DEFAULT_PAGE";

export const STORE_RADIO_BUTTON_SELECTION = "STORE_RADIO_BUTTON_SELECTION";

export const ALL_FILES = "ALL_FILES";

export const REPORT_TYPES = "REPORT_TYPES";

export const GOOGLE_RECAPTCHA = "6LfZvhkpAAAAAMUiCQXrW8ZixQpqff9A78L1wA-z";
