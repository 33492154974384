// url
import {
  adminUserManagement,
  updateUserDetails,
  userCreation,
  adminDataManagement,
  downloadCsv,
  adminDataManagementfilter,
  analyticsData,
  creditHubs,
  dataManagementSearch,
} from "../../common/urls";
// api
import { apiCall, downloadXLSFile } from "../../common/connect";

// constant
import { GET, POST, ACCESS_TOKEN } from "../../common/constants";
import { _getStorageValue } from "../../common/localStorage";

// Get user management Table Data.
export function userManagementTableData(
  successUserManagementTableDataCallBack,
  failureUserManagementTableDataCallBack,
  shouldStorePageNo,
) {
  const url = adminUserManagement(shouldStorePageNo);
  const onSuccess = (response) => {
    let userManagementTable = response.data;

    successUserManagementTableDataCallBack(userManagementTable);
  };
  const onFailure = (response) => {
    failureUserManagementTableDataCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// update user details.
export function updateDetails(
  requestPayload,
  successUpdateUserDetailsCallBack,
  failureUpdateUserDetailsCallBack,
) {
  const url = updateUserDetails();
  const onSuccess = (response) => {
    let updateDetails = response.data;
    successUpdateUserDetailsCallBack(updateDetails);
  };
  const onFailure = (response) => {
    failureUpdateUserDetailsCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, requestPayload, onSuccess, onFailure, token);
    }
  });
}

// user creation api.
export function addUser(
  requestPayload,
  successUserCreationCallBack,
  failureUserCreationCallBack,
) {
  const url = userCreation();
  const onSuccess = (response) => {
    let updateDetails = response.data;
    successUserCreationCallBack(updateDetails);
  };
  const onFailure = (response) => {
    failureUserCreationCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, requestPayload, onSuccess, onFailure, token);
    }
  });
}

// data management table api.
export function getDataManagementValue(
  reportName,
  pageNumber,
  shouldStoreSearchKeyword,
  shouldDownloadCsv,
  successDataManagementCallBack,
  failureDataManagementCallBack,
  startDate = null,
  endDate = null,
  creditHub = "",
  state = "",
) {
  const formattedStartDate = startDate
    ? `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
    : null;
  const formattedEndDate = endDate
    ? `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    : null;

  const params = new URLSearchParams({
    reportType: reportName,
    pageNo: pageNumber,
    pageSize: 20,
  });

  if (formattedStartDate && formattedEndDate) {
    params.append("startDate", formattedStartDate);
    params.append("endDate", formattedEndDate);
  }

  if (creditHub) {
    params.append("creditHub", creditHub.toUpperCase());
  }

  if (state) {
    params.append("state", state);
  }

  let url = `${adminDataManagementfilter()}?${params.toString()}`;

  if (!startDate && !endDate && !creditHub && !state) {
    if (shouldStoreSearchKeyword) {
      url = adminDataManagement(
        reportName,
        pageNumber,
        shouldStoreSearchKeyword,
        shouldDownloadCsv,
      );
    } else {
      url = adminDataManagement(reportName, pageNumber, shouldDownloadCsv);
    }
  }

  const onSuccess = (response) => {
    successDataManagementCallBack(response.data);
  };

  const onFailure = (response) => {
    console.error(response);
    failureDataManagementCallBack(response);
  };

  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// data management table download csv api.
export function getDataManagementCsv(
  reportName,
  pageNumber,
  shouldDownloadCsv,
  successDataManagementCallBack,
  failureDataManagementCallBack,
  startDate = null,
  endDate = null,
  creditHub = "",
  state = "",
) {
  const formatUrl = (params) => {
    return (
      adminDataManagementfilter() +
      "?reportType=" +
      reportName +
      "&pageNo=" +
      pageNumber +
      "&pageSize=20&csv=true" +
      (startDate && endDate
        ? "&startDate=" + startDate + "&endDate=" + endDate
        : "") +
      (creditHub ? "&creditHub=" + creditHub.toUpperCase() : "") +
      (state ? "&state=" + state : "")
    );
  };

  let url;

  if ((startDate && endDate) || creditHub || state) {
    url = formatUrl();
  } else {
    url = adminDataManagement(reportName, pageNumber, shouldDownloadCsv);
    if (state) {
      url =
        adminDataManagementfilter() +
        "?reportType=" +
        reportName +
        "&pageNo=" +
        pageNumber +
        "&state=" +
        state +
        "&pageSize=20&csv=true";
    } else {
      url = downloadCsv(reportName, pageNumber, shouldDownloadCsv);
    }
  }

  const onSuccess = (response) => {
    console.log(response);
    successDataManagementCallBack(response.data);
  };

  const onFailure = (response) => {
    console.log(response, "error response");
    failureDataManagementCallBack(response);
  };

  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token) {
      downloadXLSFile(GET, url, "", reportName, onSuccess, onFailure, token);
    }
  });
}

//Analytics API
export function getAnalyticsValue(
  successAnalyticsCallBack,
  failureAnalyticsCallBack,
  startDate = null,
  endDate = null,
  reportName = "",
  branchName = "",
  stateName = "",
) {
  if (branchName !== undefined) {
    branchName = branchName.toLowerCase();
  }

  const formatDateString = (date) => {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  };

  const queryParams = {
    startDate: startDate ? formatDateString(startDate) : undefined,
    endDate: endDate ? formatDateString(endDate) : undefined,
    branchName: branchName,
    state: stateName,
    satsource: reportName === "satsource" ? true : undefined,
    ["satsource & ownership"]:
      reportName === "satsource & ownership" ? true : undefined,
    ownership: reportName === "ownership" ? true : undefined,
  };

  const queryParts = Object.entries(queryParams)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  const url = analyticsData() + (queryParts ? `?${queryParts}` : "");

  const onSuccess = (response) => {
    let analyticsData = response.data;
    successAnalyticsCallBack(analyticsData);
  };
  const onFailure = (response) => {
    failureAnalyticsCallBack(response);
  };

  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// Get the credit hubs Data.
export function creditHubDataApi(
  companyId,
  stateName,
  successCreditHubCallBack,
  failureCreditHubCallBack,
) {
  const url = `${creditHubs()}?company=${companyId}&state=${stateName}`;

  const onSuccess = successCreditHubCallBack;
  const onFailure = failureCreditHubCallBack;

  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// Get the search results on the data management table.
export function searchDataManagementTable(
  reportName,
  searchParam,
  // pageNumber,
  successDataManagementSearchCallBack,
  failureDataManagementSearchCallBack,
) {
  const url = dataManagementSearch(reportName, searchParam);
  const onSuccess = (response) => {
    let searchResults = response;

    successDataManagementSearchCallBack(searchResults);
  };
  const onFailure = (response) => {
    failureDataManagementSearchCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}
