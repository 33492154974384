import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { withStyles } from "@material-ui/core/styles";
import "./add-user.css";
import Dropdowncomponents from "./Dropdown-component";
import {
  addUserFormInputOne,
  userRolesForSuperAdmin,
  userRolesForAdmin,
  formLabelOne,
  formLabelTwo,
} from "./Add-user-data";

const CssTextField = styled(TextField)({
  "& .MuiInputLabel-outlined": {
    fontSize: "12px",
    paddingTop: "3px",
    fontWeight: "200",
    fontStyle: "italic",
  },
});

const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: "transparent",
    border: "1px solid #3342B5",
    height: 24,
    position: "relative",
    fontStyle: "italic",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "#000",
    },
    "& .MuiChip-deleteIcon": {
      color: "#3342B5",
    },
    "&:after": {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
})(Autocomplete);

export default function Adduser({
  onSubmit,
  handleSubmit,
  handleAddUserFormOneInput,
  handleRoleChange,
  reportAccess,
  handleBranchName,
  shouldShowValidationMessage,
  showDuplicateValidation,
  shouldStoreRegionFilterDistricts,
  storeRegionFilterStates,
  shouldStoreCreditHubs,
  shouldStoreDefaultpage,
  handleDefaultLanding,
  handleReportAccess,
  branch,
  shouldStoreDistrictName,
  handleStateFromDropdown,
  handleDistrictChangeFromDropdown,
  stateName,
  validateInput,
}) {
  // extract the user access level from local storage.
  const getUserAccessLevel = localStorage.getItem("user access level");
  // extract the page accesses.
  const extractPageAccesses = JSON.parse(
    localStorage.getItem("defaultRadioButtonSelection"),
  );

  const renameArrayItems = extractPageAccesses?.map((page) => {
    // Create a new object with the "default" key and its value set to the "default_page" value.
    // Remove the "default_page" key.
    const { default_page, ...rest } = page;
    return {
      ...rest,
      default: default_page,
    };
  });

  // Use map to set default_page to false for all objects
  const updatedData = renameArrayItems?.map((item) => ({
    ...item,
    default: false,
  }));

  //finally sort the extractPageAccesses array page no wise.
  const sortArr = updatedData
    ? updatedData.sort((a, b) => a.page_no - b.page_no)
    : [];

  const keyNames = ["username", "email", "phone", "access_level"];

  // function to store the matching labels corresponding to the report access array.
  function storeMatchingLabels() {
    const storeMatchingDropDownValues = [];
    sortArr.forEach((items) => {
      reportAccess.forEach((labels) => {
        if (items.page_name === labels.page_name) {
          storeMatchingDropDownValues.push(labels);
        }
      });
    });
    return storeMatchingDropDownValues;
  }

  // function to render the states based on the report access.
  function renderStatesBasedOnReportAccess() {
    let regions = [];

    // Loop through each item in reportAccess
    reportAccess.forEach((item) => {
      // Check if the item is a "Satsource Report"
      if (
        item.page_name === "satsource" ||
        item.page_name === "satsource & ownership" ||
        item.page_name === "ownership"
      ) {
        regions = [...storeRegionFilterStates];
      }
    });

    return regions;
  }

  return (
    <Paper className="addUserView" elevation={4}>
      {shouldShowValidationMessage && (
        <div className="validation-message-wrapper">
          <p>* Please enter all input fields</p>
        </div>
      )}
      {showDuplicateValidation && (
        <div className="validation-message-wrapper">
          <p>
            Email or Phone Number already exists, hence not able to create the
            user. Please enter a new email id or phone number.
          </p>
        </div>
      )}
      <div className="add-user-form-wrapper">
        <form onSubmit={handleSubmit(onSubmit)} className="create-user-form">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <div className="grid-wrapper">
                  <div className="applicationInfo1">
                    {formLabelOne.map((labels, index) => {
                      return (
                        <p className="label" key={index}>
                          {labels}
                        </p>
                      );
                    })}
                  </div>
                  <div className="applicationInfo2">
                    <Stack direction="column">
                      {addUserFormInputOne.map((formOneLabels, index) => {
                        const keys = keyNames[index];
                        if (keys === "phone") {
                          return (
                            <>
                              <CssTextField
                                className="inputFisetReportAccesseld"
                                sx={{ paddingBottom: "23px" }}
                                size="small"
                                label={formOneLabels.label}
                                autoComplete="off"
                                InputProps={{
                                  style: {
                                    fontSize: "14px!important",
                                    width: "268px",
                                  },
                                }}
                                onKeyDown={(e) =>
                                  validateInput(formOneLabels.type, e)
                                }
                                onChange={(e) =>
                                  handleAddUserFormOneInput(
                                    e,
                                    formOneLabels.type,
                                    keys,
                                  )
                                }
                              />
                              {formOneLabels.type === "email" ? (
                                <p
                                  id="emailInvalid"
                                  style={{
                                    display: "none",
                                    color: "red",
                                    fontStyle: "italic",
                                    marginTop: "-10px",
                                    paddingBottom: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Invalid email ID
                                </p>
                              ) : formOneLabels.type === "phoneNo" ? (
                                <p
                                  id="phoneInvalid"
                                  style={{
                                    display: "none",
                                    color: "red",
                                    fontStyle: "italic",
                                    marginTop: "-10px",
                                    paddingBottom: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Invalid phone number
                                </p>
                              ) : null}
                            </>
                          );
                        } else {
                          return (
                            <>
                              <CssTextField
                                className="inputFisetReportAccesseld"
                                sx={{ paddingBottom: "23px" }}
                                size="small"
                                label={formOneLabels.label}
                                autocomplete="off"
                                InputProps={{
                                  style: {
                                    fontSize: "14!important",
                                    width: "268px",
                                  },
                                }}
                                onKeyDown={(e) =>
                                  validateInput(formOneLabels.type, e)
                                }
                                onChange={(e) =>
                                  handleAddUserFormOneInput(
                                    e,
                                    formOneLabels.type,
                                    keys,
                                  )
                                }
                              />
                              {formOneLabels.type === "email" ? (
                                <p
                                  id="emailInvalid"
                                  style={{
                                    display: "none",
                                    color: "red",
                                    fontStyle: "italic",
                                    marginTop: "-10px",
                                    paddingBottom: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Invalid email ID
                                </p>
                              ) : formOneLabels.type === "phoneNo" ? (
                                <p
                                  id="phoneInvalid"
                                  style={{
                                    display: "none",
                                    color: "red",
                                    fontStyle: "italic",
                                    marginTop: "-10px",
                                    paddingBottom: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Invalid phone number
                                </p>
                              ) : (
                                <p
                                  id={`invalidTextField${index}`}
                                  style={{
                                    display: "none",
                                    color: "red",
                                    fontStyle: "italic",
                                    marginTop: "-10px",
                                    paddingBottom: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {`Invalid ${formOneLabels.type}`}
                                </p>
                              )}
                            </>
                          );
                        }
                      })}

                      <Autocomplete
                        className="inputField"
                        disablePortal
                        disableClearable
                        getOptionLabel={(option) => option.role}
                        sx={{ width: "268px", paddingBottom: "23px" }}
                        size="small"
                        closeicon=""
                        options={
                          parseInt(getUserAccessLevel) === 1
                            ? userRolesForAdmin
                            : userRolesForSuperAdmin
                        }
                        onChange={(e, value) => handleRoleChange(value)}
                        noOptionsText="No Matching Keyword"
                        ListboxProps={{
                          style: {
                            maxHeight: "250px",
                            border: "1px solid dedede",
                            overflowY: "auto",
                            fontSize: "12px",
                            width: "268px",
                          },
                        }}
                        renderInput={(params) => (
                          <CssTextField
                            size="medium"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                fontSize: "18!important",
                                width: "268px",
                              },
                            }}
                            {...params}
                            label="Select Role"
                          />
                        )}
                      />
                    </Stack>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="grid-wrapper">
                  <div className="applicationInfo3">
                    <p className="label" style={{ maxWidth: "118px" }}>
                      REPORT ACCESS
                    </p>
                    {formLabelTwo.map((label, index) => {
                      if (index === 0) {
                        return (
                          <p
                            className="label"
                            style={{ maxWidth: "118px" }}
                            key={index}
                          >
                            DEFAULT LANDING SELECTION
                          </p>
                        );
                      }
                      return (
                        <p
                          className="label"
                          style={{ maxWidth: "118px" }}
                          key={index}
                        >
                          {label}
                        </p>
                      );
                    })}
                  </div>
                  <div className="applicationInfo4">
                    <CustomAutocomplete
                      disablePortal
                      className="inputField"
                      disableClearable
                      limitTags={1}
                      id="combo-box-demo"
                      multiple={true}
                      options={sortArr}
                      getOptionLabel={(option) => option.page_name || ""}
                      sx={{ width: "268px", paddingBottom: "23px" }}
                      size="small"
                      onChange={(e, value, reason) =>
                        handleReportAccess(value, reason)
                      }
                      ListboxProps={{
                        style: {
                          maxHeight: "250px",
                          border: "1px solid dedede",
                          overflowY: "auto",
                          fontSize: "12px",
                          width: "268px",
                          textTransform: "capitalize",
                        },
                      }}
                      renderInput={(params) => (
                        <CssTextField
                          size="medium"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontSize: "18px !important",
                              width: "268px",
                            },
                          }}
                          {...params}
                          label="Select Report Access"
                        />
                      )}
                    />
                    {/* dropdown components to render rest of the components invidually namely default landing, state etc... */}
                    <Dropdowncomponents
                      storeMatchingLabels={storeMatchingLabels}
                      handleDefaultLanding={handleDefaultLanding}
                      shouldStoreDefaultpage={shouldStoreDefaultpage}
                      renderStatesBasedOnReportAccess={
                        renderStatesBasedOnReportAccess
                      }
                      shouldStoreRegionFilterDistricts={
                        shouldStoreRegionFilterDistricts
                      }
                      handleBranchName={handleBranchName}
                      handleStateFromDropdown={handleStateFromDropdown}
                      handleDistrictChangeFromDropdown={
                        handleDistrictChangeFromDropdown
                      }
                      shouldStoreCreditHubs={shouldStoreCreditHubs}
                      shouldStoreDistrictName={shouldStoreDistrictName}
                      branch={branch}
                      CustomAutocomplete={CustomAutocomplete}
                      CssTextField={CssTextField}
                      stateName={stateName}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                variant="contained"
                style={{ background: "#3342B5" }}
                type="submit"
              >
                Create User
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Paper>
  );
}
