import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import debounce from "lodash/debounce";
import Adminsidepanel from "../admin-sidepanel";
import HeaderLayoutComp from "../../common-header-component/header";

import { _getStorageValue } from "../../../common/localStorage";
import { USER_ID } from "../../../common/constants";
// import { userManagementTableData } from "./adminApi";
import Datamanagementtable from "./dataManagementTable";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
// plugin
import { useHistory } from "react-router-dom";
import { getRegions } from "../../filter-component/filterfun";
import {
  getDataManagementValue,
  getDataManagementCsv,
  creditHubDataApi,
  searchDataManagementTable,
} from "../adminApi";
import { getOwnershipPdfData } from "../../../layouts/archive-report-layout/layout-component/archivefun";
import {
  getFullPdfData,
  getPartialPdfData,
} from "../../../layouts/archive-report-layout/layout-component/archivefun";

function ReportButton({ isActive, id, onClick, text }) {
  return (
    <Button
      variant="outlined"
      style={{
        color: isActive === id ? "#3342B5" : "#95969D",
        border: isActive === id ? "1px solid #3342B5" : "1px solid #95969D",
        background: "white",
        width: "200px",
        height: "36px",
        marginRight: "10px",
        textTransform: "capitalize",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

export default function DataManagement() {
  const getUserAccessLevel = localStorage.getItem("user access level");
  // data loader.
  const [
    shouldShowDataManagementDataLoader,
    setShouldShowDataManagementDataLoader,
  ] = useState(true);
  const [tabNav, setTabNav] = useState("");
  // to render the states from the region api.
  const [shouldStoreStates, setShouldStoreStates] = useState([]);
  const [reportType, setReportType] = useState(0);

  const [dataManagementTableValue, setDataManagementTableValue] = useState([]);
  const [totalRows, setTotalRows] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  // store the report names.
  const [shouldStoreReportName, setShouldStoreReportName] = useState("");
  // state for enabling/disabling the apply filter button.
  const [shouldEnableDisableButton, setShouldEnableDisableButton] =
    useState(true);
  // to store the states from the states dropdown.
  const [shouldStoreStatesFromDropdown, setShouldStoreStatesFromDropdown] =
    useState("");
  // store credit hubs.
  const [shouldStoreCreditHubs, setShouldStoreCreditHubs] = useState("");
  // state to store the start and end dates.
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // store credit hubs name.
  const [shouldStoreCreditHubNames, setShouldStoreCreditHubNames] = useState(
    [],
  );
  // for active buttons.
  const [activeButton, setActiveButton] = useState(0);
  // store the search keyword.
  const [shouldStoreSearchKeyword, setShouldStoreSearchKeyword] = useState("");

  useEffect(() => {
    let path = window.location.pathname;
    setTabNav(path);
  }, []);

  // extract the page accesses.
  const extractPageAccesses = JSON.parse(
    localStorage.getItem("defaultRadioButtonSelection"),
  );
  let pageName = "";
  if (extractPageAccesses) {
    extractPageAccesses.forEach((items) => {
      if (items.default_page === true) {
        pageName = items.page_name;
      }
    });
  } else {
    console.warn("extractPageAccesses is null or undefined");
  }

  const sortArr = extractPageAccesses
    ? extractPageAccesses.sort((a, b) => a.page_no - b.page_no)
    : [];

  const pageNumberMappings = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
  };

  const newMatchingReportTypes = sortArr
    .map((items) => pageNumberMappings[items.page_no])
    .filter(Boolean);
  const reportTypesButtons = [];
  if (newMatchingReportTypes.length >= 3) {
    reportTypesButtons.push(
      { id: 0, name: "SatSource Report", reportName: "satsource" },
      { id: 1, name: "Ownership Report", reportName: "ownership" },
    );
  } else {
    if (newMatchingReportTypes.includes(1)) {
      reportTypesButtons.push({
        id: 0,
        name: "SatSource Report",
        reportName: "satsource",
      });
    }
    if (newMatchingReportTypes.includes(4)) {
      reportTypesButtons.push(
        { id: 0, name: "SatSource Report", reportName: "satsource" },
        { id: 1, name: "Ownership Report", reportName: "ownership" },
      );
    }

    if (newMatchingReportTypes.includes(6)) {
      reportTypesButtons.push({
        id: 0,
        name: "Ownership Report",
        reportName: "ownership",
      });
    }
  }

  // data management table api integration.

  useEffect(() => {
    reportTypesButtons.forEach((items) => {
      if (reportType === items.id) {
        setShouldStoreReportName(items.reportName);
        _getStorageValue(USER_ID).then((user_id) => {
          getDataManagementValue(
            items.reportName,
            pageNumber,
            shouldStoreSearchKeyword,
            false,
            successDataManagementCallBack,
            failureDataManagementCallBack,
            startDate,
            endDate,
            shouldStoreCreditHubs,
            shouldStoreStatesFromDropdown,
          );
        });
      }
    });
  }, [reportType, pageNumber]);

  const successDataManagementCallBack = (response) => {
    setShouldShowDataManagementDataLoader(false);
    setTotalRows(response.totalRows);
    let dataTable = response.requests;
    setDataManagementTableValue(dataTable);
  };

  const failureDataManagementCallBack = (error) => {
    console.log(error, "===== error calling api");
  };

  // region api to generate the states.
  useEffect(() => {
    const storeStateRegionId = "27841bce-248f-4342-a78d-b6d817dceb24";
    _getStorageValue(USER_ID).then((user_id) => {
      getRegions(
        storeStateRegionId,
        pageName,
        successRegionCallBack,
        failureFailureCallBack,
      );
    });
  }, []);

  const successRegionCallBack = (response) => {
    setShouldStoreStates(response.regions);
  };

  const failureFailureCallBack = (error) => {
    console.log(error, "====== error response");
  };

  // function to handle the data management table search.
  const handleUserSearch = debounce(async (value) => {
    const searchTerm = value.replace(/\s+/g, "").toLowerCase();
    setShouldStoreSearchKeyword(searchTerm);

    setShouldShowDataManagementDataLoader(true);
    await _getStorageValue(USER_ID);

    if (searchTerm.length >= 3) {
      searchDataManagementTable(
        shouldStoreReportName,
        searchTerm,
        successDataManagementSearchCallBack,
        failureDataManagementSearchCallBack,
      );
    } else if (searchTerm.length === 0) {
      // Only execute this block if searchTerm is empty
      const user_id = await _getStorageValue(USER_ID);
      getDataManagementValue(
        shouldStoreReportName,
        pageNumber,
        searchTerm,
        false,
        successDataManagementCallBack,
        failureDataManagementCallBack,
      );
    }
  }, 800);

  const successDataManagementSearchCallBack = (response) => {
    setShouldShowDataManagementDataLoader(false);
    let dataTable = response.data.requests;
    setDataManagementTableValue(dataTable);
    setTotalRows(response.data.totalRows);
  };

  const failureDataManagementSearchCallBack = (error) => {
    console.log(error, "==== failure data management search api");
  };

  // function to handle the state selection.
  function handleStateSelection(states, reason) {
    setShouldEnableDisableButton(false);
    setShouldStoreCreditHubs("");
    let state = states;
    if (state?.indexOf(" ") !== -1) {
      // if the state name contains a space, replace spaces with %20
      state = state?.replace(/\s+/g, "%20");
    }
    // fetch the company id from the local storage.
    const storeCompanyId = parseInt(localStorage.getItem("companyId"));
    _getStorageValue(USER_ID).then(() => {
      creditHubDataApi(
        storeCompanyId,
        state,
        successCreditHubCallBack,
        failureCreditHubCallBack,
      );
    });

    if (states != null) {
      setShouldStoreStatesFromDropdown(states);
    } else {
      setShouldStoreStatesFromDropdown("");
      const convertCreditHubsName = shouldStoreCreditHubs?.toLowerCase();
      // _getStorageValue(USER_ID).then(() => {
      //     getDataManagementValue(
      //         shouldStoreReportName,
      //         pageNumber,
      //         false,
      //         successDataManagementCallBack,
      //         failureDataManagementCallBack,
      //         startDate,
      //         endDate,
      //         convertCreditHubsName,
      //         ""
      //     );
      // });
    }

    if (reason === "clear") {
      setShouldStoreCreditHubs("");
      if (
        states === null &&
        shouldStoreCreditHubs !== "" &&
        startDate == null &&
        endDate == null
      ) {
        setShouldEnableDisableButton(true);
      } else if (
        states === null &&
        shouldStoreCreditHubs !== "" &&
        startDate !== null &&
        endDate !== null
      ) {
        setShouldEnableDisableButton(false);
      } else if (states === null && startDate !== null && endDate !== null) {
        setShouldEnableDisableButton(false);
      } else if (!states || !shouldStoreCreditHubs) {
        setShouldEnableDisableButton(true);
      } else {
        setShouldEnableDisableButton(true);
      }
      _getStorageValue(USER_ID).then((user_id) => {
        getDataManagementValue(
          shouldStoreReportName,
          pageNumber,
          shouldStoreSearchKeyword,
          false,
          successDataManagementCallBack,
          failureDataManagementCallBack,
        );
      });
    }

    const successCreditHubCallBack = (response) => {
      const fetchTheCreditHubs = [];
      response.data.forEach((creditHubs) => {
        fetchTheCreditHubs.push(creditHubs.credit_hub);
      });
      setShouldStoreCreditHubNames(fetchTheCreditHubs);
    };

    const failureCreditHubCallBack = (error) => {
      console.log(error, "===== error calling credit hub api");
    };
  }

  // function to handle the credit hub selection.
  function handleCreditHubSelection(creditHubs, reason) {
    setShouldStoreCreditHubs(creditHubs);
    if (reason === "selectOption") {
      if (
        (shouldStoreStatesFromDropdown !== "" &&
          creditHubs === null &&
          startDate === null &&
          endDate === null) ||
        (shouldStoreStatesFromDropdown !== "" &&
          creditHubs === null &&
          startDate !== null &&
          endDate !== null)
      ) {
        setShouldEnableDisableButton(false);
      } else if (shouldStoreStatesFromDropdown === "") {
        setShouldEnableDisableButton(true);
      } else {
        setShouldEnableDisableButton(false);
      }
      _getStorageValue(USER_ID).then(() => {
        getDataManagementValue(
          shouldStoreReportName,
          pageNumber,
          shouldStoreSearchKeyword,
          false,
          successDataManagementCallBack,
          failureDataManagementCallBack,
        );
      });
    }
  }

  // function to apply the filters.
  function handleSearchFilter() {
    const convertToLowerCase = shouldStoreStatesFromDropdown?.toLowerCase(); //state
    const convertCreditHubsName = shouldStoreCreditHubs?.toLowerCase(); //credit hub
    // const reportAccess = [...storeTheReportAccess];
    let filteredResult = [...dataManagementTableValue];
    //let filteredResultForReportAccess = [];
    setPageNumber(0);

    if (convertToLowerCase) {
      filteredResult = filteredResult.filter((item) =>
        item.state?.toString().toLowerCase().includes(convertToLowerCase),
      );
    }
    if (convertCreditHubsName) {
      filteredResult = filteredResult.filter((item) =>
        item.userBranch
          ?.toString()
          .toLowerCase()
          .includes(convertCreditHubsName),
      );
    }
    _getStorageValue(USER_ID).then(() => {
      getDataManagementValue(
        shouldStoreReportName,
        pageNumber,
        shouldStoreSearchKeyword,
        false,
        successDataManagementCallBack,
        failureDataManagementCallBack,
        startDate,
        endDate,
        convertCreditHubsName,
        convertToLowerCase,
        shouldStoreStatesFromDropdown,
      );
    });
  }

  //pdf download functions
  //satsource
  const exportPdfTrigger = (requestId) => {
    _getStorageValue(USER_ID).then((userID) => {
      getFullPdfData(
        userID,
        requestId,
        shouldStoreReportName,
        successPdfResponse,
        failPdfResponse,
      );
    });
  };

  // // partial pdf
  // const exportPartialPdfTrigger = (requestId) => {
  //     _getStorageValue(USER_ID).then((userID) => {
  //         getFullPdfData(
  //             userID,
  //             requestId,
  //             shouldStoreReportName,
  //             successPdfResponse,
  //             failPdfResponse,
  //         );
  //     });
  // };

  // pdf download
  const exportOwnershipPdfTrigger = (requestId) => {
    _getStorageValue(USER_ID).then((userID) => {
      getOwnershipPdfData(
        userID,
        requestId,
        "ownership",
        successPdfResponse,
        failPdfResponse,
      );
    });
  };

  const successPdfResponse = (response) => {
    // const setPdf = response[0].reportLink;
    window.open(response[0].reportLink);
  };
  const failPdfResponse = (response) => {
    console.log("fail", response);
  };

  // function to handle the date range.
  const handleDateRange = (range) => {
    const [start, end] = range;
    setStartDate(start);
    setEndDate(end);
    if (
      shouldStoreCreditHubs !== "" ||
      (shouldStoreStatesFromDropdown !== "" && start == null && end == null)
    ) {
      setShouldEnableDisableButton(false);
    } else if (start != null && end != null) {
      setShouldEnableDisableButton(false);
    } else if (start == null && end == null) {
      setShouldEnableDisableButton(true);
      _getStorageValue(USER_ID).then((user_id) => {
        getDataManagementValue(
          shouldStoreReportName,
          pageNumber,
          shouldStoreSearchKeyword,
          false,
          successDataManagementCallBack,
          failureDataManagementCallBack,
        );
      });
    } else {
      setShouldEnableDisableButton(true);
    }
  };

  const handleReportTypeChange = (reportId) => {
    setReportType(reportId);
    setActiveButton(reportId);
    setPageNumber(0);
  };

  const handleDownloadCSV = () => {
    const convertStateToLowerCase =
      shouldStoreStatesFromDropdown?.toLowerCase();
    const convertCreditHubsName = shouldStoreCreditHubs?.toLowerCase();
    let formattedEndDate;
    let formattedStartDate;

    if (endDate != null && startDate != null) {
      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();
      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
      formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
    }

    getDataManagementCsv(
      shouldStoreReportName,
      pageNumber,
      true,
      successDataManagementCallBack,
      failureDataManagementCallBack,
      formattedStartDate,
      formattedEndDate,
      convertCreditHubsName,
      convertStateToLowerCase,
    );
  };

  return (
    <>
      <div className="sage-user-manual-sections">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Adminsidepanel
                shouldStoreStates={shouldStoreStates}
                handleUserSearch={handleUserSearch}
                shouldEnableDisableButton={shouldEnableDisableButton}
                startDate={startDate}
                endDate={endDate}
                handleDateRange={handleDateRange}
                handleStateSelection={handleStateSelection}
                handleCreditHubSelection={handleCreditHubSelection}
                handleSearchFilter={handleSearchFilter}
                dataManagementTableValue={dataManagementTableValue}
                shouldStoreCreditHubNames={shouldStoreCreditHubNames}
                shouldStoreCreditHubs={shouldStoreCreditHubs}
                shouldStoreStatesFromDropdown={shouldStoreStatesFromDropdown}
              />
            </Grid>
            <Grid item xs={10}>
              <div className="sage-user-report-wrapper">
                <HeaderLayoutComp tabNav={tabNav} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    {reportTypesButtons.map(({ id, name }) => (
                      <ReportButton
                        key={id}
                        isActive={activeButton}
                        onClick={() => handleReportTypeChange(id)}
                        text={name}
                        id={id}
                      />
                    ))}
                  </div>
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon style={{ color: "#3342B5" }} />}
                    style={{
                      color: "#3342B5",
                      border: "1px solid #3342B5",
                      background: "white",
                      width: "190px",
                    }}
                    onClick={handleDownloadCSV}
                  >
                    Download CSV
                  </Button>
                </div>
                <div className="page-container" style={{ overflow: "hidden" }}>
                  <div className="slide-container">
                    <Datamanagementtable
                      reportType={reportType}
                      setPageNumber={setPageNumber}
                      dataManagementTableValue={dataManagementTableValue}
                      totalRows={totalRows}
                      getDataManagementValue={getDataManagementValue}
                      pageNumber={pageNumber}
                      shouldShowDataManagementDataLoader={
                        shouldShowDataManagementDataLoader
                      }
                      exportPdfTrigger={exportPdfTrigger}
                      exportOwnershipPdfTrigger={exportOwnershipPdfTrigger}
                      shouldStoreReportName={shouldStoreReportName}
                      reportTypesButtons={reportTypesButtons}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
