import React, { useEffect, useState } from "react";
import { _getStorageValue } from "../../common/localStorage";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { responsiveFontSizes } from "@mui/material/styles";
// mui icon import
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import modal
import Raisequery from "../../PopUps/Raisequery";
// star icon import
import StarIcon from "@mui/icons-material/Star";
// arrow icon import
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import filter buttons
import Filterbuttons from "./filterButtons";
// import archieve data image.
import archiveDataImg from "../../assets/images/archieve-data.svg";
import RequestedIcon from "../../assets/images/explorerTable/requested.svg";
import CompletedIcon from "../../assets/images/explorerTable/Generated.svg";
import PartiallyCompleted from "../../assets/images/explorerTable/Login.svg";
import ErrorIcon from "../../assets/images/explorerTable/errorIcon.svg";
import Notavaliable from "../../assets/images/explorerTable/not-avaliable.svg";
import DelayedIcon from "../../assets/images/explorerTable/Clock.svg";
import InProgressIcon from "../../assets/images/explorerTable/Loader.svg";
import caption from "../../assets/images/explorerTable/caption.svg";
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import "./styles.css";
// import Dropdownmenu component
import Dropdownmenu from "./Dropdownmenu";
// report dropdown component import
import Reportdropdown from "./Reportdropdown";
// import status dropdown component
import Status from "./Status";

import { useSelector } from "react-redux";
// toaster component.
import Toaster from "./Toaster";
// map view component.
import Mapview from "./Map-view/Mapview";
// Reportregenerationmodal modal.
import Reportregenerationmodal from "../../PopUps/Report-regeneration/index";

const useStyles = makeStyles({
  "@keyframes blinker": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  selectedRow: {
    background: "#FF9506",
    animationName: "$blinker",
    animationDuration: "0.7s",
    animationTimingFunction: "cubic-bezier(.5, 0, 1, 1)",
    animationIterationCount: 3,
  },
  root: {
    "& tr": {
      height: "10px",
    },
    "& td": {
      height: "auto !important",
    },
  },
});

const ArchiveTable = (props) => {
  let checkStarredStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkStarredStatus,
  );
  let checkPendingStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkPendingStatus,
  );
  let checkOnHoldStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkOnHoldStatus,
  );
  let checkApprovedStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkApprovedStatus,
  );
  let checkDeclinedStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkDeclinedStatus,
  );
  let checkArchiveStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkArchiveStatus,
  );

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const classes = useStyles();

  window.index = 0;
  const rotateArror = (id) => {
    if (props.statusDropDown === id) {
      return "rotate(180deg)";
    } else {
      return "rotate(0deg)";
    }
  };
  useEffect(() => {
    window.onload = (event) => {
      setSelectedRow(null);
    };
    try {
      if (props.selectedRowProps.prevPath === "/sageDashboardNotification") {
        setSelectedRow(props.selectedRowProps.refId);
      }
    } catch (err) {
    } finally {
      setTimeout(function () {
        setSelectedRow(null);
      }, 5000);
    }
  }, []);

  // function to conditionally add color based on the status.
  const renderReportStatusColor = (data) => {
    switch (data.dropDownStatus) {
      case "pending":
        return "#E5CF00";
      case "onhold":
        return "#FC7900";
      case "approved":
        return "#25B900";
      case "declined":
        return "#D10000";
      default:
        return data.color;
    }
  };

  // function to handle tooltip title capitalization
  function toolTipTitle(dropdownStatus) {
    var splitStr = dropdownStatus
      ?.toLowerCase()
      .split(",")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(", ");
    return splitStr;
  }

  let storeRadioButtonSelection = useSelector(
    (s) => s.radioButtonSelectionReducer.storeRadioButtonSelection,
  );
  const getDefaultRadioButtonSelection =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];

  function renderWidthBasedOnLength() {
    if (
      getDefaultRadioButtonSelection.length === 1 ||
      getDefaultRadioButtonSelection.length === 2
    ) {
      return "70%";
    } else {
      return "90%";
    }
  }
  // table columns stored in an array of objects.
  const getColumns = () => {
    const pageNumberList = [];
    const pageNameList = [];
    const defaultPageList = [];

    for (const item of getDefaultRadioButtonSelection) {
      pageNumberList.push(item.page_no);
      pageNameList.push(item.page_name);
      defaultPageList.push(item.default_page);
    }

    let storeColumns = [
      {
        label: "SELECT",
        name: "Options",
        options: {
          filter: true,
          sort: false,
          customBodyRenderLite: (value, id) => {
            if (props.tableData[value].refNo === selectedRow) {
              setSelectedRowId(id);
            }
            return (
              <div
                className={
                  props.tableData[value].archiveStatus === true &&
                  checkPendingStatus === undefined &&
                  checkOnHoldStatus === undefined &&
                  checkApprovedStatus === undefined &&
                  checkDeclinedStatus === undefined
                    ? "custom-elements-width"
                    : "custom-elements-wrapper"
                }
                style={
                  props.tableData[value].archiveStatus === true &&
                  checkPendingStatus === undefined &&
                  checkOnHoldStatus === undefined &&
                  checkApprovedStatus === undefined &&
                  checkDeclinedStatus === undefined
                    ? { width: renderWidthBasedOnLength() }
                    : { width: "inherit" }
                }
              >
                {props.tableData[value].archiveStatus === true &&
                checkPendingStatus === undefined &&
                checkOnHoldStatus === undefined &&
                checkApprovedStatus === undefined &&
                checkDeclinedStatus === undefined ? (
                  <>
                    {props.tableData[value].archiveStatus === false && (
                      <Tooltip
                        title={
                          props.tableData[value].starredStatus &&
                          props.tableData[value].starredStatus
                            ? "Starred"
                            : "Not Starred"
                        }
                      >
                        <StarIcon
                          className="custom-icon"
                          sx={{ strokeWidth: 0.1 }}
                          style={
                            props.tableData[value].starredStatus
                              ? { color: "#534F96" }
                              : { color: "#B4B4B4" }
                          }
                          onClick={() =>
                            props.handleStarIcon(value, props.tableData[value])
                          }
                        />
                      </Tooltip>
                    )}
                    <div className="align-image">
                      <Tooltip title="Unarchive">
                        <img
                          src={archiveDataImg}
                          alt="archieve image"
                          onClick={() =>
                            props.handleUnarchieveData(props.tableData[value])
                          }
                        />
                      </Tooltip>{" "}
                    </div>{" "}
                  </>
                ) : (
                  <>
                    <Tooltip
                      title={
                        props.tableData[value].starredStatus &&
                        props.tableData[value].starredStatus
                          ? "Starred"
                          : "Not Starred"
                      }
                    >
                      <StarIcon
                        className="custom-icon"
                        sx={{ strokeWidth: 0.1 }}
                        style={
                          props.tableData[value].starredStatus
                            ? { color: "rgba(27, 69, 173, 1)" }
                            : { color: "#B4B4B4" }
                        }
                        onClick={() =>
                          props.handleStarIcon(value, props.tableData[value])
                        }
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        props.statusDropDownToolTip &&
                        props.statusDropDownToolTip === false
                          ? ""
                          : toolTipTitle(props.tableData[value].dropDownStatus)
                      }
                    >
                      <div className="status-wrapper">
                        <span
                          style={{
                            backgroundColor: renderReportStatusColor(
                              props.tableData[value],
                            ),
                          }}
                        ></span>
                        <div
                          className="arrow-wrapper"
                          style={
                            props.statusDropDown === id
                              ? { backgroundColor: "#534F96" }
                              : { backgroundColor: "#F3F1F1" }
                          }
                        >
                          <KeyboardArrowDownIcon
                            onClick={() =>
                              props.handleArrowClick(id, props.statusDropDown)
                            }
                            style={{
                              cursor: "pointer",
                              transform: rotateArror(id),
                              transition: "0.2s ease",
                              color:
                                props.statusDropDown === id
                                  ? "#fff"
                                  : "#534F96",
                            }}
                            className="dont-close-on-outside-click"
                          />
                        </div>
                      </div>
                    </Tooltip>
                  </>
                )}
                <Status
                  tableId={id}
                  statusDropDown={props.statusDropDown}
                  handleStatusColor={props.handleStatusColor}
                  tableDataColor={props.tableData[value]}
                  statusMenu={props.statusMenu}
                />
              </div>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 100,
              padding: "5px",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#EEEEF5",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 11,
              fontSize: "13px",
            },
          }),
        },
      },
      {
        label: "REFERENCE ID",
        name: "refNo",
        options: {
          filter: true,
          sort: false,
          setCellProps: () => ({
            style: {
              left: "0",
              height: "20px",
              background: "#ffffff",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              background: "#EEEEF5",
              height: 10,
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 11,
              fontSize: "13px",
            },
          }),
        },
      },
      {
        label: "ADDED ON",
        name: "reqTime",
        options: {
          filter: true,
          sort: false,
          customBodyRenderLite: (value) => {
            return (
              <>
                <div>
                  <div>{props.tableData[value].reqTime} </div>
                  <div className="timeStamp">
                    {props.tableData[value].reqDate}
                  </div>
                </div>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              height: "20px",
              background: "#ffffff",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#EEEEF5",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 11,
              fontSize: "13px",
            },
          }),
        },
      },
      {
        label: "REGION",
        name: "state",
        options: {
          filter: true,
          sort: false,
          setCellProps: () => ({
            style: {
              left: "0",
              height: "20px",
              background: "#ffffff",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#EEEEF5",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 11,
              fontSize: "13px",
            },
          }),
        },
      },
    ];
    if (pageNameList.includes("property") && defaultPageList.includes(true)) {
      if (pageNumberList.includes(7) || pageNumberList.includes(8)) {
        storeColumns.push({
          label: "PROPERTY REPORT",
          name: "propertyReport",
          options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (value) => {
              return (
                <div>
                  {props.tableData[value].propertyReport === 0 && (
                    <p
                      style={{
                        color: "#116366",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <RequestedToolTip title="Requested" arrow>
                            <img src={RequestedIcon} />
                          </RequestedToolTip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].propertyReport === -1 && (
                    <p style={{ color: "#E4B302", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <InProgressTooltip title="In Progress" arrow>
                            <img src={InProgressIcon} />
                          </InProgressTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].propertyReport === -3 && (
                    <p style={{ color: "#E4B302", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <ErrorTooltip title="Not Available" arrow>
                            <img src={Notavaliable} />
                          </ErrorTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].propertyReport === 1 && (
                    <p
                      style={{ color: "#116366", cursor: "pointer" }}
                      onClick={() =>
                        props.exportPropertyPdfTrigger(
                          props.tableData[value].requestId,
                        )
                      }
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <CompletedTooltip title="Generated" arrow>
                            <img src={CompletedIcon} />
                          </CompletedTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].propertyReport === -2 && (
                    <p style={{ color: "#116366", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <CompletedTooltip title="Failed" arrow>
                            <img src={Notavaliable} />
                          </CompletedTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].propertyReport === null && "NA"}
                </div>
              );
            },

            setCellProps: () => ({
              style: {
                right: "0",
                background: "#ffffff",
                textAlign: "center",
                borderRight: "2px solid #CECECE",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                right: 0,
                background: "#EEEEF5",
                textAlign: "center",
                borderRight: "2px solid #CECECE",
                zIndex: 11,
                fontSize: "13px",
              },
            }),
          },
        });
      }
      if (pageNumberList.includes(8)) {
        storeColumns.push({
          label: "OWNERSHIP REPORT",
          name: "farmMonitoringReport",
          options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (value) => {
              return (
                <div>
                  {props.tableData[value].farmMonitoringReport === 0 && (
                    <p
                      style={{
                        color: "#116366",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <RequestedToolTip title="Requested" arrow>
                            <img src={RequestedIcon} />
                          </RequestedToolTip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === -1 && (
                    <p style={{ color: "#E4B302", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <InProgressTooltip title="In Progress" arrow>
                            <img src={InProgressIcon} />
                          </InProgressTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === -4 && (
                    <p style={{ color: "#E4B302", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <ErrorTooltip title="Not Available" arrow>
                            <img src={Notavaliable} />
                          </ErrorTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}

                  {props.tableData[value].farmMonitoringReport === 1 && (
                    <p
                      style={{ color: "#116366", cursor: "pointer" }}
                      onClick={() =>
                        props.exportOwnershipPdfTrigger(
                          props.tableData[value].requestId,
                        )
                      }
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <CompletedTooltip title="Generated" arrow>
                            <img src={CompletedIcon} />
                          </CompletedTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === -2 && (
                    <p
                      style={{ color: "#116366", cursor: "pointer" }}
                      onClick={() =>
                        props.exportOwnershipPdfTrigger(
                          props.tableData[value].requestId,
                        )
                      }
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <CompletedTooltip title=" Partially Generated" arrow>
                            <img src={PartiallyCompleted} />
                          </CompletedTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === -3 && (
                    <p style={{ color: "#D10000", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <InProgressTooltip title="Delayed" arrow>
                            <img src={DelayedIcon} />
                          </InProgressTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === null && "NA"}
                </div>
              );
            },

            setCellProps: () => ({
              style: {
                right: "0",
                background: "#ffffff",
                textAlign: "center",
                borderRight: "2px solid #CECECE",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                right: 0,
                background: "#EEEEF5",
                textAlign: "center",
                borderRight: "2px solid #CECECE",
                zIndex: 11,
                fontSize: "13px",
              },
            }),
          },
        });
      }
    } else {
      if (pageNumberList.includes(1) || pageNumberList.includes(4)) {
        storeColumns.push({
          label: "SATSOURCE REPORTS",
          name: "farmReport",
          options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (value) => {
              return (
                <>
                  {props.tableData[value].farmReport === 0 && (
                    <p
                      style={{
                        color: "#262261",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <RequestedToolTip title="Requested" arrow>
                            <img src={RequestedIcon} id={index++} />
                          </RequestedToolTip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmReport === -1 && (
                    <p style={{ color: "#E4B302", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <InProgressTooltip title="In Progress" arrow>
                            <img src={InProgressIcon} id={index++} />
                          </InProgressTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmReport === 2 && (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          color: "#116366",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 10,
                        }}
                      >
                        {
                          <MuiThemeProvider theme={defaultTheme}>
                            <CompletedTooltip
                              title=" Partially Generated"
                              arrow
                              className="dropdown-outside-click"
                            >
                              <img
                                src={PartiallyCompleted}
                                id={index++}
                                onClick={(e) => {
                                  props.handleGenerateReportDropdown(e);
                                }}
                              />
                            </CompletedTooltip>
                            <Reportdropdown
                              handleBriefReportDownload={
                                props.handleBriefReportDownload
                              }
                              handleDetailedReportDownload={
                                props.handleDetailedReportDownload
                              }
                              tableData={props.tableData[value]}
                              dropdownstate={props.dropdownstate}
                              reportDropdown={props.reportDropdown}
                            />
                          </MuiThemeProvider>
                        }
                      </p>
                    </div>
                  )}
                  {props.tableData[value].farmReport === 1 && (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          color: "#116366",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 10,
                        }}
                      >
                        {
                          <MuiThemeProvider theme={defaultTheme}>
                            <CompletedTooltip
                              title="Generated"
                              arrow
                              className="dropdown-outside-click"
                            >
                              <img
                                src={CompletedIcon}
                                id={index++}
                                onClick={(e) => {
                                  props.handleGenerateReportDropdown(e);
                                }}
                              />
                            </CompletedTooltip>
                            <Reportdropdown
                              handleBriefReportDownload={
                                props.handleBriefReportDownload
                              }
                              handleDetailedReportDownload={
                                props.handleDetailedReportDownload
                              }
                              tableData={props.tableData[value]}
                              dropdownstate={props.dropdownstate}
                              reportDropdown={props.reportDropdown}
                            />
                          </MuiThemeProvider>
                        }
                      </p>
                    </div>
                  )}
                  {props.tableData[value].farmReport === 3 && (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          color: "#116366",
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 10,
                        }}
                      >
                        {
                          <MuiThemeProvider theme={defaultTheme}>
                            <CompletedTooltip
                              title="Generated"
                              arrow
                              className="dropdown-outside-click"
                            >
                              <img
                                src={CompletedIcon}
                                id={index++}
                                onClick={(e) => {
                                  props.handleGenerateReportDropdown(e);
                                }}
                              />
                            </CompletedTooltip>
                            <Reportdropdown
                              handleBriefReportDownload={
                                props.handleBriefReportDownload
                              }
                              handleDetailedReportDownload={
                                props.handleDetailedReportDownload
                              }
                              tableData={props.tableData[value]}
                              dropdownstate={props.dropdownstate}
                              reportDropdown={props.reportDropdown}
                            />
                          </MuiThemeProvider>
                        }
                      </p>
                      {props.tableData[value].reportReadStatus === 0 ||
                      props.tableData[value].reportReadStatus === null ? (
                        <div className="clippath-wrapper">
                          <img src={caption} alt="new" />
                        </div>
                      ) : null}
                    </div>
                  )}
                  {props.tableData[value].farmReport === -2 && (
                    <p style={{ color: "#D10000", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <ErrorTooltip title="Error" arrow>
                            <img src={ErrorIcon} id={index++} />
                          </ErrorTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmReport === -3 && (
                    <p style={{ color: "#D10000", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <ErrorTooltip
                            title="Not Avaliable: Requested farms are either too small or no boundary is available in the land survey data"
                            arrow
                          >
                            <img src={Notavaliable} id={index++} />
                          </ErrorTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmReport === null && "NA"}
                </>
              );
            },
            setCellProps: () => ({
              style: {
                height: "40px",
                left: "0",
                background: "#ffffff",
                textAlign: "center",
                borderRight: "2px solid #CECECE",
                zIndex: 100,
                padding: "0",
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                left: 0,
                background: "#EEEEF5",
                textAlign: "center",
                borderRight: "2px solid #CECECE",
                zIndex: 11,
                fontSize: "13px",
              },
            }),
          },
        });
      }
      if (pageNumberList.includes(4) || pageNumberList.includes(6)) {
        storeColumns.push({
          label: "OWNERSHIP REPORT",
          name: "farmMonitoringReport",
          options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (value) => {
              return (
                <div>
                  {props.tableData[value].farmMonitoringReport === 0 && (
                    <p
                      style={{
                        color: "#116366",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <RequestedToolTip title="Requested" arrow>
                            <img src={RequestedIcon} />
                          </RequestedToolTip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === -1 && (
                    <p style={{ color: "#E4B302", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <InProgressTooltip title="In Progress" arrow>
                            <img src={InProgressIcon} />
                          </InProgressTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === -4 && (
                    <p style={{ color: "#E4B302", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <ErrorTooltip title="Not Available" arrow>
                            <img src={Notavaliable} />
                          </ErrorTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}

                  {props.tableData[value].farmMonitoringReport === 1 && (
                    <p
                      style={{ color: "#116366", cursor: "pointer" }}
                      onClick={() =>
                        props.exportOwnershipPdfTrigger(
                          props.tableData[value].requestId,
                        )
                      }
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <CompletedTooltip title="Generated" arrow>
                            <img src={CompletedIcon} />
                          </CompletedTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === -2 && (
                    <p
                      style={{ color: "#116366", cursor: "pointer" }}
                      onClick={() =>
                        props.exportOwnershipPdfTrigger(
                          props.tableData[value].requestId,
                        )
                      }
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <CompletedTooltip title=" Partially Generated" arrow>
                            <img src={PartiallyCompleted} />
                          </CompletedTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === -3 && (
                    <p style={{ color: "#D10000", cursor: "pointer" }}>
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <InProgressTooltip title="Delayed" arrow>
                            <img src={DelayedIcon} />
                          </InProgressTooltip>
                        </MuiThemeProvider>
                      }
                    </p>
                  )}
                  {props.tableData[value].farmMonitoringReport === null && "NA"}
                </div>
              );
            },

            setCellProps: () => ({
              style: {
                right: "0",
                background: "#ffffff",
                textAlign: "center",
                borderRight: "2px solid #CECECE",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                right: 0,
                background: "#EEEEF5",
                textAlign: "center",
                borderRight: "2px solid #CECECE",
                zIndex: 11,
                fontSize: "13px",
              },
            }),
          },
        });
      }
      storeColumns.push({
        label: "MORE",
        name: "MORE",
        options: {
          filter: true,
          sort: false,
          customBodyRenderLite: (value, id) => {
            return (
              <div style={{ position: "relative" }}>
                <Tooltip
                  title={
                    props.optionsDropDownToolTip &&
                    props.optionsDropDownToolTip === false
                      ? ""
                      : "More Options"
                  }
                >
                  <IconButton
                    onClick={() =>
                      props.handleDropdownMenu(
                        id,
                        props.anchorEl,
                        props.tableData[value].refNo,
                      )
                    }
                  >
                    <MoreHorizIcon
                      style={
                        props.toggleIcon === id
                          ? {
                              transform: "rotate(0deg)",
                              cursor: "pointer",
                              transition: "0.2s ease",
                            }
                          : {
                              transform: "rotate(-90deg)",
                              transition: "0.2s ease",
                            }
                      }
                      className="show-dropDown"
                    />
                    <Dropdownmenu
                      anchorEl={props.anchorEl}
                      handleQuery={props.handleQuery}
                      handleMapView={props.handleMapView}
                      handleArchieve={props.handleArchieve}
                      handleReportRegeneration={props.handleReportRegeneration}
                      storeRefNo={props.storeRefNo}
                      addActive={props.addActive}
                      tableData={id}
                      storeRequestId={props.tableData[value].requestId}
                      storeTableRowData={props.tableData[value]}
                      surveyDetails={props.tableData[value].surveyDetails}
                      dropDownOptions={props.dropDownOptions}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },

          setCellProps: () => ({
            style: {
              right: "0",
              height: "20px",
              background: "#ffffff",
              textAlign: "center",
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              right: 0,
              height: 20,
              background: "#EEEEF5",
              textAlign: "center",
              zIndex: 11,
              fontSize: "13px",
            },
          }),
        },
      });
    }
    return storeColumns;
  };
  const tableBodyHeight = props.tableBodyHeight;
  const options = {
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows:
      checkPendingStatus ||
      checkOnHoldStatus ||
      checkApprovedStatus ||
      checkDeclinedStatus
        ? false
        : "multiple",
    // responsive: "scroll",
    resizableColumns: false,
    tableBodyHeight,
    filter: false,
    download: false,
    print: false,
    search: false,
    rowsPerPage: 20,
    count: props.totalRows,
    rowsPerPageOptions: [5],
    rowStyle: { height: 50 },
    page: props.pageNumber,
    rowsSelected: props.storeAllRowsSelected.map((row) => row.index),
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        //setPage(tableState.page);
        props.setPageNumber(tableState.page);
      }
    },
    serverSide: true,
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      props.handleCheckBoxes(
        currentRowsSelected,
        allRowsSelected,
        props.tableData,
      );
    },
    selectToolbarPlacement: "none",
    setRowProps: (row) => {
      if (row[1] === selectedRow) {
        if (selectedRowId != 1) {
          document
            .getElementsByTagName("tr")
            [selectedRowId + 1].scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
        }
        return {
          className: classes.selectedRow,
        };
      }
    },
  };

  const defaultTheme = createTheme();
  const theme1 = createTheme({
    overrides: {
      // MUIDataTable: {
      //   responsiveScroll: {
      //     maxHeight: 'none',
      //   },
      // },
    },
  });

  const CompletedTooltip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#219653",
      zIndex: "2",
    },
  })(Tooltip);
  const InProgressTooltip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#FF8A00",
      zIndex: "2",
    },
  })(Tooltip);

  const ErrorTooltip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#D10000",
      zIndex: "2",
    },
  })(Tooltip);
  const RequestedToolTip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#2F80ED",
      zIndex: "2",
    },
  })(Tooltip);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  // function to add validations incase there are no respective cases.
  function renderValidationCheck() {
    if (props.tableData.length) {
      return null;
    } else {
      return statusMessages();
    }
  }

  // function to render the last element header name from the get columns function.
  const renderTableHeaders = () => {
    const renderFilteredLabels = getColumns().filter(
      (labels) => labels.label !== "MORE",
    );
    const lastElement = renderFilteredLabels[renderFilteredLabels.length - 1];
    return lastElement;
  };

  // the earlier code has been heavily refactored. Removed the if else statements and made the code more modular.
  function statusMessages() {
    const messages = [
      { check: props.tableData.length, message: null },
      {
        check: checkPendingStatus,
        message:
          "No Decision Pending cases. Marking cases according to decision making stages will help you keep track of your portfolio. Click on the drop down beside the reference ID and select the status of the case.",
      },
      {
        check: checkOnHoldStatus,
        message:
          "No On Hold cases. Marking cases according to decision making stages will help you keep track of your portfolio. Click on the drop down beside the reference ID and select the status of the case.",
      },
      {
        check: checkApprovedStatus,
        message:
          "No Approved cases. Marking cases according to decision making stages will help you keep track of your portfolio. Click on the drop down beside the reference ID and select the status of the case.",
      },
      {
        check: checkDeclinedStatus,
        message:
          "No Declined cases. Marking cases according to decision making stages will help you keep track of your portfolio. Click on the drop down beside the reference ID and select the status of the case.",
      },
      {
        check: checkStarredStatus,
        message:
          "No starred cases. Starred cases will help you to have quick access to important cases. click on the star beside the reference ID.",
      },
      {
        check: checkArchiveStatus,
        message: `No archive cases. Archive cases will help you to have quick access to the cases which are kept for long-term retention. Click on the three dots beside the ${renderTableHeaders().label.toLowerCase()} column.`,
      },
    ];

    for (const { check, message } of messages) {
      if (check) {
        if (
          getDefaultRadioButtonSelection.length >= 1 &&
          getDefaultRadioButtonSelection.length <= 6
        ) {
          return (
            <p className="loader-text">
              <span>{message}</span>
            </p>
          );
        } else {
          return null;
        }
      }
    }
    return null;
  }

  return (
    <div className="archiveTable-sections">
      <Toaster
        showToaster={props.showToaster}
        storeStatusName={props.storeStatusName}
      />
      <Filterbuttons
        handleAllStarred={props.handleAllStarred}
        tableIndex={props.tableIndex}
        status={true}
        handleAllReportRenegerate={props.handleAllReportRenegerate}
        handleAllArchieve={props.handleAllArchieve}
        tableData={props.tableData}
        storeAllRowsSelected={props.storeAllRowsSelected}
        handleAllUnArchieve={props.handleAllUnArchieve}
        handleAllUnstarred={props.handleAllUnstarred}
      />
      <Raisequery
        tableRefId={props.tableRefId}
        modalIsOpen={props.modalIsOpen}
        closePopUp={props.closePopUp}
        checkBoxError={props.checkBoxError}
        handleFormSubmit={props.handleFormSubmit}
        handleCheckboxState={props.handleCheckboxState}
        handleComment={props.handleComment}
        successMessage={props.successMessage}
        disableTextField={props.disableTextField}
        activeButton={props.activeButton}
      />
      <Mapview
        mapViewModal={props.mapViewModal}
        setMapViewModal={props.setMapViewModal}
        getSurveyDetails={props.storeSurveyDetails}
        storeRefNo={props.storeRefNo}
        handleDropDownToogle={props.handleDropDownToogle}
        mapViewDropdown={props.mapViewDropdown}
        setMap={props.setMap}
        map={props.map}
        requestTime={props.requestTime}
        setMapViewDropdown={props.setMapViewDropdown}
        mapViewLoader={props.mapViewLoader}
        setStoreSurveyNoDetails={props.setStoreSurveyNoDetails}
        setStoreIndex={props.setStoreIndex}
      />

      <ThemeProvider theme={theme1}>
        <Reportregenerationmodal
          showReportRegenerationmodal={props.showReportRegenerationmodal}
          handleReportRegenerationCheckboxes={
            props.handleReportRegenerationCheckboxes
          }
          disabledButton={props.disabledButton}
          handleSelectedReportRegeneration={
            props.handleSelectedReportRegeneration
          }
          tableData={props.tableData}
          storeAllRowsSelected={props.storeAllRowsSelected}
          handleCheckboxModalClose={props.handleCheckboxModalClose}
          storeIndividualTableRowData={props.storeIndividualTableRowData}
          showSingleReportFlow={props.showSingleReportFlow}
        />
        {props.tableDataLoader &&
          getDefaultRadioButtonSelection.length >= 1 &&
          getDefaultRadioButtonSelection.length <= 6 && (
            <p className="loader-text">Loading...</p>
          )}
        {renderValidationCheck()}
        <MUIDataTable
          data={props.tableData}
          columns={getColumns()}
          options={options}
        />
        <p className="table-footer-text">
          <img src={PartiallyCompleted} alt="Tooltip Image" /> This represents a
          partially generated report. We are sourcing data from govt website and
          will notify you when the report is fully generated.
        </p>
      </ThemeProvider>
    </div>
  );
};

export default ArchiveTable;
