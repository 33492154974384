export const commonTabNavData = [
  {
    pageName: "GENERATOR",
    pageLink: "/generate-report",
  },
  {
    pageName: "EXPLORER",
    pageLink: "/archive-report",
  },
];

export const adminPortalTabNavData = [
  {
    pageName: "User Management",
    pageLink: "/adminMain",
  },
  {
    pageName: "Data Management",
    pageLink: "/dataManagement",
  },
  {
    pageName: "Analytics",
    pageLink: "/analytics",
  },
];
