import { useEffect, useRef } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

export default function RenderGeoJson({ geoJSONData }) {
  const map = useMap();
  const mapLayer = useRef(null);
  const aoiLayerGroup = L.featureGroup();

  useEffect(() => {
    if (!mapLayer.current) return;
    setTimeout(() => {
      const bounds = mapLayer.current.getBounds();
      map.fitBounds(bounds);
    }, 2000);
  }, [mapLayer.current]);

  useEffect(() => {
    if (!geoJSONData) {
      return;
    }

    geoJSONData.map((fetchGeometryDetails) => {
      var mapBoundery = L.geoJSON(JSON.parse(fetchGeometryDetails.geometry));
      return aoiLayerGroup.addLayer(mapBoundery);
    });
    aoiLayerGroup.addTo(map);
    mapLayer.current = aoiLayerGroup;
    // clear the previous map boundery on updation.
    // return () => {
    //     aoiLayerGroup.removeLayer(mapBoundery);
    // }
  }, [geoJSONData, map]);

  return null;
}
