// Request methods & other constants.
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
export const CONTENT_TYPE_JSON = "application/json";
export const APPLICATION_OCTET_STREAM = "application/octet-stream";

// Async variable names.
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const USER_ID = "USER_ID";
export const COMP_ID = "COMP_ID";
