import React from "react";
import "./styles.css";

// custom made dropdown menus with toggle functionality. Made with love and care.
export default function Status({
  tableId,
  statusDropDown,
  handleStatusColor,
  tableDataColor,
  statusMenu,
}) {
  const statusType = [
    {
      color: "#E5CF00",
      type: "pending",
      name: "Decision Pending",
      id: 0,
    },
    {
      color: "#FC7900",
      type: "onhold",
      name: "On Hold",
      id: 1,
    },
    {
      color: "#25B900",
      type: "approved",
      name: "Approved",
      id: 2,
    },
    {
      color: "#D10000",
      type: "declined",
      name: "Declined",
      id: 3,
    },
  ];
  return (
    <>
      {statusDropDown === tableId && (
        <div className="dropdown-wrapper">
          <div className="arrow-pointer"></div>
          <ul ref={statusMenu}>
            {statusType.map((status) => {
              return (
                <li
                  key={status.id}
                  className={
                    tableDataColor.dropDownStatus === status.type
                      ? "active-list"
                      : ""
                  }
                  onClick={() =>
                    handleStatusColor(
                      status.color,
                      status.type,
                      tableDataColor,
                      status.name,
                    )
                  }
                >
                  <span style={{ backgroundColor: status.color }}></span>{" "}
                  <p>{status.name}</p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
