// @flow
import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

// Authentication Reducers.
import login from "./login";
import logout from "./logout";
// disabled select pickers reducers.
import disableSelectPickers from "./disableSelectPickers";
// filter table component reducers.
import filterTableComponentReducer from "./filterTableComponent";

// default radio button selection.
import disableRadioButtonReducer from "./defaultRadioButtonSelection";

// for default page.
import defaultPageReducer from "./defaultPage";

// radio button selection.
import radioButtonSelectionReducer from "./storeRadioButtonSelection";

// sage
import notificationHeader from "./notificationHeader";

// to store the report types.
import reportTypesReducer from "./reportTypes";

// Root Reducer.
const rootReducer = combineReducers({
  login: login,
  logout: logout,
  disableSelectPickers: disableSelectPickers,
  filterTableComponentReducer: filterTableComponentReducer,
  disableRadioButtonReducer: disableRadioButtonReducer,
  defaultPageReducer: defaultPageReducer,
  radioButtonSelectionReducer: radioButtonSelectionReducer,
  notificationHeaderReducer: notificationHeader,
  reportTypesReducer: reportTypesReducer,
});
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
