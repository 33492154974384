import { REPORT_TYPES } from "../action/actionConstants";

const initialState = {
  storeReportTypes: [],
};

const reportTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_TYPES:
      return action.payLoad;
    default:
      return state;
  }
};

export default reportTypesReducer;
