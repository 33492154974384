// react
import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";

// plugin
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

// component
import CommonButton from "../../component/common-button/CommonButton";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

//helper
import { loginRsa } from "../login-layout/authentication";
import { changePasswordURL } from "../../common/urls";
import { apiCallChangePassword } from "../../common/connect";
import { rsaEnc } from "../../common/encode-decode";
import { POST } from "../../common/constants";

// style
import "../../layouts/login-layout/login.css";
import passwordhide from "../../assets/images/hide-eye.svg";
import passwordshow from "../../assets/images/show-eye.svg";
import satsureGreyLogo from "../../assets/images/satsure-grey.png";
import { Stack } from "@mui/material";

// use styles
//usestyles code block
const useStyles = makeStyles({
  doneIcon: {
    fontSize: "11px !important",
    paddingTop: "2px",
    marginRight: "4px",
  },
  validationTopRow: {
    paddingBottom: "0.5rem",
    color: "#95969D",
  },
  validationBottomRow: {
    color: "#95969D",
  },
});

const ResetPasswordLayout = (props) => {
  let history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const oneDigit = useRef(0);
  const oneUppercase = useRef(0);
  const eightCharacter = useRef(0);
  const oneSpecialCharacter = useRef(0);
  const [loginLoader, setLoginLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isResetPasswordDisabled, setisResetPasswordDisabled] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [hideShowPassword, sethideShowPassword] = useState(true);
  const [hideShowPassword2, sethideShowPassword2] = useState(true);
  const [rsaKey, setRsaKey] = useState("");

  // calling the rsa token api here
  useEffect(() => {
    loginRsa(successLoginRsaCallBack, failureLoginRsaCallBack);
  }, []);

  // success and failure callbacks for the rsa login api
  const successLoginRsaCallBack = (response) => {
    setRsaKey(response.data.rsa);
  };
  const failureLoginRsaCallBack = (response) => {
    console.log(response);
  };

  function changePassword(
    payload,
    successChangePasswordCallBack,
    failureChangePasswordCallBack,
  ) {
    const url = changePasswordURL();

    const onSuccess = (response) => {
      let notificationDataTable = response.data;
      successChangePasswordCallBack(notificationDataTable);
    };
    const onFailure = (response) => {
      failureChangePasswordCallBack(response);
    };
    apiCallChangePassword(
      POST,
      url,
      payload,
      location.state,
      onSuccess,
      onFailure,
    );
  }
  const successChangePasswordCallBack = (response) => {
    console.log(response, "====== login response");
    setLoginLoader(false);
    setErrorMessage("");
    document.getElementById("second-logo-div-reset-password").style.paddingTop =
      "4.7rem";

    history.push({ pathname: "/", state: "isUpdatedPassword" });
  };

  const failureChangePasswordCallBack = (response) => {
    console.log(response);
    setLoginLoader(false);
    setErrorMessage(response.message);
    document.getElementById("second-logo-div-reset-password").style.paddingTop =
      "2.5rem";
  };

  function hasANumber(string) {
    return Array.prototype.some.call(string, (c) => !isNaN(c) && c.trim());
  }
  function hasUppercaseCharacter(string) {
    return /[A-Z]/.test(string);
  }

  function isMinimumLength(string) {
    if (string.length >= 8) {
      return true;
    } else {
      return false;
    }
  }

  function hasSpecialCharacter(string) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string);
  }

  const triggerResetPassword = () => {
    var changedPassword = {
      // utilised the rsaEnc function here to encrypt the password
      password: rsaEnc(newPassword2, rsaKey),
    };
    changePassword(
      changedPassword,
      successChangePasswordCallBack,
      failureChangePasswordCallBack,
    );
    setLoginLoader(true);
  };
  return (
    <Stack className="login-sections">
      <div className="login-heading">
        <p>Reset Password</p>
      </div>

      <div className="forgot-passwor-sections">
        <ul className="forgot-password-field">
          <li>
            <div className="form-group">
              <label className="group-title">Enter New Password</label>
              <span className="input-feild">
                <input
                  type={hideShowPassword ? "password" : "text"}
                  className={"input-text "}
                  name="password"
                  placeholder="Enter your new password"
                  value={newPassword}
                  onChange={(e) => {
                    const filter =
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()_+=:;"'.,<>|-])[A-Za-z\d@$!%*?&#^()_+=:;"'.,<>|-]{8,}$/;
                    if (hasANumber(e.target.value)) {
                      oneDigit.current.style.color = "green";
                    } else {
                      oneDigit.current.style.color = "#95969D";
                    }

                    if (hasUppercaseCharacter(e.target.value)) {
                      oneUppercase.current.style.color = "green";
                    } else {
                      oneUppercase.current.style.color = "#95969D";
                    }
                    if (isMinimumLength(e.target.value)) {
                      eightCharacter.current.style.color = "green";
                    } else {
                      eightCharacter.current.style.color = "#95969D";
                    }
                    if (hasSpecialCharacter(e.target.value)) {
                      oneSpecialCharacter.current.style.color = "green";
                    } else {
                      oneSpecialCharacter.current.style.color = "#95969D";
                    }
                    if (
                      e.target.value == newPassword2 &&
                      newPassword2.length != 0 &&
                      hasANumber(e.target.value) &&
                      isMinimumLength(e.target.value) &&
                      hasSpecialCharacter(e.target.value) &&
                      hasUppercaseCharacter(e.target.value)
                    ) {
                      setisResetPasswordDisabled(false);
                    } else {
                      setisResetPasswordDisabled(true);
                    }
                    if (
                      e.target.value == newPassword2 &&
                      hasANumber(e.target.value) &&
                      isMinimumLength(e.target.value) &&
                      hasSpecialCharacter(e.target.value) &&
                      hasUppercaseCharacter(e.target.value) &&
                      newPassword2.length != 0 &&
                      newPassword.length != 0
                    ) {
                      document.getElementById(
                        "password-mismatch",
                      ).style.visibility = "hidden";
                    } else {
                      if (newPassword2.length != 0)
                        document.getElementById(
                          "password-mismatch",
                        ).style.visibility = "visible";
                    }
                    setNewPassword(e.target.value.replace(/\s/g, ""));
                  }}
                />
                <img
                  src={hideShowPassword ? passwordhide : passwordshow}
                  alt="passwordLogo"
                  onClick={() => sethideShowPassword((prev) => !prev)}
                  className="passwordIcon"
                />
              </span>
            </div>
          </li>
          <li>
            <div className="pwd-validation-section">
              <div className="pwd-validation-section1">
                <Stack
                  className={classes.validationTopRow}
                  direction="row"
                  ref={oneSpecialCharacter}
                >
                  <DoneOutlinedIcon className={classes.doneIcon} />
                  <p>One special character</p>
                </Stack>

                <Stack
                  className={classes.validationBottomRow}
                  direction="row"
                  ref={oneDigit}
                >
                  <DoneOutlinedIcon className={classes.doneIcon} />
                  <p>One number</p>
                </Stack>
              </div>
              <div className="pwd-validation-section2">
                <Stack
                  className={classes.validationTopRow}
                  direction="row"
                  ref={oneUppercase}
                >
                  <DoneOutlinedIcon className={classes.doneIcon} />
                  <p>One uppercase character</p>
                </Stack>

                <Stack
                  className={classes.validationBottomRow}
                  direction="row"
                  ref={eightCharacter}
                >
                  <DoneOutlinedIcon className={classes.doneIcon} />
                  <p>8 characters minimum</p>
                </Stack>
              </div>
            </div>
          </li>
          <li>
            <div className="form-group">
              <label className="group-title">
                Re-Enter New Password
                <span className="validation-indicator">*</span>
              </label>
              <span className="input-feild">
                <input
                  type={hideShowPassword2 ? "password" : "text"}
                  className={"input-text "}
                  name="password"
                  placeholder="Re-enter your new password"
                  value={newPassword2}
                  onChange={(e) => {
                    if (e.target.value == "" || e.target.value == " ") {
                      document.getElementById(
                        "password-mismatch",
                      ).style.visibility = "hidden";
                    } else {
                      if (
                        e.target.value == newPassword &&
                        hasANumber(e.target.value) &&
                        isMinimumLength(e.target.value) &&
                        hasSpecialCharacter(e.target.value) &&
                        hasUppercaseCharacter(e.target.value)
                      ) {
                        document.getElementById(
                          "password-mismatch",
                        ).style.visibility = "hidden";
                        setisResetPasswordDisabled(false);
                      } else {
                        document.getElementById(
                          "password-mismatch",
                        ).style.visibility = "visible";
                        setisResetPasswordDisabled(true);
                      }
                    }
                    setNewPassword2(e.target.value.replace(/\s/g, ""));
                  }}
                />
                <img
                  src={hideShowPassword2 ? passwordhide : passwordshow}
                  alt="passwordLogo"
                  onClick={() => sethideShowPassword2((prev) => !prev)}
                  className="passwordIcon"
                />
              </span>
            </div>
          </li>
          <li>
            <p id="password-mismatch">Password Mismatch</p>
          </li>
        </ul>

        <div>
          {isResetPasswordDisabled ? (
            <div className={"continue-btn-disabled"}>
              <CommonButton
                btnName={"Reset Password"}
                btnLoader={loginLoader}
              />
            </div>
          ) : (
            <div className={"continue-btn"}>
              <CommonButton
                btnName={"Reset Password"}
                btnCallBack={(e) => {
                  triggerResetPassword();
                }}
                btnLoader={loginLoader}
              />
            </div>
          )}
          <p
            style={{
              color: "red",
              fontStyle: "italic",
              fontSize: "14px",
              paddingTop: "8px",
            }}
          >
            {errorMessage}
          </p>
        </div>
      </div>
      <div
        className="second-logo-div-reset-password"
        id="second-logo-div-reset-password"
      >
        <p>Powered By</p>
        <div className="satsure-logo-div">
          <img src={satsureGreyLogo} />
        </div>
      </div>
    </Stack>
  );
};

export default ResetPasswordLayout;
