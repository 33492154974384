// react
import React, { useState } from "react";
// Loyout
import LogInLayout from "../../layouts/login-layout/LoginLayout";
import BackGroundImage from "../../assets/images/LoginBackgroundImage.png";

import SageLogo from "../../assets/images/Sage-new-logo.svg";
import ForgotPasswordLayout from "../../layouts/forgot-layout/ForgotPasswordLayout";

// Style
import "../login/Login.css";

const ForgotPassword = () => {
  const [key, setKey] = useState(0);
  return (
    <div className="login-page">
      <div className="logo-div">
        <div>
          <img src={SageLogo} alt="loginLogo" className="sage-logo" />
        </div>
      </div>
      <div className="login-layout">
        <ForgotPasswordLayout />
      </div>
    </div>
  );
};

export default ForgotPassword;
