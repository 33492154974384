import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import NewCommonHeaderComponent from "../../common-header-component/NewCommonHeaderComponent";
import ApiExplorerDashboard from "./ApiExplorerDashboard";
import "../monitoringModuleStyles.css";

export default function Apiexplorer() {
  // show/hide the dropdown menu.
  const [shouldShowDropDownMenu, setShouldShowDropDownMenu] = useState(false);
  // for dropdown to show/hide.
  const [showDropDown, setShowDropDown] = useState(false);
  // store the dropdown list names.
  const [shouldStoreListNames, setShouldStoreListNames] =
    useState("Select a value");
  // for active buttons.
  const [activeButton, setActiveButton] = useState(1);
  // Drop Down
  const [anchorEl, setAnchorEl] = React.useState(null);
  // for modal open/close.
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);

  const history = useHistory();

  const handleClick = (event) => {
    setShouldShowDropDownMenu(!shouldShowDropDownMenu);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // legend open/close function
  const handleLegendOpen = () => {
    setShowDropDown(!showDropDown);
  };
  const handleListitem = (items) => {
    console.log(items, "==== items");
    setShouldStoreListNames(items);
    setShowDropDown(false);
  };
  const handleCustomTabs = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };

  const handleCompanyInfoModal = (modalIndex) => {
    setOpenModal(modalIndex + 1);
  };

  // copy to clipboard functionality.
  const handleCopyToClipboard = (copyContent) => {
    const copyDefaultApiKey = copyContent;
    const text = document.createElement("textarea");
    document.body.appendChild(text);
    text.value = copyDefaultApiKey;
    text.select();
    document.execCommand("copy");
    document.body.removeChild(text);
  };

  const handleModalClose = () => setOpenModal(false);

  const modalHeading = "Company Info and Permission JSON";
  const companyInfoModalData = {
    Name: "HDFC Ltd",
    Admins: ["Sugam.m@SatSure.co", "Bhuvan@SatSure.co"],
    Permissions: ["SatSource Report", "Ownership Report"],
  };

  const counters = [
    {
      count: "1023",
      gridContent: "Prod API Requests",
      apiKeyLink: "View Prod Key",
    },
    {
      count: "4004",
      gridContent: "Dashboard Activities",
      apiKeyLink: "",
    },
  ];

  return (
    <div
      className="sage-user-manual-sections"
      style={{ backgroundColor: "#fff" }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className="internal-dashboard-wrapper">
              <NewCommonHeaderComponent
                handleClick={handleClick}
                handleClose={handleClose}
                anchorEl={anchorEl}
                shouldShowDropDownMenu={shouldShowDropDownMenu}
                setShouldShowDropDownMenu={setShouldShowDropDownMenu}
              />

              <ApiExplorerDashboard
                showDropDown={showDropDown}
                handleLegendOpen={handleLegendOpen}
                handleListitem={handleListitem}
                shouldStoreListNames={shouldStoreListNames}
                handleCustomTabs={handleCustomTabs}
                activeButton={activeButton}
                handleCompanyInfoModal={handleCompanyInfoModal}
                openModal={openModal}
                handleModalClose={handleModalClose}
                companyInfoModalData={companyInfoModalData}
                modalHeading={modalHeading}
                counters={counters}
                handleCopyToClipboard={handleCopyToClipboard}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
