import { DISABLE_SELECTPICKER } from "../action/actionConstants";

const initialState = {
  disableState: false,
};

const disableSelectReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISABLE_SELECTPICKER:
      return action.payLoad;
    default:
      return state;
  }
};

export default disableSelectReducer;
