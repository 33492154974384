import React from "react";
import openIcon from "../../assets/images/open.png";
// stylesheet import
import "./map.css";

export default function Maplegends({ handleLegendOpen, showLegend }) {
  return (
    <div className="legends-wrapper">
      {showLegend && (
        <div className="legends-container">
          <ul className="legend-items-wrapper">
            <li>
              <p>
                <span></span>Detected Boundary
              </p>
            </li>
            <li>
              <p>
                <span></span>No Boundary Detected
              </p>
            </li>
            <li>
              <p>
                <span></span>Selected Boundary
              </p>
            </li>
          </ul>
        </div>
      )}
      <div className="legends-inner-content-wrapper" onClick={handleLegendOpen}>
        <h1>Legends</h1>
        <img
          src={openIcon}
          alt="Open Icon"
          className={showLegend ? "rotateIcon" : "reverseIcon"}
        />
      </div>
    </div>
  );
}
