import { sendListData } from "../../common/urls";

import { apiCall } from "../../common/connect";

// constant
import { GET, POST, ACCESS_TOKEN } from "../../common/constants";
import { _getStorageValue } from "../../common/localStorage";

export function sendDataToDb(
  userId,
  villageId,
  surveyId,
  refNo,
  successCallBack,
  failureCallBack,
) {
  const url = sendListData(userId, villageId, surveyId, refNo);
  // console.log()
  const onSuccess = (response) => {
    console.log("success data", response.status);
    successCallBack(response.status);
  };
  const onFailure = (response) => {
    failureCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}
