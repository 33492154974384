import React from "react";
import "./analytics.css";

import { Paper } from "@material-ui/core";
import { Stack } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { PieChart, pieChartDefaultProps } from "react-minimal-pie-chart";

export default function Analyticsdatacount(props) {
  function getHitrate(farmsAnalysed, noBoundryReports) {
    return ((farmsAnalysed / (farmsAnalysed + noBoundryReports)) * 100).toFixed(
      2,
    );
  }

  return (
    <div>
      {props.shouldStoreAnalyticsData.length != 0 && (
        <Paper className="analyticsView" elevation={4}>
          <div className="analytics1">
            <Paper
              style={{
                height: "75px",
                width: "440px",
                display: "flex",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
              }}
              elevation={4}
            >
              <p className="totalNumberLabel">Total Active Admins</p>
              <p className="totalNumberValue">
                {props.shouldStoreAnalyticsData[0]?.activeAdminCount}
              </p>
            </Paper>
            <Paper
              style={{
                height: "75px",
                width: "440px",
                display: "flex",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
              }}
              elevation={4}
            >
              <p className="totalNumberLabel">Total Active Users</p>
              <p className="totalNumberValue">
                {props.shouldStoreAnalyticsData[0]?.activeUserCount}
              </p>
            </Paper>
          </div>
          <div className="analytics2">
            <Stack spacing={3} pt={5}>
              <Paper className="subTopic" direction="column">
                <p className="subtopic_label">Reports Generated</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <CircleIcon
                    style={{
                      height: "12px",
                      paddingTop: "5px",
                      color: "green",
                    }}
                  />
                  <p className="subtopic_label2">
                    {
                      props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                        ?.generatedCount
                    }
                  </p>
                </div>
              </Paper>
              <Paper className="subTopic" direction="column">
                <p className="subtopic_label">Reports In Progress</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <CircleIcon
                    style={{
                      height: "12px",
                      paddingTop: "5px",
                      color: "orange",
                    }}
                  />
                  <p className="subtopic_label2">
                    {
                      props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                        ?.totalInProgress
                    }
                  </p>
                </div>
              </Paper>
              <Paper className="subTopic" direction="column">
                <p className="subtopic_label">Errors</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <CircleIcon
                    style={{ height: "12px", paddingTop: "5px", color: "red" }}
                  />
                  <p className="subtopic_label2">
                    {
                      props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                        ?.totalError
                    }
                  </p>
                </div>
              </Paper>
            </Stack>
            <div>
              <div className="analytics_subheading">
                <p>Total Reports</p>
                <p>
                  (
                  {
                    props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                      ?.totalrequest
                  }
                  )
                </p>
              </div>
              <div className="pieChart">
                <PieChart
                  data={[
                    {
                      title:
                        props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                          ?.totalInProgress,
                      value:
                        props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                          ?.totalInProgress,
                      color: "#FF8A00",
                    }, //YELLOW
                    {
                      title:
                        props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                          ?.totalError,
                      value:
                        props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                          ?.totalError,
                      color: "#D10000",
                    }, //RED
                    {
                      title:
                        props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                          ?.generatedCount,
                      value:
                        props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                          ?.generatedCount,
                      color: "#219653",
                    }, //GREEN
                  ]}
                  animate
                  labelPosition={0}
                  // label={() => {
                  //     return "hello";
                  //     }
                  //   }
                  labelStyle={{
                    fontWeight: 300,
                    fontSize: "0.4rem",
                    fill: "var(--Dark-01)",
                  }}
                  lineWidth={35}
                  radius={pieChartDefaultProps.radius - 9}
                  background="#e5e5e5"
                  viewBoxSize={[100, 100]}
                >
                  <div className="totalReports">
                    <p>Total Reports</p>
                    <p className="totalReportsValue">2344</p>
                  </div>
                </PieChart>
              </div>
            </div>
            <Stack spacing={3} pt={5}>
              <Paper className="subTopic" direction="column">
                <p className="subtopic_label">Total Farms Analysed</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <p className="subtopic_label2">
                    {
                      props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                        ?.farmsAnalysedCount
                    }
                  </p>
                </div>
              </Paper>
              <Paper className="subTopic" direction="column">
                <p className="subtopic_label">No Boundary Reports</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <p className="subtopic_label2">
                    {
                      props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                        ?.noGeometryCount
                    }
                  </p>
                </div>
              </Paper>
              <Paper className="subTopic" direction="column">
                <p className="subtopic_label">Hit Rate</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <p className="subtopic_label2">
                    {getHitrate(
                      props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                        ?.farmsAnalysedCount,
                      props.shouldStoreAnalyticsData[0]?.analyticsCount[0]
                        ?.noGeometryCount,
                    )}
                    %
                  </p>
                </div>
              </Paper>
            </Stack>
          </div>
        </Paper>
      )}
    </div>
  );
}
