import React from "react";
import moment from "moment";

export const Renderstatewiseprodtable = (prodDistrictLevelData) => {
  const columns = [
    {
      name: "DATE",
      label: "DATE",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          const dateUpdation =
            prodDistrictLevelData[value] !== undefined &&
            prodDistrictLevelData[value].date;
          return (
            <>
              <div>
                <p>{moment(dateUpdation).format("DD MMM YYYY")}</p>{" "}
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "0!important",
            textTransform: "capitalize",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "DISTRICT NAME",
      label: "DISTRICT NAME",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          const districtName =
            prodDistrictLevelData[value] !== undefined &&
            prodDistrictLevelData[value].districtName;
          return (
            <>
              <div>
                <span>{districtName}</span>{" "}
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "0!important",
            textTransform: "capitalize",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "DISTRICT RID",
      label: "DISTRICT RID",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          const districtId =
            prodDistrictLevelData[value] !== undefined &&
            prodDistrictLevelData[value].districtId;
          return (
            <>
              <div>{districtId} </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "0!important",
            textTransform: "capitalize",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "VILLAGE NAME",
      label: "VILLAGE NAME",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          const villageName =
            prodDistrictLevelData[value] !== undefined &&
            prodDistrictLevelData[value].villageName;
          return (
            <>
              <div className="text-wrapper">
                <p>{villageName}</p>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "VILLAGE LGD CODE",
      label: "VILLAGE LGD CODE",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          const villageLgdCode =
            prodDistrictLevelData[value] !== undefined &&
            prodDistrictLevelData[value].villageLgdCode;
          return (
            <>
              <div>
                <p>{villageLgdCode === null ? "NA" : villageLgdCode}</p>{" "}
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
            textTransform: "capitalize",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "HIERARCHY COUNT",
      label: "HIERARCHY COUNT",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, index) => {
          const hierarchyCount =
            prodDistrictLevelData[value] !== undefined &&
            prodDistrictLevelData[value].hierarchyCount;
          return (
            <>
              <div>
                <p>{hierarchyCount}</p>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "D1 COUNT",
      label: "D1 COUNT",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          const d1Count =
            prodDistrictLevelData[value] !== undefined &&
            prodDistrictLevelData[value].d1Count;
          return (
            <>
              <div class="checkbox-con">
                <p>{d1Count}</p>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "D2 COUNT",
      label: "D2 COUNT",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          const d2Count =
            prodDistrictLevelData[value] !== undefined &&
            prodDistrictLevelData[value].d2Count;
          return (
            <>
              <div class="checkbox-con">
                <p>{d2Count}</p>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "TOTAL MATCHED ENTRIES (D1 - D2)",
      label: "TOTAL MATCHED ENTRIES (D1 - D2)",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          const matchedEntries =
            prodDistrictLevelData[value] !== undefined &&
            prodDistrictLevelData[value].matchedEntries;
          return (
            <>
              <div class="checkbox-con">
                <p>{matchedEntries}</p>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
  ];
  return columns;
};
