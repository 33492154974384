import React from "react";
import "./add-user.css";

export default function Dropdowncomponents({
  storeMatchingLabels,
  handleDefaultLanding,
  shouldStoreDefaultpage,
  renderStatesBasedOnReportAccess,
  shouldStoreRegionFilterDistricts,
  handleBranchName,
  handleStateFromDropdown,
  handleDistrictChangeFromDropdown,
  shouldStoreCreditHubs,
  shouldStoreDistrictName,
  CustomAutocomplete,
  branch,
  CssTextField,
  stateName,
}) {
  return (
    <div>
      <CustomAutocomplete
        disablePortal
        className="inputField"
        value={shouldStoreDefaultpage}
        disableClearable="true"
        id="combo-box-demo"
        options={storeMatchingLabels()}
        getOptionLabel={(option) => option.page_name || ""}
        sx={{ width: "268px", paddingBottom: "23px" }}
        size="small"
        onChange={(e, value) => handleDefaultLanding(value)}
        ListboxProps={{
          style: {
            maxHeight: "250px",
            border: "1px solid dedede",
            overflowY: "auto",
            fontSize: "12px",
            width: "268px",
            textTransform: "capitalize",
          },
        }}
        renderInput={(params) => (
          <CssTextField
            size="medium"
            InputProps={{
              ...params.InputProps,
              style: { fontSize: "18px !important", width: "268px" },
            }}
            {...params}
            label="Select Default Landing"
          />
        )}
      />
      <CustomAutocomplete
        className="inputField"
        disablePortal
        disableClearable
        sx={{ width: "268px", paddingBottom: "23px" }}
        size="small"
        closeicon=""
        value={stateName}
        options={renderStatesBasedOnReportAccess()}
        getOptionLabel={(option) => option.name || ""}
        noOptionsText="No Matching Keyword"
        onChange={(e, value, reason) => handleStateFromDropdown(value, reason)}
        ListboxProps={{
          style: {
            maxHeight: "250px",
            border: "1px solid dedede",
            overflowY: "auto",
            fontSize: "12px",
            width: "268px",
          },
        }}
        renderInput={(params) => (
          <CssTextField
            size="medium"
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: "18!important",
                width: "268px",
              },
            }}
            {...params}
            label="Select State"
          />
        )}
      />
      <CustomAutocomplete
        className="inputField"
        disablePortal
        disableClearable="true"
        value={shouldStoreDistrictName}
        sx={{ width: "268px", paddingBottom: "23px" }}
        size="small"
        closeicon=""
        options={shouldStoreRegionFilterDistricts}
        noOptionsText="No Matching Keyword"
        onChange={(e, value, reason) =>
          handleDistrictChangeFromDropdown(value, reason)
        }
        ListboxProps={{
          style: {
            maxHeight: "250px",
            border: "1px solid dedede",
            overflowY: "auto",
            fontSize: "12px",
            width: "268px",
          },
        }}
        renderInput={(params) => (
          <CssTextField
            size="medium"
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: "18!important",
                width: "268px",
              },
            }}
            {...params}
            label="Select District"
          />
        )}
      />

      <CustomAutocomplete
        disablePortal
        disableClearable="true"
        className="inputField"
        id="combo-box-demo"
        options={shouldStoreCreditHubs}
        sx={{ width: "268px", paddingBottom: "23px" }}
        size="small"
        value={branch}
        onChange={(e, value, reason) => handleBranchName(value, reason)}
        ListboxProps={{
          style: {
            maxHeight: "250px",
            border: "1px solid dedede",
            overflowY: "auto",
            fontSize: "12px",
            width: "268px",
          },
        }}
        renderInput={(params) => (
          <CssTextField
            size="medium"
            InputProps={{
              ...params.InputProps,
              style: { fontSize: "18px !important", width: "268px" },
            }}
            {...params}
            label="Select Credit Hub"
          />
        )}
      />
    </div>
  );
}
