import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "../../adminPortal.css";
import Button from "@mui/material/Button";
import { withStyles } from "@material-ui/core/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
// date picker imports.
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./adminSidePanel.css";

const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: "transparent",
    // border: "1px solid #3342B5",
    height: 24,
    position: "relative",
    fontStyle: "italic",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "#000",
    },
    "& .MuiChip-deleteIcon": {
      color: "#3342B5",
    },
    "&:after": {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
})(Autocomplete);

const CssTextField = styled(TextField)({
  "& .MuiInputLabel-outlined": {
    fontSize: "11px",
    top: "-3px",
    zIndex: "0 !important",
  },
  "& .MuiInputBase-formControl": {
    padding: "0",
  },
});

const filterButtonStyles = {
  width: "100%",
  bgcolor: "#3342B5",
  texttransform: "capitalize",
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 170,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dedede",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
    zIndex: "50",
  },
}));

const Admincommonsidepanel = ({
  shouldStoreStates,
  handleUserSearch,
  handleSearchFilter,
  handleStateSelection,
  handleFilterDropDownReportAccess,
  shouldEnableDisableButton,
  handleCreditHubSelection,
  startDate,
  endDate,
  handleDateRange,
  shouldStoreCreditHubNames,
  shouldStoreCreditHubs,
  shouldStoreStatesFromDropdown,
}) => {
  // get the page permission from the local storage.
  const getPagePermissions = JSON.parse(
    localStorage.getItem("defaultRadioButtonSelection"),
  );

  let history = useHistory();
  // clear
  const clearTrigger = () => {
    window.location.reload();
  };

  // for the other two dropdowns. (state and credit hubs)
  const filterOptions = (options, { inputValue }) => {
    // Filter the options based on the inputValue (user's search query)
    return options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  // for the report access dropdown.
  const filterOptionsForReportAccess = (options, { inputValue }) => {
    // Filter the options based on the inputValue (user's search query)
    return options.filter((option) =>
      option.page_name.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  function renderStates() {
    const states = shouldStoreStates.map((items) => {
      return items.name;
    });

    return states;
  }

  return (
    <div className="side-panel-filter-wrapper">
      <div className="input-wrapper">
        {history.location.pathname === "/adminMain" ? (
          <input
            type="search"
            placeholder="Search"
            className="search-field"
            onChange={(e) => handleUserSearch(e.target.value)}
          />
        ) : history.location.pathname === "/dataManagement" ? (
          <HtmlTooltip
            title={
              <React.Fragment>
                <p>Search by Reference Id or Requested By</p>
              </React.Fragment>
            }
            arrow
            placement="bottom"
          >
            <input
              type="search"
              placeholder="Minimum 3 characters"
              className="search-field"
              onChange={(e) => handleUserSearch(e.target.value)}
            />
          </HtmlTooltip>
        ) : null}
      </div>
      <div className="search-filter-wrapper">
        <h1>
          FILTERS<span onClick={clearTrigger}>Clear All</span>
        </h1>
        <div className="filter-dropdowns">
          {(history.location.pathname === "/dataManagement" ||
            history.location.pathname === "/analytics") && (
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              selectsRange={true}
              dateFormat="dd/MMM/yyyy"
              placeholderText="Select Date Range"
              isClearable={true}
              monthsShown={2}
              onChange={(update) => handleDateRange(update)}
              maxDate={moment().toDate()}
            />
          )}
          <Autocomplete
            disablePortal
            filterOptions={filterOptions}
            id="combo-box-demo"
            options={renderStates()}
            value={shouldStoreStatesFromDropdown}
            disableClearable={!shouldStoreStatesFromDropdown && true}
            sx={{ width: "auto", marginBottom: "15px" }}
            onChange={(e, value, reason) => handleStateSelection(value, reason)}
            ListboxProps={{
              style: {
                maxHeight: "100px",
                border: "1px solid dedede",
                overflowY: "auto",
                fontSize: "12px",
              },
            }}
            renderInput={(params) => (
              <CssTextField
                size="small"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: "12px !important",
                    textTransform: "uppercase",
                  },
                }}
                {...params}
                label="Select State"
              />
            )}
          />
          <div className="custom-input-wrapper">
            <Autocomplete
              disablePortal
              filterOptions={filterOptions}
              value={shouldStoreCreditHubs}
              id="combo-box-demo"
              options={shouldStoreCreditHubNames}
              sx={{ width: "auto", marginBottom: "15px" }}
              disableClearable={!shouldStoreCreditHubs && true}
              onChange={(e, value, reason) =>
                handleCreditHubSelection(value, reason)
              }
              ListboxProps={{
                style: {
                  maxHeight: "100px",
                  border: "1px solid dedede",
                  overflowY: "auto",
                  fontSize: "12px",
                  textTransform: "capitalize",
                },
              }}
              renderInput={(params) => (
                <CssTextField
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: "12px !important" },
                  }}
                  {...params}
                  label="Select Credit Hub"
                />
              )}
            />
          </div>
          {history.location.pathname === "/adminMain" ? (
            <CustomAutocomplete
              disablePortal
              multiple
              filterOptions={filterOptionsForReportAccess}
              limitTags={1}
              id="combo-box-demo"
              disableClearable
              options={getPagePermissions}
              getOptionLabel={(option) => option.page_name}
              onChange={(e, value, reason) =>
                handleFilterDropDownReportAccess(value, reason)
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {option.page_name}
                </li>
              )}
              sx={{ width: "auto", marginBottom: "15px" }}
              ListboxProps={{
                style: {
                  maxHeight: "100px",
                  border: "1px solid dedede",
                  overflowY: "auto",
                  fontSize: "10px",
                  textTransform: "capitalize",
                },
              }}
              renderInput={(params) => (
                <CssTextField
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: "12px !important" },
                  }}
                  {...params}
                  label="Select Report Access"
                />
              )}
            />
          ) : history.location.pathname === "/analytics" ? (
            <Autocomplete
              disablePortal
              filterOptions={filterOptionsForReportAccess}
              id="combo-box-demo"
              options={getPagePermissions}
              getOptionLabel={(option) => option.page_name}
              sx={{ width: "auto", marginBottom: "15px" }}
              onChange={(e, value, reason) =>
                handleFilterDropDownReportAccess(value, reason)
              }
              ListboxProps={{
                style: {
                  maxHeight: "100px",
                  border: "1px solid dedede",
                  overflowY: "auto",
                  fontSize: "10px",
                  textTransform: "capitalize",
                },
              }}
              renderInput={(params) => (
                <CssTextField
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: "12px !important" },
                  }}
                  {...params}
                  label="Select Report Access"
                />
              )}
            />
          ) : null}
          <Button
            variant="contained"
            size="large"
            sx={filterButtonStyles}
            disabled={shouldEnableDisableButton}
            onClick={() => handleSearchFilter()}
          >
            Apply Filter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Admincommonsidepanel;
