import React from "react";
import { useSelector } from "react-redux";
// import images
import regenarateReport from "../../assets/images/clarity_note-edit-line.svg";
import raiseQuery from "../../assets/images/carbon_query.svg";
import mapView from "../../assets/images/carbon_map.svg";
import archieve from "../../assets/images/archieve.svg";
import "./styles.css";

// state less pure function component.
export default function Dropdownmenu({
  anchorEl,
  tableData,
  handleQuery,
  handleArchieve,
  handleReportRegeneration,
  storeRefNo,
  addActive,
  storeRequestId,
  storeTableRowData,
  handleMapView,
  surveyDetails,
  dropDownOptions,
}) {
  // array to store the list items
  let archiveButtonActiveColor = useSelector(
    (s) => s.filterTableComponentReducer.archiveButtonActiveColor,
  );
  const listItems = [
    {
      list: "Map View",
      listImage: mapView,
      listFunction: handleMapView,
      index: 0,
    },
    {
      list: "Regenerate Report",
      listImage: regenarateReport,
      listFunction: handleReportRegeneration,
      index: 1,
    },
    {
      list: "Raise a Query",
      listImage: raiseQuery,
      listFunction: handleQuery,
      index: 2,
    },
    {
      list: "Archive",
      listImage: archieve,
      listFunction: handleArchieve,
      index: 3,
    },
  ];
  return (
    <>
      {anchorEl === tableData && (
        <ul className="custom-dropdown" ref={dropDownOptions}>
          <li className="arrow-pointer"></li>
          {listItems.map((items) => {
            if (archiveButtonActiveColor == true && items.list == "Archive") {
              return null;
            } else {
              return (
                <li
                  onClick={() => {
                    items.listFunction(
                      storeTableRowData,
                      storeRefNo,
                      storeRequestId,
                      items.index,
                      surveyDetails,
                    );
                  }}
                  key={items.index}
                  className={
                    addActive === items.index ? "custom-menu-list" : ""
                  }
                >
                  <img src={items.listImage} alt="List Images" />
                  <p className="custom-dropdown-content">{items.list}</p>
                </li>
              );
            }
          })}
        </ul>
      )}
    </>
  );
}
