// react
import React, { useState } from "react";
// Loyout
import DashboardLayout from "../../layouts/dashboard-layout/DashboardLayout";

// Style

const Dashboard = () => {
  const [key, setKey] = useState(0);
  return (
    <div className="home-sections">
      <DashboardLayout />
    </div>
  );
};

export default Dashboard;
