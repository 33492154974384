import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./add-user.css";
import Adduser from "./Add-user";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Adminsidepanel from "../admin-sidepanel";
import HeaderLayoutComp from "../../common-header-component/header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// plugin
import { useHistory } from "react-router-dom";
import { debounce, isEmpty } from "lodash";
import { getRegions } from "../../filter-component/filterfun";
import { _getStorageValue, getCompId } from "../../../common/localStorage";
import { USER_ID } from "../../../common/constants";
import { addUser } from "../adminApi";
import Successmessagemodal from "./Successmessagemodal";
import { userManagementTableData, creditHubDataApi } from "../adminApi";

var storeDefaultPage;

export default function AddUser() {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [tabNav, setTabNav] = useState("");
  const [role, setRole] = useState("");
  const [shouldStoreDefaultpage, setShouldStoreDefaultPage] = useState("");
  const [reportAccess, setReportAccess] = useState([]);
  const [stateName, setStateName] = useState({});
  const [district, setDistrict] = useState([]);
  const [branch, setBranch] = useState("");
  // state to store the input values.
  const [inputValues, setInputValues] = useState([]);
  // to store the district name from the dropdown change function.
  const [shouldStoreDistrictName, setShouldStoreDistrictName] = useState("");
  // form validation error message.
  const [shouldShowValidationMessage, setShouldShowValidationMessage] =
    useState(false);
  // store the employee id.
  const [shouldStoreEmpId, setShouldStoreEmpId] = useState("");

  // show user creation toaster message.
  const [shouldShowUserCreationMessage, setShouldShowUserCreationMessage] =
    useState(false);
  // store the designation.
  const [shouldStoreDesignation, setShouldStoreDesignation] = useState("");
  // state to show a validation message incase of any duplicate entry for email or phone number.
  const [showDuplicateValidation, setShowDuplicateValidation] = useState(false);
  // store the user states.
  const [shouldStoreUserStates, setShouldStoreUserStates] = useState([]);
  // store the districts from the region filter api.
  const [
    shouldStoreRegionFilterDistricts,
    setShouldStoreUserRegionFilterDistricts,
  ] = useState([]);
  // store the states from the region filter api.
  const [storeRegionFilterStates, shouldStoreRegionFilterStates] = useState([]);
  // store the credit hubs.
  const [shouldStoreCreditHubs, setShouldStoreCreditHubs] = useState([]);

  let history = useHistory();

  useEffect(() => {
    let path = window.location.pathname;
    setTabNav(path);
  }, []);

  //fetch page permission to pass it as a query parameter in region API
  const getDefaultRadioButtonSelection =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];
  let pageName = "";
  getDefaultRadioButtonSelection.forEach((items) => {
    if (items.default_page === true) {
      pageName = items.page_name;
    }
  });

  // region api to generate the states.
  useEffect(() => {
    const storeStateRegionId = "27841bce-248f-4342-a78d-b6d817dceb24";
    _getStorageValue(USER_ID).then((user_id) => {
      getRegions(
        storeStateRegionId,
        pageName,
        successRegionCallBack,
        failureFailureCallBack,
      );
    });
  }, []);

  const successRegionCallBack = (response) => {
    shouldStoreRegionFilterStates(response.regions);
  };

  const failureFailureCallBack = (error) => {
    console.log(error, "====== error response");
  };

  // user management table data api.
  useEffect(() => {
    _getStorageValue(USER_ID).then(() => {
      userManagementTableData(
        successUserManagementCallBack,
        failureUserManagementLandCallBack,
        shouldStoreDefaultpage,
      );
    });
  }, []);

  const successUserManagementCallBack = (response) => {
    setShouldStoreUserStates(response.states);
  };

  const failureUserManagementLandCallBack = (error) => {
    console.log(error, "====== user management table data error");
  };

  // on change function for the input fields.
  const handleAddUserFormOneInput = debounce((e, type, keys) => {
    setShouldShowValidationMessage(false);
    setShowDuplicateValidation(false);
    if (type === "email") {
      let filter = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      if (e.target.value.trim() === "") {
        document.getElementById("emailInvalid").style.display = "none";
      } else {
        if (filter.test(e.target.value)) {
          document.getElementById("emailInvalid").style.display = "none";
          setInputValues((prev) => ({
            ...prev,
            [keys]: e.target.value.trim(),
          }));
        } else {
          document.getElementById("emailInvalid").style.display = "block";
        }
      }
    } else if (type === "phoneNo") {
      let filter = /^[0-9]{10}$/;
      let trimmedValue = e.target.value.trim();
      if (trimmedValue === "") {
        document.getElementById("phoneInvalid").style.display = "none";
      } else {
        if (filter.test(trimmedValue)) {
          document.getElementById("phoneInvalid").style.display = "none";
          setInputValues((prev) => ({
            ...prev,
            [keys]: trimmedValue,
          }));
        } else {
          document.getElementById("phoneInvalid").style.display = "block";
        }
      }
    } else if (type === "empid") {
      if (e.target.value.trim() === "") {
        document.getElementById("invalidTextField3").style.display = "none";
      } else {
        const name = e.target.value.trim();
        const specialChars = /[!@#$%^&*(),.?":{}|<>]/;

        if (name.charAt(0) === " " || specialChars.test(name)) {
          document.getElementById("invalidTextField3").style.display = "block";
        } else {
          setShouldStoreEmpId(e.target.value);
        }
      }
    } else if (type === "designation") {
      if (e.target.value.trim() === "") {
        document.getElementById("invalidTextField4").style.display = "none";
      } else {
        const name = e.target.value.trim();
        const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
        const digits = /\d/;

        if (
          name.charAt(0) === " " ||
          specialChars.test(name) ||
          digits.test(name)
        ) {
          document.getElementById("invalidTextField4").style.display = "block";
        } else {
          document.getElementById("invalidTextField4").style.display = "none";
          setShouldStoreDesignation(e.target.value);
        }
      }
    } else if (type === "name") {
      if (e.target.value.trim() === "") {
        document.getElementById("invalidTextField0").style.display = "none";
      } else {
        const name = e.target.value.trim();
        const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
        const digits = /\d/;

        if (
          name.charAt(0) === " " ||
          specialChars.test(name) ||
          digits.test(name)
        ) {
          document.getElementById("invalidTextField0").style.display = "block";
        } else {
          document.getElementById("invalidTextField0").style.display = "none";
          setInputValues((prev) => ({
            ...prev,
            [keys]: name,
          }));
        }
      }
    } else {
      setInputValues((prev) => ({
        ...prev,
        [keys]: e.target.value,
      }));
    }
  }, 500);

  // on key press function to add rigid validations on the add user input fields.
  function validateInput(formLabelType, event) {
    const key = event.key;
    const inputElement = event.target;

    // Check if the entered key is a letter (a-z or A-Z)
    if (formLabelType === "name" || formLabelType === "designation") {
      if (key === " " && inputElement.value.trim() === "") {
        event.preventDefault(); // Prevent space at the beginning
        return false;
      } else if (/[a-zA-Z\s]/.test(key)) {
        return true; // Allow the input
      } else {
        // If the entered key is a special character or number, prevent the input
        event.preventDefault();
        return false;
      }
    } else if (formLabelType === "phoneNo") {
      let phoneNumberKey = event.key;
      if (!/^\d$/.test(phoneNumberKey) && phoneNumberKey !== "Backspace") {
        event.preventDefault();
        return false;
      } else {
        const currentPhoneNumber = inputElement.value.replace(/\D/g, ""); // Remove non-numeric characters
        if (currentPhoneNumber.length >= 10 && phoneNumberKey !== "Backspace") {
          event.preventDefault();
          return false;
        }
        return true;
      }
    } else if (formLabelType === "email") {
      // Check if the entered key is a letter (a-z or A-Z), number, or allowed symbols
      if (/[a-zA-Z0-9@._-]/.test(key)) {
        // Disallow space at the beginning
        if (key === " " && inputElement.value.trim() === "") {
          event.preventDefault();
          return false;
        }
        return true; // Allow the input
      } else {
        // If the entered key is a special character, prevent the input
        event.preventDefault();
        return false;
      }
    }
  }

  // function to handle the default landing selection.
  const handleDefaultLanding = (value) => {
    setShouldStoreDefaultPage(value);
    setShouldShowValidationMessage(false);
    setShowDuplicateValidation(false);
  };

  const succesDistrictCallBack = (response) => {
    const storeFilteredDistrictsName = [];
    response.regions.filter((district) =>
      storeFilteredDistrictsName.push(district.name),
    );
    setShouldStoreUserRegionFilterDistricts(storeFilteredDistrictsName);
  };

  const failureDistrictCallBack = (error) => {
    console.log(error, "====== error response");
  };

  // function to handle the role change.
  const handleRoleChange = (value) => {
    setRole(value.id);
    setShouldShowValidationMessage(false);
    setShowDuplicateValidation(false);
  };

  // function to handle the report access.
  const handleReportAccess = (value, reason) => {
    setReportAccess(value);
    setShouldShowValidationMessage(false);
    if (reason === "removeOption") {
      setShouldStoreDefaultPage("");
      setStateName({});
      setBranch("");
      setShouldStoreDistrictName("");
      setShouldStoreCreditHubs([]);
      setShouldStoreUserRegionFilterDistricts([]);
    }
  };

  // function to handle the state names.
  const handleStateFromDropdown = (value, reason) => {
    setBranch("");
    setShouldStoreDistrictName("");
    let state = value.name;
    if (state?.indexOf(" ") !== -1) {
      // if the state name contains a space, replace spaces with %20
      state = state?.replace(/\s+/g, "%20");
    }
    // fetch the company id from the local storage.
    const storeCompanyId = parseInt(localStorage.getItem("companyId"));

    _getStorageValue(USER_ID).then(() => {
      creditHubDataApi(
        storeCompanyId,
        state,
        successCreditHubCallBack,
        failureCreditHubCallBack,
      );
    });
    setStateName({
      name: value.name,
    });
    setShouldShowValidationMessage(false);
    setShowDuplicateValidation(false);
    _getStorageValue(USER_ID).then((user_id) => {
      getRegions(
        value.id,
        pageName,
        succesDistrictCallBack,
        failureDistrictCallBack,
      );
    });

    const successCreditHubCallBack = (response) => {
      const fetchTheCreditHubs = [];
      response.data.forEach((creditHubs) => {
        fetchTheCreditHubs.push(creditHubs.credit_hub);
      });
      setShouldStoreCreditHubs(fetchTheCreditHubs);
    };
    const failureCreditHubCallBack = (error) => {
      console.log(error, "===== error calling credit hub api");
    };
  };

  // function to handle the districts from the dropdown.
  const handleDistrictChangeFromDropdown = (value, reason) => {
    setShouldStoreDistrictName(value);
    setShouldShowValidationMessage(false);
    setShowDuplicateValidation(false);
  };

  // function to handle the branch name.
  const handleBranchName = (value) => {
    setBranch(value);
    setShouldShowValidationMessage(false);
    setShowDuplicateValidation(false);
  };

  // form on submit function.
  const onSubmit = () => {
    // checking if all the values in the input values object is not empty or undefined.
    const inputValuesFilled = Object.keys(inputValues).every((key) => {
      return inputValues[key] !== "" && inputValues[key] !== undefined;
    });

    if (
      inputValuesFilled &&
      role !== "" &&
      reportAccess.length > 0 &&
      shouldStoreDefaultpage !== "" &&
      Object.keys(stateName).length > 0 &&
      shouldStoreDistrictName !== "" &&
      branch !== ""
    ) {
      // remove the label key and value from the report access array.
      const filteredArray = reportAccess.map(
        ({ page_name, user_id, ...rest }) => rest,
      );

      if (
        shouldStoreDefaultpage.page_name === "satsource" ||
        shouldStoreDefaultpage.page_name === "satsource & ownership" ||
        shouldStoreDefaultpage.page_name === "ownership"
      ) {
        storeDefaultPage = true;
      }

      // below I am checking if the page no matches with shouldStoreDefaultpage.defaultpageno value. If it does match then I am updating the default value with dynamic stored default page value also I am returning true outside the if block so that it indludes all the items in the filteredReportAccessData array.
      const filteredReportAccessData = filteredArray.filter((items) => {
        if (items.page_no === shouldStoreDefaultpage.page_no) {
          items.default = storeDefaultPage;
        }
        return true;
      });

      let branch_capitalised;
      if (branch.toLowerCase() === "satsure") {
        branch_capitalised = "SatSure";
        setBranch(branch_capitalised);
      } else {
        branch_capitalised = branch;
      }

      const requestBody = {
        email: inputValues.email,
        username: inputValues.username,
        phone: inputValues.phone,
        company_id: parseInt(getCompId()),
        page_access: filteredReportAccessData,
        attributes: {
          state: stateName.name,
          designation: shouldStoreDesignation,
          employee_Id: shouldStoreEmpId,
          branch: branch_capitalised,
          userRole: parseInt(role),
          district: shouldStoreDistrictName,
        },
      };

      _getStorageValue(USER_ID).then((user_id) => {
        addUser(
          requestBody,
          successUserCreationCallBack,
          failureUserCreationCallBack,
        );
      });
    } else {
      setShouldShowValidationMessage(true);
    }
  };

  const successUserCreationCallBack = (response) => {
    setShouldShowUserCreationMessage(true);
    setShouldShowValidationMessage(false);
    setTimeout(() => {
      history.push("/adminMain");
    }, 2000);
  };

  const failureUserCreationCallBack = (error) => {
    setShowDuplicateValidation(true);
  };

  return (
    <>
      <div className="sage-user-manual-sections">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Adminsidepanel />
            </Grid>
            <Grid item xs={10}>
              <div className="sage-user-report-wrapper">
                <HeaderLayoutComp tabNav={tabNav} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    paddingLeft: "40px",
                  }}
                >
                  <ArrowBackIcon
                    style={{
                      color: "#3342B5",
                      paddingTopsetReportAccess: "7px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      history.push("/adminMain");
                    }}
                  />
                  <div>
                    <p
                      size="medium"
                      style={{
                        color: "#3342B5",
                        paddingTop: "0px !important",
                        textTransform: "capitalize",
                        fontWeight: "600",
                        textIndent: "15px",
                      }}
                    >
                      Add User
                    </p>
                  </div>
                </div>
                <div className="page-container" style={{ overflow: "hidden" }}>
                  <div className="slide-container">
                    <Adduser
                      onSubmit={onSubmit}
                      handleSubmit={handleSubmit}
                      reportAccess={reportAccess}
                      setRole={setRole}
                      handleAddUserFormOneInput={handleAddUserFormOneInput}
                      handleRoleChange={handleRoleChange}
                      handleBranchName={handleBranchName}
                      district={district}
                      shouldShowValidationMessage={shouldShowValidationMessage}
                      showDuplicateValidation={showDuplicateValidation}
                      shouldStoreUserStates={shouldStoreUserStates}
                      shouldStoreRegionFilterDistricts={
                        shouldStoreRegionFilterDistricts
                      }
                      stateName={stateName}
                      storeRegionFilterStates={storeRegionFilterStates}
                      shouldStoreCreditHubs={shouldStoreCreditHubs}
                      shouldStoreDefaultpage={shouldStoreDefaultpage}
                      handleDefaultLanding={handleDefaultLanding}
                      handleReportAccess={handleReportAccess}
                      branch={branch}
                      shouldStoreDistrictName={shouldStoreDistrictName}
                      handleStateFromDropdown={handleStateFromDropdown}
                      handleDistrictChangeFromDropdown={
                        handleDistrictChangeFromDropdown
                      }
                      validateInput={validateInput}
                    />
                  </div>
                  {shouldShowUserCreationMessage && <Successmessagemodal />}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
