import React from "react";
import pencil_icon from "../../assets/images/pencil_edit.svg";
import eyeImage from "../../assets/images/visibility.svg";
// Export the renderText function
export const renderText = (text) => {
  if (text === "satsure") {
    const satsureText = "Sat" + "Sure";
    return satsureText;
  } else {
    return text;
  }
};

export const Rendercolumns = (
  shouldStoreUserManagementData,
  handleInputChange,
  handleViewUserModal,
  handleEditUserModal,
  shouldShowSwitchLoader,
  shouldStoreSelectedUserDetails,
) => {
  // function to caplitalize the first letter in each word.
  function capitalizeWords(str) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, function (firstLetter) {
      return firstLetter.toUpperCase();
    });
  }

  const columns = [
    {
      name: "NAME",
      label: "NAME",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <>
              <div>
                <div>
                  {shouldStoreUserManagementData[value].userName === ""
                    ? "NA"
                    : shouldStoreUserManagementData[value].userName}{" "}
                </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "0!important",
            textTransform: "capitalize",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "STATE",
      label: "STATE",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <>
              <div>
                <div>
                  <span>
                    {" "}
                    {shouldStoreUserManagementData[value].state === null
                      ? "NA"
                      : capitalizeWords(
                          shouldStoreUserManagementData[value].state,
                        )}{" "}
                  </span>{" "}
                </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "0!important",
            textTransform: "capitalize",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "CREDIT HUB",
      label: "CREDIT HUB",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <>
              <div>
                <div>
                  {shouldStoreUserManagementData[value].userBranch === null ||
                  shouldStoreUserManagementData[value].userBranch === ""
                    ? "NA"
                    : renderText(
                        shouldStoreUserManagementData[value].userBranch,
                      )}{" "}
                </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "0!important",
            textTransform: "capitalize",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "REPORT ACCESS",
      label: "REPORT ACCESS",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          const nameReplacements = {
            satsource: "SatSource",
            "satsource & ownership": "SatSource,Ownership",
          };
          return (
            <>
              <div>
                <div className="text-wrapper">
                  {shouldStoreUserManagementData[value]?.reportAccess ===
                    null ||
                  shouldStoreUserManagementData[value]?.reportAccess.length ===
                    0 ? (
                    <span>NA</span>
                  ) : (
                    shouldStoreUserManagementData[value]?.reportAccess.map(
                      (reports, index) => {
                        return (
                          <span key={index}>
                            {nameReplacements[reports] || reports}
                          </span>
                        );
                      },
                    )
                  )}
                </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "DESIGNATION",
      label: "DESIGNATION",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <>
              <div>
                <div>
                  {shouldStoreUserManagementData[value].designation === null ||
                  shouldStoreUserManagementData[value].designation === ""
                    ? "NA"
                    : shouldStoreUserManagementData[value].designation}{" "}
                </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
            textTransform: "capitalize",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "VIEW/EDIT",
      label: "VIEW/EDIT",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, index) => {
          return (
            <>
              <div className="image-wrapper">
                <div className="image-holder">
                  <img
                    src={eyeImage}
                    alt="eye image"
                    onClick={() =>
                      handleViewUserModal(shouldStoreUserManagementData[value])
                    }
                  />{" "}
                  <img
                    src={pencil_icon}
                    alt="pencil icon"
                    onClick={() =>
                      handleEditUserModal(shouldStoreUserManagementData[value])
                    }
                  />{" "}
                </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
    {
      name: "ACTION",
      label: "ACTION",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, index) => {
          return (
            <>
              <div class="checkbox-con">
                {shouldShowSwitchLoader &&
                  shouldStoreSelectedUserDetails.userId ===
                    shouldStoreUserManagementData[value].userId && (
                    <div class="toggle-switch-loader">
                      <p>Loading...</p>
                    </div>
                  )}
                <input
                  id="checkbox"
                  disabled={
                    shouldStoreUserManagementData[value].lastLogin === null &&
                    shouldStoreUserManagementData[value].activeStatus === false
                  }
                  type="checkbox"
                  checked={shouldStoreUserManagementData[value].activeStatus}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      shouldStoreUserManagementData[value],
                      index,
                    )
                  }
                  style={
                    shouldStoreUserManagementData[value].lastLogin === null &&
                    shouldStoreUserManagementData[value].activeStatus === false
                      ? { opacity: "0.4", cursor: "not-allowed" }
                      : { opacity: "10" }
                  }
                />
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#E0E0E0",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
          },
        }),
      },
    },
  ];
  return columns;
};
