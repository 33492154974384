import React from "react";
import { Redirect, Route } from "react-router-dom";
import { _getStorageValue, getCompId } from "../common/localStorage";

import { userLoggedIn } from "../common/common";

const PrivateRouteForAdminCheck = ({ component: Component, ...rest }) => {
  const whetherUserLoggedIn = userLoggedIn();
  // get the user access level from local storage.
  const getUserAccessLevel = parseInt(
    localStorage.getItem("user access level"),
  );

  const isValid = getUserAccessLevel === 1 || getUserAccessLevel === 3;

  return (
    <Route
      {...rest}
      render={(props) =>
        whetherUserLoggedIn && isValid ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

export default PrivateRouteForAdminCheck;
