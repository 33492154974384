// react
import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";

// plugin
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

// Action
import { login, loginRsa } from "./authentication";
import { logout } from "./authentication";
import { changePasswordURL } from "../../common/urls";
import { _getStorageValue } from "../../common/localStorage";
import { apiCall, apiCallChangePassword } from "../../common/connect";
import { ACCESS_TOKEN, POST } from "../../common/constants";
import { userLoggedIn } from "../../common/common";

// component
import CommonButton from "../../component/common-button/CommonButton";

// encode-decode
import { rsaEnc } from "../../common/encode-decode";

// style
import "../../layouts/login-layout/login.css";

// Images
import satsureGreyLogo from "../../assets/images/satsure-grey.png";
import loginLogo from "../../assets/images/loginLogo.png";

import passwordhide from "../../assets/images/common/eye-close.svg";
import passwordshow from "../../assets/images/common/eye.svg";
import { Stack } from "@mui/material";

//usestyles code block
const useStyles = makeStyles({
  doneIcon: {
    fontSize: "11px !important",
    paddingTop: "2px",
    marginRight: "4px",
  },
  validationTopRow: {
    paddingBottom: "0.5rem",
    color: "#95969D",
  },
  validationBottomRow: {
    color: "#95969D",
  },
});

const ChangePassword = () => {
  userLoggedIn();
  let history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const oneDigit = useRef(0);
  const oneUppercase = useRef(0);
  const eightCharacter = useRef(0);
  const oneSpecialCharacter = useRef(0);
  const [newPassword2, setNewPassword2] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [hideShowOTP, sethideShowOTP] = useState(true);
  const [disableBTN, setdisableBTN] = useState(true);
  const [loginLoader, setLoginLoader] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [hideShowPassword2, sethideShowPassword2] = useState(true);
  // state to store the rsa key
  const [rsaKey, setRsaKey] = useState("");

  function changePassword(
    payload,
    successChangePasswordCallBack,
    failureChangePasswordCallBack,
  ) {
    const url = changePasswordURL();

    const onSuccess = (response) => {
      let notificationDataTable = response.data;
      successChangePasswordCallBack(notificationDataTable);
    };
    const onFailure = (response) => {
      failureChangePasswordCallBack(response);
    };
    apiCallChangePassword(
      POST,
      url,
      payload,
      location.state,
      onSuccess,
      onFailure,
    );
  }
  const successChangePasswordCallBack = (response) => {
    console.log(response, "====== login response");
    setLoginLoader(false);
    localStorage.setItem("activeStatus", "true");
    userLoggedIn();

    logout(successLogoutCallBack, failureLogoutCallBack);
  };

  const successLogoutCallBack = (response) => {
    setTimeout(() => {
      history.push({ pathname: "/", state: "isNewUser" });
    }, 100);
  };
  const failureLogoutCallBack = (response) => {
    console.log(response);
  };
  const failureChangePasswordCallBack = (response) => {
    console.log(response);
    setLoginLoader(false);
    setInvalidMessage(true);
  };

  // calling the rsa token api here
  useEffect(() => {
    loginRsa(successLoginRsaCallBack, failureLoginRsaCallBack);
  }, []);

  // success and failure callbacks for the rsa login api
  const successLoginRsaCallBack = (response) => {
    setRsaKey(response.data.rsa);
  };
  const failureLoginRsaCallBack = (response) => {
    console.log(response);
  };
  const triggerLogin = () => {
    var changedPassword = {
      // utilised the rsaEnc function here to encrypt the password
      password: rsaEnc(newPassword2, rsaKey),
    };
    changePassword(
      changedPassword,
      successChangePasswordCallBack,
      failureChangePasswordCallBack,
    );
    setLoginLoader(true);
  };

  function hasANumber(string) {
    return Array.prototype.some.call(string, (c) => !isNaN(c) && c.trim());
  }
  function hasUppercaseCharacter(string) {
    return /[A-Z]/.test(string);
  }

  function isMinimumLength(string) {
    if (string.length >= 8) {
      return true;
    } else {
      return false;
    }
  }

  function hasSpecialCharacter(string) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string);
  }

  return (
    <div className="login-page">
      <div className="logo-div">
        <div>
          <img src={loginLogo} alt="loginLogo" height="90px" width="255px" />
        </div>
      </div>
      <div className="login-layout">
        <Stack className="login-sections">
          <div className="login-heading">
            <p>Change Password</p>
          </div>

          <div className="forgot-passwor-sections">
            <ul className="forgot-password-field">
              <li>
                <div className="form-group">
                  <label className="group-title">New Password</label>
                  <span className="input-feild">
                    <input
                      type={hideShowPassword2 ? "password" : "text"}
                      className={"input-text "}
                      autocomplete="off"
                      name="password"
                      value={newPassword}
                      onChange={(e) => {
                        const filter =
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()_+=:;"'.,<>|-])[A-Za-z\d@$!%*?&#^()_+=:;"'.,<>|-]{8,}$/;

                        if (hasANumber(e.target.value)) {
                          oneDigit.current.style.color = "green";
                        } else {
                          oneDigit.current.style.color = "#95969D";
                        }
                        if (hasUppercaseCharacter(e.target.value)) {
                          oneUppercase.current.style.color = "green";
                        } else {
                          oneUppercase.current.style.color = "#95969D";
                        }
                        if (isMinimumLength(e.target.value)) {
                          eightCharacter.current.style.color = "green";
                        } else {
                          eightCharacter.current.style.color = "#95969D";
                        }
                        if (hasSpecialCharacter(e.target.value)) {
                          oneSpecialCharacter.current.style.color = "green";
                        } else {
                          oneSpecialCharacter.current.style.color = "#95969D";
                        }
                        if (
                          e.target.value == newPassword2 &&
                          newPassword2.length != 0 &&
                          hasANumber(e.target.value) &&
                          isMinimumLength(e.target.value) &&
                          hasSpecialCharacter(e.target.value) &&
                          hasUppercaseCharacter(e.target.value)
                        ) {
                          setdisableBTN(false);
                        } else {
                          setdisableBTN(true);
                        }
                        if (
                          e.target.value == newPassword2 &&
                          hasANumber(e.target.value) &&
                          isMinimumLength(e.target.value) &&
                          hasSpecialCharacter(e.target.value) &&
                          hasUppercaseCharacter(e.target.value) &&
                          newPassword2.length != 0 &&
                          newPassword.length != 0
                        ) {
                          document.getElementById(
                            "password-mismatch",
                          ).style.visibility = "hidden";
                        } else {
                          if (newPassword2.length != 0)
                            document.getElementById(
                              "password-mismatch",
                            ).style.visibility = "visible";
                        }
                        setNewPassword(e.target.value);
                      }}
                    />
                    <img
                      src={hideShowPassword2 ? passwordhide : passwordshow}
                      alt="passwordLogo"
                      onClick={() => sethideShowPassword2((prev) => !prev)}
                      className="passwordIcon"
                    />
                  </span>
                </div>
              </li>
              <li>
                <div className="pwd-validation-section">
                  <div className="pwd-validation-section1">
                    <Stack
                      className={classes.validationTopRow}
                      direction="row"
                      ref={oneSpecialCharacter}
                    >
                      <DoneOutlinedIcon className={classes.doneIcon} />
                      <p>One special character</p>
                    </Stack>

                    <Stack
                      className={classes.validationBottomRow}
                      direction="row"
                      ref={oneDigit}
                    >
                      <DoneOutlinedIcon className={classes.doneIcon} />
                      <p>One number</p>
                    </Stack>
                  </div>

                  <div className="pwd-validation-section2">
                    <Stack
                      className={classes.validationTopRow}
                      direction="row"
                      ref={oneUppercase}
                    >
                      <DoneOutlinedIcon className={classes.doneIcon} />
                      <p>One uppercase character</p>
                    </Stack>

                    <Stack
                      className={classes.validationBottomRow}
                      direction="row"
                      ref={eightCharacter}
                    >
                      <DoneOutlinedIcon className={classes.doneIcon} />
                      <p>8 characters minimum</p>
                    </Stack>
                  </div>
                </div>
              </li>
              <li>
                <div className="form-group">
                  <label className="group-title">Confirm New Password</label>
                  <span className="input-feild">
                    <input
                      type={hideShowOTP ? "password" : "text"}
                      className={"input-text "}
                      name="password"
                      value={newPassword2}
                      onChange={(e) => {
                        if (e.target.value == "" || e.target.value == " ") {
                          document.getElementById(
                            "password-mismatch",
                          ).style.visibility = "hidden";
                        } else {
                          if (
                            e.target.value == newPassword &&
                            hasANumber(e.target.value) &&
                            isMinimumLength(e.target.value) &&
                            hasSpecialCharacter(e.target.value) &&
                            hasUppercaseCharacter(e.target.value)
                          ) {
                            document.getElementById(
                              "password-mismatch",
                            ).style.visibility = "hidden";
                            setdisableBTN(false);
                          } else {
                            document.getElementById(
                              "password-mismatch",
                            ).style.visibility = "visible";
                            setdisableBTN(true);
                          }
                        }
                        setNewPassword2(e.target.value);
                      }}
                    />
                    <img
                      src={hideShowOTP ? passwordhide : passwordshow}
                      alt="passwordLogo"
                      onClick={() => sethideShowOTP((prev) => !prev)}
                      className="passwordIcon"
                    />
                  </span>
                </div>
              </li>
              <li>
                <p id="password-mismatch">Password Mismatch</p>
              </li>
            </ul>

            <div>
              {disableBTN ? (
                <div className={"continue-btn-disabled"}>
                  <CommonButton
                    btnName={"Change Password"}
                    btnLoader={loginLoader}
                  />
                </div>
              ) : (
                <div className={"continue-btn"}>
                  <CommonButton
                    btnName={"Change Password"}
                    btnCallBack={(e) => triggerLogin()}
                    btnLoader={loginLoader}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="second-logo-div-reset-password">
            <p>Powered By</p>
            <div className="satsure-logo-div">
              <img src={satsureGreyLogo} />
            </div>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default ChangePassword;
