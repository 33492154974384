// react
import React from "react";

// plugin
import Button from "react-bootstrap/Button";

import "./button.css";

const CommonButton = (props) => {
  return (
    <Button
      variant="contained"
      onClick={props.btnCallBack}
      disabled={props.btnDisable || props.disableSearchButton}
      className="loader-button"
    >
      {(() => {
        if (props.btnLoader) {
          return (
            <div className="loadbtn" data-testid="loader">
              <div className="loader">
                <div className="circle" id="a"></div>
                <div className="circle" id="b"></div>
                <div className="circle" id="c"></div>
              </div>
            </div>
          );
        } else {
          return <p>{props.btnName}</p>;
        }
      })()}
    </Button>
  );
};

export default CommonButton;
