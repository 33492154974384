import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Paper } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { apiCall } from "../../../common/connect";
import { _getStorageValue } from "../../../common/localStorage";

// state less pure function component.
export default function NotificationViewPage(props) {
  const classes = props.useStyles();
  const location = useLocation();
  let history = useHistory();

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        className={classes.notificationViewHeader}
      >
        <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
          {/* <Checkbox onChange={(e) => handleChange(e, props.data)} value="selectAll" checked={checkedNotifications?.length === props.data?.length} /> */}
          <Typography className={classes.notificationHeader}>
            NOTIFICATIONS
          </Typography>
          <Stack
            className={classes.markallasread}
            direction="row"
            sx={{ marginLeft: "14px" }}
            id="markAsReadBtn"
            onClick={() => {
              props.markAsRead();
            }}
          >
            <DoneOutlinedIcon
              sx={{ fontSize: 14 }}
              style={
                props.renderMarkAsReadText === true
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
            />
            {props.renderMarkAsReadText && <p>Mark as read</p>}
          </Stack>
        </Stack>
        <Stack
          className={classes.markallasread}
          direction="row"
          sx={{ marginLeft: "5px" }}
          onClick={() => {
            props.markAllAsRead(props.tableData);
          }}
        >
          <DoneOutlinedIcon sx={{ fontSize: 14 }} />
          <p>Mark all as read</p>
        </Stack>
      </Stack>
      <Paper elevation={2} className={classes.notificationViewContainer}>
        {props.queryListLoader && (
          <p style={{ textAlign: "center", fontWeight: "bold", color: "red" }}>
            Loading...
          </p>
        )}
        {props.tableData.length === 0 && props.queryListLoader === false ? (
          <p style={{ textAlign: "center", fontWeight: "bold", color: "red" }}>
            No Data Avaliable for the Selected Option.
          </p>
        ) : (
          props.tableData.map((items, index) => {
            return (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={
                    items.read_status
                      ? classes.notificationListItemRead
                      : classes.notificationListItemUnread
                  }
                  key={index}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Checkbox
                      value={items.reference_id}
                      onChange={(e) =>
                        props.handleChange(e.target.checked, items)
                      }
                      id="checkbox"
                      checked={props.storeCheckedStatus.includes(items.sno)}
                    />
                    <CircleIcon
                      className={
                        items.error_status === 1
                          ? classes.notificationStatusRed
                          : items.error_status === 2
                            ? classes.notificationStatusOrange
                            : classes.notificationStatusGreen
                      }
                      sx={{ fontSize: 15, paddingTop: "1px" }}
                    />
                    <p className={classes.notificationItem}>
                      {items.message1}
                      <b>{items.message_bold}</b>
                      {items.message2}
                    </p>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <p className={classes.timeStamp}>
                      {props.dateTimeFormatter(items.update_time)}
                    </p>
                    <Button
                      disableRipple
                      variant="text"
                      className={classes.viewbutton}
                      onClick={(e) => {
                        history.push({
                          pathname: "/archive-report",
                          state: {
                            refId: items.reference_id,
                            prevPath: location.pathname,
                          },
                        });
                        props.markAllAsRead(
                          [
                            {
                              sno: items.sno,
                              reference_id: items.reference_id,
                              read_status: "true",
                            },
                          ],
                          false,
                        );
                      }}
                    >
                      View
                    </Button>
                  </Stack>
                </Stack>
                <Divider orientation="horizontal" flexItem />
              </>
            );
          })
        )}
      </Paper>
    </div>
  );
}
