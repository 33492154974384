import React, { useEffect, useState } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ArchiveIcon from "@mui/icons-material/Archive";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import myFiles from "../../assets/images/my-files.svg";
import CircleIcon from "@mui/icons-material/Circle";
import "./styles.css";
import { USER_ID } from "../../common/constants";

import { useSelector } from "react-redux";
import { _getStorageValue } from "../../common/localStorage";
import { reportStatusCount } from "../../layouts/archive-report-layout/layout-component/archivefun";

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "1px solid #3342B5 !important",
  color: "#5D5D5D !important",
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  "&:hover": {
    backgroundColor: "transparent",
  },
  boxShadow: "none !important",
}));

export default function Tablefiltercomponents({
  handleListItems,
  handleStarredTableData,
  handleAllFiles,
  handleTableSearch,
  storeOriginalData,
  handleArchiveData,
}) {
  const [storeItemLabels, setStoreItemLabels] = useState([
    {
      items: `ALL (0)`,
      id: 0,
    },
    {
      items: `DECISION PENDING (0)`,
      id: 1,
    },
    {
      items: `ON HOLD (0)`,
      id: 2,
    },
    {
      items: `APPROVED (0)`,
      id: 3,
    },
    {
      items: `DECLINED (0)`,
      id: 4,
    },
  ]);

  const [storeStarredCounts, setStoreStarredCounts] = useState(0);
  const [storeArchiveDataCounts, setStoreArchievedDataCounts] = useState(0);

  let activeList = useSelector((s) => s.filterTableComponentReducer.activeList);
  let defaultColor = useSelector(
    (s) => s.filterTableComponentReducer.defaultColor,
  );
  let starredButtonActiveColor = useSelector(
    (s) => s.filterTableComponentReducer.starredButtonActiveColor,
  );
  let allFiles = useSelector((s) => s.filterTableComponentReducer.allFiles);
  let allFilesButtonActiveColor = useSelector(
    (s) => s.filterTableComponentReducer.allFilesButtonActiveColor,
  );
  let archiveButtonActiveColor = useSelector(
    (s) => s.filterTableComponentReducer.archiveButtonActiveColor,
  );

  useEffect(() => {
    getReportStatusCount();
  }, []);

  const getReportStatusCount = () => {
    _getStorageValue(USER_ID).then((userID) => {
      reportStatusCount(
        successReportStatusCountCallBack,
        failureReportStatusCountCallBack,
      );
    });
  };

  const successReportStatusCountCallBack = (response) => {
    console.log(response, "response");

    const storeItemLabelsFromApi = [
      {
        items: `ALL (${response.totalCount})`,
        id: 0,
      },
      {
        items: `DECISION PENDING (${response.pending})`,
        id: 1,
      },
      {
        items: `ON HOLD (${response.onhold})`,
        id: 2,
      },
      {
        items: `APPROVED (${response.approved})`,
        id: 3,
      },
      {
        items: `DECLINED (${response.declined})`,
        id: 4,
      },
    ];

    setStoreItemLabels(storeItemLabelsFromApi);
    setStoreStarredCounts(response.starred);
    setStoreArchievedDataCounts(response.archived);

    console.log("updated setStoreItemLabels ==> ", storeItemLabels);
  };
  const failureReportStatusCountCallBack = (response) => {
    console.log(response, "error");
  };

  // functions to conditionally render the li elements color and dot based on id.
  const renderDynamicListItemsColor = (id) => {
    if (defaultColor === true && id === 0) {
      return "rgba(27, 69, 173, 1)";
    } else if (activeList === id || allFiles === id) {
      return "rgba(27, 69, 173, 1)";
    } else {
      return "#999999";
    }
  };
  const renderDynamicListItemsPointer = (id) => {
    if (defaultColor === true && id === 0) {
      return "1";
    } else if (activeList === id || allFiles === id) {
      return "1";
    } else {
      return "0";
    }
  };

  return (
    <div className="table-filter-wrapper">
      <input
        type="text"
        placeholder="SEARCH"
        onChange={(e) => handleTableSearch(e.target.value)}
      />
      <ColorButton
        variant="contained"
        style={
          allFilesButtonActiveColor === true
            ? { backgroundColor: "rgba(83, 79, 150, 0.1)" }
            : { backgroundColor: "transparent" }
        }
        onClick={() => handleAllFiles(storeItemLabels[0].id)}
      >
        <img src={myFiles} alt="My Files" />
        <p>My Files</p>
      </ColorButton>
      <ul style={{ paddingLeft: "10px" }}>
        {storeItemLabels.map((labels) => {
          return (
            <li
              key={labels.id}
              onClick={() => handleListItems(labels.id)}
              style={{ color: renderDynamicListItemsColor(labels.id) }}
            >
              <CircleIcon
                className="small-icon"
                style={{ opacity: renderDynamicListItemsPointer(labels.id) }}
              />
              {labels.items}
            </li>
          );
        })}
      </ul>
      <ColorButton
        variant="contained"
        style={
          ({ marginBottom: "1.5rem" },
          starredButtonActiveColor === true
            ? { backgroundColor: "rgba(83, 79, 150, 0.1)" }
            : { backgroundColor: "transparent" })
        }
        onClick={() => handleStarredTableData()}
      >
        <StarBorderIcon />
        <p>Starred ({storeStarredCounts})</p>
      </ColorButton>
      <ColorButton
        variant="contained"
        style={
          archiveButtonActiveColor === true
            ? { backgroundColor: "rgba(83, 79, 150, 0.1)" }
            : { backgroundColor: "transparent" }
        }
        onClick={() => handleArchiveData()}
      >
        <ArchiveIcon />
        <p>ARCHIVED ({storeArchiveDataCounts})</p>
      </ColorButton>
    </div>
  );
}
