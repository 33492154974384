import React, { useMemo, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
// style
import "./filter.css";

// Custom label

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": checked && {
    // Change color here
    color: "#fff",
    opacity: "1",
    border: "7px solid rgba(51, 66, 181, 1)!important",
    width: "20px",
    height: "20px",
    padding: "3px!important",
  },
}));

// Custom FormControl

function MyFormControlLabel(props) {
  // MUI UseRadio Group
  const radioGroup = useRadioGroup();
  props.setStoreRadioGroupValue(radioGroup.value);

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

export default function Reportgenerator({ handleRadioButton }) {
  let radioButtonSelectionWithoutSorting = useSelector(
    (s) => s.disableRadioButtonReducer.defaultRadioButtonSelection,
  );
  // keep a clone of the original array.
  let cloneRadioButtonSelectionWithoutSorting = [
    ...radioButtonSelectionWithoutSorting,
  ];
  let loader = useSelector((s) => s.disableRadioButtonReducer.dataLoader);

  const [storeRadioGroupValue, setStoreRadioGroupValue] = useState("");

  // sort the radioButtonSelectionWithoutSortingArray based on page no.
  const sortedArray = cloneRadioButtonSelectionWithoutSorting.sort(
    (a, b) => a.page_no - b.page_no,
  );

  // extract the local storage value for the radio button selection.
  const getStoreRadioButtonSelection = localStorage.getItem(
    "storeRadioButtonSelection",
  );

  const renderRadioButtons = () => {
    return useMemo(() => {
      const pageNameMappings = {
        satsource: "satsource",
        "satsource & ownership": "satsource & ownership",
        ownership: "ownership",
      };
      const newArray =
        sortedArray &&
        sortedArray.map((item) => pageNameMappings[item.page_name]);

      const defaultValue = sortedArray.find(
        (item) => item.default_page,
      )?.page_name;

      const nameReplacements = {
        satsource: "SatSource",
        "satsource & ownership": "SatSource & Ownership",
      };

      return (
        <>
          {sortedArray.length !== 0 ? (
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={
                getStoreRadioButtonSelection === null
                  ? defaultValue
                  : getStoreRadioButtonSelection
              }
              name="radio-buttons-group"
              onChange={(e) => handleRadioButton(e.target.value)}
            >
              {sortedArray.map((item) => (
                <MyFormControlLabel
                  key={item.page_name}
                  value={item.page_name}
                  control={<Radio />}
                  label={nameReplacements[item.page_name] || item.page_name}
                  className="radio-button-label capitalized-label"
                  disabled={!newArray.includes(item.page_name)}
                  setStoreRadioGroupValue={setStoreRadioGroupValue}
                />
              ))}
            </RadioGroup>
          ) : null}
        </>
      );
    }, [sortedArray, storeRadioGroupValue]);
  };

  return (
    <>
      <h1 className="reports-heading">
        <span>{sortedArray.length === 1 ? "Report" : "Reports"}</span>
      </h1>
      <FormControl className="custom-radio-group">
        {loader && <p className="data-loader">Loading...</p>}
        {renderRadioButtons()}
      </FormControl>
    </>
  );
}
