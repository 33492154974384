import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Adminsidepanel from "./admin-sidepanel";
import HeaderLayoutComp from "../common-header-component/header";
import Usermanagementtable from "./userManagementTable";
import { _getStorageValue } from "../../common/localStorage";
import { USER_ID } from "../../common/constants";
import {
  userManagementTableData,
  updateDetails,
  creditHubDataApi,
} from "./adminApi";
import Button from "@mui/material/Button";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { debounce } from "lodash";
// plugin
import { useHistory } from "react-router-dom";
import { getRegions } from "../filter-component/filterfun";

// global variables used on certain cases to minimize the use of states.
var storeTheUserId;
var storeUserRole;
var storeUserManagementObj;
var userManagementData;
var isChecked;
var storeStateName;
var storeDistrictName;
var storeDefaultPage;

export default function Adminmain() {
  let history = useHistory();
  // state to store the user management table data.
  const [shouldStoreUserManagementData, setShouldStoreUserManagementData] =
    useState([]);
  // data loader.
  const [
    shouldShowUserManagementDataLoader,
    setShouldShowUserManagementDataLoader,
  ] = useState(true);
  const [tableHeight, setTableHeight] = useState("calc(100vh - 254px)");
  const [tabNav, setTabNav] = useState("");
  // state to show/hide the view user modal.
  const [shouldShowViewUserModal, setShouldShowViewUserModal] = useState(false);
  // state to store the user management data.
  const [viewUserManagementData, setViewUserManagementData] = useState([]);
  // state to show/hide the edit user modal.
  const [shouldShowEditUserModal, setShouldShowEditUserModal] = useState(false);
  //state to show/hide successful user updation in a Toast
  const [showUpdationToast, setShowUpdationToast] = useState(false);
  // should store user role.
  const [shouldStoreRole, setShouldStoreRole] = useState("");
  // to render the states from the region api.
  const [shouldStoreStates, setShouldStoreStates] = useState([]);
  // store the input values.
  const [inputValues, setInputValues] = useState([]);
  // store the roles as an array.
  const [shouldStoreReportAccess, setShouldStoreReportAccess] = useState([]);
  // to store the states from the states dropdown.
  const [shouldStoreStatesFromDropdown, setShouldStoreStatesFromDropdown] =
    useState("");
  // state for enabling/disabling the apply filter button.
  const [shouldEnableDisableButton, setShouldEnableDisableButton] =
    useState(true);
  // districts.
  const [shouldStoreDistricts, setShouldStoreDistricts] = useState([]);
  // store credit hubs from the credit hub dropdown.
  const [shouldStoreCreditHubs, setShouldStoreCreditHubs] = useState("");
  // show/hide a loader for the toggle switch.
  const [shouldShowSwitchLoader, setShouldShowSwitchLoader] = useState(false);
  // store selected user details.
  const [shouldStoreSelectedUserDetails, setShouldStoreSelectedUserDetails] =
    useState(null);
  // should store report access.
  const [storeTheReportAccess, shouldStoreTheReportAccess] = useState([]);
  // store handle search value.
  const [storeSearchFieldValue, setStoreSearchFieldValue] = useState("");
  // store the page access.
  const [shouldStorePageAccess, setShouldStorePageAccess] = useState([]);
  // store the default district.
  const [shouldStoreDefaultDistrict, setShouldStoreDefaultDistrict] =
    useState("");
  // store the districts from the region api.
  const [
    shouldStoreUserRegionFilterDistricts,
    setShouldStoreUserRegionFilterDistricts,
  ] = useState([]);
  // enable/disable update user button on change of report access.
  const [shouldEnableUpdateUserButton, setShouldEnableUpdateUserButton] =
    useState(true);
  // store the default landing selection value for the dropdown.
  const [storeDefaultLandingValue, setStoreDefaultLandingValue] =
    useState(null);
  //show invalid number edit user modal
  const [invalidNumber, setInvalidNumber] = useState(false);
  // to store the credit hub names on calling the credit hub api..
  const [shouldStoreCreditHubNames, setShouldStoreCreditHubNames] = useState(
    [],
  );
  // to store the default value of credit hub.
  const [storeDefaultCreditHubValue, setStoreDefaultCreditHubValue] =
    useState("");
  // to set the page no in order to call the user requests api.
  const [shouldStorePageNo, setShouldStorePageNo] = useState(0);
  // to store the total row count.
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    let path = window.location.pathname;
    setTabNav(path);
  }, []);

  // user management table data api.
  useEffect(() => {
    setShouldShowUserManagementDataLoader(true);
    _getStorageValue(USER_ID).then(() => {
      userManagementTableData(
        successUserManagementCallBack,
        failureUserManagementLandCallBack,
        shouldStorePageNo,
      );
    });
  }, [shouldStorePageNo]);

  //fetch page permission to pass it as a query parameter in region API
  const getDefaultRadioButtonSelection =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];
  let pageName = "";
  getDefaultRadioButtonSelection.forEach((items) => {
    if (items.default_page === true) {
      pageName = items.page_name;
    }
  });
  // region api to generate the states.
  useEffect(() => {
    const storeStateRegionId = "27841bce-248f-4342-a78d-b6d817dceb24";
    _getStorageValue(USER_ID).then((user_id) => {
      getRegions(
        storeStateRegionId,
        pageName,
        successRegionCallBack,
        failureFailureCallBack,
      );
    });
  }, []);

  const successRegionCallBack = (response) => {
    setShouldStoreStates(response.regions);
  };

  const failureFailureCallBack = (error) => {
    console.log(error, "====== error response");
  };

  const userDataSet = (res) => {
    let dataObj = [];
    res.forEach((data, i) => {
      let userManagementDataObj = {
        userName: data.name,
        activeStatus: data.activeStatus,
        state: data.state,
        userBranch: data.creditHub,
        reportAccess: data.reportAccess,
        designation: data.designation,
        lastLogin: data.lastLogin,
        email: data.email,
        phoneNo: data.phoneNumber,
        defaultReport: data.defaultReport,
        district: data.district,
        lastLogin: data.lastLogin,
        role: data.role,
        userId: data.userId,
      };
      dataObj.push(userManagementDataObj);
    });
    setShouldStoreUserManagementData(dataObj);
    userManagementData = dataObj;
  };

  const successUserManagementCallBack = (response) => {
    setTotalRows(response.totalRows);
    setShouldShowUserManagementDataLoader(false);
    setShouldShowSwitchLoader(false);
    setShouldStoreSelectedUserDetails(null);

    if (storeSearchFieldValue === "") {
      userDataSet(response.users);
      return response;
    } else {
      const filteredSearchResult = response.users.filter(
        (filteredData) =>
          filteredData.name
            ?.toString()
            .toLowerCase()
            .includes(storeSearchFieldValue) ||
          filteredData.state
            ?.toString()
            .toLowerCase()
            .includes(storeSearchFieldValue) ||
          filteredData.creditHub
            ?.toString()
            .toLowerCase()
            .includes(storeSearchFieldValue) ||
          filteredData.reportAccess
            ?.toString()
            .toLowerCase()
            .includes(storeSearchFieldValue) ||
          filteredData.designation
            ?.toString()
            .toLowerCase()
            .includes(storeSearchFieldValue),
      );
      userDataSet(filteredSearchResult);
      return filteredSearchResult;
    }
  };

  const failureUserManagementLandCallBack = (error) => {
    console.log(error, "====== user management table data error");
    setShouldShowSwitchLoader(false);
    setShouldStoreSelectedUserDetails(null);
  };

  // on change toggle switch functionality.
  function handleInputChange(event, getTheUserDetails, index) {
    isChecked = event.target.checked;
    setShouldStoreSelectedUserDetails(getTheUserDetails);

    const requestBody = {
      activeStatus: isChecked,
      userId: getTheUserDetails.userId,
    };
    setShouldShowSwitchLoader(true);

    _getStorageValue(USER_ID).then((user_id) => {
      updateDetails(
        requestBody,
        successActiveDeactiveCallBack,
        failureActiveDeactiveCallBack,
      );
    });
  }

  const successActiveDeactiveCallBack = (response) => {
    setShouldShowSwitchLoader(false);
    setStoreSearchFieldValue("");
    _getStorageValue(USER_ID).then(() => {
      userManagementTableData(
        successUserManagementCallBack,
        failureUserManagementLandCallBack,
        shouldStorePageNo,
      );
    });
  };

  const failureActiveDeactiveCallBack = (error) => {
    setShouldShowSwitchLoader(false);
    setShouldStoreSelectedUserDetails(null);
  };

  const handleViewUserModal = (getUserManagementData) => {
    setShouldShowViewUserModal(getUserManagementData.userId);
    let userManagementDataObj = [];
    const storeUserManagementData = {
      userName: getUserManagementData.userName,
      email: getUserManagementData.email,
      phoneNumber: getUserManagementData.phoneNo,
      role: getUserManagementData.role,
      designation:
        getUserManagementData.designation === null
          ? "NA"
          : getUserManagementData.designation,
      state: getUserManagementData.state,
      district: getUserManagementData.district,
      branch: getUserManagementData.userBranch,
      reportAccess: getUserManagementData.reportAccess,
      defaultReport: getUserManagementData.defaultReport,
      lastLogin:
        getUserManagementData.lastLogin === null
          ? "NA"
          : getUserManagementData.lastLogin,
    };
    userManagementDataObj.push(storeUserManagementData);
    setViewUserManagementData(userManagementDataObj);
  };

  const handleViewModalClose = () => {
    setShouldShowViewUserModal(false);
  };

  // function to handle the edit user modal.
  const handleEditUserModal = (userData) => {
    setShouldShowEditUserModal(userData.userId);
    setShouldEnableUpdateUserButton(true);
    let userManagementDataObj = [];
    const storeUserManagementData = {
      name: userData.userName,
      email: userData.email,
      phoneNo: userData.phoneNo,
      role: userData.role,
      designation: userData.designation,
      state: userData.state,
      district: userData.district,
      branch: userData.userBranch,
      pageAccess: userData.reportAccess,
      defaultLanding: userData.defaultReport,
    };
    userManagementDataObj.push(storeUserManagementData);
    setViewUserManagementData(userManagementDataObj);
    setShouldStoreRole(userData.role);
    setShouldStoreDefaultDistrict(userData.district);
    setStoreDefaultCreditHubValue(userData.userBranch);
    storeUserManagementObj = storeUserManagementData;
    storeTheUserId = userData.userId;

    const state = shouldStoreStates.find((selectedState) => {
      if (selectedState.name === userData.state.toUpperCase()) {
        return selectedState;
      }
    });

    _getStorageValue(USER_ID).then((user_id) => {
      getRegions(
        state.id,
        pageName,
        successRegionDistrictCallBack,
        failureDistrictCallBack,
      );
    });

    const successRegionDistrictCallBack = (response) => {
      setShouldStoreDistricts(response.regions);
    };

    const failureDistrictCallBack = (error) => {
      console.log(error, "====== error callback");
    };

    // fetch the company id from the local storage.
    const storeCompanyId = parseInt(localStorage.getItem("companyId"));
    // credit hub api.
    _getStorageValue(USER_ID).then(() => {
      creditHubDataApi(
        storeCompanyId,
        state.name,
        successCreditHubCallBack,
        failureCreditHubCallBack,
      );
    });

    const successCreditHubCallBack = (response) => {
      const fetchTheCreditHubs = [];
      response.data.forEach((creditHubs) => {
        fetchTheCreditHubs.push(creditHubs.credit_hub);
      });
      setShouldStoreCreditHubNames(fetchTheCreditHubs);
    };

    const failureCreditHubCallBack = (error) => {
      console.log(error, "==== error");
    };

    const defaultLandingSection = [
      {
        defaultPage: "Satsource Report",
        defaultLanding: "satsource",
        label: "Satsource",
        defaultPageNo: 1,
      },
      {
        defaultPage: "Satsource & Ownership Report",
        defaultLanding: "satsource & ownership",
        label: "Satsource & Ownership",
        defaultPageNo: 4,
      },
      {
        defaultPage: "Ownership Report",
        defaultLanding: "ownership",
        label: "Ownership",
        defaultPageNo: 6,
      },
    ];

    // get the defaultRadioButtonSelection array from.
    const pageAccess = JSON.parse(
      localStorage.getItem("defaultRadioButtonSelection"),
    );

    // sort the radioButtonSelectionWithoutSortingArray based on page no.
    const sortedArray = pageAccess.sort((a, b) => a.page_no - b.page_no);
    // Use map to set default_page to false for all objects
    const updatedData = sortedArray.map((item) => ({
      ...item,
      default: false,
    }));

    const storeMatchingPageAccess = [];
    updatedData.forEach((report) => {
      userManagementDataObj[0].pageAccess?.forEach((pageAccess) => {
        if (report.page_name === pageAccess) {
          storeMatchingPageAccess.push(report);
        }
      });
    });
    setShouldStorePageAccess(storeMatchingPageAccess);

    const showDefaultLandingPage = defaultLandingSection.find(
      (defaultLandingSelection) => {
        if (
          defaultLandingSelection.defaultLanding ===
          userManagementDataObj[0].defaultLanding
        ) {
          return defaultLandingSection;
        }
      },
    );
    setStoreDefaultLandingValue(showDefaultLandingPage);
  };

  // function to close the edit modal.
  const handleEditModalClose = () => {
    setShouldShowEditUserModal(false);
    setShouldStoreDistricts([]);
    setShouldStoreDefaultDistrict("");
    setStoreDefaultCreditHubValue("");
    setShouldStoreCreditHubNames([]);
    setShouldStorePageAccess([]);
    setShouldEnableUpdateUserButton(false);
  };

  // function to handle the edit user modal input types.
  const handleUserInput = debounce((values, keyName) => {
    setInputValues((prev) => ({
      ...prev,
      [keyName]: values,
    }));

    const areAllFieldsFilled = Object.values({
      ...inputValues,
      [keyName]: values,
    }).every((value) => value !== "");

    setShouldEnableUpdateUserButton(!areAllFieldsFilled);

    if (keyName === "phoneNo") {
      if (values.length < 10) {
        setInvalidNumber(true);
      } else {
        setInvalidNumber(false);
      }
    } else if (
      storeDefaultCreditHubValue === null ||
      storeDefaultCreditHubValue === "" ||
      shouldStoreDefaultDistrict === "" ||
      storeDefaultLandingValue === "" ||
      storeDefaultLandingValue === null
    ) {
      setShouldEnableUpdateUserButton(true);
    }
  }, 500);

  // function to handle the role change.
  const handleRoleChange = (value, reason) => {
    const areAnyFieldsFilled = Object.values(inputValues).every(
      (value) => value !== "",
    );
    setShouldStoreRole(value?.id);
    storeUserRole = value?.id;
    if (reason === "clear") {
      setShouldEnableUpdateUserButton(true);
    } else if (
      shouldStoreDefaultDistrict === "" ||
      shouldStoreDefaultDistrict === null ||
      storeDefaultCreditHubValue === "" ||
      storeDefaultCreditHubValue === null
    ) {
      setShouldEnableUpdateUserButton(true);
    } else if (
      !areAnyFieldsFilled ||
      shouldStorePageAccess.length === 0 ||
      storeDefaultLandingValue === null
    ) {
      setShouldEnableUpdateUserButton(true);
    } else {
      setShouldEnableUpdateUserButton(false);
    }
  };

  // function to handle the state change from the edit modal.
  const handleStateChange = (value) => {
    storeStateName = value.name;
    setShouldEnableUpdateUserButton(true);
    setShouldStoreDefaultDistrict("");
    setStoreDefaultCreditHubValue("");
    _getStorageValue(USER_ID).then((user_id) => {
      getRegions(
        value.id,
        pageName,
        successDistrictCallBack,
        failureDistrictCallBack,
      );
    });

    const successDistrictCallBack = (response) => {
      setShouldStoreDistricts(response.regions);
    };

    const failureDistrictCallBack = (error) => {
      console.log(error, "====== error response");
    };

    // fetch the company id from the local storage.
    const storeCompanyId = parseInt(localStorage.getItem("companyId"));
    // credit hub api.
    _getStorageValue(USER_ID).then(() => {
      creditHubDataApi(
        storeCompanyId,
        value.name,
        successCreditHubCallBack,
        failureCreditHubCallBack,
      );
    });

    const successCreditHubCallBack = (response) => {
      const fetchTheCreditHubs = [];
      response.data.forEach((creditHubs) => {
        fetchTheCreditHubs.push(creditHubs.credit_hub);
      });
      setShouldStoreCreditHubNames(fetchTheCreditHubs);
    };

    const failureCreditHubCallBack = (error) => {
      console.log(error, "==== error");
    };
  };

  // function to handle the district change from the edit modal.
  const handleDistrictChange = (value, reason) => {
    const areAnyFieldsFilled = Object.values(inputValues).every(
      (value) => value !== "",
    );
    storeDistrictName = value?.name;
    setShouldStoreDefaultDistrict(value?.name);
    if (reason === "clear") {
      setShouldEnableUpdateUserButton(true);
      setStoreDefaultCreditHubValue("");
      setShouldStoreDefaultDistrict("");
    } else if (
      !areAnyFieldsFilled ||
      shouldStorePageAccess.length === 0 ||
      shouldStoreRole === undefined
    ) {
      setShouldEnableUpdateUserButton(true);
    } else if (
      storeDistrictName !== "" &&
      (storeDefaultCreditHubValue === "" || storeDefaultCreditHubValue === null)
    ) {
      setShouldEnableUpdateUserButton(true);
    } else {
      setShouldEnableUpdateUserButton(false);
    }
  };

  // function to handle the page access dropdown.
  const handlePageAccess = (value, reason) => {
    const areAnyFieldsFilled = Object.values(inputValues).every(
      (value) => value !== "",
    );
    // create a new array for the updated values
    let updatedValues = [];

    // loop through the previously selected values
    shouldStorePageAccess.forEach((option) => {
      // if the option is still selected, add it to the updated values array
      if (value.some((v) => v.page_name === option.page_name)) {
        updatedValues.push(option);
      }
    });

    // loop through the newly selected values
    value.forEach((v) => {
      // if the value is not already in the updated values array, add it
      if (!updatedValues.some((option) => option.page_name === v.page_name)) {
        updatedValues.push(v);
      }
    });

    // update the state with the updated values array
    setShouldStorePageAccess(updatedValues);
    setShouldEnableUpdateUserButton(true);
    setStoreDefaultLandingValue(null);
    if (reason === "removeOption") {
      setShouldEnableUpdateUserButton(true);
      setStoreDefaultLandingValue(null);
    } else if (!areAnyFieldsFilled) {
      setShouldEnableUpdateUserButton(true);
    }
  };

  // function to handle the default landing selection.
  const handleDefaultLandingSelection = (value, reason) => {
    const areAnyFieldsFilled = Object.values(inputValues).every(
      (value) => value !== "",
    );
    setStoreDefaultLandingValue(value);
    if (reason === "clear") {
      setShouldEnableUpdateUserButton(true);
    } else if (
      shouldStoreDefaultDistrict === "" ||
      shouldStoreDefaultDistrict === null ||
      storeDefaultCreditHubValue === "" ||
      storeDefaultCreditHubValue === null
    ) {
      setShouldEnableUpdateUserButton(true);
    } else if (
      !areAnyFieldsFilled ||
      shouldStorePageAccess.length === 0 ||
      shouldStoreRole === undefined
    ) {
      setShouldEnableUpdateUserButton(true);
    } else {
      setShouldEnableUpdateUserButton(false);
    }
  };

  // function to handle the credit hubs from the edit modal.
  const handleCreditHubChange = (value, reason) => {
    const areAnyFieldsFilled = Object.values(inputValues).every(
      (value) => value !== "",
    );
    setStoreDefaultCreditHubValue(value);
    if (reason === "clear") {
      setShouldEnableUpdateUserButton(true);
    } else if (
      !areAnyFieldsFilled ||
      shouldStorePageAccess.length === 0 ||
      shouldStoreRole === undefined ||
      storeDefaultLandingValue === null
    ) {
      setShouldEnableUpdateUserButton(true);
    } else if (shouldStoreDefaultDistrict !== "" && value !== "") {
      setShouldEnableUpdateUserButton(false);
    } else if (shouldStoreDefaultDistrict === "" && value !== "") {
      setShouldEnableUpdateUserButton(true);
    } else {
      setShouldEnableUpdateUserButton(false);
    }
  };

  // function to update the user details.
  const userDetails = {};
  function handleUserDetails() {
    // created an array with page numbers.
    const pageNumbers = [1, 2, 3, 4, 5, 6];

    // // filtered out the onwership from the storeUserManagementObj.page access array since from the backend automatically onwership will be added if satsource or monitoring is selected.
    const filterOwnershipReport =
      storeUserManagementObj.pageAccess &&
      storeUserManagementObj.pageAccess?.filter(
        (items) => items !== "ownership",
      );

    // looped over the filtered array and created an object then assigned the page_no and default based on the conditions.
    const storePageAccess = [];
    filterOwnershipReport?.forEach((items) => {
      storePageAccess.push({
        page_no:
          items === "satsource"
            ? pageNumbers[0]
            : items === "satsource & ownership"
              ? pageNumbers[3]
              : pageNumbers[5],
        default: items === storeUserManagementObj.defaultLanding ? true : false,
      });
    });

    const renderUserRoleInNumber = () => {
      const rolesMap = {
        user: 2,
        2: 2,
        admin: 1,
        1: 1,
        // add more mappings as needed
      };
      return rolesMap[shouldStoreRole] || 1;
    };

    userDetails.name = inputValues.name ?? storeUserManagementObj.name;
    userDetails.email = inputValues.email ?? storeUserManagementObj.email;
    userDetails.phoneNo = inputValues.phoneNo ?? storeUserManagementObj.phoneNo;
    userDetails.state = storeStateName ?? storeUserManagementObj.state;
    userDetails.district = storeDistrictName ?? storeUserManagementObj.district;
    userDetails.branch =
      storeDefaultCreditHubValue ?? storeUserManagementObj.branch;
    userDetails.designation =
      inputValues.designation ?? storeUserManagementObj.designation;
    userDetails.userRole = renderUserRoleInNumber() ?? parseInt(storeUserRole);

    // Create an empty array
    const values = [];
    // Extract the values from the object and store them in the array
    for (const obj of shouldStoreReportAccess) {
      for (const val of Object.values(obj)) {
        values.push(val);
      }
    }

    // reomve the label key and value from the report access array.
    const filteredArray = shouldStorePageAccess.map(
      ({ page_name, user_id, ...rest }) => rest,
    );

    if (
      storeDefaultLandingValue.label === "Satsource" ||
      storeDefaultLandingValue.label === "satsource & ownership" ||
      storeDefaultLandingValue.label === "ownership"
    ) {
      storeDefaultPage = true;
    }

    // below I am checking if the page no matches with shouldStoreDefaultpage.defaultpageno value. If it does match then I am updating the default value with dynamic stored default page value also I am returning true outside the if block so that it indludes all the items in the filteredReportAccessData array.
    const filteredPageAccessData = filteredArray.filter((items) => {
      if (items.page_no === storeDefaultLandingValue.defaultPageNo) {
        items.default = storeDefaultPage;
      }
      return true;
    });

    const requestBody = {
      pageAccess:
        filteredPageAccessData.length === 0
          ? storePageAccess
          : filteredPageAccessData,
      userId: storeTheUserId,
      userDetails: userDetails,
    };

    _getStorageValue(USER_ID).then((user_id) => {
      updateDetails(
        requestBody,
        successUpdateUserDetailsCallBack,
        failureUpdateUserDetailsCallBack,
      );
    });
  }

  const successUpdateUserDetailsCallBack = (response) => {
    setShouldShowEditUserModal(false);
    setShouldStoreDistricts([]);
    setShouldStoreDefaultDistrict("");
    setStoreDefaultCreditHubValue("");
    setShouldStoreCreditHubNames([]);
    setInputValues([]);
    setShouldStoreRole("");
    setShouldEnableUpdateUserButton(false);
    // calling the user api here to rerender the user table in order to see the updated value.()
    setShowUpdationToast(true);
    _getStorageValue(USER_ID).then(() => {
      userManagementTableData(
        successUserManagementCallBack,
        failureUserManagementLandCallBack,
        shouldStorePageNo,
      );
    });
  };

  const failureUpdateUserDetailsCallBack = (error) => {
    console.log(error, "===== error");
  };

  // function to handle the user management table search.
  const handleUserSearch = debounce((value) => {
    const searchTerm = value.trim().toLowerCase();
    setStoreSearchFieldValue(value.trim().toLowerCase());
    setTotalRows(0);
    if (searchTerm) {
      const result = userManagementData.filter((filteredData) => {
        return (
          filteredData.userName
            ?.toString()
            .toLowerCase()
            .includes(searchTerm) ||
          filteredData.state?.toString().toLowerCase().includes(searchTerm) ||
          filteredData.userBranch
            ?.toString()
            .toLowerCase()
            .includes(searchTerm) ||
          filteredData.reportAccess
            ?.toString()
            .toLowerCase()
            .includes(searchTerm) ||
          filteredData.designation
            ?.toString()
            .toLowerCase()
            .includes(searchTerm)
        );
      });
      setShouldStoreUserManagementData(result);
    } else {
      setShouldStoreUserManagementData(userManagementData);
      setStoreSearchFieldValue("");
    }
  }, 100);

  // function to handle the state selection.
  function handleStateSelection(states, reason) {
    setShouldStoreCreditHubs("");
    let state = states;
    if (state?.indexOf(" ") !== -1) {
      // if the state name contains a space, replace spaces with %20
      state = state?.replace(/\s+/g, "%20");
    }
    // fetch the company id from the local storage.
    const storeCompanyId = parseInt(localStorage.getItem("companyId"));
    setShouldStoreStatesFromDropdown(states);
    setShouldEnableDisableButton(false);

    _getStorageValue(USER_ID).then(() => {
      creditHubDataApi(
        storeCompanyId,
        state,
        successCreditHubCallBack,
        failureCreditHubCallBack,
      );
    });
    if (reason === "clear") {
      setShouldStoreCreditHubs("");
      if (
        states === null &&
        shouldStoreCreditHubs !== "" &&
        storeTheReportAccess.length === 0
      ) {
        setShouldEnableDisableButton(true);
      } else if (
        storeTheReportAccess.length !== 0 &&
        (!states || !shouldStoreCreditHubs)
      ) {
        setShouldEnableDisableButton(false);
      } else if (
        states !== null &&
        shouldStoreCreditHubs !== "" &&
        storeTheReportAccess.length !== 0
      ) {
        setShouldEnableDisableButton(false);
      } else {
        setShouldEnableDisableButton(true);
      }
      _getStorageValue(USER_ID).then(() => {
        userManagementTableData(
          successUserManagementCallBack,
          failureUserManagementLandCallBack,
          shouldStorePageNo,
        );
      });
    }

    const successCreditHubCallBack = (response) => {
      const fetchTheCreditHubs = [];
      response.data.forEach((creditHubs) => {
        fetchTheCreditHubs.push(creditHubs.credit_hub);
      });
      setShouldStoreCreditHubNames(fetchTheCreditHubs);
    };

    const failureCreditHubCallBack = (error) => {
      console.log(error, "===== error calling credit hub api");
    };
  }

  // function to handle the credit hub selection.
  function handleCreditHubSelection(creditHubs, reason) {
    setShouldStoreCreditHubs(creditHubs);
    if (reason === "clear") {
      if (
        creditHubs &&
        (shouldStoreStatesFromDropdown !== "" ||
          shouldStoreStatesFromDropdown == null ||
          storeTheReportAccess.length !== 0)
      ) {
        setShouldEnableDisableButton(true);
      } else if (shouldStoreStatesFromDropdown == null && creditHubs == null) {
        setShouldEnableDisableButton(true);
      } else if (shouldStoreStatesFromDropdown !== "" && creditHubs === null) {
        setShouldEnableDisableButton(false);
      } else if (
        shouldStoreStatesFromDropdown === "" ||
        storeTheReportAccess.length === 0
      ) {
        setShouldEnableDisableButton(true);
      } else {
        setShouldEnableDisableButton(true);
      }
      _getStorageValue(USER_ID).then(() => {
        userManagementTableData(
          successUserManagementCallBack,
          failureUserManagementLandCallBack,
          shouldStorePageNo,
        );
      });
    }
  }

  // function to handle the report access dropdown from the filter component.
  const handleFilterDropDownReportAccess = (value, reason) => {
    shouldStoreTheReportAccess(value);
    if (value.length !== 0) {
      setShouldEnableDisableButton(false);
    } else if (reason === "removeOption") {
      if (
        shouldStoreStatesFromDropdown === "" ||
        shouldStoreStatesFromDropdown === null ||
        ((shouldStoreCreditHubs === "" || shouldStoreCreditHubs === null) &&
          value.length !== 0)
      ) {
        setShouldEnableDisableButton(true);
      } else if (
        (shouldStoreStatesFromDropdown !== "" ||
          shouldStoreStatesFromDropdown !== null) &&
        (shouldStoreCreditHubs !== "" || shouldStoreCreditHubs !== null)
      ) {
        setShouldEnableDisableButton(false);
      } else {
        setShouldEnableDisableButton(true);
      }
      _getStorageValue(USER_ID).then(() => {
        userManagementTableData(
          successUserManagementCallBack,
          failureUserManagementLandCallBack,
          shouldStorePageNo,
        );
      });
    }
  };

  // function to apply the filters.
  function handleSearchFilter() {
    const convertToLowerCase = shouldStoreStatesFromDropdown?.toLowerCase();
    const convertCreditHubsName = shouldStoreCreditHubs?.toLowerCase();
    const reportAccess = [...storeTheReportAccess];
    let filteredResult = [...userManagementData];
    let filteredResultForReportAccess = [];
    setTotalRows(0);

    if (convertToLowerCase) {
      filteredResult = filteredResult.filter((item) =>
        item.state?.toString().toLowerCase().includes(convertToLowerCase),
      );
    }
    if (convertCreditHubsName) {
      filteredResult = filteredResult.filter((item) =>
        item.userBranch
          ?.toString()
          .toLowerCase()
          .includes(convertCreditHubsName),
      );
    }
    if (reportAccess.length !== 0) {
      // functionality for the report access dropdowns.
      filteredResult = filteredResult.filter((items) => {
        return items.reportAccess.some((innerData) => {
          return reportAccess.some((reports) => {
            return (
              reports &&
              reports.page_name &&
              innerData === reports.page_name.toLowerCase()
            );
          });
        });
      });
    }

    setShouldStoreUserManagementData(
      filteredResultForReportAccess.length !== 0
        ? filteredResultForReportAccess
        : filteredResult,
    );
  }

  return (
    <>
      <div className="sage-user-manual-sections">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Adminsidepanel
                shouldStoreStates={shouldStoreStates}
                handleUserSearch={handleUserSearch}
                handleSearchFilter={handleSearchFilter}
                handleStateSelection={handleStateSelection}
                handleFilterDropDownReportAccess={
                  handleFilterDropDownReportAccess
                }
                shouldEnableDisableButton={shouldEnableDisableButton}
                handleCreditHubSelection={handleCreditHubSelection}
                shouldStoreCreditHubNames={shouldStoreCreditHubNames}
                shouldStoreCreditHubs={shouldStoreCreditHubs}
                shouldStoreStatesFromDropdown={shouldStoreStatesFromDropdown}
              />
            </Grid>
            <Grid item xs={10}>
              <div className="sage-user-report-wrapper">
                <HeaderLayoutComp tabNav={tabNav} />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="outlined"
                    startIcon={<GroupAddIcon style={{ color: "#3342B5" }} />}
                    style={{
                      color: "#3342B5",
                      border: "1px solid #3342B5",
                      background: "white",
                      width: "190px",
                    }}
                    onClick={(e) => {
                      history.push("/addUser");
                    }}
                  >
                    Add User
                  </Button>
                </div>
                <div className="user-management-table-wrapper">
                  <div className="slide-container">
                    <Usermanagementtable
                      shouldStoreUserManagementData={
                        shouldStoreUserManagementData
                      }
                      shouldShowUserManagementDataLoader={
                        shouldShowUserManagementDataLoader
                      }
                      handleInputChange={handleInputChange}
                      tableHeight={tableHeight}
                      handleViewUserModal={handleViewUserModal}
                      shouldShowViewUserModal={shouldShowViewUserModal}
                      viewUserManagementData={viewUserManagementData}
                      handleViewModalClose={handleViewModalClose}
                      shouldShowEditUserModal={shouldShowEditUserModal}
                      handleEditUserModal={handleEditUserModal}
                      handleEditModalClose={handleEditModalClose}
                      handleUserInput={handleUserInput}
                      shouldStoreRole={shouldStoreRole}
                      handleRoleChange={handleRoleChange}
                      inputValues={inputValues}
                      handleUserDetails={handleUserDetails}
                      shouldStoreStates={shouldStoreStates}
                      storeUserManagementObj={storeUserManagementObj}
                      handleStateChange={handleStateChange}
                      handleDistrictChange={handleDistrictChange}
                      shouldStoreDistricts={shouldStoreDistricts}
                      shouldShowSwitchLoader={shouldShowSwitchLoader}
                      shouldStoreSelectedUserDetails={
                        shouldStoreSelectedUserDetails
                      }
                      handlePageAccess={handlePageAccess}
                      shouldStorePageAccess={shouldStorePageAccess}
                      handleDefaultLandingSelection={
                        handleDefaultLandingSelection
                      }
                      shouldStoreDefaultDistrict={shouldStoreDefaultDistrict}
                      shouldStoreUserRegionFilterDistricts={
                        shouldStoreUserRegionFilterDistricts
                      }
                      shouldEnableUpdateUserButton={
                        shouldEnableUpdateUserButton
                      }
                      storeDefaultLandingValue={storeDefaultLandingValue}
                      invalidNumber={invalidNumber}
                      shouldStoreCreditHubNames={shouldStoreCreditHubNames}
                      handleCreditHubChange={handleCreditHubChange}
                      storeDefaultCreditHubValue={storeDefaultCreditHubValue}
                      shouldStorePageNo={shouldStorePageNo}
                      setShouldStorePageNo={setShouldStorePageNo}
                      totalRows={totalRows}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Snackbar
              open={showUpdationToast}
              autoHideDuration={2000}
              onClose={() => {
                setShowUpdationToast(false);
              }}
            >
              <Alert
                onClose={() => {
                  setShowUpdationToast(false);
                }}
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  color: "#3342B5",
                  border: "1px solid #3342B5",
                }}
              >
                User updated successfully!
              </Alert>
            </Snackbar>
          </Grid>
        </Box>
      </div>
    </>
  );
}
