// url
import {
  getGenerateStateFilter,
  getLandUrlFilter,
  getAddDataBase,
  getSurveyNumbers,
  getExistingRefId,
  newRefId,
  getGenerateFilter,
  userPermissionRole,
  shapeFile,
} from "../../common/urls";
// api
import { apiCall, apiCallDownloadZip } from "../../common/connect";

// constant
import { GET, POST, ACCESS_TOKEN } from "../../common/constants";
import { _getStorageValue } from "../../common/localStorage";

// State
export function getRegions(
  userId,
  getStoreRadioButtonSelection,
  successCallBack,
  failureCallBack,
) {
  const cleanedValue = getStoreRadioButtonSelection.replace(/\s&\s/g, "&");
  const url = getGenerateStateFilter(userId, cleanedValue);
  const onSuccess = (response) => {
    successCallBack(response.data);
  };
  const onFailure = (response) => {
    failureCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// get dynamic regions.
export function getDynamicRegions(
  regionId,
  getStoreRadioButtonSelection,
  getTheCurrentLevel,
  successCallBack,
  failureCallBack,
) {
  const cleanedValue = getStoreRadioButtonSelection.replace(/\s&\s/g, "&");
  const url = getGenerateFilter(regionId, cleanedValue);
  const onSuccess = (response) => {
    successCallBack(response.data, getTheCurrentLevel);
  };
  const onFailure = (response) => {
    failureCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// All 4 Land url
export function landList(
  regionId,
  getStoreRadioButtonSelection,
  successLandCallBack,
  failureLandCallBack,
) {
  const cleanedValue = getStoreRadioButtonSelection.replace(/\s&\s/g, "&");
  const url = getLandUrlFilter(regionId, cleanedValue);

  const onSuccess = (response) => {
    var landdata = response.data;
    successLandCallBack(landdata);
  };
  const onFailure = (response) => {
    failureLandCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

export function landListMapView(
  regionId,
  getStoreRadioButtonSelection,
  successLandCallBack,
  failureLandCallBack,
  storeIndex,
  setStoreIndex,
) {
  const cleanedValue = getStoreRadioButtonSelection.replace(/\s&\s/g, "&");
  const url = getLandUrlFilter(regionId, cleanedValue);

  const onSuccess = (response) => {
    setTimeout(() => {
      setStoreIndex(storeIndex + 1);
    }, 100);
    var landdata = response.data;
    successLandCallBack(landdata);
  };
  const onFailure = (response) => {
    failureLandCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

//AddDataBase Table Data Post
export function AddTableData(
  refNo,
  surveyNo,
  successAddTableDataCallBack,
  failureAddTableDataCallBack,
) {
  const url = getAddDataBase(refNo, surveyNo);

  const onSuccess = (response) => {
    successAddTableDataCallBack(response);
  };
  const onFailure = (response) => {
    failureAddTableDataCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, onSuccess, onFailure, token);
    }
  });
}

export function fetchSurveyNumbers(
  regionId,
  onSuccessCallback,
  onFailureCallback,
) {
  const url = getSurveyNumbers(regionId);
  const onSuccess = (response) => {
    onSuccessCallback(response.data.regions);
  };
  const onFailure = (response) => {
    onFailureCallback(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// existing ref id
export function checkExistingRefId(
  refNo,
  onSuccessCallback,
  onFailureCallback,
) {
  const url = getExistingRefId(refNo);
  const onSuccess = (response) => {
    const checkRefId = response.data;
    onSuccessCallback(checkRefId);
  };
  const onFailure = (response) => {
    onFailureCallback(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// api for new reference id
export function fetchNewReferenceId(
  requestPayload,
  successNewRefIdCallback,
  failureNewRefIdCallBack,
) {
  const url = newRefId();
  const onSuccess = (response) => {
    const newRefId = response.data;
    successNewRefIdCallback(newRefId);
  };
  const onFailure = (response) => {
    failureNewRefIdCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, requestPayload, onSuccess, onFailure, token);
    }
  });
}

// user permission role api call
export function userPermission(
  successUserPermissionCallBack,
  failureUserPermissionCallBack,
) {
  const url = userPermissionRole();
  const onSuccess = (response) => {
    successUserPermissionCallBack(response);
  };
  const onFailure = (response) => {
    failureUserPermissionCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// api integration to download the shape file..
export function downloadShapeFile(
  requestPayload,
  successDownloadShapeFileCallback,
  failureDownloadShapeFileCallback,
) {
  const url = shapeFile();
  const onSuccess = (response) => {
    const shapeFile = response;
    successDownloadShapeFileCallback(shapeFile);
  };
  const onFailure = (response) => {
    console.log(response, "===== failed response");
    failureDownloadShapeFileCallback(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCallDownloadZip(
        POST,
        url,
        requestPayload,
        onSuccess,
        onFailure,
        token,
      );
    }
  });
}
