import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import searchImage from "../../../assets/images/search.svg";
import Customdropdown from "./Customdropdown";
import { Renderprodtable } from "./Prodtable";
import { Renderbetatable } from "./Betatable";
import Companyinfomodal from "./Company-info/Companyinfomodal";
// import Betatable from "./Betatable";
import "../monitoringModuleStyles.css";

export default function ApiExplorerDashboard({
  showDropDown,
  handleLegendOpen,
  handleListitem,
  shouldStoreListNames,
  handleCustomTabs,
  activeButton,
  handleCompanyInfoModal,
  openModal,
  handleModalClose,
  companyInfoModalData,
  modalHeading,
  counters,
  handleCopyToClipboard,
}) {
  const tableBodyHeight = "calc(100vh - 254px)";
  const data = [
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
  ];

  const theme = createTheme({
    overrides: {
      ".MuiPaper-root": {
        boxShadow: "none!important", // Remove box shadow
        border: "2px solid blue", // Add a blue border
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#FF0000",
            padding: "0",
          },
        },
      },
      MUIDataTableToolbar: {
        root: {
          height: "0px",
          visibility: "hidden",
        },
      },
    },
  });

  const options = {
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: false,
    // responsive: "scroll",
    resizableColumns: false,
    tableBodyHeight,
    filter: false,
    download: false,
    print: false,
    search: false,
    rowsPerPage: 20,
    rowsPerPageOptions: [5],
    // count: totalRows,
    rowStyle: { height: 50 },
    // page: shouldStorePageNo,
    serverSide: true,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    // onTableChange: (action, tableState) => {
    //     if (action === "changePage") {
    //         //setPage(tableState.page);
    //         setShouldStorePageNo(tableState.page)
    //     }
    // },
    // rowsSelected: props.storeAllRowsSelected.map((row) => row.index),
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
    },
    selectToolbarPlacement: "none",
    // Disable the table toolbar
    toolbar: false,
  };

  const buttonsArr = [
    {
      buttonId: 1,
      buttonName: "PROD",
    },
    {
      buttonId: 2,
      buttonName: "BETA",
    },
  ];

  return (
    <section className="internal-module-wrapper">
      <div className="search-input-styles custom-search-input-wrapper">
        <h1>Requests</h1>
        <img src={searchImage} alt="Search Image" style={{ left: "145px" }} />
        <input type="text" placeholder="Search by Client or API Key" />
        <Customdropdown
          showDropDown={showDropDown}
          handleLegendOpen={handleLegendOpen}
          handleListitem={handleListitem}
          shouldStoreListNames={shouldStoreListNames}
        />
      </div>
      <div className="table-layout">
        <div className="custom-tabs">
          <Stack spacing={1} direction="row">
            {buttonsArr.map((items) => {
              return (
                <Button
                  variant="text"
                  key={items.buttonId}
                  onClick={() => handleCustomTabs(items.buttonId)}
                  className={
                    activeButton === items.buttonId
                      ? "active-button"
                      : "inactive-button"
                  }
                >
                  {items.buttonName}
                </Button>
              );
            })}
          </Stack>
        </div>
        <ThemeProvider theme={theme}>
          <Companyinfomodal
            openModal={openModal}
            handleModalClose={handleModalClose}
            companyInfoModalData={companyInfoModalData}
            modalHeading={modalHeading}
            counters={counters}
          />
          <MUIDataTable
            data={data}
            columns={
              activeButton === 1
                ? Renderprodtable(handleCompanyInfoModal, handleCopyToClipboard)
                : Renderbetatable()
            }
            options={options}
          />
        </ThemeProvider>
        {/* <Betatable/> */}
      </div>
      )
    </section>
  );
}
