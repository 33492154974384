// react
import React, { useRef, useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// plugin
import { makeStyles } from "@material-ui/core/styles";

// Action
import { logout } from "../../layouts/login-layout/authentication";

// notification, user manual and logout import.
import HeaderRightComponent from "./HeaderRightComponent.js";

// style
import "./header.css";

import { _getStorageValue, removeAllCookies } from "../../common/localStorage";
import { apiCall } from "../../common/connect";
import { getNotifications } from "../../common/urls";
import { USER_ID, ACCESS_TOKEN, GET } from "../../common/constants";
// header tab nav data import.
import { commonTabNavData, adminPortalTabNavData } from "./tabNavData.js";

const useStyles = makeStyles({
  notificationStateExpand: {
    backgroundColor: "#E8F3F4 !important",
    borderRadius: "50% !important",
  },
});

const HeaderLayoutComp = React.memo((props) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  //Notification dropdown
  const [notificationClicked, setNotificationClicked] = React.useState(0);
  // to set the notification dot state.
  const [showNotificationDot, setShowNotificationDot] = React.useState(false);
  // show/hide the dropdown menu.
  const [shouldShowDropDownMenu, setShouldShowDropDownMenu] = useState(false);
  const notificationRef = useRef();

  // Drop Down
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const triggerLogout = () => {
    logout(successLogoutCallBack, failureLogoutCallBack);
  };
  const successLogoutCallBack = (response) => {
    // removeAllCookies();
    setTimeout(() => {
      window.location.reload();
    }, 100);
    console.log(response);
  };
  const failureLogoutCallBack = (response) => {
    console.log(response);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const handleClick = (event) => {
    setShouldShowDropDownMenu(!shouldShowDropDownMenu);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getNotificationsData(
    userID,
    successNotificationDataCallBack,
    failureNotificationDataCallBack,
  ) {
    const url = getNotifications(userID);

    const onSuccess = (response) => {
      let notificationDataTable = response.data;
      successNotificationDataCallBack(notificationDataTable);
    };
    const onFailure = (response) => {
      failureNotificationDataCallBack(response);
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
      if (token !== undefined && token !== null) {
        apiCall(GET, url, "", onSuccess, onFailure, token);
      }
    });
  }

  const mapDataSet = (res) => {
    let dataObj = [];

    for (var i = 0; i < res.length; i++) {
      if (!res[i].read_status) {
        setShowNotificationDot(true);
        break;
      }
    }
  };

  const successNotificationDataCallBack = (response) => {
    mapDataSet(response);
  };

  const failureNotificationDataCallBack = (response) => {};

  const getNotificationData = () => {
    _getStorageValue(USER_ID).then((userID) => {
      getNotificationsData(
        userID,
        successNotificationDataCallBack,
        failureNotificationDataCallBack,
      );
    });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        notificationClicked &&
        notificationRef.current &&
        !notificationRef.current.contains(e.target)
      ) {
        setNotificationClicked(0);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [notificationClicked]);

  useEffect(() => {
    getNotificationData();
    const interval = setInterval(() => {
      getNotificationData();
    }, 30000);

    if (location.pathname === "/sageDashboardNotification") {
      dispatch({ type: "NOTIFICATION_NOT_EXPANDED" });
    }
    return () => clearInterval(interval);
  }, []);

  const validTabs = [
    "/generate-report",
    "/archive-report",
    "/sageDashboardNotification",
    "/sageDashboardManual",
    "/satScoreReportManual",
    "/monitoringReportManual",
    "/sageDashboardFAQ",
    "/satScoreReportFAQ",
    "/monitoringReportFAQ",
  ];

  // for the time being created this array to disable the other two links later we shall remove this.
  const adminTabs = [];

  // storing all the monitoring internal dashboard links inside an array.
  const monitoringModuleLinks = [
    "/monitoringInternal",
    "/users",
    "/banks",
    "/ownership",
    "/region",
    "/retro",
    "/logsandmonitor",
  ];

  return (
    <div className="header-sections">
      {monitoringModuleLinks.includes(history.location.pathname) ? (
        <p>Satsure Internal Admin</p>
      ) : (
        <ul className="header-left">
          {validTabs.includes(props.tabNav)
            ? commonTabNavData.map((navItems, index) => {
                return (
                  <li
                    className={
                      history.location.pathname === navItems.pageLink
                        ? "active"
                        : ""
                    }
                    key={index}
                  >
                    <Link className="section-list" to={navItems.pageLink}>
                      {navItems.pageName}
                    </Link>
                  </li>
                );
              })
            : adminPortalTabNavData.map((navItems, index) => {
                return (
                  <li
                    className={
                      history.location.pathname === navItems.pageLink ||
                      (index === 0 && history.location.pathname === "/addUser")
                        ? "active"
                        : ""
                    }
                    key={index}
                  >
                    <Link
                      className="section-list"
                      to={
                        adminTabs.includes(navItems.pageLink)
                          ? "#"
                          : navItems.pageLink
                      }
                    >
                      {navItems.pageName}
                    </Link>
                  </li>
                );
              })}
        </ul>
      )}
      <ul className="header-right">
        <li>
          <HeaderRightComponent
            triggerLogout={triggerLogout}
            notificationClicked={notificationClicked}
            setNotificationClicked={setNotificationClicked}
            notificationRef={notificationRef}
            open={open}
            showNotificationDot={showNotificationDot}
            handleClose={handleClose}
            location={location}
            classes={classes}
            handleClick={handleClick}
            anchorEl={anchorEl}
            shouldShowDropDownMenu={shouldShowDropDownMenu}
            setShouldShowDropDownMenu={setShouldShowDropDownMenu}
          />
        </li>
      </ul>
    </div>
  );
});

export default HeaderLayoutComp;
