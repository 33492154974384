import React from "react";
import "./styles.css";
import { useSelector } from "react-redux";

export default function Toaster({ showToaster, storeStatusName }) {
  let checkPendingStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkPendingStatus,
  );
  let checkOnHoldStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkOnHoldStatus,
  );
  let checkApprovedStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkApprovedStatus,
  );
  let checkDeclinedStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkDeclinedStatus,
  );
  return (
    <>
      {showToaster && (
        <div className="toaster-wrapper">
          {checkPendingStatus ||
          checkOnHoldStatus ||
          checkApprovedStatus ||
          checkDeclinedStatus ? (
            <h1>Moved to "{storeStatusName}"</h1>
          ) : (
            <h1>Status set to "{storeStatusName}"</h1>
          )}
        </div>
      )}
    </>
  );
}
