import { _getStorageValue } from "../../common/localStorage";
import { apiCall } from "../../common/connect";
// constant
import { GET, POST, ACCESS_TOKEN } from "../../common/constants";
import {
  stateData,
  districtDevData,
  districtProdData,
  stateProdData,
} from "../../common/urls";

// Get statewise dev Data.
export function fetchStateWiseData(successStateWiseData, failureStateWiseData) {
  const url = stateData();
  const onSuccess = (response) => {
    let stateWiseCount = response;
    successStateWiseData(stateWiseCount);
  };
  const onFailure = (response) => {
    console.log(response, "===== error response");
    failureStateWiseData(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// get statewise prod data.
export function fetchStateWiseProdata(
  successStateWiseProdData,
  failureStateWiseProdData,
) {
  const url = stateProdData();
  const onSuccess = (response) => {
    let stateWiseCount = response;
    successStateWiseProdData(stateWiseCount);
  };
  const onFailure = (response) => {
    console.log(response, "===== error response");
    failureStateWiseProdData(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// get district level dev data.
export function fetchDistrictWiseDevData(
  stateRegion,
  level,
  pageNo,
  successDistrictWiseDataCallBack,
  failureDistrictWiseDataCallBack,
) {
  const url = districtDevData(stateRegion, level, pageNo);
  const onSuccess = (response) => {
    let stateWiseCount = response;
    successDistrictWiseDataCallBack(stateWiseCount);
  };
  const onFailure = (response) => {
    console.log(response, "===== error response");
    failureDistrictWiseDataCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// get district level prop data.
export function fetchDistrictWiseProdData(
  stateRegion,
  level,
  pageNo,
  successDistrictWiseDataCallBack,
  failureDistrictWiseDataCallBack,
) {
  const url = districtProdData(stateRegion, level, pageNo);
  const onSuccess = (response) => {
    let stateWiseCount = response;
    successDistrictWiseDataCallBack(stateWiseCount);
  };
  const onFailure = (response) => {
    console.log(response, "===== error response");
    failureDistrictWiseDataCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}
