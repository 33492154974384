import React, { useMemo } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import modalClose from "../../../assets/images/modal-close.svg";
import Surveydetails from "./Surveydetails";
import RenderGeoJson from "./Rendergeojson";
import MapPopup from "./Map-popup";
import loadingPage from "../../../assets/images/Loading_page.gif";
import { MapContainer, ScaleControl } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const imgStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  zIndex: "500",
};

const mapViewLoaderStyles = {
  position: "absolute",
  top: "50px",
  zIndex: "2000",
  left: "0",
  right: "0",
  margin: "auto",
  width: "90%",
  padding: "15px",
  fontSize: "12px",
  height: "520px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function Mapview({
  mapViewModal,
  setMapViewDropdown,
  setMapViewModal,
  getSurveyDetails,
  storeRefNo,
  handleDropDownToogle,
  mapViewDropdown,
  setMap,
  requestTime,
  mapViewLoader,
  setStoreSurveyNoDetails,
  setStoreIndex,
}) {
  const renderMapContainer = useMemo(() => {
    return (
      <MapContainer
        whenCreated={(map) => setMap(map)}
        center={[20.5937, 78.9629]}
        zoom={4}
        maxZoom={19}
        minZoom={4}
        scrollWheelZoom={true}
      >
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyB_SAzfU9SgPNdtVRb-yArJZ2a08CPFquE"
          type={"hybrid"}
          useGoogMapsLoader={true}
        />
        <ScaleControl position="bottomleft" />
        <MapComp mapData={getSurveyDetails} />
        <RenderGeoJson geoJSONData={getSurveyDetails} />
      </MapContainer>
    );
  }, [getSurveyDetails]);

  function closeMapRelatedPopups() {
    setMapViewModal(false);
    setMapViewDropdown(false);
    setStoreSurveyNoDetails([]);
    setStoreIndex(0);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={mapViewModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={Boolean(mapViewModal)}>
          <Box sx={style}>
            <img
              src={modalClose}
              alt="Modal Close"
              style={imgStyles}
              onClick={() => closeMapRelatedPopups()}
            />
            {mapViewLoader && (
              <div style={mapViewLoaderStyles}>
                <img
                  src={loadingPage}
                  alt="Loading Page"
                  style={{ width: "80%" }}
                />
              </div>
            )}
            <Surveydetails
              storeRefNo={storeRefNo}
              handleDropDownToogle={handleDropDownToogle}
              mapViewDropdown={mapViewDropdown}
              getSurveyDetails={getSurveyDetails}
              requestTime={requestTime}
            />
            {renderMapContainer}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const MapComp = ({ mapData }) => {
  return (
    <>
      {mapData &&
        mapData.length > 0 &&
        mapData.map((fetchGeometryDetails) => {
          return (
            <div key={fetchGeometryDetails.id}>
              <MapPopup
                mapDetails={JSON.parse(fetchGeometryDetails.centroid)}
                geoDetails={fetchGeometryDetails}
              />
            </div>
          );
        })}
    </>
  );
};
