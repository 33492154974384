import React, { useMemo } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import select_report from "../../assets/images/select_report.png";
// style
import "./checkbox-modal-styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};

export default function Reportregenerationmodal({
  showReportRegenerationmodal,
  disabledButton,
  handleReportRegenerationCheckboxes,
  handleSelectedReportRegeneration,
  handleCheckboxModalClose,
  tableData,
  storeAllRowsSelected,
  storeIndividualTableRowData,
  showSingleReportFlow,
}) {
  // get default page local storage.
  const getDefaultPage =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];

  const sortedArray = [
    {
      default_page: false,
      page_name: "SatSource",
      page_no: 1,
      user_id: "0cc810cc-b3f5-432b-8142-97e287dfc1c2",
    },
    {
      default_page: false,
      page_name: "ownership",
      page_no: 6,
      user_id: "0cc810cc-b3f5-432b-8142-97e287dfc1c2",
    },
  ];

  // for role based access.
  const newArray = getDefaultPage.map((item) => {
    if (item.page_no === 1) {
      return "1";
    }
    if (item.page_no === 2) {
      return "2";
    }
    if (item.page_no === 3) {
      return "3";
    }
    if (item.page_no === 4) {
      return "4";
    }
    if (item.page_no === 6) {
      return "6";
    }
    return "";
  });

  // function to disable the checkboxes only if there is a null value for sourcing report.
  const checkForSourcingNullValues = () => {
    const storeSourcingnullEntries = [];
    storeAllRowsSelected.forEach((items) => {
      tableData.forEach((tableRowData, index) => {
        if (items.index === index) {
          if (tableRowData.farmReport === null) {
            return storeSourcingnullEntries.push(tableRowData.farmReport);
          }
        }
      });
    });
    return storeSourcingnullEntries;
  };

  // function to disable the checkboxes only if there is a null value for ownership report.
  const checkForOwnershipNullValues = () => {
    const storeOwnershipnullEntries = [];
    storeAllRowsSelected.forEach((items) => {
      tableData.forEach((tableRowData, index) => {
        if (items.index === index) {
          if (tableRowData.farmMonitoringReport === null) {
            return storeOwnershipnullEntries.push(
              tableRowData.farmMonitoringReport,
            );
          }
        }
      });
    });
    return storeOwnershipnullEntries;
  };

  function disableSourcingCheckbox() {
    const arrayIncludes = !newArray.includes("1") && !newArray.includes("4");
    // Check if showSingleReportFlow is false
    if (showSingleReportFlow === false) {
      return storeIndividualTableRowData.farmReport === null || arrayIncludes;
    } else {
      return checkForSourcingNullValues().includes(null) || arrayIncludes;
    }
  }

  function disableOwnershipCheckbox() {
    const arrayIncludes = !newArray.includes("4") && !newArray.includes("6");
    if (showSingleReportFlow === false) {
      return (
        storeIndividualTableRowData.farmMonitoringReport === null ||
        arrayIncludes
      );
    } else {
      return checkForOwnershipNullValues().includes(null) || arrayIncludes;
    }
  }

  const renderCheckBoxComponent = useMemo(() => {
    return (
      showReportRegenerationmodal && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showReportRegenerationmodal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showReportRegenerationmodal}>
            <Box sx={style} className="checkBoxModalStyles">
              <Typography id="transition-modal-description">
                <span className="select-report-heading">
                  <img src={select_report} alt="Select Image" />
                  Select Report
                </span>
              </Typography>
              <div className="checkbox-component-wrapper">
                {sortedArray.map((checkboxes) => {
                  return (
                    <FormGroup
                      className="checkbox-form-group"
                      key={checkboxes.page_no}
                    >
                      <FormControlLabel
                        disabled={
                          checkboxes.page_name === "SatSource"
                            ? disableSourcingCheckbox()
                            : checkboxes.page_name === "ownership"
                              ? disableOwnershipCheckbox()
                              : null
                        }
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleReportRegenerationCheckboxes(
                                e.target.checked,
                                checkboxes.page_name,
                              )
                            }
                          />
                        }
                        label={`${checkboxes.page_name} ${sortedArray.length === 1 ? "Report" : "Reports"}`}
                        style={{ textTransform: "capitalize" }}
                      />
                    </FormGroup>
                  );
                })}
              </div>

              <div className="checkbox-button-wrapper">
                {disabledButton === true ? (
                  <Button
                    disabled
                    variant="contained"
                    sx={{ boxShadow: "none !important" }}
                  >
                    Regenerate
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="custom-checkbox-button"
                    onClick={handleSelectedReportRegeneration}
                    sx={{ boxShadow: "none !important" }}
                  >
                    Regenerate
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleCheckboxModalClose}
                  sx={{ boxShadow: "none !important" }}
                >
                  <p>Cancel</p>
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )
    );
  }, [
    showReportRegenerationmodal,
    disabledButton,
    handleReportRegenerationCheckboxes,
    handleSelectedReportRegeneration,
    handleCheckboxModalClose,
    tableData,
    storeAllRowsSelected,
    storeIndividualTableRowData,
    showSingleReportFlow,
  ]);

  return <div>{renderCheckBoxComponent}</div>;
}
