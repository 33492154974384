import React from "react";
import styles from "./styles.module.css";
import Missingreportselectpicker from "./Missingreportselectpicker";
import Inputoptions from "./Inputoptions";
import { useSelector } from "react-redux";

export default function Selectpickerelements(props) {
  let radioButtonSelection = useSelector(
    (s) => s.disableRadioButtonReducer.defaultRadioButtonSelection,
  );
  let storeRadioButtonSelection = useSelector(
    (s) => s.radioButtonSelectionReducer.storeRadioButtonSelection,
  );
  let defaultPage = useSelector((s) => s.defaultPageReducer.defaultPage);

  const getStoreRadioButtonSelection = localStorage.getItem(
    "storeRadioButtonSelection",
  );

  // function to render the filter dropdowns based on user permission role.
  function renderFilterDropdowns(dropDownItems, currentlevel) {
    let regions;
    if (
      radioButtonSelection.length === 1 ||
      radioButtonSelection.length === 2 ||
      radioButtonSelection.length === 3 ||
      radioButtonSelection.length === 4 ||
      radioButtonSelection.length === 5 ||
      radioButtonSelection.length === 6
    ) {
      const validValues = ["satsource", "satsource & ownership", "ownership"];

      if (
        validValues.includes(storeRadioButtonSelection) &&
        validValues.includes(getStoreRadioButtonSelection) &&
        currentlevel === "State"
      ) {
        regions = [...dropDownItems];
      } else if (
        (defaultPage[0].page_name == "satsource" ||
          defaultPage[0].page_name == "satsource & ownership" ||
          defaultPage[0].page_name == "ownership" ||
          getStoreRadioButtonSelection === "satsource" ||
          getStoreRadioButtonSelection === "ownership" ||
          getStoreRadioButtonSelection === "satsource & ownership") &&
        currentlevel === "State"
      ) {
        regions = [...dropDownItems];
      } else {
        regions = [...dropDownItems];
      }
    } else {
      regions = [...dropDownItems];
    }
    return regions;
  }

  return (
    <ul className={styles.ulWrapper}>
      {props.filterDropDownComponents.map((dropDownItem, index) => {
        return (
          <li key={index}>
            <Missingreportselectpicker
              selecterLable={`${dropDownItem.currentLevel} *`}
              selecterChoice={0}
              selecterOnChange={props.onSelectDropdownChange}
              selecterOptions={renderFilterDropdowns(
                dropDownItem.regions,
                dropDownItem.currentLevel,
              )}
              currentLevel={dropDownItem.currentLevel}
              selectedCurrentLevel={props.currentlevel}
              index={index}
              selectPickeCloneNames={props.selectPickeCloneNames}
              storeDataFromDistrictLevel={props.storeDataFromDistrictLevel}
            />
            {props.currentlevel === dropDownItem.currentLevel &&
              props.storeDataFromDistrictLevel.includes(
                dropDownItem.currentLevel,
              ) && (
                <Inputoptions
                  missingText={`${dropDownItem.currentLevel}`}
                  showTextBox={props.showTextBox}
                  showMissingText={props.showMissingText}
                  filter={props.filter}
                  currentLevel={props.currentlevel}
                  handleMissingText={props.handleMissingText}
                  handleInputClose={props.handleInputClose}
                  setShowMissingText={props.setShowMissingText}
                  handleTextInput={props.handleTextInput}
                  storeBasedOnCurrentLevelAccess={
                    props.storeBasedOnCurrentLevelAccess
                  }
                />
              )}
          </li>
        );
      })}
    </ul>
  );
}
