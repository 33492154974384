import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import leftArrow from "../../../assets/images/Internal-dashboard/Left.svg";
import { Renderstatewiseprodtable } from "./Prodtabledata";
import { Renderstatewisedevtable } from "./Devtabledata";
import searchImage from "../../../assets/images/search.svg";

const Statewisedatadashboard = React.memo(
  ({
    handleCustomTabs,
    activeButton,
    devDistrictLevelData,
    prodDistrictLevelData,
    showDataLoader,
    showProdDataLoader,
    handleSearchTableData,
    setPageNo,
    totalRows,
  }) => {
    const location = useLocation();
    const history = useHistory();

    // Access the stateName from query parameters
    const stateName = new URLSearchParams(location.search).get("stateName");

    const tableBodyHeight = "calc(100vh - 254px)";

    const theme = createTheme({
      overrides: {
        MUIDataTable: {
          paper: {
            boxShadow: "none !important", // Remove default box-shadow
            border: "1px solid #000", // Add a border
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
              padding: "0",
            },
          },
        },
        MUIDataTableToolbar: {
          root: {
            height: "0px",
            visibility: "hidden",
          },
        },
      },
    });

    const options = {
      fixedHeader: true,
      fixedSelectColumn: false,
      selectableRows: false,
      // responsive: "scroll",
      resizableColumns: false,
      tableBodyHeight,
      filter: false,
      download: false,
      print: false,
      search: false,
      rowsPerPage: 20,
      rowsPerPageOptions: [5],
      count: totalRows,
      rowStyle: { height: 50 },
      // page: shouldStorePageNo,
      serverSide: true,
      filter: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      customToolbar: null,
      onTableChange: (action, tableState) => {
        if (action === "changePage") {
          setPageNo(tableState.page);
        }
      },
      textLabels: {
        pagination: {
          next: "Next Page",
          previous: "Previous Page",
          rowsPerPage: "Rows per page:",
          displayRows: "of",
        },
      },
      selectToolbarPlacement: "none",
      // Disable the table toolbar
      toolbar: false,
    };

    const buttonsArr = [
      {
        buttonId: 1,
        buttonName: "PROD",
      },
      {
        buttonId: 2,
        buttonName: "DEV",
      },
    ];

    return (
      <section className="internal-module-wrapper">
        <div className="statename-wrapper">
          <h1>
            {" "}
            <img
              src={leftArrow}
              alt="Left Arrow"
              onClick={() => history.push("/database")}
            />{" "}
            {stateName}
          </h1>
          <img src={searchImage} alt="Search Image" className="search-img" />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => handleSearchTableData(e.target.value)}
          />
        </div>
        <div className="table-layout">
          <div className="custom-tabs">
            <Stack spacing={1} direction="row">
              {buttonsArr.map((items) => {
                return (
                  <Button
                    variant="text"
                    key={items.buttonId}
                    onClick={() => handleCustomTabs(items.buttonId)}
                    className={
                      activeButton === items.buttonId
                        ? "active-button"
                        : "inactive-button"
                    }
                  >
                    {items.buttonName}
                  </Button>
                );
              })}
            </Stack>
          </div>
          <ThemeProvider theme={theme}>
            {activeButton === 1 ? (
              showProdDataLoader ? (
                <p className="dev-table-loader">Loading...</p>
              ) : null
            ) : showDataLoader ? (
              <p className="dev-table-loader">Loading...</p>
            ) : null}
            <MUIDataTable
              data={
                activeButton === 1
                  ? prodDistrictLevelData
                  : devDistrictLevelData
              }
              columns={
                activeButton === 1
                  ? Renderstatewiseprodtable(prodDistrictLevelData)
                  : Renderstatewisedevtable(devDistrictLevelData)
              }
              options={options}
            />
          </ThemeProvider>
        </div>
      </section>
    );
  },
);

export default Statewisedatadashboard;
