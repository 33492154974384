// react
import React from "react";
import Button from "@mui/material/Button";

// plugin
import { useHistory } from "react-router-dom";

// Action
import { _getStorageValue } from "../../common/localStorage";

// style
import "../../layouts-components/filter-component/filter.css";

// Images
import sideLogo from "../../assets/images/Sage-new-logo.svg";

// data file import.
import { sidePanelUserManualData, sidePanelFaqData } from "../sidePanelData";

const SidePanelFAQandUserMAanual = () => {
  let history = useHistory();

  const handleSidePanelUserManualLink = (pageRoute) => {
    history.push(pageRoute);
  };

  const handleSidePanelfaqLink = (pageRoute) => {
    history.push(pageRoute);
  };

  return (
    <div className="filter-sections" style={{ height: "100vh" }}>
      <div>
        <div className="filter-logo">
          <img src={sideLogo} alt="loginLogo" style={{ width: "100%" }} />
        </div>
      </div>

      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <h4>User's Manual</h4>
        <ul>
          {sidePanelUserManualData.map((items, index) => {
            return (
              <li key={index}>
                <Button
                  className="profile"
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={() => handleSidePanelUserManualLink(items.pageRoute)}
                  style={
                    history.location.pathname === items.pageRoute
                      ? { backgroundColor: "rgba(51, 66, 181, 1)" }
                      : { backgroundColor: "transparent" }
                  }
                >
                  <label
                    style={
                      history.location.pathname === items.pageRoute
                        ? { color: "#fff", cursor: "pointer" }
                        : { color: "rgba(27, 69, 173, 1)", cursor: "pointer" }
                    }
                  >
                    {items.pageName}
                  </label>
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <h4>FAQ</h4>
        <ul>
          {sidePanelFaqData.map((items, index) => {
            return (
              <li key={index}>
                <Button
                  className="profile"
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={() => handleSidePanelfaqLink(items.pageRoute)}
                  style={
                    history.location.pathname === items.pageRoute
                      ? { backgroundColor: "rgba(51, 66, 181, 1)" }
                      : { backgroundColor: "transparent" }
                  }
                >
                  <label
                    style={
                      history.location.pathname === items.pageRoute
                        ? { color: "#fff", cursor: "pointer" }
                        : { color: "rgba(27, 69, 173, 1)", cursor: "pointer" }
                    }
                  >
                    {items.pageName}
                  </label>
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SidePanelFAQandUserMAanual;
