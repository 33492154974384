// react
import React from "react";
// component
// style
// import "../ConfirmationPopUp/styles.css";
// mui modal imports.
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import errorImage from "../../assets/images/bx_error-circle.svg";
import "./styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const headerStyles = {
  color: "#42444A", // Adjust the shade of grey as needed
  fontFamily: "Manrope, sans-serif",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
};

const buttonStyles = {
  display: "flex",
  height: "2rem",
  padding: "0.375rem 1.125rem 0.4375rem 1.125rem",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "0.25rem",
  background: "#3370FA",
  marginLeft: "15rem",
};

const RefifexistsPopUp = (props) => {
  const renderRefIdExists = () => {
    return `Reference ID ${props.storeRefId} already exists. Enter another reference ID.`;
  };

  return (
    <>
      {props.refIdExistsPopUp && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.refIdExistsPopUp}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style} style={{ opacity: "1", visibility: "visible" }}>
              <div style={headerStyles}>Choose a Unique Reference ID</div>
              <hr class="grey-line"></hr>

              <div class="reference-id-exists">{renderRefIdExists()}</div>
              <hr class="grey-line"></hr>

              <div className="button-container">
                <Button
                  variant="contained"
                  onClick={props.refIdExistsClosePopUp}
                  //   className="cancel-button-refid"
                  sx={buttonStyles}
                >
                  <p
                    className="button-text"
                    style={{
                      color: "white",
                      fontStyle: "normal",
                    }}
                  >
                    Cancel
                  </p>
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default RefifexistsPopUp;
