import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  useHistory,
  Route,
} from "react-router-dom";

import PrivateRoute from "../privateRouter/PrivateRouter";
import PrivateRouteWithCompanyIdCheck from "../privateRouter/PrivateRouteWithCompanyIdCheck";
import PrivateRouteForAdminCheck from "../privateRouter/PrivateRouteForAdminCheck";
import OnBoardingRoute from "../onBoardingRouter/OnBoardingRouter";

import LogIn from "../container/login/Login";
import ChangePassword from "../layouts/login-layout/change-password";
import ForgotPassword from "../container/forgot-password/ForgotPassword";
import ResetPassword from "../container/reset-password";
import Dashboard from "../container/dashboard/index";
import GenerateReport from "../container/generate-report/index";
import ArchiveReport from "../container/archive-report/index";
import SageDashboardManual from "../userManual-FAQ/user-manual/Sage-dashboard/index";
import SatScoreReportManual from "../userManual-FAQ/user-manual/satSource-Report/index";
import MonitoringReportManual from "../userManual-FAQ/user-manual/monitoring-report/index";
import SatScoreReportFAQ from "../userManual-FAQ/FAQ/SatSource-Report/index";
import MonitoringReportFAQ from "../userManual-FAQ/FAQ/Sage-Monitoring/index";
import SageDashboardFAQ from "../userManual-FAQ/FAQ/Sage-Dashboard/index";
import SageDashboardNotification from "../layouts-components/common-header-component/notification/sageDashboardNotification";
// for the new admin portal.
import Adminmain from "../layouts-components/admin-portal";
import AddUser from "../layouts-components/admin-portal/add-user";
import Datamanagementtable from "../layouts-components/admin-portal/data-management";
import Analytics from "../layouts-components/admin-portal/Analytics";
// for the new monitoring internal dashboard portal.
import Database from "../layouts-components/monitoring-portal";
import Statewisedata from "../layouts-components/monitoring-portal/Statewise-data/index";
import Apiexplorer from "../layouts-components/monitoring-portal/API-Explorer";

// import NotFound from "../public-screen/not-found";

const Routes = () => {
  return (
    <Router history={useHistory}>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <OnBoardingRoute exact path="/" component={LogIn} />
        <Route exact path="/changePassword" component={ChangePassword} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/resetPassword" component={ResetPassword} />
        <PrivateRoute
          exact
          path="/sageDashboardManual"
          component={SageDashboardManual}
        />
        <PrivateRoute
          exact
          path="/satScoreReportFAQ"
          component={SatScoreReportFAQ}
        />
        <PrivateRoute
          exact
          path="/monitoringReportFAQ"
          component={MonitoringReportFAQ}
        />
        <PrivateRoute
          exact
          path="/satScoreReportManual"
          component={SatScoreReportManual}
        />
        <PrivateRoute
          exact
          path="/monitoringReportManual"
          component={MonitoringReportManual}
        />
        <PrivateRoute
          exact
          path="/sageDashboardFAQ"
          component={SageDashboardFAQ}
        />
        <PrivateRoute
          exact
          path="/sageDashboardNotification"
          component={SageDashboardNotification}
        />
        <PrivateRoute
          exact
          path="/generate-report"
          component={GenerateReport}
        />
        <PrivateRoute exact path="/archive-report" component={ArchiveReport} />
        <PrivateRouteForAdminCheck
          exact
          path="/adminMain"
          component={Adminmain}
        />
        <PrivateRouteForAdminCheck exact path="/addUser" component={AddUser} />
        <PrivateRouteForAdminCheck
          exact
          path="/dataManagement"
          component={Datamanagementtable}
        />
        <PrivateRouteForAdminCheck
          exact
          path="/analytics"
          component={Analytics}
        />
        <PrivateRouteWithCompanyIdCheck
          exact
          path="/database"
          component={Database}
        />
        <PrivateRouteWithCompanyIdCheck
          exact
          path="/statewisedata"
          component={Statewisedata}
        />
        <PrivateRouteWithCompanyIdCheck
          exact
          path="/apiexplorer"
          component={Apiexplorer}
        />

        {/* not Found */}
        {/* <Route exact path="*" component={NotFound} /> */}
      </Switch>
    </Router>
  );
};

export default Routes;
