import React, { useEffect } from "react";
import cross from "../../assets/images/table/Cross.svg";
import Checkbox from "@mui/material/Checkbox";
import information from "../../assets/images/table/information.svg";
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
// component
import ConfirmationPopUp from "../../PopUps/ConfirmationPopUp/index";
import RefifexistsPopUp from "../../PopUps/RefidexistPopUp/index";
import { useDispatch } from "react-redux";
import { DISABLE_SELECTPICKER } from "../../action/actionConstants";
import { _getStorageValue, getCompId } from "../../common/localStorage";

export default function Bufferzone({
  bufferResult,
  onBufferAllCheck,
  onClearAll,
  onChangehandlerBuffer,
  onRemoveBufferResult,
  bufferCheckAll,
  activeBtn,
  storeRefId,
  selectedBufferResult,
  handleRefIdApi,
  modalIsOpen,
  refIdExists,
  closePopUp,
  handleReferenceId,
  inputFocus,
  errorMessage,
  storeTableHeaders,
  setModalIsOpen,
  storeDropdowncomponents,
  refIdExistsPopUp,
  refIdExistsClosePopUp,
  enableShapeButton,
  handleDownloadShapeFile,
}) {
  let dispatch = useDispatch();

  const customButtonWrapper = {
    flexDirection: "row",
    justifyContent: "space-around",
    position: "absolute",
    width: "100%",
  };
  //Tooltip
  const defaultTheme = createTheme();
  const theme1 = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "10px",
          color: "white",
          backgroundColor: "grey",
        },
      },
    },
  });
  const TableToolTip = withStyles({
    tooltip: {
      fontSize: "12px",
      color: "white",
      width: "250px",
      backgroundColor: "grey",
    },
  })(Tooltip);

  useEffect(() => {
    if (bufferResult.length !== 0) {
      dispatch({
        type: DISABLE_SELECTPICKER,
        payLoad: {
          disableState: true,
        },
      });
    }
    return () => {
      dispatch({
        type: DISABLE_SELECTPICKER,
        payLoad: {
          disableState: false,
        },
      });
    };
  }, []);

  const LEVELS = [
    "levelOne",
    "levelTwo",
    "levelThree",
    "levelFour",
    "levelFive",
    "levelSix",
    "levelSeven",
    "levelEight",
    "levelNine",
    "levelTen",
    "levelEleven",
    "levelTwelve",
    "levelThirteen",
    "levelFourteen",
    "levelFifteen",
  ];

  const isCurrentLevelAccessTrue = (element) =>
    element.currentLevelAccess === true;
  const index = storeDropdowncomponents.findIndex(isCurrentLevelAccessTrue);
  const levels = LEVELS.splice(index);

  const storeNewFilteredLevels = [];
  var titles = [];

  const numWords = [
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
  ];

  bufferResult.map((item) => {
    if (item.datavalue) {
      const key = Object.keys(item.datavalue).filter((keyItem) => {
        return keyItem.includes("level");
      });
      // sort the array using a compare function
      const sortedKeys = key.sort((a, b) => {
        // compare the index of numerical word of a and b in numWords array
        // by removing 'level' prefix from a and b
        return (
          numWords.indexOf(a.replace("level", "")) -
          numWords.indexOf(b.replace("level", ""))
        );
      });

      const keys = [];
      sortedKeys.map((keyValue) => {
        if (levels.includes(keyValue)) {
          keys.push(item.datavalue[keyValue]);
          if (!titles.includes(item.datavalue[keyValue].label)) {
            titles.push(item.datavalue[keyValue].label);
          }
        }
        return item.datavalue[keyValue];
      });
      storeNewFilteredLevels.push(keys);
    }
  });

  // render dynamic table header function
  const renderBufferZoneTableHeaders = () => {
    if (storeTableHeaders === true) {
      return (
        <>
          <tr>
            <td>
              <Checkbox
                {...label}
                style={{
                  padding: 0,
                }}
                sx={{
                  color: "#828282",
                  "&.Mui-checked": {
                    color: "#262261",
                  },
                }}
                size="small"
                checked={bufferCheckAll}
                onChange={(e) => onBufferAllCheck(e)}
              />
            </td>
            {titles.map((getFilteredItemsLabels, index) => {
              return (
                <td key={index} style={{ textTransform: "capitalize" }}>
                  {getFilteredItemsLabels}
                </td>
              );
            })}
            <td className="clear-head" onClick={() => onClearAll()}>
              Clear All
            </td>
          </tr>
        </>
      );
    }
  };

  return (
    <>
      <div className="bottom">
        <div className="box">
          <label className="color-add"></label>
          <p className="box-title">
            2. BUFFER ZONE ({bufferResult ? bufferResult.length : "0"})
            <MuiThemeProvider theme={theme1}>
              <TableToolTip title="Add farms corresponding to a reference ID in this section.">
                {/* <div style={{ marginBottom: "20px" }}> */}
                <img src={information} alt="icon"></img>
                {/* </div> */}
              </TableToolTip>
            </MuiThemeProvider>
          </p>
        </div>
        <table className="buffer-zone-custom-table">
          <thead>{renderBufferZoneTableHeaders()}</thead>
          <tbody className="custom-tbody">
            {bufferResult && bufferResult.length !== 0 ? (
              bufferResult.map((item, id) => {
                return (
                  <tr key={id}>
                    <td>
                      <Checkbox
                        {...label}
                        style={{
                          padding: 0,
                        }}
                        sx={{
                          color: "#828282",
                          "&.Mui-checked": {
                            color:
                              item.coordinates === null ? "#FF8A00" : "#65d630",
                          },
                        }}
                        size="small"
                        checked={
                          item.datavalue &&
                          item.datavalue.id !== undefined &&
                          item.datavalue[item.datavalue.id] !== undefined
                            ? item.datavalue[item.datavalue.id]
                            : false
                        }
                        onChange={(e) => onChangehandlerBuffer(e, item)}
                      />
                    </td>
                    {storeNewFilteredLevels[id]?.map((getDataValue, index) => {
                      return (
                        <td key={index}>
                          {getDataValue.value === null
                            ? "-"
                            : getDataValue.value}
                        </td>
                      );
                    })}
                    <td>
                      <img
                        src={cross}
                        alt="clear"
                        onClick={() => onRemoveBufferResult(id, item)}
                      ></img>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="empty">
                <td colSpan={4}>
                  Select from Search Results to link to ONE Reference ID.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* modal to check for existing ref id */}
        {refIdExists !== 0 ? (
          <RefifexistsPopUp
            storeRefId={storeRefId}
            refIdExistsPopUp={refIdExistsPopUp}
            refIdExistsClosePopUp={refIdExistsClosePopUp}
          />
        ) : (
          <ConfirmationPopUp
            modalIsOpen={modalIsOpen}
            storeRefId={storeRefId}
            refIdExists={refIdExists}
            bufferResult={bufferResult}
            closePopUp={closePopUp}
            setModalIsOpen={setModalIsOpen}
          />
        )}
      </div>
      <div className="box buffer-zone-box">
        <label className="color-add"></label>
        <p className="box-title">
          3. REFERENCE ID{" "}
          <MuiThemeProvider theme={theme1}>
            <TableToolTip
              title="Reference ID a unique ID which identifies a set of farms
                  corresponding to a particular application. One unified report is generated for one reference
                   ID in each request."
            >
              <img src={information} alt="icon"></img>
            </TableToolTip>
          </MuiThemeProvider>
        </p>
        <div className="input-wrapper">
          <p>Enter Reference ID</p>
          <input
            type="number"
            placeholder="Type here..."
            value={storeRefId}
            // pattern="/^-?\d+\.?\d*$/"
            onKeyDown={(e) => {
              let key = Number(e.key);
              if (!((key >= 0 && key <= 9) || e.key === "Backspace")) {
                e.preventDefault();
              }
            }}
            onChange={(e) => handleReferenceId(e.target.value)}
            ref={inputFocus}
          ></input>
        </div>
        {errorMessage && <p className="error-message">Please Enter 8 digit</p>}
      </div>
      <div
        className="button-group"
        style={getCompId() === "1" ? customButtonWrapper : null}
      >
        {getCompId() === "1" && (
          <button
            className={
              bufferResult.length === 0
                ? "btn btn-report-disabled"
                : "btn btn-buffer"
            }
            disabled={
              enableShapeButton ||
              !selectedBufferResult.length ||
              !bufferResult.length
            }
            style={{ width: "140px", fontSize: "12px" }}
            onClick={() => handleDownloadShapeFile()}
          >
            Download Shape File
          </button>
        )}
        <button
          className={
            bufferResult.length === 0
              ? "btn btn-report-disabled"
              : "btn btn-buffer"
          }
          disabled={
            storeRefId.length < 1 ||
            !selectedBufferResult.length ||
            !bufferResult.length
          }
          onClick={activeBtn === false ? null : handleRefIdApi}
          style={
            getCompId() === "1"
              ? { width: "140px", fontSize: "12px" }
              : { width: "289px", fontSize: "inherit" }
          }
        >
          Request Report
        </button>
      </div>
    </>
  );
}
