import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Adminsidepanel from "../admin-sidepanel";
import HeaderLayoutComp from "../../common-header-component/header";
import Analyticsdatacount from "./Analyticsdatacount";
// plugin
import { _getStorageValue } from "../../../common/localStorage";
import { USER_ID } from "../../../common/constants";
import { useHistory } from "react-router-dom";
import { getRegions } from "../../filter-component/filterfun";
import { getAnalyticsValue, creditHubDataApi } from "../adminApi";
// import moment from "moment";

export default function Analytics() {
  let history = useHistory();
  const getUserAccessLevel = localStorage.getItem("user access level");
  // state to store the analytics data.
  const [shouldStoreAnalyticsData, setShouldStoreAnalyticsData] = useState([]);
  // to store the states from the states dropdown.
  const [shouldStoreStatesFromDropdown, setShouldStoreStatesFromDropdown] =
    useState("");
  // store credit hubs.
  const [shouldStoreCreditHubs, setShouldStoreCreditHubs] = useState("");

  const [tabNav, setTabNav] = useState("");
  // to render the states from the region api.
  const [shouldStoreStates, setShouldStoreStates] = useState([]);
  const [reportType, setReportType] = useState("");
  // state to store the start and end dates.
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // enable/disable filter button.
  const [shouldEnableDisableButton, setShouldEnableDisableButton] =
    useState(true);
  // to store the report types from the report access dropdown.
  const [shouldStoreReportTypes, setShouldStoreReportTypes] = useState("");
  // store credit hub names.
  const [shouldStoreCreditHubNames, setShouldStoreCreditHubNames] = useState(
    [],
  );

  useEffect(() => {
    let path = window.location.pathname;
    setTabNav(path);

    _getStorageValue(USER_ID).then((user_id) => {
      getAnalyticsValue(
        successAnalyticsCallBack,
        failureAnalyticsCallBack,
        startDate,
        endDate,
        shouldStoreReportTypes,
        shouldStoreCreditHubs,
        shouldStoreStatesFromDropdown,
      );
    });
  }, []);

  const successAnalyticsCallBack = (response) => {
    setShouldStoreAnalyticsData([response]);
  };

  const failureAnalyticsCallBack = (error) => {
    console.log(error, "===== error callback");
  };

  //fetch page permission to pass it as a query parameter in region API
  const getDefaultRadioButtonSelection =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];
  let pageName = "";
  getDefaultRadioButtonSelection.forEach((items) => {
    if (items.default_page === true) {
      pageName = items.page_name;
    }
  });

  // region api to generate the states.
  useEffect(() => {
    const storeStateRegionId = "27841bce-248f-4342-a78d-b6d817dceb24";
    _getStorageValue(USER_ID).then((user_id) => {
      getRegions(
        storeStateRegionId,
        pageName,
        successRegionCallBack,
        failureFailureCallBack,
      );
    });
  }, []);

  const successRegionCallBack = (response) => {
    setShouldStoreStates(response.regions);
  };

  const failureFailureCallBack = (error) => {
    console.log(error, "====== error response");
  };

  // function to handle the date range.
  const handleDateRange = (range) => {
    const [start, end] = range;
    setStartDate(start);
    setEndDate(end);
    if (
      start === null &&
      end === null &&
      shouldStoreReportTypes === undefined &&
      shouldStoreStatesFromDropdown === ""
    ) {
      setShouldEnableDisableButton(true);
    } else if (
      shouldStoreCreditHubs !== "" ||
      shouldStoreStatesFromDropdown !== "" ||
      (shouldStoreReportTypes !== "" && start == null && end == null)
    ) {
      setShouldEnableDisableButton(false);
    } else if (start != null && end != null) {
      setShouldEnableDisableButton(false);
    } else {
      setShouldEnableDisableButton(true);
      // _getStorageValue(USER_ID).then((user_id) => {
      //     getAnalyticsValue(successAnalyticsCallBack, failureAnalyticsCallBack, null, null, shouldStoreReportTypes, shouldStoreCreditHubs, shouldStoreStatesFromDropdown);
      // });
    }
  };

  // function to handle the report access dropdown.
  const handleFilterDropDownReportAccess = (value, reason) => {
    setShouldStoreReportTypes(value?.page_name);
    if (reason === "clear") {
      if (
        (shouldStoreStatesFromDropdown !== "" &&
          shouldStoreStatesFromDropdown !== null) ||
        (shouldStoreCreditHubs !== "" && shouldStoreCreditHubs !== null)
      ) {
        if (startDate === null && endDate === null) {
          setShouldEnableDisableButton(false);
        }
      } else if (
        shouldStoreStatesFromDropdown === "" ||
        shouldStoreStatesFromDropdown === null ||
        shouldStoreCreditHubs === "" ||
        shouldStoreCreditHubs === null
      ) {
        if (startDate !== null && endDate !== null) {
          setShouldEnableDisableButton(false);
        } else {
          setShouldEnableDisableButton(true);
        }
      } else {
        setShouldEnableDisableButton(true);
      }
    } else {
      setShouldEnableDisableButton(false);
    }
    _getStorageValue(USER_ID).then((user_id) => {
      getAnalyticsValue(
        successAnalyticsCallBack,
        failureAnalyticsCallBack,
        startDate,
        endDate,
        "",
        shouldStoreCreditHubs,
        shouldStoreStatesFromDropdown,
      );
    });
  };

  // function to apply the filters.
  function handleSearchFilter() {
    _getStorageValue(USER_ID).then(() => {
      getAnalyticsValue(
        successAnalyticsCallBack,
        failureAnalyticsCallBack,
        startDate,
        endDate,
        shouldStoreReportTypes,
        shouldStoreCreditHubs,
        shouldStoreStatesFromDropdown,
      );
    });
  }

  // function to handle the state selection.
  function handleStateSelection(states, reason) {
    setShouldStoreCreditHubs("");
    let state = states;
    if (state?.indexOf(" ") !== -1) {
      // if the state name contains a space, replace spaces with %20
      state = state?.replace(/\s+/g, "%20");
    }
    // fetch the company id from the local storage.
    const storeCompanyId = parseInt(localStorage.getItem("companyId"));
    _getStorageValue(USER_ID).then(() => {
      creditHubDataApi(
        storeCompanyId,
        state,
        successCreditHubCallBack,
        failureCreditHubCallBack,
      );
    });

    if (states != null) {
      setShouldStoreStatesFromDropdown(states);
      setShouldEnableDisableButton(false);
    } else {
      setShouldStoreStatesFromDropdown("");
    }

    if (reason === "clear") {
      // setShouldStoreCreditHubs("");
      if (
        states === null &&
        shouldStoreCreditHubs !== "" &&
        (shouldStoreReportTypes === "" ||
          shouldStoreReportTypes === undefined) &&
        startDate == null &&
        endDate == null
      ) {
        setShouldEnableDisableButton(true);
      } else if (
        states === null &&
        shouldStoreCreditHubs !== "" &&
        shouldStoreReportTypes !== "" &&
        startDate !== null &&
        endDate !== null
      ) {
        setShouldEnableDisableButton(false);
      } else if (states === null && startDate !== null && endDate !== null) {
        setShouldEnableDisableButton(false);
      } else if (states === null && shouldStoreReportTypes === undefined) {
        setShouldEnableDisableButton(true);
      } else if (
        states === null &&
        shouldStoreCreditHubs !== "" &&
        shouldStoreReportTypes !== ""
      ) {
        setShouldEnableDisableButton(false);
      } else if (
        (states === "" || states === null) &&
        shouldStoreReportTypes !== ""
      ) {
        setShouldEnableDisableButton(false);
      } else {
        setShouldEnableDisableButton(true);
      }
      _getStorageValue(USER_ID).then((user_id) => {
        getAnalyticsValue(
          successAnalyticsCallBack,
          failureAnalyticsCallBack,
          startDate,
          endDate,
          shouldStoreReportTypes,
          shouldStoreCreditHubs,
          "",
        );
      });
    }

    const successCreditHubCallBack = (response) => {
      const fetchTheCreditHubs = [];
      response.data.forEach((creditHubs) => {
        fetchTheCreditHubs.push(creditHubs.credit_hub);
      });
      setShouldStoreCreditHubNames(fetchTheCreditHubs);
    };

    const failureCreditHubCallBack = (error) => {
      console.log(error, "===== error calling credit hub api");
    };
  }

  // function to handle the credit hubs.
  const handleCreditHubSelection = (value, reason) => {
    setShouldStoreCreditHubs(value);
    if (reason === "clear") {
      setShouldStoreCreditHubs("");
      if (
        (shouldStoreStatesFromDropdown !== "" &&
          shouldStoreReportTypes !== "" &&
          value === null &&
          startDate === null &&
          endDate === null) ||
        (shouldStoreStatesFromDropdown !== "" &&
          shouldStoreReportTypes !== "" &&
          value === null &&
          startDate !== null &&
          endDate !== null)
      ) {
        setShouldEnableDisableButton(false);
      } else if (shouldStoreStatesFromDropdown === "") {
        setShouldEnableDisableButton(true);
      } else {
        setShouldEnableDisableButton(false);
      }
      // _getStorageValue(USER_ID).then((user_id) => {
      //     getAnalyticsValue(successAnalyticsCallBack, failureAnalyticsCallBack, startDate, endDate, shouldStoreReportTypes, value, "");
      // });
    }
  };

  return (
    <>
      <div className="sage-user-manual-sections">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Adminsidepanel
                shouldStoreStates={shouldStoreStates}
                handleDateRange={handleDateRange}
                endDate={endDate}
                startDate={startDate}
                shouldEnableDisableButton={shouldEnableDisableButton}
                handleSearchFilter={handleSearchFilter}
                handleFilterDropDownReportAccess={
                  handleFilterDropDownReportAccess
                }
                handleStateSelection={handleStateSelection}
                shouldStoreCreditHubNames={shouldStoreCreditHubNames}
                handleCreditHubSelection={handleCreditHubSelection}
                shouldStoreCreditHubs={shouldStoreCreditHubs}
                shouldStoreStatesFromDropdown={shouldStoreStatesFromDropdown}
              />
            </Grid>
            <Grid item xs={10}>
              <div className="sage-user-report-wrapper">
                <HeaderLayoutComp tabNav={tabNav} />
                <div className="page-container" style={{ height: "auto" }}>
                  <div className="slide-container">
                    <Analyticsdatacount
                      shouldStoreAnalyticsData={shouldStoreAnalyticsData}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
