// react
import React, { useState, useEffect } from "react";
// Loyout
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeaderLayoutComp from "../../layouts-components/common-header-component/header";
import GenerateMapLayout from "../../layouts-components/generate-map-component/generate-map-component";
import GenerateTableComp from "../../layouts-components/generate-table-component/generate-table-component";
import FilterLayoutComp from "../../layouts-components/filter-component/filter-component";

// style
import "./generateReport.css";
const GenerateReportLayout = () => {
  const [mapPolygonData, setMapPolygonData] = useState([]);
  const [tabNav, setTabNav] = useState("");
  const [listOrMap, setListOrMap] = useState("List View");
  const [tableView, setTableView] = useState(false);
  const [mapView, setMapView] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [tabularData, setTabularData] = useState({});
  const [tabularDataArr, setTabularDataArr] = useState([]);
  const [zoomIn, setZoomIn] = useState(false);
  const [stateChange, setStateChange] = useState(false);
  const [coordinates, setCoordinates] = useState([20.5937, 78.9629]);
  const [storeCurrentlevel, setStoreCurrentlevel] = useState("");
  const [storeTableHeaders, setStoreTableHeaders] = useState(null);
  const [storeFilter, setStoreFilter] = useState([]);
  const [storeDropdowncomponents, setStoreDropdowncomponents] = useState([]);

  const clearMapPolygonData = () => {
    setMapPolygonData([]);
  };

  const triggerListViewCallBack = () => {
    if (listOrMap === "Map View") {
      setListOrMap("List View");
      setMapView(true);
      setTableView(false);
    } else if (listOrMap === "List View") {
      setListOrMap("Map View");
      setTableView(true);
      setMapView(false);
    }
  };
  const showSelectedDetails = () => {
    setShowDetails(true);
  };
  const showTableView = () => {
    setListOrMap("Map View");
    setTableView(true);
    setMapView(false);
  };
  const showMapView = () => {
    setListOrMap("List View");
    setMapView(true);
    setTableView(false);
  };
  const showMapViewFromCell = () => {
    setZoomIn(true);
    setListOrMap("List View");
    setMapView(true);
    setTableView(false);
  };
  const setTableData = (data) => {
    setTabularDataArr((tabularDataArr) => [...tabularDataArr, data]);
  };
  const resetTableData = (data) => {
    setTabularDataArr(data);
  };
  const setZoomFalse = () => {
    setZoomIn(false);
  };
  const setZoomTrue = () => {
    setZoomIn(true);
  };
  const setStateChanged = () => {
    !stateChange && setStateChange(true);
    stateChange && setStateChange(false);
  };
  const triggerMapDataCallBack = (
    response,
    currentlevel,
    showTableHeaders,
    filter,
    dropDownComponents,
  ) => {
    setStoreCurrentlevel(currentlevel);
    setStoreTableHeaders(showTableHeaders);
    setStoreFilter(filter);
    setMapPolygonData([]);
    setStoreDropdowncomponents(dropDownComponents);
    let data = response;
    let geodata = [];

    // function to handle text capitalization
    const handleTextCapitalization = (generateTableData) => {
      const stateName = generateTableData.levelOne.value;
      const convertToTextCapitalized =
        stateName.charAt(0).toUpperCase() + stateName.slice(1);
      return convertToTextCapitalized;
    };

    for (let i in data) {
      let polygonGeoBoundry = {
        datavalue: data[i],
        coordinates: JSON.parse(data[i].centroid),
        polygon: {
          type: "FeatureCollection",
          features: [
            {
              datavalue: {
                state: handleTextCapitalization(data[i]),
                district: data[i].levelTwo.value,
                tehsil: data[i].levelThree.value,
                village: data[i].levelFour.value,
                murabba: data[i].hasOwnProperty("surveyno")
                  ? data[i].levelSix.value
                  : null,
                khasara: data[i].hasOwnProperty("surveyno")
                  ? data[i].levelSeven.value
                  : null,
                khewat: data[i].levelFive.value,
                surveyno: data[i].hasOwnProperty("surveyno")
                  ? data[i].surveyno
                  : null,
                ownerDetails: data[i].ownerDetails,
                surveyId: data[i].surveyId,
                villageId: data[i].villageId,
              },
              type: "Feature",
              properties: {
                name: data[i].murabba + "//" + data[i].khasara,
              },
              geometry: JSON.parse(data[i].geometry),
            },
          ],
        },
      };
      geodata.push(polygonGeoBoundry);
    }
    setMapPolygonData(geodata);
    let noNullCoordinates = [];
    geodata.forEach((elem) => {
      if (elem.coordinates !== null) {
        noNullCoordinates.push(elem.coordinates);
      }
    });
    if (noNullCoordinates.length == 0) {
      setZoomIn(false);
    } else {
      setZoomIn(true);
    }
  };
  useEffect(() => {
    let path = window.location.pathname;
    setTabNav(path);
  }, []);
  return (
    <div className="generate-report-sections">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <FilterLayoutComp
              listOrMapStr={listOrMap}
              isMapView={mapView}
              isTableView={tableView}
              stateChanged={stateChange}
              setStateChanged={setStateChanged}
              zoomTrue={setZoomTrue}
              triggerListView={triggerListViewCallBack}
              mapDataCallBack={triggerMapDataCallBack}
              showDetailsPopup={showSelectedDetails}
              setTableData={setTableData}
              clearMapPolygonData={clearMapPolygonData}
            />
          </Grid>
          <Grid item xs={10}>
            <div className="generate-report-wrapper">
              <HeaderLayoutComp tabNav={tabNav} />
              {/* self call functions */}
              {(() => {
                if (tableView) {
                  return (
                    <GenerateTableComp
                      showMapView={showMapViewFromCell}
                      listData={tabularDataArr}
                      resetTableData={resetTableData}
                      isMapView={mapView}
                      isTableView={tableView}
                      zoomTrue={setZoomTrue}
                      coordinates={coordinates}
                    />
                  );
                } else if (mapView) {
                  return (
                    <GenerateMapLayout
                      mapPolygonData={mapPolygonData}
                      sendTableData={showTableView}
                      setTableData={setTableData}
                      stateChanged={stateChange}
                      zoomIn={zoomIn}
                      zoomFalse={setZoomFalse}
                      isMapView={mapView}
                      isTableView={tableView}
                      coordinates={coordinates}
                      storeTableHeaders={storeTableHeaders}
                      storeFilter={storeFilter}
                      storeDropdowncomponents={storeDropdowncomponents}
                    />
                  );
                }
              })()}
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default GenerateReportLayout;
