import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import "./selectpicker.css";

// Customize the tooltip function
const Customtooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow={false} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.grey,
    boxShadow: theme.shadows[1],
  },
}));

const autoCompleteWrapperStyles = {
  marginBottom: "20px",
};

const CssTextField = styled(TextField)({
  "& .MuiInputLabel-outlined": {
    fontSize: "11px",
    paddingTop: "3px",
    fontWeight: "600",
  },
});

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 1300, // Ensure it is above other elements
  "& .MuiAutocomplete-noOptions": {
    maxHeight: "250px",
    border: "1px solid #dedede",
    overflowY: "auto",
    fontSize: "14px",
  },
}));

const SelectPicker = (props) => {
  const [selectedValue, setSelectedValue] = useState({});

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name || "",
  });

  return (
    <div className="select-picker" style={autoCompleteWrapperStyles}>
      <Customtooltip title={props.selecterLable} placement="left">
        <Autocomplete
          filterOptions={filterOptions}
          disablePortal
          disableClearable
          value={selectedValue}
          size="small"
          closeIcon=""
          options={props.selecterOptions}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option) =>
            option.regionId === selectedValue.regionId
          }
          sx={{ width: "auto" }}
          onChange={(e, value) => {
            setSelectedValue(value);
            props.selecterOnChange(value, props.currentLevel);
          }}
          PopperComponent={(popperProps) => (
            <StyledPopper
              {...popperProps}
              placement="bottom-start"
              modifiers={[
                {
                  name: "flip",
                  enabled: false,
                },
                {
                  name: "preventOverflow",
                  options: {
                    altBoundary: true,
                    tether: false,
                  },
                },
              ]}
            />
          )}
          noOptionsText="No Matching Keyword"
          disabled={
            props.index === 0 || props.index === 1 ? props.disablePicker : false
          }
          ListboxProps={{
            style: {
              maxHeight: "250px",
              border: "1px solid #dedede",
              overflowY: "auto",
              fontSize: "12px",
            },
          }}
          renderInput={(params) => (
            <CssTextField
              size="small"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: "12px !important" },
              }}
              {...params}
              label={props.selecterLable}
            />
          )}
        />
      </Customtooltip>
    </div>
  );
};

export default SelectPicker;
