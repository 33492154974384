import React, { useState, useEffect } from "react";

// Layout
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeaderLayoutComp from "../../../layouts-components/common-header-component/header";
import SidePanelFAQandUserMAanual from "../../sidePanel/index.js";

//slides
import SatSourceFAQ from "../../../assets/images/SageUserManual-faq-img/FAQSatSourceReport.svg";

//stylings
// import "./styles.css";

const SatScoreReportFAQ = () => {
  const [tabNav, setTabNav] = useState("");

  useEffect(() => {
    let path = window.location.pathname;
    setTabNav(path);
  }, []);

  return (
    <>
      <div className="sage-user-manual-sections">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <SidePanelFAQandUserMAanual />
            </Grid>
            <Grid item xs={10}>
              <div className="sage-user-report-wrapper">
                <HeaderLayoutComp tabNav={tabNav} />
                <div className="page-container">
                  <div className="slide-container">
                    <img src={SatSourceFAQ}></img>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};
export default SatScoreReportFAQ;
