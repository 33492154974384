import { DEFAULT_PAGE } from "../action/actionConstants";

const initialState = {
  defaultPage: [],
};

const defaultPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_PAGE:
      return action.payLoad;
    default:
      return state;
  }
};

export default defaultPageReducer;
