import React from "react";
import MUIDataTable from "mui-datatables";
import { _getStorageValue } from "../../../common/localStorage";
import RequestedIcon from "../../../assets/images/explorerTable/requested.svg";
import CompletedIcon from "../../../assets/images/explorerTable/Generated.svg";
import PartiallyCompleted from "../../../assets/images/explorerTable/Login.svg";
import ErrorIcon from "../../../assets/images/explorerTable/errorIcon.svg";
import Notavaliable from "../../../assets/images/explorerTable/not-avaliable.svg";
import InProgressIcon from "../../../assets/images/explorerTable/Loader.svg";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import "../adminPortal.css";

export default function Datamanagementtable(props) {
  const CompletedTooltip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#219653",
    },
  })(Tooltip);
  const InProgressTooltip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#FF8A00",
    },
  })(Tooltip);

  const ErrorTooltip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#D10000",
    },
  })(Tooltip);
  const RequestedToolTip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#2F80ED",
    },
  })(Tooltip);

  // function to fix the satsure text as per the company guideline.
  function renderText(text) {
    if (text === "satsure" || text === "Satsure") {
      const satsureText = "Sat" + "Sure";
      return satsureText;
    } else {
      return text;
    }
  }

  //code to convert the timestamp
  function tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }

  const getColumns = () => {
    let columns = [
      {
        name: "REFERENCE ID",
        label: "REFERENCE ID",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            return (
              <>
                <div>
                  <div>
                    {props.dataManagementTableValue[value].referenceId == null
                      ? "NA"
                      : props.dataManagementTableValue[value].referenceId}
                  </div>
                </div>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              color: "#4A4A4A",
              textAlign: "center",
              zIndex: 100,
              padding: "0!important",
              textTransform: "capitalize",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#E0E0E0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 1,
            },
          }),
        },
      },
      {
        name: "REQUESTED BY",
        label: "REQUESTED BY",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            return (
              <>
                <div>
                  <div>
                    {props.dataManagementTableValue[value].requestedBy == null
                      ? "NA"
                      : props.dataManagementTableValue[value].requestedBy}
                  </div>
                </div>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              color: "#4A4A4A",
              textAlign: "center",
              zIndex: 100,
              padding: "0!important",
              textTransform: "capitalize",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#E0E0E0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 1,
            },
          }),
        },
      },
      {
        name: "STATE",
        label: "STATE",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            return (
              <>
                <div>
                  <div>
                    {props.dataManagementTableValue[value].state == null
                      ? "NA"
                      : props.dataManagementTableValue[value].state}
                  </div>
                </div>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              color: "#4A4A4A",
              textAlign: "center",
              zIndex: 100,
              padding: "0!important",
              textTransform: "capitalize",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#E0E0E0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 1,
            },
          }),
        },
      },
      {
        name: "CREDIT HUB",
        label: "CREDIT HUB",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            return (
              <>
                <div>
                  <div>
                    {props.dataManagementTableValue[value].creditHub == null
                      ? "NA"
                      : renderText(
                          props.dataManagementTableValue[value].creditHub,
                        )}
                  </div>
                </div>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              color: "#4A4A4A",
              textAlign: "center",
              zIndex: 100,
              padding: "0!important",
              textTransform: "capitalize",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#E0E0E0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 1,
            },
          }),
        },
      },
      {
        name: "DISTRICT",
        label: "DISTRICT",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            return (
              <>
                <div>
                  <div style={{ display: "flex" }} className="text-wrapper">
                    {props.dataManagementTableValue[value].district == null
                      ? "NA"
                      : props.dataManagementTableValue[value].district}
                  </div>
                </div>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              color: "#4A4A4A",
              textAlign: "center",
              zIndex: 100,
              padding: "3px!important",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#E0E0E0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 1,
            },
          }),
        },
      },
      {
        name: "REQUEST DATE/TIME",
        label: "REQUEST DATE/TIME",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            return (
              <>
                <div>
                  <div>
                    {props.dataManagementTableValue[value].requestDate == null
                      ? "NA"
                      : props.dataManagementTableValue[value].requestDate}{" "}
                  </div>
                  <div className="timeStamp">
                    {props.dataManagementTableValue[value].requestTime == null
                      ? "NA"
                      : tConvert(
                          props.dataManagementTableValue[value].requestTime,
                        )}
                  </div>
                </div>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              color: "#4A4A4A",
              textAlign: "center",
              zIndex: 100,
              padding: "3px!important",
              textTransform: "capitalize",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#E0E0E0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 1,
            },
          }),
        },
      },
      {
        name: "GENERATION DATE/TIME",
        label: "GENERATION DATE/TIME",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value, index) => {
            return (
              <>
                <div>
                  <div>
                    {props.dataManagementTableValue[value].updateDate == null
                      ? "NA"
                      : props.dataManagementTableValue[value].updateDate}{" "}
                  </div>
                  <div className="timeStamp">
                    {props.dataManagementTableValue[value].updateTime == null
                      ? "NA"
                      : tConvert(
                          props.dataManagementTableValue[value].updateTime,
                        )}
                  </div>
                </div>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              color: "#4A4A4A",
              textAlign: "center",
              zIndex: 100,
              padding: "3px!important",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#E0E0E0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 1,
            },
          }),
        },
      },
      {
        name: "FARMS ANALYSED",
        label: "FARMS ANALYSED",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value, index) => {
            return (
              <>
                <div>
                  {props.dataManagementTableValue[value].farmsAnalysed == null
                    ? "NA"
                    : props.dataManagementTableValue[value].farmsAnalysed}
                </div>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              color: "#4A4A4A",
              textAlign: "center",
              zIndex: 100,
              padding: "3px!important",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#E0E0E0",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 1,
            },
          }),
        },
      },
    ];
    props.reportTypesButtons.map((items) => {
      if (items.id === props.reportType) {
        return columns.push({
          name: `${items.name.toUpperCase()}S`,
          label: `${items.name.toUpperCase()}S`,
          options: {
            filter: false,
            sort: false,
            customBodyRenderLite: (value, index) => {
              return (
                <>
                  {props.dataManagementTableValue[value].reportStatus === 0 && (
                    <p
                      style={{
                        color: "#262261",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <RequestedToolTip title="Requested" arrow>
                        <img src={RequestedIcon} id={index++} />
                      </RequestedToolTip>
                    </p>
                  )}
                  {props.dataManagementTableValue[value].reportStatus ===
                    -1 && (
                    <p style={{ color: "#E4B302", cursor: "pointer" }}>
                      {
                        <InProgressTooltip title="In Progress" arrow>
                          <img src={InProgressIcon} id={index++} />
                        </InProgressTooltip>
                      }
                    </p>
                  )}
                  {props.dataManagementTableValue[value].reportStatus === 1 && (
                    <p
                      style={{
                        color: "#116366",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {
                        <CompletedTooltip
                          title="Generated"
                          arrow
                          className="dropdown-outside-click"
                        >
                          <img
                            src={CompletedIcon}
                            id={index++}
                            onClick={() => {
                              items.reportName === "satsource"
                                ? props.exportPdfTrigger(
                                    props.dataManagementTableValue[value]
                                      .requestId,
                                  )
                                : items.reportName === "ownership"
                                  ? props.exportOwnershipPdfTrigger(
                                      props.dataManagementTableValue[value]
                                        .requestId,
                                    )
                                  : "";
                            }}
                          />
                        </CompletedTooltip>
                      }
                    </p>
                  )}
                  {(props.dataManagementTableValue[value].reportStatus === 3 ||
                    props.dataManagementTableValue[value].reportStatus ===
                      2) && (
                    <p
                      style={{
                        color: "#116366",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {
                        <CompletedTooltip
                          title="Partially Generated"
                          arrow
                          className="dropdown-outside-click"
                        >
                          <img
                            src={PartiallyCompleted}
                            id={index++}
                            onClick={() => {
                              items.reportName === "satsource"
                                ? props.exportPdfTrigger(
                                    props.dataManagementTableValue[value]
                                      .requestId,
                                  )
                                : items.reportName === "ownership"
                                  ? props.exportOwnershipPdfTrigger(
                                      props.dataManagementTableValue[value]
                                        .requestId,
                                    )
                                  : "";
                            }}
                          />
                        </CompletedTooltip>
                      }
                    </p>
                  )}

                  {props.dataManagementTableValue[value].reportStatus ===
                    -2 && (
                    <p style={{ color: "#D10000", cursor: "pointer" }}>
                      {
                        <ErrorTooltip title="Error" arrow>
                          <img src={ErrorIcon} id={index++} />
                        </ErrorTooltip>
                      }
                    </p>
                  )}
                  {(props.dataManagementTableValue[value].reportStatus === -3 ||
                    props.dataManagementTableValue[value].reportStatus ===
                      -4) && (
                    <p style={{ color: "#D10000", cursor: "pointer" }}>
                      {
                        <ErrorTooltip
                          title="Not Avaliable: Requested farms are either too small or no boundary is available in the land survey data"
                          arrow
                        >
                          <img src={Notavaliable} id={index++} />
                        </ErrorTooltip>
                      }
                    </p>
                  )}
                  {props.dataManagementTableValue[value].reportStatus ===
                    null && "NA"}
                </>
              );
            },
            setCellProps: () => ({
              style: {
                left: "0",
                background: "#ffffff",
                color: "#4A4A4A",
                textAlign: "center",
                zIndex: 100,
                padding: "3px!important",
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                left: 0,
                height: 25,
                background: "#E0E0E0",
                fontSize: "12px",
                fontWeight: "600",
                textAlign: "center",
                zIndex: 1,
              },
            }),
          },
        });
      }
    });

    return columns;
  };

  const tableBodyHeight = "calc(100vh - 254px)";

  const options = {
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: false,
    // responsive: "scroll",
    resizableColumns: false,
    tableBodyHeight,
    filter: false,
    download: false,
    print: false,
    search: false,
    rowsPerPage: 20,
    count: props.totalRows,
    rowsPerPageOptions: [5],
    rowStyle: { height: 50 },
    page: props.pageNumber,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    // rowsSelected: props.storeAllRowsSelected.map((row) => row.index),
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        //setPage(tableState.page);
        props.setPageNumber(tableState.page);
      }
    },
    serverSide: true,
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
    },
    selectToolbarPlacement: "none",
    // Disable the table toolbar
    toolbar: false,
  };

  return (
    <div className="archiveTable-sections">
      {props.shouldShowDataManagementDataLoader && (
        <div className="layered-mask">
          <p>Loading...</p>
        </div>
      )}
      <MUIDataTable
        data={props.dataManagementTableValue}
        columns={getColumns()}
        options={options}
      />
    </div>
  );
}
