import { CONTENT_TYPE_JSON } from "./constants";
import axios from "axios";

const header = {
  "Content-Type": CONTENT_TYPE_JSON,
  accept: CONTENT_TYPE_JSON,
};

// redux api
export function apiCall(
  requestMethod,
  url,
  data,
  onSuccess,
  onFailure,
  accessToken = null,
) {
  // TODO: Create a middle ware to handle to refresh token scenario
  if (accessToken != null) {
    header["Authorization"] = accessToken;
  }

  var formData = {
    method: requestMethod,
    headers: header,
  };
  var formBody = JSON.stringify(data);

  if (data !== undefined && data !== "") {
    formData["body"] = formBody;
  }

  fetch(url, formData)
    .then((response) => {
      // TODO: Need to create all API fallbacks.
      // if (response.status === 401) {
      //   dispatch(forcedLogout());
      // } else
      response
        .json()
        .then((responseJson) => {
          console.log("response", responseJson);

          // Check if responseJson is a valid object
          if (responseJson && typeof responseJson === "object") {
            // Check if 'status' key exists in the responseJson
            const status = responseJson.status;

            if (status !== undefined) {
              if (status) {
                console.log("success");
                onSuccess(responseJson);
              } else {
                onFailure(responseJson);
              }
            } else {
              //call the on success function even if there is no status key present in the response to prevent any frontend issues.
              onSuccess(responseJson);
            }
          } else {
            // Handle unexpected response format
            onFailure({ error: "Unexpected response format" });
          }
        })
        .catch((err) => {
          console.log(err);
          onFailure(err);
        });
    })
    .catch((error) => {
      onFailure(error);
      console.log(error);
      onFailure("Unable to process your request.");
    });
}

// function for downloading the shape file in a zip format.
export function apiCallDownloadZip(
  requestMethod,
  url,
  data,
  onSuccess,
  onFailure,
  accessToken = null,
) {
  // TODO: Create a middle ware to handle to refresh token scenario
  if (accessToken != null) {
    header["Authorization"] = accessToken;
  }

  var formData = {
    method: requestMethod,
    headers: header,
  };
  var formBody = JSON.stringify(data);

  if (data !== undefined && data !== "") {
    formData["body"] = formBody;
  }

  fetch(url, formData)
    .then((response) => {
      // TODO: Need to create all API fallbacks.
      // if (response.status === 401) {
      //   dispatch(forcedLogout());
      // } else

      response
        .blob()
        .then((responseJson) => {
          console.log("response", responseJson);
          if (responseJson) {
            console.log("success");
            onSuccess(responseJson);
          } else {
            onFailure(responseJson);
          }
        })
        .catch((err) => {
          onFailure(err);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}

export function apiCallChangePassword(
  requestMethod,
  url,
  data,
  apiKey,
  onSuccess,
  onFailure,
  accessToken = null,
) {
  // TODO: Create a middle ware to handle to refresh token scenario
  if (apiKey != null) {
    header["api-key"] = apiKey;
  }
  var formData = {
    method: requestMethod,
    headers: header,
  };
  var formBody = JSON.stringify(data);

  if (data !== undefined && data !== "") {
    formData["body"] = formBody;
  }

  fetch(url, formData)
    .then((response) => {
      // TODO: Need to create all API fallbacks.
      // if (response.status === 401) {
      //   dispatch(forcedLogout());
      // } else

      response
        .json()
        .then((responseJson) => {
          console.log("resposne", responseJson.status);
          if (responseJson.status) {
            console.log("success");
            console.log(responseJson.status);
            onSuccess(responseJson);
          } else {
            onFailure(responseJson);
          }
        })
        .catch((err) => {
          onFailure(err);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}
//send otp
export function sendOTPapiCall(
  requestMethod,
  url,
  data,
  onSuccess,
  onFailure,
  accessToken = null,
) {
  var formData = {
    method: requestMethod,
    headers: header,
  };
  var formBody = JSON.stringify(data);

  if (data !== undefined && data !== "") {
    formData["body"] = formBody;
  }

  fetch(url, formData)
    .then((response) => {
      // TODO: Need to create all API fallbacks.
      // if (response.status === 401) {
      //   dispatch(forcedLogout());
      // } else

      response
        .json()
        .then((responseJson) => {
          console.log("resposne", responseJson.status);
          if (responseJson.status) {
            console.log("success");
            console.log(responseJson.status);
            onSuccess(responseJson);
          } else {
            onFailure(responseJson);
          }
        })
        .catch((err) => {
          onFailure(err);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}

//verify otp
export function verifyOTPapiCall(
  requestMethod,
  url,
  data,
  onSuccess,
  onFailure,
  accessToken = null,
) {
  var formData = {
    method: requestMethod,
    headers: header,
  };
  var formBody = JSON.stringify(data);

  if (data !== undefined && data !== "") {
    formData["body"] = formBody;
  }

  fetch(url, formData)
    .then((response) => {
      // TODO: Need to create all API fallbacks.
      // if (response.status === 401) {
      //   dispatch(forcedLogout());
      // } else

      response
        .json()
        .then((responseJson) => {
          console.log("resposne", responseJson.status);
          if (responseJson.status) {
            console.log("success");
            console.log(responseJson.status);
            onSuccess(responseJson);
          } else {
            onFailure(responseJson);
          }
        })
        .catch((err) => {
          onFailure(err);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}

// refresh token
export async function refreshAccessToken(requestMethod, url, refreshToken) {
  header["Authorization"] = refreshToken;

  var formData = {
    method: requestMethod,
    headers: header,
  };

  // var requestJson = JSON.stringify({
  //   request: 'Requested to update access token',
  // });

  return await fetch(url, formData)
    .then(async (response) => {
      try {
        const responseJson = await response.json();
        return responseJson;
      } catch (error) {
        console.log(error);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

// download csv api

export async function downloadXLSFile(
  requestMethod,
  url,
  data,
  reportName,
  onSuccess,
  onFailure,
  accessToken = null,
) {
  const apiService = axios.create();
  const headersCsv = { "Content-Type": "blob", authorization: accessToken };
  try {
    const response = await apiService.get(url, {
      headers: headersCsv,
      responseType: "arraybuffer",
    });
    const outputFilename = `${reportName + "_" + Date.now()}.xls`;
    const fileUrl = URL.createObjectURL(new Blob([response.data]));
    console.log(fileUrl);
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();
    // to save/write file locally.
    // fs.writeFileSync(outputFilename, response.data);
  } catch (error) {
    throw Error(error);
  }
}
