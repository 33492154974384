// import logo from './logo.svg';
import React, { Fragment, useEffect } from "react";
import "./App.css";
import Routes from "./router/Routes";
// import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  // for looging out a user whenever the browser is closed.
  useEffect(() => {
    // define increment counter part
    const tabsOpen = localStorage.getItem("tabsOpen");
    if (tabsOpen == null) {
      localStorage.setItem("tabsOpen", 1);
    } else {
      localStorage.setItem("tabsOpen", parseInt(tabsOpen) + parseInt(1));
    }

    // define decrement counter part
    window.onunload = function (e) {
      const newTabCount = localStorage.getItem("tabsOpen");
      if (newTabCount !== null) {
        localStorage.setItem("tabsOpen", newTabCount - 1);
      }
    };
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      window.localStorage.isMySessionActive = "false";
    } else {
      const newTabCount2 = localStorage.getItem("tabsOpen");
      let value = localStorage.getItem("isMySessionActive");
      if (value == "true") {
        if (newTabCount2 - 1 == 0) {
          localStorage.clear();
          window.localStorage.isMySessionActive = "false";
        } else {
          window.localStorage.isMySessionActive = "false";
        }
      }
    }
  }, []);

  return (
    <Fragment>
      {/* <ToastContainer /> */}
      <Routes />
    </Fragment>
  );
}

export default App;
