// react
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
// Loyout
import ArchiveReportLayout from "../../layouts/archive-report-layout/ArchiveReportLayout";

const ArchiveReport = () => {
  const location = useLocation();

  return (
    <div className="home-sections">
      <ArchiveReportLayout selectedRowProps={location.state} />
    </div>
  );
};

export default ArchiveReport;
