import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import content_copy from "../../../assets/images/Internal-dashboard/content_copy.svg";
import { color } from "@mui/system";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const Renderprodtable = (
  handleCompanyInfoModal,
  handleCopyToClipboard,
) => {
  const columns = [
    {
      name: "CLIENT",
      label: "CLIENT",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <>
              <div>
                <div>
                  <p>HDFC Ltd</p>{" "}
                </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "0!important",
            textTransform: "capitalize",
            color: "#696B72",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#F3F3F3",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
            color: "#95969D",
          },
        }),
      },
    },
    {
      name: "PRODUCT",
      label: "PRODUCT",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <>
              <div>
                <div>
                  <span>Satsource Report </span>{" "}
                </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "0!important",
            textTransform: "capitalize",
            color: "#696B72",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#F3F3F3",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
            color: "#95969D",
          },
        }),
      },
    },
    {
      name: "MEMBER ID",
      label: "MEMBER ID",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <>
              <div>
                <div>HDFC-03 </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "0!important",
            textTransform: "capitalize",
            color: "#696B72",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#F3F3F3",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
            color: "#95969D",
          },
        }),
      },
    },
    {
      name: "API VERSION",
      label: "API VERSION",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <>
              <div>
                <div className="text-wrapper">Version V1</div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
            color: "#696B72",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#F3F3F3",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
            color: "#95969D",
          },
        }),
      },
    },
    {
      name: "TOTAL API CALLS",
      label: "TOTAL API CALLS",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <>
              <div>
                <div>100 </div>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
            textTransform: "capitalize",
            color: "#696B72",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#F3F3F3",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
            color: "#95969D",
          },
        }),
      },
    },
    {
      name: "SUCCESS",
      label: "SUCCESS",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, index) => {
          return (
            <>
              <div>100</div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
            color: "#696B72",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#F3F3F3",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
            color: "#95969D",
          },
        }),
      },
    },
    {
      name: "FAILED",
      label: "FAILED",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, index) => {
          return (
            <>
              <div class="checkbox-con">70</div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
            color: "#696B72",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#F3F3F3",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
            color: "#95969D",
          },
        }),
      },
    },
    {
      name: "API KEY",
      label: "API KEY",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, index) => {
          return (
            <>
              <div style={{ display: "flex" }}>
                <p>Default Key</p>
                <BootstrapTooltip title="Copy">
                  <img
                    src={content_copy}
                    alt="Copy Content"
                    style={{ width: "18px" }}
                    onClick={() => handleCopyToClipboard("Default Key")}
                  />
                </BootstrapTooltip>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
            color: "#696B72",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#F3F3F3",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
            color: "#95969D",
          },
        }),
      },
    },
    {
      name: "ACTIVITY",
      label: "ACTIVITY",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, index) => {
          return (
            <>
              <div class="checkbox-con">
                <p onClick={() => handleCompanyInfoModal(index)}>VIEW</p>
              </div>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            left: "0",
            background: "#ffffff",
            color: "#4A4A4A",
            textAlign: "center",
            zIndex: 100,
            padding: "3px!important",
            color: "#696B72",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            left: 0,
            height: 20,
            background: "#F3F3F3",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            zIndex: 20,
            color: "#95969D",
            // fontFamily: "'Manrope', sans-serif;"
          },
        }),
      },
    },
  ];
  return columns;
};
