import React from "react";
import { Popup, Marker } from "react-leaflet";
import "../styles.css";

export default function MapPopup({ mapDetails, geoDetails }) {
  const position = mapDetails?.coordinates;

  const levels = [
    "levelFive",
    "levelSix",
    "levelSeven",
    "levelEight",
    "levelNine",
    "levelTen",
    "levelEleven",
    "levelTwelve",
    "levelThirteen",
    "levelFourteen",
    "levelFifteen",
  ];

  const storeNewFilteredLevels = [];
  const key = Object.keys(geoDetails).filter((keyItem) =>
    keyItem.includes("level"),
  );
  const keys = [];
  key.map((keyValue) => {
    if (levels.includes(keyValue)) {
      keys.push(geoDetails[keyValue]);
    }
    return geoDetails[keyValue];
  });
  storeNewFilteredLevels.push(keys);

  // filtering out an array entry which consists of village label.
  const storeNewLevels = [];
  storeNewFilteredLevels.map((e) => {
    return e.filter((innerItems) => {
      if (innerItems.label !== "village") {
        return storeNewLevels.push(innerItems);
      }
    });
  });

  // rendering the map popup data here
  const renderSurveyDetails = () => {
    const surveyData = storeNewLevels.map((surveyDetailsData, index) => {
      return (
        <div className="survey-details-wrapper" key={index}>
          <div className="col">
            <h5>{surveyDetailsData.label}</h5>
            <p>{surveyDetailsData.value}</p>
          </div>
        </div>
      );
    });
    return surveyData;
  };

  return (
    position !== undefined && (
      <Marker position={[position[1], position[0]]} data-testid="marker">
        <Popup>
          {position === undefined ? (
            <div className="mapPopUpWrapper">
              <p>No Data Avaliable.</p>
            </div>
          ) : (
            <div className="mapPopUpWrapper">
              <h1>Farm Centroid:</h1>
              <p>
                <span>Lat: {position[1].toFixed(4)}</span>
                <span>Long: {position[0].toFixed(4)}</span>
              </p>
              <div className="survey-details">
                <h1>Survey Details:</h1>
                <div className="col-wrapper">{renderSurveyDetails()}</div>
              </div>
            </div>
          )}
        </Popup>
      </Marker>
    )
  );
}
