import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../monitoringModuleStyles.css";
import searchImage from "../../../assets/images/search.svg";
import arrowUpward from "../../../assets/images/Internal-dashboard/arrow_upward.svg";
import arrowDownward from "../../../assets/images/Internal-dashboard/arrow_downward.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(199, 199, 204, 0.20) ",
    color: "#696B72",
    fontWeight: "600",
    textAlign: "center",
    fontSize: "12px",
    lineHeight: "20px",
    padding: "15px",
    "&:first-child": {
      textAlign: "left",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: "15px",
    textAlign: "center",
    "&:first-child": {
      textAlign: "left",
      paddingLeft: "15px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Databasedashboard = React.memo(
  ({
    storeStateData,
    storeStateProdData,
    showDataLoader,
    handleStateSearch,
  }) => {
    const history = useHistory();

    // function to calculate the village count difference.
    function calculateVillageCountDifference(
      devVillageCount,
      prodVillageCount,
    ) {
      const diff = devVillageCount - prodVillageCount;
      const percentageDiff = Math.abs((diff / devVillageCount) * 100).toFixed(
        1,
      );

      if (devVillageCount > prodVillageCount) {
        return (
          <p className="green-bg">
            <img src={arrowUpward} alt="Up Arrow" />+{diff} ({percentageDiff}%)
          </p>
        );
      } else if (devVillageCount < prodVillageCount) {
        return (
          <p className="red-bg">
            <img src={arrowDownward} alt="Down Arrow" />
            {diff} ({percentageDiff}%)
          </p>
        );
      } else {
        return <p className="orange-bg">0</p>;
      }
    }

    // function to calculate the hierarchyCount count difference.
    function calculateHierarchyCountDifference(
      devHierarchyCount,
      prodHierarchyCount,
    ) {
      const diff = devHierarchyCount - prodHierarchyCount;
      if (devHierarchyCount === 0) {
        // Handle the case where devHierarchyCount is zero
        if (prodHierarchyCount === 0) {
          return <p className="orange-bg">0</p>;
        } else {
          return (
            <p className="red-bg">
              <img src={arrowDownward} alt="Down Arrow" />
              {prodHierarchyCount} (100%)
            </p>
          );
        }
      }

      const percentageDiff = Math.abs((diff / devHierarchyCount) * 100).toFixed(
        1,
      );

      if (devHierarchyCount > prodHierarchyCount) {
        return (
          <p className="green-bg">
            <img src={arrowUpward} alt="Up Arrow" />+{diff} ({percentageDiff}%)
          </p>
        );
      } else if (devHierarchyCount < prodHierarchyCount) {
        return (
          <p className="red-bg">
            <img src={arrowDownward} alt="Down Arrow" />
            {diff} ({percentageDiff}%)
          </p>
        );
      } else {
        return <p className="orange-bg">0</p>;
      }
    }

    // function to calculate the d1 count difference.
    function calculateD1CountDifference(devD1Count, prodD1Count) {
      const diff = devD1Count - prodD1Count;
      if (devD1Count === 0) {
        if (prodD1Count === 0) {
          return <p className="orange-bg">0</p>;
        } else {
          return (
            <p className="red-bg">
              <img src={arrowDownward} alt="Down Arrow" />
              {prodD1Count} (100%)
            </p>
          );
        }
      }
      const percentageDiff = Math.abs((diff / devD1Count) * 100).toFixed(1);

      if (devD1Count > prodD1Count) {
        return (
          <p className="green-bg">
            <img src={arrowUpward} alt="Up Arrow" />+{diff} ({percentageDiff}%)
          </p>
        );
      } else if (devD1Count < prodD1Count) {
        return (
          <p className="red-bg">
            <img src={arrowDownward} alt="Down Arrow" />
            {diff} ({percentageDiff}%)
          </p>
        );
      } else {
        return <p className="orange-bg">0</p>;
      }
    }

    // function to calculate the d2 count difference.
    function calculateD2CountDifference(devD2Count, prodD2Count) {
      const diff = devD2Count - prodD2Count;
      const percentageDiff = Math.abs((diff / devD2Count) * 100).toFixed(1);

      if (devD2Count > prodD2Count) {
        return (
          <p className="green-bg">
            <img src={arrowUpward} alt="Up Arrow" />+{diff} ({percentageDiff}%)
          </p>
        );
      } else if (devD2Count < prodD2Count) {
        return (
          <p className="red-bg">
            <img src={arrowDownward} alt="Down Arrow" />
            {diff} ({percentageDiff}%)
          </p>
        );
      } else if (devD2Count === 0 && prodD2Count === 0) {
        return <p className="orange-bg">0</p>;
      }
    }

    // function to calculate the matched entries difference.
    function calculateMatchedEntriesDifference(
      devMatchedEntries,
      prodMatchedEntries,
    ) {
      const diff = devMatchedEntries - prodMatchedEntries;
      if (devMatchedEntries === 0) {
        if (prodMatchedEntries === 0) {
          return <p className="orange-bg">0</p>;
        } else {
          return (
            <p className="red-bg">
              <img src={arrowDownward} alt="Down Arrow" />
              {prodMatchedEntries} (100%)
            </p>
          );
        }
      }
      const percentageDiff = Math.abs((diff / devMatchedEntries) * 100).toFixed(
        1,
      );

      if (devMatchedEntries > prodMatchedEntries) {
        return (
          <p className="green-bg">
            <img src={arrowUpward} alt="Up Arrow" />+{diff} ({percentageDiff}%)
          </p>
        );
      } else if (devMatchedEntries < prodMatchedEntries) {
        return (
          <p className="red-bg">
            <img src={arrowDownward} alt="Down Arrow" />
            {diff} ({percentageDiff}%)
          </p>
        );
      } else {
        return <p className="orange-bg">0</p>;
      }
    }

    return (
      <section className="internal-module-wrapper">
        <div className="search-input-styles">
          <img src={searchImage} alt="Search Image" />
          <input
            type="text"
            placeholder="Search by State"
            onChange={(e) => handleStateSearch(e.target.value)}
          />
        </div>
        <div className="grid-wrapper">
          <Box sx={{ flexGrow: 1 }}>
            {showDataLoader && <p className="loader-text">Loading...</p>}
            <Grid container spacing={4}>
              {storeStateData.map((stateData, index) => {
                const prodData = storeStateProdData.find(
                  (item) => item.state === stateData.state,
                );

                return (
                  <>
                    {prodData ? (
                      <Grid
                        item
                        xs={6}
                        sx={{ paddingBottom: "20px" }}
                        key={index}
                      >
                        <div
                          className="card-layout"
                          onClick={() =>
                            history.push(
                              `/statewisedata?stateName=${stateData.state}&stateId=${stateData.stateRegionId}&level=district`,
                            )
                          }
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                paddingBottom: "5px",
                              }}
                            >
                              <h1>{stateData.state}</h1>
                            </Grid>
                          </Grid>
                          <TableContainer
                            component={Paper}
                            className="grid-table-wrapper"
                          >
                            <Table
                              aria-label="customized table"
                              className="customized-table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>ENV</StyledTableCell>
                                  <StyledTableCell>
                                    VILLAGE COUNT
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    HIERARCHY COUNT
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <span className="text-alignment">D1</span>
                                    <span className="text-updation">
                                      Last Updated on:{" "}
                                      {moment(stateData.d1Updation).format(
                                        "DD MMM YYYY",
                                      )}
                                    </span>
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <span className="text-alignment">D2</span>
                                    <span className="text-updation">
                                      Last Updated on:{" "}
                                      {moment(stateData.d2Updation).format(
                                        "DD MMM YYYY",
                                      )}
                                    </span>
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <span className="text-alignment">
                                      Matched Entries{" "}
                                    </span>
                                    <span className="text-alignment">
                                      {" "}
                                      D1 & D2
                                    </span>
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* DEV Data Row */}
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    DEV
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {stateData.villageCount === 0
                                      ? "0"
                                      : stateData.villageCount || "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {stateData.hierarchyCount === 0
                                      ? "0"
                                      : stateData.hierarchyCount || "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {stateData.totalD1Count === 0
                                      ? "0"
                                      : stateData.totalD1Count || "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {stateData.totalD2Count === 0
                                      ? "0"
                                      : stateData.totalD2Count || "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {stateData.matchedEntries === 0
                                      ? "0"
                                      : stateData.matchedEntries || "N/A"}
                                  </StyledTableCell>
                                </StyledTableRow>
                                {/* PROD Data Row */}
                                {prodData && (
                                  <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                      PROD
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {prodData.villageCount === 0
                                        ? "0"
                                        : prodData.villageCount || "N/A"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {prodData.hierarchyCount === 0
                                        ? "0"
                                        : prodData.hierarchyCount || "N/A"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {prodData.totalD1Count === 0
                                        ? "0"
                                        : prodData.totalD1Count || "N/A"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {prodData.totalD2Count === 0
                                        ? "0"
                                        : prodData.totalD2Count || "N/A"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {prodData.matchedEntries === 0
                                        ? "0"
                                        : prodData.matchedEntries || "N/A"}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )}
                                {/* Show the differences */}
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    DIFFERENCE
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {calculateVillageCountDifference(
                                      stateData.villageCount,
                                      prodData?.villageCount,
                                    ) || "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {calculateHierarchyCountDifference(
                                      stateData.hierarchyCount,
                                      prodData?.hierarchyCount,
                                    ) || "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {calculateD1CountDifference(
                                      stateData.totalD1Count,
                                      prodData?.totalD1Count,
                                    ) || "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {calculateD2CountDifference(
                                      stateData.totalD2Count,
                                      prodData?.totalD2Count,
                                    ) || "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {calculateMatchedEntriesDifference(
                                      stateData.matchedEntries,
                                      prodData?.matchedEntries,
                                    ) || "N/A"}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Grid>
                    ) : null}
                  </>
                );
              })}
            </Grid>
          </Box>
        </div>
      </section>
    );
  },
);

export default Databasedashboard;
