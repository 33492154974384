import React from "react";
// plugin
import { useHistory } from "react-router-dom";
// logo import.
import sideLogo from "../../../assets/images/Sage-new-logo.svg";
// import the Sidepanelfilter component here.
import Admincommonsidepanel from "./Sidepanel-filter/Admincommonsidepanel";

export default function Adminsidepanel({
  shouldStoreStates,
  handleUserSearch,
  handleSearchFilter,
  handleStateSelection,
  handleFilterDropDownReportAccess,
  shouldEnableDisableButton,
  handleCreditHubSelection,
  startDate,
  endDate,
  handleDateRange,
  shouldStoreCreditHubNames,
  shouldStoreCreditHubs,
  shouldStoreStatesFromDropdown,
}) {
  const history = useHistory();
  const adminTabNavs = ["/adminMain", "/dataManagement", "/analytics"];
  return (
    <div className="filter-sections">
      <div>
        <div className="filter-logo">
          <img src={sideLogo} alt="loginLogo" />
        </div>
      </div>
      {adminTabNavs.includes(history.location.pathname) && (
        <Admincommonsidepanel
          shouldStoreStates={shouldStoreStates}
          handleUserSearch={handleUserSearch}
          handleSearchFilter={handleSearchFilter}
          handleStateSelection={handleStateSelection}
          handleFilterDropDownReportAccess={handleFilterDropDownReportAccess}
          shouldEnableDisableButton={shouldEnableDisableButton}
          handleCreditHubSelection={handleCreditHubSelection}
          startDate={startDate}
          endDate={endDate}
          handleDateRange={handleDateRange}
          shouldStoreCreditHubNames={shouldStoreCreditHubNames}
          shouldStoreCreditHubs={shouldStoreCreditHubs}
          shouldStoreStatesFromDropdown={shouldStoreStatesFromDropdown}
        />
      )}
    </div>
  );
}
