// react
import React, { useState, useEffect, useRef } from "react";
// Action
import { _getStorageValue } from "../../common/localStorage";
import { ACCESS_TOKEN, USER_ID } from "../../common/constants";
// common button
import CommonButton from "../../component/common-button/CommonButton";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  getRegions,
  getDynamicRegions,
  landList,
  userPermission,
} from "./filterfun";

// component
// style
import "./filter.css";

// Images
import sideLogo from "../../assets/images/Sage-new-logo.svg";

// // missing report modal
import Missingreport from "../../PopUps/Missing-report";
import SelectPicker from "../../component/input-picker/select-picker";
// report generator
import Reportgenerator from "./report-generator";
import {
  DEFAULT_RADIO_BUTTON_SELECTION,
  STORE_RADIO_BUTTON_SELECTION,
  DEFAULT_PAGE,
  DISABLE_SELECTPICKER,
  REPORT_TYPES,
  USER_PAGE_PERMISSION,
} from "../../action/actionConstants";

const FilterLayoutComp = (props) => {
  let disablePickers = useSelector((s) => s.disableSelectPickers.disableState);

  const [storeDisabledPicker, setStoreDisabledPicker] =
    useState(disablePickers);

  const [selectPickerValues, setSelectPickerValues] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  // for enable/disabled search button
  const [isActive, setIsActive] = useState(false);
  // to set the next level access.
  const [nextLevelAccess, setNextLevelAccess] = useState(true);

  // to set the current level
  const [currentlevel, setCurrentlevel] = useState("");
  // to access the current level
  const [currentLevelAccess, setCurrentLevelAccess] = useState(false);
  // modal open/close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // show/hide table headers
  const [showTableHeaders, setShowTableHeaders] = useState(false);
  // disable search button on api call to prevent multiple clicks
  const [disableSearchButton, setDisabledButton] = useState(false);
  const [dropDownComponents, setDropDownComponents] = useState([]);
  const [filter, setFilters] = useState([]);
  // to show dropdowns
  const [showFilterDropDowns, setShowFilterDropDowns] = useState(false);
  // to store select picker names
  const [selectPickerNames, setSelectPickerNames] = useState([]);
  // store selected missing text.
  const [selectedMissingText, setSelectedMissingText] = useState(true);
  // to show/hide textboxes.
  const [showSelectedTextBoxes, setShowSelectedTextBoxes] = useState(false);
  const [storeBasedOnCurrentLevelAccess, setStoreBasedOnCurrentLevelAccess] =
    useState([]);
  const [storeBasedOnSelectChange, setStoreBasedOnSelectChange] = useState("");
  // to enable/disable notify missing data submit button.
  const [disableMissingDataSubmitButton, setShowDisabledMissingSubmitButton] =
    useState(false);
  // store default radio button selection.
  const [
    storeDefaultRadioButtonSelection,
    setStoreDefaultRadioButtonSelection,
  ] = useState([]);
  // store radio button value.
  const [storeRadioButtonValue, setStoreRadioButtonValue] = useState("");
  // store only from district level.
  const [storeDataFromDistrictLevel, setStoreDataFromDistrictLevel] = useState(
    [],
  );
  // state to make a clone of the original select picker names array.
  const [selectPickeCloneNames, setSelectPickeCloneNames] = useState([]);
  // to store the last current level if data is null.
  const [storeLastIndexFilterData, setStoreLastIndexFilterData] = useState([]);
  const [isNotifyButtonDisabled, setIsNotifyButtonDisabled] = useState(true);

  const stateRef = useRef();
  stateRef.current = showTableHeaders;
  let dispatch = useDispatch();

  useEffect(() => {
    // calling the user permission api here.
    userPermission(
      successUserPermissionCallBack,
      failureUserPermissionCallBack,
    );

    return () => {
      dispatch({
        type: STORE_RADIO_BUTTON_SELECTION,
        payLoad: {
          storeRadioButtonSelection: "",
        },
      });
    };
  }, []);

  const successUserPermissionCallBack = (response) => {
    setStoreDefaultRadioButtonSelection(response.data);
    dispatch({
      type: DEFAULT_RADIO_BUTTON_SELECTION,
      payLoad: {
        defaultRadioButtonSelection: response.data,
        showDefaultPageNo: response.data[0].page_name,
        dataLoader: false,
      },
    });

    dispatch({
      type: USER_PAGE_PERMISSION,
      payLoad: response.data,
    });
    localStorage.setItem(
      "defaultRadioButtonSelection",
      JSON.stringify(response.data),
    );
  };

  const failureUserPermissionCallBack = (response) => {
    dispatch({
      type: DEFAULT_RADIO_BUTTON_SELECTION,
      payLoad: {
        dataLoader: false,
      },
    });
  };

  useEffect(() => {
    const storeStateRegionId = "27841bce-248f-4342-a78d-b6d817dceb24";

    _getStorageValue(USER_ID).then((user_id) => {
      let pageName = "";
      storeDefaultRadioButtonSelection.forEach((items) => {
        if (items.default_page === true) {
          pageName = items.page_name;
        }
      });
      getRegions(
        storeStateRegionId,
        pageName,
        successRegionCallBack,
        failureFailureCallBack,
      );
    });
  }, [storeDefaultRadioButtonSelection]);

  useEffect(() => {
    setStoreDisabledPicker(disablePickers);
  }, [disablePickers]);

  // store filtered default page.
  const storeDefaultPage = [];
  storeDefaultRadioButtonSelection.filter((filteredDefaultPage) => {
    if (filteredDefaultPage.default_page == true) {
      return storeDefaultPage.push(filteredDefaultPage);
    }
  });

  useEffect(() => {
    dispatch({
      type: DEFAULT_PAGE,
      payLoad: {
        defaultPage: storeDefaultPage,
      },
    });
    localStorage.setItem("default page", JSON.stringify(storeDefaultPage));
  }, [storeDefaultPage]);

  const successRegionCallBack = (response, getTheCurrentLevel) => {
    const isDisabled =
      response === null ||
      response.currentLevel === "State" ||
      response.currentLevel === "District";
    setIsNotifyButtonDisabled(isDisabled);

    let copiedDropDownComponents = [...dropDownComponents];
    let copiedFilters = [...filter];

    if (response !== null) {
      setNextLevelAccess(response.nextLevelAccess);
      if (
        response.currentLevelAccess &&
        !storeBasedOnCurrentLevelAccess.includes(response.currentLevel)
      ) {
        const storeNewArray = [
          ...storeBasedOnCurrentLevelAccess,
          response.currentLevel,
        ];
        setStoreBasedOnCurrentLevelAccess(storeNewArray);
      }
      setCurrentLevelAccess(response.currentLevelAccess);
      setCurrentlevel(response.currentLevel);
    }

    if (response !== null && copiedFilters.includes(response.currentLevel)) {
      const index = filter.indexOf(response.currentLevel);

      const newDropDownComponents = dropDownComponents.slice(0, index);
      const dropDownFilterComponents = [...newDropDownComponents];
      setDropDownComponents(dropDownFilterComponents);

      const newFilter = filter.slice(0, index);
      const newCopiedFilter = [...newFilter];
      setFilters(newCopiedFilter);

      copiedFilters = [...newCopiedFilter];
      copiedDropDownComponents = [...dropDownFilterComponents];
    }

    if (response !== null) {
      setFilters([...copiedFilters, response.currentLevel]);
      setDropDownComponents([...copiedDropDownComponents, response]);
    }

    if (response === null) {
      const index = filter.indexOf(getTheCurrentLevel);

      const newDropDownComponents1 = dropDownComponents.slice(0, index + 1);
      const newFilter1 = filter.slice(0, index + 1);

      if (storeBasedOnCurrentLevelAccess.includes(getTheCurrentLevel)) {
        const indexOfStoreBasedOnCurrentLevelAccess =
          storeBasedOnCurrentLevelAccess.indexOf(getTheCurrentLevel);
        const newStoreBasedOnCurrentLevelAccess =
          storeBasedOnCurrentLevelAccess.slice(
            0,
            indexOfStoreBasedOnCurrentLevelAccess + 1,
          );
        setStoreLastIndexFilterData(newStoreBasedOnCurrentLevelAccess);
        setStoreBasedOnCurrentLevelAccess([
          ...newStoreBasedOnCurrentLevelAccess,
        ]);
      }

      setFilters([...newFilter1]);
      setDropDownComponents([...newDropDownComponents1]);
      setCurrentlevel(getTheCurrentLevel);
    }
  };

  const failureFailureCallBack = (response) => {
    console.log(response);
  };

  useEffect(() => {
    if (
      storeBasedOnCurrentLevelAccess.includes(storeBasedOnSelectChange.level)
    ) {
      setIsActive(true);
      setShowDisabledMissingSubmitButton(true);
    } else {
      setIsActive(false);
      setShowDisabledMissingSubmitButton(false);
    }
  }, [storeBasedOnCurrentLevelAccess, storeBasedOnSelectChange]);

  const onSelectChange = (value, getTheCurrentLevel) => {
    const getDefaultPage = JSON.parse(localStorage.getItem("default page"));
    const defaultValue = getDefaultPage.filter((item) => item.default_page)[0]
      ?.page_name;
    if (getTheCurrentLevel === "State") {
      setStoreBasedOnCurrentLevelAccess([]);
    }

    setStoreBasedOnSelectChange({
      level: getTheCurrentLevel,
      ...value,
    });
    setSelectedMissingText(getTheCurrentLevel);
    setSelectPickerValues(value.id);
    setShowFilterDropDowns(true);
    if (
      !selectPickerNames.map((item) => item.level).includes(getTheCurrentLevel)
    ) {
      setSelectPickerNames([
        ...selectPickerNames,
        { ...value, level: getTheCurrentLevel },
      ]);
    } else {
      const index = selectPickerNames.findIndex(
        (item) => item.level === getTheCurrentLevel,
      );
      const cloneSelectPickerNames = [...selectPickerNames];
      cloneSelectPickerNames.splice(index, 1, {
        ...value,
        level: getTheCurrentLevel,
      });
      const sliceTheClonedArr = cloneSelectPickerNames.slice(0, index + 1);
      setSelectPickerNames(sliceTheClonedArr);
    }
    setDisabledButton(false);

    // extract the local storage value for the radio button selection.
    const getStoreRadioButtonSelection = localStorage.getItem(
      "storeRadioButtonSelection",
    );

    _getStorageValue(USER_ID).then((user_id) => {
      getDynamicRegions(
        value.id,
        getStoreRadioButtonSelection === null ||
          getStoreRadioButtonSelection === undefined
          ? defaultValue || storeRadioButtonValue
          : getStoreRadioButtonSelection,
        getTheCurrentLevel,
        successRegionCallBack,
        failureFailureCallBack,
      );
    });
  };

  const triggerSearch = () => {
    const getDefaultPage = JSON.parse(localStorage.getItem("default page"));
    const defaultValue = getDefaultPage.filter((item) => item.default_page)[0]
      ?.page_name;
    dispatch({
      type: DISABLE_SELECTPICKER,
      payLoad: {
        disableState: true,
      },
    });
    localStorage.removeItem("toaster");
    props.clearMapPolygonData();
    setShowTableHeaders(true);
    setSearchLoader(true);
    // extract the local storage value for the radio button selection.
    const getStoreRadioButtonSelection = localStorage.getItem(
      "storeRadioButtonSelection",
    );
    const setLandListApi = _getStorageValue(USER_ID).then(() => {
      landList(
        selectPickerValues,
        getStoreRadioButtonSelection === null
          ? defaultValue || storeRadioButtonValue
          : getStoreRadioButtonSelection,
        successLandCallBack,
        failureLandCallBack,
      );
    });
    return setLandListApi;
  };

  const successLandCallBack = (response) => {
    setDisabledButton(true);
    props.mapDataCallBack(
      response,
      currentlevel,
      stateRef.current,
      filter,
      dropDownComponents,
    );
    setSearchLoader(false);
  };
  const failureLandCallBack = (response) => {
    setSearchLoader(false);
  };

  // modal open
  const handleReportModal = () => {
    setSelectPickeCloneNames(selectPickerNames);
    setModalIsOpen(true);
    setShowFilterDropDowns(true);
    const storeFromDistrictLevel = [];
    filter.filter((items) => {
      if (items !== "State" && items !== "District") {
        return storeFromDistrictLevel.push(items);
      }
    });
    return setStoreDataFromDistrictLevel(storeFromDistrictLevel);
  };

  const missingModalClosePopUp = () => {
    setModalIsOpen(false);
  };

  // clear
  const clearTrigger = () => {
    window.location.reload();
  };

  // function to trigger search.
  function searchFunction() {
    if (isActive) {
      return triggerSearch;
    } else {
      return null;
    }
  }

  // function enable/disable search button.
  function enableButton() {
    if (isActive) {
      return "add-btn";
    } else {
      return "disabled-btn";
    }
  }

  // function to handle selected missing text.
  function handleSelectedMissingText(getSelectedCurrentLevel) {
    setSelectedMissingText(false);
    setShowSelectedTextBoxes(getSelectedCurrentLevel);
    setShowDisabledMissingSubmitButton(false);
  }

  // function handle the missing report textboxes.
  function handleSelectedInputClose() {
    setSelectedMissingText(true);
    setShowSelectedTextBoxes(false);
    setShowDisabledMissingSubmitButton(false);
  }

  function resetDropDowns() {
    setStoreDataFromDistrictLevel([]);
    setSelectPickeCloneNames([]);
    setShowSelectedTextBoxes(false);
    setIsActive(false);
    setShowDisabledMissingSubmitButton(false);
    setStoreLastIndexFilterData([]);
  }

  // function to handle the radio button choices.
  const handleRadioButton = (value) => {
    // Remove spaces between "satsource" and "ownership" for internal operations
    // const cleanedValue = value.replace(/\s&\s/g, "&");

    setDropDownComponents([]);
    setStoreDataFromDistrictLevel([]);
    setFilters([]);
    setIsActive(false);
    setShowDisabledMissingSubmitButton(false);

    const storeStateRegionId = "27841bce-248f-4342-a78d-b6d817dceb24";
    _getStorageValue(USER_ID).then((user_id) => {
      getRegions(
        storeStateRegionId,
        value,
        successRegionCallBack,
        failureFailureCallBack,
      );
    });

    setStoreRadioButtonValue(value);

    dispatch({
      type: STORE_RADIO_BUTTON_SELECTION,
      payLoad: {
        storeRadioButtonSelection: value,
      },
    });

    dispatch({
      type: DEFAULT_PAGE,
      payLoad: {
        defaultPage: [],
      },
    });

    // Use the original value when setting in localStorage
    localStorage.setItem("storeRadioButtonSelection", value);
  };

  useEffect(() => {
    // extract the local storage value for the radio button selection.
    const getStoreRadioButtonSelection = localStorage.getItem(
      "storeRadioButtonSelection",
    );
    const getDefaultPage = JSON.parse(localStorage.getItem("default page"));
    const defaultValue = getDefaultPage.filter((item) => item.default_page)[0]
      ?.page_name;
    const storeReportTypes = [];
    if (
      getStoreRadioButtonSelection === "satsource" ||
      (getStoreRadioButtonSelection === null && defaultValue === "satsource")
    ) {
      storeReportTypes.push("satsource");
    } else if (
      getStoreRadioButtonSelection === "satsource & ownership" ||
      (getStoreRadioButtonSelection === null &&
        defaultValue === "satsource & ownership")
    ) {
      storeReportTypes.push("satsource", "ownership");
    } else if (
      getStoreRadioButtonSelection === "ownership" ||
      (getStoreRadioButtonSelection === null && defaultValue === "ownership")
    ) {
      storeReportTypes.push("ownership");
    } else if (
      getStoreRadioButtonSelection === "property" ||
      (getStoreRadioButtonSelection === null && defaultValue === "property")
    ) {
      storeReportTypes.push("property");
    } else if (
      getStoreRadioButtonSelection === "property & ownership" ||
      (getStoreRadioButtonSelection === null &&
        defaultValue === "property & ownership")
    ) {
      storeReportTypes.push("property", "ownership");
    }

    dispatch({
      type: REPORT_TYPES,
      payLoad: {
        storeReportTypes: storeReportTypes && storeReportTypes,
      },
    });
  }, [storeDefaultPage]);

  // function to render the filter dropdowns based on user permission role.
  function renderFilterDropdowns(dropDownItems, currentlevel) {
    // extract the local storage value for the radio button selection.
    const getStoreRadioButtonSelection = localStorage.getItem(
      "storeRadioButtonSelection",
    );
    // console.log(storeDefaultRadioButtonSelection.length,"storeDefaultRadioButtonSelection")

    let regions;
    if (
      storeDefaultRadioButtonSelection.length === 1 ||
      storeDefaultRadioButtonSelection.length === 2 ||
      storeDefaultRadioButtonSelection.length === 3 ||
      storeDefaultRadioButtonSelection.length === 4 ||
      storeDefaultRadioButtonSelection.length === 5 ||
      storeDefaultRadioButtonSelection.length === 6 ||
      storeDefaultRadioButtonSelection.length === 7 ||
      storeDefaultRadioButtonSelection.length === 8
    ) {
      if (
        storeRadioButtonValue === "satsource" ||
        storeRadioButtonValue === "satsource & ownership" ||
        storeRadioButtonValue === "ownership" ||
        storeRadioButtonValue === "property" ||
        getStoreRadioButtonSelection === "satsource" ||
        getStoreRadioButtonSelection === "satsource & ownership" ||
        getStoreRadioButtonSelection === "ownership" ||
        getStoreRadioButtonSelection === "property" ||
        (getStoreRadioButtonSelection === "property & ownership" &&
          currentlevel === "State")
      ) {
        regions = [...dropDownItems];
      } else if (
        (storeDefaultPage[0].page_name == "satsource" ||
          storeDefaultPage[0].page_name == "satsource & ownership" ||
          storeDefaultPage[0].page_name == "ownership" ||
          storeDefaultPage[0].page_name == "property" ||
          storeDefaultPage[0].page_name == "property & ownership" ||
          getStoreRadioButtonSelection === "satsource" ||
          getStoreRadioButtonSelection === "ownership" ||
          getStoreRadioButtonSelection === "satsource & ownership" ||
          getStoreRadioButtonSelection === "property" ||
          getStoreRadioButtonSelection === "property & ownership") &&
        currentlevel === "State"
      ) {
        regions = [...dropDownItems];
      } else {
        regions = [...dropDownItems];
      }
    } else {
      regions = [...dropDownItems];
    }
    return regions;
  }

  return (
    <div className="filter-sections">
      <Missingreport
        modalIsOpen={modalIsOpen}
        missingModalClosePopUp={missingModalClosePopUp}
        dropDownComponents={dropDownComponents}
        setDropDownComponents={setDropDownComponents}
        filter={filter}
        showFilterDropDowns={showFilterDropDowns}
        selectPickeCloneNames={selectPickeCloneNames}
        onSelectChange={onSelectChange}
        selectedMissingText={selectedMissingText}
        selectedCurrentLevel={currentlevel}
        handleSelectedMissingText={handleSelectedMissingText}
        showSelectedTextBoxes={showSelectedTextBoxes}
        handleSelectedInputClose={handleSelectedInputClose}
        setSelectPickerNames={setSelectPickerNames}
        setShowSelectedTextBoxes={setShowSelectedTextBoxes}
        setSelectedMissingText={setSelectedMissingText}
        resetDropDowns={resetDropDowns}
        disableMissingDataSubmitButton={disableMissingDataSubmitButton}
        setShowDisabledMissingSubmitButton={setShowDisabledMissingSubmitButton}
        storeDataFromDistrictLevel={storeDataFromDistrictLevel}
        selectPickerNames={selectPickerNames}
        basedOnCurrentLevelAccess={storeBasedOnCurrentLevelAccess}
        filterDropdownsCurrentLevelAccess={currentLevelAccess}
        nextLevelAccess={nextLevelAccess}
        setStoreBasedOnCurrentLevelAccess={setStoreBasedOnCurrentLevelAccess}
        storeLastIndexFilterData={storeLastIndexFilterData}
        setStoreLastIndexFilterData={setStoreLastIndexFilterData}
      />
      <div>
        <div className="filter-logo">
          <img src={sideLogo} alt="loginLogo" />
        </div>
        {/* <Reportgenerator handleRadioButton={handleRadioButton} /> */}
      </div>
      <div className="filter-order">
        <div className="filter-title">
          <label>FILTERS</label>
          <span onClick={clearTrigger}>CLEAR ALL</span>
        </div>
        <div className="filter-wrapper">
          <ul className="filter-list">
            {dropDownComponents.map((dropDownItem, index) => {
              return (
                <li key={index}>
                  <SelectPicker
                    selecterLable={`${dropDownItem.currentLevel} *`}
                    selecterChoice={0}
                    selecterOnChange={onSelectChange}
                    selecterOptions={renderFilterDropdowns(
                      dropDownItem.regions,
                      dropDownItem.currentLevel,
                    )}
                    currentLevel={dropDownItem.currentLevel}
                    filter={filter}
                    disablePicker={storeDisabledPicker}
                    index={index}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="submit-btn-wrapper">
        <div className={enableButton()}>
          <CommonButton
            btnName={"Search"}
            btnCallBack={searchFunction()}
            btnLoader={searchLoader}
            disableSearchButton={!isActive}
          />
        </div>
        <div className="img-wrapper">
          <Button
            variant="outlined"
            onClick={handleReportModal}
            disabled={isNotifyButtonDisabled}
            className={isNotifyButtonDisabled ? "btnDisabled" : "btnActive"}
          >
            Notify Missing Data
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterLayoutComp;
