// react
import React, { useState } from "react";
import { useSelector } from "react-redux";
// component
// style
import "./styles.css";
// Action
import { _getStorageValue } from "../../common/localStorage";
import { ACCESS_TOKEN, USER_ID } from "../../common/constants";
import { fetchNewReferenceId } from "../../layouts-components/filter-component/filterfun";
import FinalPopUp from "../../PopUps/FinalPopUP/index";
// mui modal imports.
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import errorImage from "../../assets/images/bx_error-circle.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const headerStyles = {
  color: "#42444A",
  fontFamily: "Manrope, sans-serif",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginTop: "1rem",
  marginBottom: "1rem",
};

const buttonStyles = {
  background: "transparent",
  padding: 0.5,
  borderRadius: "5px",
  width: "30%",
};

const ConfirmationPopUp = (props) => {
  // final pop up states
  const [renderFinalModal, setRenderFinalModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [disableModalButton, setDisableModalButton] = useState(false);

  let radioButtonSelection = useSelector(
    (s) => s.disableRadioButtonReducer.defaultRadioButtonSelection,
  );
  let storeRadioButtonSelection = useSelector(
    (s) => s.radioButtonSelectionReducer.storeRadioButtonSelection,
  );
  let defaultPage = useSelector((s) => s.defaultPageReducer.defaultPage);
  let reportTypes = useSelector((s) => s.reportTypesReducer.storeReportTypes);

  // extract the local storage value for the radio button selection.
  const getStoreRadioButtonSelection = localStorage.getItem(
    "storeRadioButtonSelection",
  );

  // function to handle new ref id api to generate the reports.
  const handleNewRefId = () => {
    setDisableModalButton(true);
    props.setModalIsOpen(false);
    const storeSurveyIds = [];
    props.bufferResult.forEach((fetchSurveyIds) => {
      storeSurveyIds.push(fetchSurveyIds.datavalue.id);
    });
    const requestPayload = {
      report_types: reportTypes,
      referenceId: props.storeRefId,
      surveyNodetails: storeSurveyIds,
    };
    _getStorageValue(USER_ID).then(() => {
      fetchNewReferenceId(
        requestPayload,
        successNewRefIdCallback,
        failureNewRefIdCallBack,
      );
    });

    // for sourcing and onwership report.
    const successNewRefIdCallback = (response) => {
      setRenderFinalModal(true);
      setOpen(true);
      setTimeout(() => {
        setRenderFinalModal(false);
        // refreshes the document
        window.location.reload();
      }, 10000);
    };
    const failureNewRefIdCallBack = (response) => {
      console.log(response, "failureNewRefIdCallBack");
    };
  };

  const handlePopUpClose = () => {
    setRenderFinalModal(false);
  };
  const selectedRadio =
    storeRadioButtonSelection ||
    getStoreRadioButtonSelection ||
    defaultPage?.[0]?.page_name;
  const getRequestMessage = () => {
    const radioOptions = {
      satsource: "SatSource",
      "satsource & ownership": "SatSource & ownership",
      ownership: "ownership",
      property: "property",
      "property & ownership": "Property & Ownership",
    };

    const message = (
      <div>
        <div className="report-request">Report Request</div>
        <hr class="grey-line"></hr>
        <h1 style={headerStyles}>
          Do you want to request{" "}
          <span style={{ textTransform: "capitalize" }}>
            {radioOptions[selectedRadio]}
          </span>{" "}
          report for Reference ID: {props.storeRefId}?
        </h1>
        <hr class="grey-line"></hr>
      </div>
    );
    return message;
  };

  const isPropEye =
    props.bufferResult?.length > 1 &&
    (selectedRadio === "property" || selectedRadio === "property & ownership");

  // render confirmation message based on ref if
  const renderConfirmationMessage = () => {
    const bufferLength = props.bufferResult?.length;
    const radioSelectionLength = radioButtonSelection.length;
    if (isPropEye) {
      return "Requesting for more than 1 survey number is not allowed.";
    }
    if (bufferLength > 100) {
      return "Requesting more than 100 farms is not allowed.";
    }

    if (radioSelectionLength >= 1 && radioSelectionLength <= 6) {
      return getRequestMessage();
    }

    return null;
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.modalIsOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} style={{ opacity: "1", visibility: "visible" }}>
            {(props.bufferResult?.length > 100 || isPropEye) && (
              <img src={errorImage} alt="Error Image" className="error-img" />
            )}
            {renderConfirmationMessage()}
            <div className="confirmation-modal-button-container">
              <Button
                variant="contained"
                onClick={props.closePopUp}
                className="cancel-button-1"
              >
                <p style={{ fontStyle: "normal", color: "#3370FA" }}>Cancel</p>
              </Button>
              <Button
                variant="contained"
                onClick={props.refIdExists === 0 && handleNewRefId}
                disabled={disableModalButton}
                className="add-button"
                sx={buttonStyles}
                style={
                  props.bufferResult?.length > 100 || isPropEye
                    ? { display: "none", backgroundColor: "#3370FA" }
                    : { display: "block", backgroundColor: "#3370FA" }
                }
              >
                <p
                  className="button-text1"
                  style={{ color: "#fff", fontStyle: "normal" }}
                >
                  {props.refIdExists === 0 ? "Yes" : "Replace"}
                </p>
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>

      {renderFinalModal && (
        <FinalPopUp
          open={open}
          storeRefId={props.storeRefId}
          handlePopUpClose={handlePopUpClose}
        />
      )}
    </>
  );
};

export default ConfirmationPopUp;
