import React from "react";
import Close from "../../assets/images/common/close.svg";
import SatsourceOwnership from "../../assets/GIFs/SatSource&Ownership.gif";
// stylesheet import
import "./map.css";

export default function ToastContainer({
  showToaster,
  handlePopUpClose,
  mapPolygonData,
}) {
  const getDefaultRadioButtonSelection =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];
  let isPropEye = false;

  for (const item of getDefaultRadioButtonSelection) {
    if (
      (item.page_name === "property" ||
        item.page_name === "property & ownership") &&
      item.default_page
    ) {
      isPropEye = true;
      break;
    }
  }
  const storeNullCoordinates = [];
  const storeCoordinatesWithData = [];
  mapPolygonData.filter((elem) => {
    if (elem.coordinates === null) {
      return storeNullCoordinates.push(elem);
    } else {
      return storeCoordinatesWithData.push(elem);
    }
  });
  return (
    showToaster && (
      <div className="toast-container-wrapper">
        <div className="inner-content-wrapper">
          <div className="align-items">
            <div>
              <h1>
                Boundaries Detected:
                <span className="boundary-value">
                  {storeCoordinatesWithData.length}
                </span>
              </h1>
              <h1>
                Boundaries Under Updation/ Not Available:
                <span className="boundary-value">
                  {storeNullCoordinates.length}
                </span>
              </h1>
              {storeCoordinatesWithData.length ? (
                <div className="boundary-description">
                  We are working on improving our data coverage and the
                  {isPropEye ? " Property" : " SatSource"} report will be
                  generated for the detected
                  <br /> boundaries only.{" "}
                </div>
              ) : (
                <h1 className="boundary-description">
                  {isPropEye ? (
                    "No boundary detected for this survey number. We are working on improving our coverage."
                  ) : (
                    <>
                      Click <b>“Move to Buffer zone”</b> to complete the
                      Ownership Report Request.
                    </>
                  )}
                </h1>
              )}
            </div>
            <div className="image-wrapper">
              <img
                src={SatsourceOwnership}
                alt="Info Image"
                className="info-img"
              />
            </div>
          </div>
          <img
            src={Close}
            alt="close image"
            className="close-img"
            onClick={handlePopUpClose}
          />
        </div>
      </div>
    )
  );
}
