// react
import React from "react";
// mui modal imports.
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "react-bootstrap/Button";

import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
//GIF
import RequestedGIF from "../../assets/GIFs/reportRequested.gif";
import Close from "../../assets/images/common/close.svg";
// style
import "./styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "26.25rem",
  height: "19.4375rem",
  // flex-shrink: 0,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const headerStyles = {
  fontSize: "15px",
  fontFamily: "Manrope,sans-serif",
  fontWeight: "500",
  lineHeight: "20px",
  textAlign: "left",
  paddingBottom: "15px",
  background: "rgba(38, 34, 97, 1",
};

const FinalPopUp = (props) => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/archive-report");
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} style={{ opacity: "1", visibility: "visible" }}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={headerStyles}
            >
              {" "}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flexGrow: 1 }}>
                  <p className="popup-header">Your report is on its way!</p>
                </div>
                <div>
                  <img
                    src={Close}
                    style={{ cursor: "pointer" }}
                    alt="Your image"
                    onClick={props.handlePopUpClose}
                  />
                </div>
              </div>
              <hr class="grey-line"></hr>
              <div className="gif-container">
                <div style={{ textAlign: "center" }}>
                  <img
                    src={RequestedGIF}
                    alt="Requested"
                    style={{ width: "9rem", height: "8.6875rem" }}
                  />
                  <div className="popup-desc">
                    Report generation is in progress. <br />
                    Click <b>“Explorer”</b> to know the status of the report.
                  </div>
                </div>
              </div>
              <div className="popup-disclaimer">
                *Kindly take note that our data sourcing relies on government
                portals and <br /> report generation may require some time.
              </div>
              <hr class="grey-line"></hr>
              <div className="button-containers">
                <Button
                  variant="contained"
                  className="add-button-explorer"
                  onClick={handleClick}
                  style={{
                    display: "flex",
                    height: "2rem",
                    padding: "0.4375rem 0.6875rem 0.375rem 1rem",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    borderRadius: "0.25rem",
                    background: "#3370FA",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Explorer
                </Button>
              </div>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default FinalPopUp;
