// react
import React from "react";

// mui modal imports.
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// style
import "./styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const headerStyles = {
  fontSize: "15px",
  lineHeight: "20px",
  textAlign: "center",
  paddingBottom: "15px",
  background: "rgba(38, 34, 97, 1)",
  color: "rgba(27, 69, 173, 1)",
};

const DupEntryCheckPopUP = (props) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.duplicateEntriesMessage}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} style={{ opacity: "1", visibility: "visible" }}>
            <div className="header-content">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="p"
                sx={headerStyles}
              >
                <span>{props.duplicateEntries.length}</span>Duplicate entries
                detected in the buffer zone and remaining{" "}
                <span>{props.uniqueEntriesCount}</span> unique entries moved to
                the buffer zone. Please make a new search if no entries are
                moved.
              </Typography>
            </div>
            <div className="button-container">
              <Button
                variant="contained"
                onClick={props.closeDupEntryCheckPopUP}
                className="cancel-button"
              >
                <p className="button-text1">Ok, Got It.</p>
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default DupEntryCheckPopUP;
