import React, { useState, useEffect } from "react";

// Layout
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeaderLayoutComp from "../../../layouts-components/common-header-component/header";
import SidePanelFAQandUserMAanual from "../../sidePanel/index.js";

//slides
import Page1 from "../../../assets/images/SageUserManual-faq-img/1.svg";
import Page2 from "../../../assets/images/SageUserManual-faq-img/2.svg";
import Page3 from "../../../assets/images/SageUserManual-faq-img/3.svg";
import Page4 from "../../../assets/images/SageUserManual-faq-img/4.svg";
import Page5 from "../../../assets/images/SageUserManual-faq-img/5.svg";
import Page6 from "../../../assets/images/SageUserManual-faq-img/6.svg";
import Page7 from "../../../assets/images/SageUserManual-faq-img/7.svg";
import Page8 from "../../../assets/images/SageUserManual-faq-img/8.svg";
import Page9 from "../../../assets/images/SageUserManual-faq-img/9.svg";
import Page10 from "../../../assets/images/SageUserManual-faq-img/10.svg";
import Page11 from "../../../assets/images/SageUserManual-faq-img/11.svg";
import Page12 from "../../../assets/images/SageUserManual-faq-img/12.svg";
import Page13 from "../../../assets/images/SageUserManual-faq-img/13.svg";
import Page14 from "../../../assets/images/SageUserManual-faq-img/14.svg";
import Page15 from "../../../assets/images/SageUserManual-faq-img/15.svg";
import Page16 from "../../../assets/images/SageUserManual-faq-img/16.svg";
import Page17 from "../../../assets/images/SageUserManual-faq-img/17.svg";
import Page18 from "../../../assets/images/SageUserManual-faq-img/18.svg";
import Page19 from "../../../assets/images/SageUserManual-faq-img/19.svg";
import Page20 from "../../../assets/images/SageUserManual-faq-img/20.svg";

const Pages = [
  Page1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Page7,
  Page8,
  Page9,
  Page10,
  Page11,
  Page12,
  Page13,
  Page14,
  Page15,
  Page16,
  Page17,
  Page18,
  Page19,
  Page20,
];

//stylings
import "./styles.css";

const SageDashboardManual = () => {
  const [tabNav, setTabNav] = useState("");

  useEffect(() => {
    let path = window.location.pathname;
    setTabNav(path);
  }, []);

  return (
    <>
      <div className="sage-user-manual-sections">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <SidePanelFAQandUserMAanual />
            </Grid>
            <Grid item xs={10}>
              <div className="sage-user-report-wrapper">
                <HeaderLayoutComp tabNav={tabNav} />
                <div className="page-container">
                  {Pages.map((page, index) => {
                    return (
                      <div className="slide-container" key={index}>
                        <img src={page}></img>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};
export default SageDashboardManual;
