import React from "react";
import Paper from "@mui/material/Paper";

export default function Reportdropdown(props) {
  return (
    <div
      className="down"
      style={{
        display: "none",
        position: "absolute",
        right: "28.5%",
        zIndex: "2",
      }}
      ref={props.reportDropdown}
    >
      {props.dropdownstate === 1 && (
        <Paper
          className="dropdown"
          id={"_dropdownid"}
          elevation={2}
          sx={{ displayPrint: "none" }}
        >
          <div className="dropdown-content">
            <ul>
              <li className="dropdown-outside-click">
                <a
                  onClick={(e) => {
                    props.handleBriefReportDownload(e, props.tableData);
                  }}
                >
                  Brief Report(2MB)
                </a>
              </li>
              <li className="dropdown-outside-click">
                <a
                  onClick={(e) => {
                    props.handleDetailedReportDownload(e, props.tableData);
                  }}
                >
                  Detailed Report
                </a>
              </li>
            </ul>
          </div>
        </Paper>
      )}
    </div>
  );
}
