import React from "react";
import openIcon from "../../../assets/images/open.png";
import calendar_today from "../../../assets/images/Internal-dashboard/calendar_today.svg";

export default function Customdropdown({
  handleLegendOpen,
  showDropDown,
  handleListitem,
  shouldStoreListNames,
}) {
  const dropdownItems = ["Yesterday", "Last Month", "Last Quarter", "Custom"];
  const updatedDropdownItems = dropdownItems.filter(
    (item) => item !== shouldStoreListNames,
  );

  return (
    <div className="dropdown-wrapper">
      <div
        className={`${showDropDown ? "add-custom-border-radius" : "add-default-border-radius"} legends-inner-content-wrapper`}
        onClick={handleLegendOpen}
      >
        <h1>{shouldStoreListNames}</h1>
        <img
          src={openIcon}
          alt="Open Icon"
          className={showDropDown ? "reverseIcon" : "rotateIcon"}
        />
      </div>
      {showDropDown && (
        <ul
          className={
            showDropDown
              ? "add-custom-ul-border-radius"
              : "add-ul-default-border-radius"
          }
        >
          {updatedDropdownItems.map((items, index) => {
            return (
              <li
                key={index}
                onClick={() => handleListitem(items)}
                style={{ position: "relative" }}
              >
                {items}{" "}
                {items === "Custom" && (
                  <img src={calendar_today} alt="Calender" />
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
