// react
import React, { useState, useEffect, useRef } from "react";

import { MapContainer, ScaleControl } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

// style
import "./map.css";
import MapTable from "./mapTable";
// toastify
import ToastContainer from "./Toast-container";

// legend
import Maplegends from "./Map-legends";
// map container
import Mapfeaturegroup from "./Map-container";

const GenerateMapLayout = (props) => {
  const [mapPolyData, setMapPolyData] = useState(props.mapPolygonData);
  const [bufferZone, setBufferZone] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedBufferData, setSelectedBufferData] = useState([]);
  const [map, setMap] = useState(null);
  const [khewatData, setKhewatData] = useState();
  const [coordinates, setCoordinates] = useState([20.5937, 78.9629]);
  const [showToaster, setShowToaster] = useState(false);

  // for legend
  const [showLegend, setShowLegend] = useState(false);
  const featureGroupRef = useRef(null);

  const zoomInToLocation = () => {
    setTimeout(() => {
      if (coordinates === null) {
        setCoordinates([20.5937, 78.9629]);
      } else if (coordinates.length !== 0) {
        let latlng = coordinates.reverse();
        if (map)
          map.flyTo(latlng, 15, {
            duration: 1,
          });
      }
    }, 1000);

    setTimeout(() => {
      props.zoomFalse();
    }, 1000);
  };

  useEffect(() => {
    let polyData = props.mapPolygonData;
    let notNullCoords = [];
    polyData.forEach((element) => {
      if (element.coordinates !== null) {
        notNullCoords.push(element.coordinates.coordinates);
      }
    });
    setCoordinates(notNullCoords[0]);
  });

  useEffect(() => {
    if (props.zoomIn) {
      zoomInToLocation();
      if (map === null || featureGroupRef.current === null) {
        return;
      }
      setTimeout(() => {
        map.fitBounds(featureGroupRef.current.getBounds());
        featureGroupRef.current = null;
      }, 1200);
    }
  });

  // use effect to handle the taoster pop up based on null coordinates response.
  useEffect(() => {
    let polyData = props.mapPolygonData;
    console.log(polyData.length, "polyData=====>");
    let noBoundaryCounter = 0;
    // extracted the toaster state from local storage.
    const getToasterStatus = localStorage.getItem("toaster");

    if (polyData.length > 0) {
      polyData.forEach((element) => {
        if (element.coordinates === null) {
          noBoundaryCounter += 1;
        }
      });
      setShowToaster(true);
      // check for boolean inside local storage.
      if (getToasterStatus === "true") {
        setShowToaster(false);
      }
    }
  }, [mapPolyData]);

  // toaster close function
  const handlePopUpClose = () => {
    // saved the toaster state inside local storage.
    localStorage.setItem("toaster", true);
    setShowToaster(false);
  };

  // legend open/close function
  const handleLegendOpen = () => {
    setShowLegend(!showLegend);
  };

  // useEffect(() => {
  //   if (props.stateChanged) {
  //     setKhewatData("");
  //   }
  // });

  return (
    <div className="map-contaier">
      <div className="map-table">
        <MapTable
          mapPolygonData={props.mapPolygonData}
          setMapPolyData={setMapPolyData}
          setBufferZone={setBufferZone}
          setSelectedData={setSelectedData}
          setSelectedBufferData={setSelectedBufferData}
          storeCurrentlevel={props.storeCurrentlevel}
          storeTableHeaders={props.storeTableHeaders}
          storeMurabbaOptions={props.storeMurabbaOptions}
          filter={props.storeFilter}
          storeDropdowncomponents={props.storeDropdowncomponents}
        />
      </div>
      <ToastContainer
        showToaster={showToaster}
        handlePopUpClose={handlePopUpClose}
        mapPolygonData={props.mapPolygonData}
      />
      <Maplegends showLegend={showLegend} handleLegendOpen={handleLegendOpen} />
      <MapContainer
        whenCreated={(map) => setMap(map)}
        center={[20.5937, 78.9629]}
        zoom={4}
        maxZoom={19}
        minZoom={4}
        scrollWheelZoom={true}
        style={{ width: "100%" }}
      >
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyB_SAzfU9SgPNdtVRb-yArJZ2a08CPFquE"
          type={"hybrid"}
          useGoogMapsLoader={true}
        />
        <ScaleControl position="bottomleft" />
        <Mapfeaturegroup
          mapPolygonData={props.mapPolygonData}
          selectedData={selectedData}
          selectedBufferData={selectedBufferData}
          ref={featureGroupRef}
        />
      </MapContainer>
    </div>
  );
};

export default GenerateMapLayout;
