import React from "react";
import { GeoJSON, FeatureGroup } from "react-leaflet";

const Mapfeaturegroup = React.forwardRef(
  ({ mapPolygonData, selectedData, selectedBufferData }, ref) => {
    return (
      <FeatureGroup ref={ref}>
        {mapPolygonData &&
          mapPolygonData.length !== 0 &&
          mapPolygonData.map((data, i) => {
            // check if the selectedData and selectedBufferData array has the id which is present on the mapPolygonData array.
            const isSelected = selectedData
              .map((d) => d.datavalue.id)
              .includes(data.datavalue.id);
            const isBufferZoneSelected = selectedBufferData
              .map((d) => d.datavalue.id)
              .includes(data.datavalue.id);
            return (
              <GeoJSON
                key={data.datavalue.id}
                data={data.polygon}
                style={() => ({
                  color: isSelected ? "#D10000" : "#65D630",
                  fillOpacity: 0,
                  weight: 5,
                  opacity: 0.5,
                  dashArray: isSelected || isBufferZoneSelected ? "0" : "5,10",
                })}
              />
            );
          })}
      </FeatureGroup>
    );
  },
);

export default Mapfeaturegroup;
