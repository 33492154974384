import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import rupeeImage from "../../../../assets/images/Internal-dashboard/mdi_rupee.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  padding: "30px",
  borderRadius: "5px",
};

export default function Companyinfomodal({
  openModal,
  handleModalClose,
  companyInfoModalData,
  modalHeading,
  counters,
}) {
  const keyNames = Object.keys(companyInfoModalData);

  const keyNameMappings = {
    Admins: "Admins",
    Permissions: "Permissions",
  };

  function renderModalContent(keys) {
    const mappedKey = keyNameMappings[keys];
    if (mappedKey) {
      return companyInfoModalData[mappedKey].map((items, i) => (
        <p key={i}>
          {items}
          {i < companyInfoModalData[mappedKey].length - 1 && ","}
        </p>
      ));
    } else {
      return <p>{companyInfoModalData.Name}</p>;
    }
  }

  return (
    <div className="company-info-modal-wrapper">
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={style} className="custom-company-info-modal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {modalHeading}
            </Typography>
            <div className="custom-layout">
              {keyNames.map((keys, index) => {
                return (
                  <div className="flex-wrapper" key={index}>
                    <div>
                      <p className="keyname">{keys}:</p>
                    </div>
                    <div className="data-wrapper">
                      {renderModalContent(keys)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="grid-layout">
              <Grid container spacing={3}>
                {counters.map((items) => {
                  return (
                    <Grid item xs={6}>
                      <div className="card-wrapper">
                        <h1>{items.count}</h1>
                        <p>{items.gridContent}</p>
                        <p>
                          {items.apiKeyLink === "" ? null : items.apiKeyLink}
                        </p>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <div className="modal-footer">
              <footer>
                <div className="footer-content">
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <ul>
                        <li>
                          <img src={rupeeImage} alt="Rupee Image" />
                        </li>
                        <li>
                          <h1>15,07,500</h1>
                        </li>
                        <li>Revenue</li>
                      </ul>
                    </Grid>
                    <Grid item xs={6}>
                      <p>*300 INR Per Report</p>
                    </Grid>
                  </Grid>
                </div>
              </footer>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
