import React from "react";
import { makeStyles, TextField, Box } from "@material-ui/core";
import styles from "./styles.module.css";
import inputCross from "../../assets/images/input-cross.png";

const useStyles = makeStyles(() => ({
  textField: {
    fontWeight: 500,
    borderRadius: "5px",
    width: "40ch!important",
  },
  customInput: {
    padding: "0",
    height: "40px",
    fontSize: "12px",
  },
}));

export default function Inputoptions(props) {
  const classes = useStyles();
  return (
    <div className={styles.colInputWrapper}>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        className={styles.inputBoxWrapper}
      >
        <TextField
          id="outlined-basic"
          label={`Enter ${props.missingText} `}
          variant="outlined"
          onChange={(e) => props.handleTextInput(e.target.value)}
          // value={props.storeVillageName}
          className={classes.textField}
          InputProps={{
            className: classes.customInput,
          }}
          InputLabelProps={{
            style: { fontSize: "13px", fontFamily: "sf-regular", top: "-7px" },
          }}
          onKeyDown={(e) => {
            if (e.target.value.length == 0) {
              let key = Number(e.key);
              if (
                !(
                  (e.keyCode >= 65 && e.keyCode <= 90) ||
                  (key >= 0 && key <= 9)
                )
              ) {
                e.preventDefault();
                return null;
              } else if (e.key == " ") {
                e.preventDefault();
                return null;
              }
            }
          }}
        />
      </Box>
    </div>
  );
}
