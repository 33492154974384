// url
import { getNotifications, changeReadStatus } from "../../../common/urls";
// api
import { apiCall } from "../../../common/connect";

// constant
import { GET, POST, ACCESS_TOKEN } from "../../../common/constants";
import { _getStorageValue } from "../../../common/localStorage";

// get notifications list api integration
export function getNotificationsData(
  userID,
  successNotificationDataCallBack,
  failureNotificationDataCallBack,
) {
  const url = getNotifications(userID);
  const onSuccess = (response) => {
    let notificationDataTable = response.data;
    successNotificationDataCallBack(notificationDataTable);
  };
  const onFailure = (response) => {
    failureNotificationDataCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// mark all as read api integration.
export function markAllAsReadFun(
  userID,
  requestPayload,
  successMarkAsReadFunCallBack,
  failureMarkAsReadFunCallBack,
  shouldReload,
) {
  const url = changeReadStatus(userID);
  const onSuccess = (response) => {
    const responsedata = response.message;
    if (shouldReload) {
      successMarkAsReadFunCallBack(responsedata);
    }
  };

  const onFailure = (response) => {
    failureMarkAsReadFunCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, requestPayload, onSuccess, onFailure, token);
    }
  });
}
