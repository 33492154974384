import React, { useRef, useMemo } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
// Action
import { logout } from "../../layouts/login-layout/authentication";
import { _getStorageValue, getCompId } from "../../common/localStorage";

// logo import.
import sideLogo from "../../assets/images/Sage-new-logo.svg";
import home_icon from "../../assets/images/home.svg";
import admin_icon from "../../assets/images/admin_icon.svg";
import logout_icon from "../../assets/images/Logout.svg";
import dashboardProfile from "../../assets/images/header/dashboard-profile.svg";
// internal db img import for the logout dropdown.
import internal_db from "../../assets/images/Internal-dashboard/internal-db.svg";
// stylesheet import
import "./header.css";

// optimised this component to prevent any rerenders.
const NewCommonHeaderComponent = React.memo(
  ({
    handleClick,
    handleClose,
    anchorEl,
    shouldShowDropDownMenu,
    setShouldShowDropDownMenu,
  }) => {
    //ref for logout menu
    const logoutMenu = useRef(null);
    // extract the user access level from local storage.
    const getUserAccessLevel = parseInt(
      localStorage.getItem("user access level"),
    );
    // array to store the page nav links to check with the history location pathnmame property.
    const adminNavTabs = [
      "/adminMain",
      "/addUser",
      "/dataManagement",
      "/analytics",
    ];

    const history = useHistory();

    const closeOpenLogoutMenu = useMemo(
      () => (e) => {
        if (
          logoutMenu.current &&
          shouldShowDropDownMenu &&
          !logoutMenu.current.contains(e.target)
        ) {
          setShouldShowDropDownMenu(false);
        }
      },
      [logoutMenu, shouldShowDropDownMenu, setShouldShowDropDownMenu],
    );

    document.addEventListener("mousedown", closeOpenLogoutMenu);

    const triggerLogout = () => {
      logout(successLogoutCallBack, failureLogoutCallBack);
    };
    const successLogoutCallBack = useMemo(
      () => (response) => {
        // removeAllCookies();
        setTimeout(() => {
          window.location.reload();
        }, 100);
        console.log(response);
      },
      [],
    );
    const failureLogoutCallBack = useMemo(
      () => (response) => {
        console.log(response);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      },
      [],
    );

    const menuItems = useMemo(
      () => [
        {
          navMenuItem: "Database",
          navPath: "/database",
          id: 1,
        },
        {
          navMenuItem: "API Explorer",
          navPath: "#",
          id: 2,
        },
      ],
      [],
    );

    return (
      <div className="header-fix">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <img src={sideLogo} alt="Side Logo" />
            </Grid>
            <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={10}>
                <ul className="nav-items-wrapper">
                  {menuItems.map((items) => {
                    return (
                      <li
                        key={items.id}
                        className={
                          history.location.pathname === "/statewisedata" &&
                          items.id === 1
                            ? "active-border"
                            : history.location.pathname === items.navPath
                              ? "active-border"
                              : ""
                        }
                        onClick={() => {
                          history.push(items.navPath);
                        }}
                      >
                        {items.navMenuItem}
                      </li>
                    );
                  })}
                </ul>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "10px",
                }}
              >
                <Button
                  className="profile"
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <label style={{ color: "#1B45AD", cursor: "pointer" }}>
                    <img
                      src={dashboardProfile}
                      alt="Profile Image"
                      className="dashboard-profile-img"
                    />
                  </label>
                </Button>
                {shouldShowDropDownMenu && (
                  <div ref={logoutMenu}>
                    <div className="custom-clip-path-arrow"></div>
                    <div className="custom-dropdown-styles">
                      <ul>
                        <li
                          style={{ display: "block" }}
                          onClick={() => {
                            history.push("/generate-report");
                          }}
                        >
                          <p className="adminViewDropDown">
                            <img src={home_icon} />
                            Home
                          </p>
                        </li>
                        <li
                          style={
                            getUserAccessLevel === 1 || getUserAccessLevel === 3
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          onClick={() => {
                            history.push("/adminMain");
                          }}
                        >
                          <p className="adminViewDropDown">
                            <img src={admin_icon} />
                            Admin View
                          </p>
                        </li>
                        <li
                          onClick={() => {
                            triggerLogout();
                            handleClose();
                          }}
                        >
                          <p className="logoutDropDown">
                            <img src={logout_icon} />
                            Logout
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  },
);

export default NewCommonHeaderComponent;
