import React from "react";
import MUIDataTable from "mui-datatables";
import { Rendercolumns } from "./Tablecolumns";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import Viewuser from "./View-user";
import Edituser from "./Edit-user";
import "./adminPortal.css";

export default function Usermanagementtable({
  shouldStoreUserManagementData,
  shouldShowUserManagementDataLoader,
  handleInputChange,
  tableHeight,
  handleViewUserModal,
  shouldShowViewUserModal,
  viewUserManagementData,
  handleViewModalClose,
  shouldShowEditUserModal,
  handleEditUserModal,
  handleEditModalClose,
  handleUserInput,
  shouldStoreRole,
  handleRoleChange,
  inputValues,
  handleUserDetails,
  shouldStoreStates,
  storeUserManagementObj,
  handleStateChange,
  handleDistrictChange,
  shouldStoreDistricts,
  shouldShowSwitchLoader,
  shouldStoreSelectedUserDetails,
  handlePageAccess,
  handleDefaultLandingSelection,
  shouldStoreDefaultDistrict,
  shouldStoreUserRegionFilterDistricts,
  shouldStorePageAccess,
  shouldEnableUpdateUserButton,
  storeDefaultLandingValue,
  invalidNumber,
  shouldStoreCreditHubNames,
  handleCreditHubChange,
  storeDefaultCreditHubValue,
  totalRows,
  shouldStorePageNo,
  setShouldStorePageNo,
}) {
  const tableBodyHeight = tableHeight;

  const theme = createTheme({
    overrides: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#FF0000",
            padding: "0",
          },
        },
      },
      MuiToolbar: {
        root: {
          height: "0px",
          visibility: "hidden",
          "&.MuiToolbar-root MuiToolbar-gutters": {
            display: "none",
          },
        },
      },
    },
  });

  const options = {
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: false,
    // responsive: "scroll",
    resizableColumns: false,
    tableBodyHeight,
    filter: false,
    download: false,
    print: false,
    search: false,
    rowsPerPage: 20,
    rowsPerPageOptions: [5],
    count: totalRows === 0 ? shouldStoreUserManagementData.length : totalRows,
    rowStyle: { height: 50 },
    page: shouldStorePageNo,
    serverSide: true,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        //setPage(tableState.page);
        setShouldStorePageNo(tableState.page);
      }
    },
    // rowsSelected: props.storeAllRowsSelected.map((row) => row.index),
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
    },
    selectToolbarPlacement: "none",
    // Disable the table toolbar
    toolbar: false,
  };

  return (
    <div className="archiveTable-sections">
      {shouldShowUserManagementDataLoader && (
        <div className="layered-mask">
          <p>Loading...</p>
        </div>
      )}
      <ThemeProvider theme={theme}>
        {shouldShowViewUserModal && (
          <Viewuser
            shouldShowViewUserModal={shouldShowViewUserModal}
            viewUserManagementData={viewUserManagementData}
            handleViewModalClose={handleViewModalClose}
          />
        )}

        {shouldShowEditUserModal && (
          <Edituser
            invalidNumber={invalidNumber}
            shouldShowEditUserModal={shouldShowEditUserModal}
            handleEditModalClose={handleEditModalClose}
            viewUserManagementData={viewUserManagementData}
            handleUserInput={handleUserInput}
            shouldStoreRole={shouldStoreRole}
            handleRoleChange={handleRoleChange}
            inputValues={inputValues}
            handleUserDetails={handleUserDetails}
            shouldStoreStates={shouldStoreStates}
            storeUserManagementObj={storeUserManagementObj}
            handleStateChange={handleStateChange}
            handleDistrictChange={handleDistrictChange}
            shouldStoreDistricts={shouldStoreDistricts}
            handlePageAccess={handlePageAccess}
            shouldStorePageAccess={shouldStorePageAccess}
            handleDefaultLandingSelection={handleDefaultLandingSelection}
            shouldStoreDefaultDistrict={shouldStoreDefaultDistrict}
            shouldStoreUserRegionFilterDistricts={
              shouldStoreUserRegionFilterDistricts
            }
            shouldEnableUpdateUserButton={shouldEnableUpdateUserButton}
            storeDefaultLandingValue={storeDefaultLandingValue}
            shouldStoreCreditHubNames={shouldStoreCreditHubNames}
            handleCreditHubChange={handleCreditHubChange}
            storeDefaultCreditHubValue={storeDefaultCreditHubValue}
          />
        )}

        <MUIDataTable
          data={shouldStoreUserManagementData}
          columns={Rendercolumns(
            shouldStoreUserManagementData,
            handleInputChange,
            handleViewUserModal,
            handleEditUserModal,
            shouldShowSwitchLoader,
            shouldStoreSelectedUserDetails,
          )}
          options={options}
        />
      </ThemeProvider>
    </div>
  );
}
