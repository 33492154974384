// helper login
import Cookies from "universal-cookie";
import { getUserId } from "../common/localStorage";

export const userLoggedIn = () => {
  if (getUserId()) {
    return localStorage.getItem("activeStatus") === "true";
  } else {
    return false;
  }
};

const cookies = new Cookies();
