import React from "react";
import Selectpickerelements from "./Selectpickerelements";

export default function Selectoptions(props) {
  return (
    <Selectpickerelements
      dropDownComponents={props.dropDownComponents}
      onSelectDropdownChange={props.onSelectDropdownChange}
      filterDropDownComponents={props.filterDropDownComponents}
      filter={props.filter}
      showFilterDropDowns={props.showFilterDropDowns}
      selectPickeCloneNames={props.selectPickeCloneNames}
      onSelectChange={props.onSelectChange}
      showTextBox={props.showTextBox}
      showMissingText={props.showMissingText}
      currentlevel={props.currentlevel}
      handleMissingText={props.handleMissingText}
      handleInputClose={props.handleInputClose}
      setShowMissingText={props.setShowMissingText}
      selectedMissingText={props.selectedMissingText}
      selectedCurrentLevel={props.selectedCurrentLevel}
      handleSelectedMissingText={props.handleSelectedMissingText}
      showSelectedTextBoxes={props.showSelectedTextBoxes}
      handleSelectedInputClose={props.handleSelectedInputClose}
      handleTextInput={props.handleTextInput}
      handleSelectedTextInput={props.handleSelectedTextInput}
      storeBasedOnCurrentLevelAccess={props.storeBasedOnCurrentLevelAccess}
      storeDataFromDistrictLevel={props.storeDataFromDistrictLevel}
    />
  );
}
