export const sidePanelUserManualData = [
  {
    pageName: "Sage Dashboard",
    pageRoute: "/sageDashboardManual",
  },
  {
    pageName: "SatSource Report",
    pageRoute: "/satScoreReportManual",
  },
];

export const sidePanelFaqData = [
  {
    pageName: "Sage Dashboard",
    pageRoute: "/sageDashboardFAQ",
  },
  {
    pageName: "SatSource Report",
    pageRoute: "/satScoreReportFAQ",
  },
];
