// react
import React, { useState } from "react";
// Loyout
import BackGroundImage from "../../assets/images/LoginBackgroundImage.png";
import SageLogo from "../../assets/images/Sage-new-logo.svg";

// Style
import "../login/Login.css";
import ResetPasswordLayout from "../../layouts/reset-password-layout/reset-password";

const ResetPassword = () => {
  const [key, setKey] = useState(0);
  return (
    <div className="login-page">
      <div className="logo-div">
        <div>
          <img src={SageLogo} alt="loginLogo" className="sage-logo" />
        </div>
      </div>
      <div className="login-layout">
        <ResetPasswordLayout />
      </div>
    </div>
  );
};

export default ResetPassword;
