import {
  DEFAULT_RADIO_BUTTON_SELECTION,
  USER_PAGE_PERMISSION,
} from "../action/actionConstants";

const initialState = {
  defaultRadioButtonSelection: [],
  dataLoader: true,
  showDefaultPageNo: null,
  isPropEye: false, // Corrected typo
};

const disableRadioButtonReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_RADIO_BUTTON_SELECTION:
      return action.payLoad;
    case USER_PAGE_PERMISSION:
      for (const item of action.payLoad) {
        if (
          (item.page_name === "property" ||
            item.page_name === "property & ownership") &&
          item.default_page
        ) {
          return { ...state, isPropEye: true };
        }
      }
      return { ...state };
    default:
      return state;
  }
};

export default disableRadioButtonReducer;
