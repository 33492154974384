import React from "react";
import { Redirect, Route } from "react-router-dom";
import { _getStorageValue, getCompId } from "../common/localStorage";

import { userLoggedIn } from "../common/common";

const PrivateRouteWithCompanyIdCheck = ({ component: Component, ...rest }) => {
  const whetherUserLoggedIn = userLoggedIn();
  const userCompanyId = getCompId();

  const isCompanyAuthorized = userCompanyId === "1";

  return (
    <Route
      {...rest}
      render={(props) =>
        whetherUserLoggedIn && isCompanyAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

export default PrivateRouteWithCompanyIdCheck;
