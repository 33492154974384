// ViewUser.jsx
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../adminPortal.css";
import modalClose from "../../../assets/images/view-modal-close.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
};

const headerStyles = {
  padding: "15px",
  color: "#3342B5",
  fontWeight: "600",
  fontSize: "14px",
  textIndent: "25px",
  display: "flex",
  justifyContent: "space-between",
};

export function renderRoles(role) {
  switch (role) {
    case "1":
      return "Admin";
    case "2":
      return "User";
    default:
      return "Super Admin";
  }
}

export default function ViewUser({
  shouldShowViewUserModal,
  viewUserManagementData,
  handleViewModalClose,
}) {
  const values = [];
  for (const obj of viewUserManagementData) {
    for (const val of Object.values(obj)) {
      values.push(val);
    }
  }

  const storeKeyNamesForTheUserData = [
    "NAME OF USER",
    "E-MAIL ID",
    "PHONE NUMBER",
    "ROLE",
    "DESIGNATION",
    "STATE",
    "DISTRICT",
    "CREDIT HUB",
    "REPORT ACCESS",
    "DEFAULT REPORT",
    "LAST LOGIN",
  ];

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={shouldShowViewUserModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={shouldShowViewUserModal}>
          <Box sx={style}>
            <div className="view-user-modal-wrapper">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={headerStyles}
              >
                <span>View User</span>
                <img
                  src={modalClose}
                  alt="Modal Close Image"
                  onClick={handleViewModalClose}
                />
              </Typography>
            </div>
            <Box className="view-user-form-wrapper">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <ul>
                    {storeKeyNamesForTheUserData.map((keyNames, index) => (
                      <li key={index} style={{ color: "#3342B5" }}>
                        {keyNames}
                      </li>
                    ))}
                  </ul>
                </Grid>
                <Grid item xs={8}>
                  <ul>
                    {values.map((userData, index) => {
                      const keyNamesBasedOnIndex =
                        storeKeyNamesForTheUserData[index];
                      const reportAccessArr = values[8];
                      const nameReplacements = {
                        satsource: "SatSource",
                        "satsource & ownership": "SatSource,Ownership",
                      };
                      return (
                        <li key={index} style={{ color: "#828282" }}>
                          {keyNamesBasedOnIndex === "REPORT ACCESS" &&
                          (reportAccessArr === null ||
                            reportAccessArr.length === 0) ? (
                            <span>NA</span>
                          ) : keyNamesBasedOnIndex === "REPORT ACCESS" ? (
                            reportAccessArr.map((items, i) => (
                              <span key={i} className="text-seperator">
                                {nameReplacements[items] || items}
                              </span>
                            ))
                          ) : keyNamesBasedOnIndex === "ROLE" ? (
                            renderRoles(values[3])
                          ) : keyNamesBasedOnIndex === "E-MAIL ID" ? (
                            <p>{userData}</p>
                          ) : keyNamesBasedOnIndex === "DEFAULT REPORT" ? (
                            <p>{nameReplacements[userData] || userData}</p>
                          ) : userData === "" ? (
                            "NA"
                          ) : (
                            userData
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
