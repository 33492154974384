import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Paper } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { apiCall } from "../../../common/connect";
import { getNotifications, changeReadStatus } from "../../../common/urls";
import { USER_ID, ACCESS_TOKEN, GET, POST } from "../../../common/constants";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import Divider from "@mui/material/Divider";
import { _getStorageValue } from "../../../common/localStorage";

//usestyles code block
const useStyles = makeStyles({
  dropdownRoot: {
    position: "absolute",
    right: "140px",
    zIndex: "5001",
    width: "394px",
    maxHeight: "524px",
  },
  notificationHeader: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#3342B5",
  },
  notificationHeaderDiv: {
    display: "flex",
    flexDirection: "column-reverse",
    marginBottom: "12px",
  },
  notificationStatusOrange: {
    color: "rgb(252, 121, 0)",
  },
  markallasread: {
    color: "#3342B5",
    fontSize: "14px",
    fontWeight: "500",
    fontStyle: "underlined",
    "& p": {
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  },
  dropdownHeader: {
    height: "70px",
    padding: "0px 11px",
  },
  notificationlistroot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1px 0",
  },
  timeStamp: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#595958",
  },
  notificationListItemUnread: {
    height: "59px",
    background: "#E2E2E2",
    paddingLeft: "13px",
  },
  notificationListItemRead: {
    height: "59px",
    paddingLeft: "13px",
  },
  notificationStatusGreen: {
    color: "#38A165",
  },
  notificationStatusRed: {
    color: "#D10000",
  },
  viewbutton: {
    fontSize: "12px",
    color: "1B45AD !important",
    paddingRight: "10px",
    "&:hover": {
      backgroundColor: "transparent !important",
      textDecoration: "underline !important",
      cursor: "pointer",
    },
  },
  expandIcon: {
    padding: "4px",
    "&:hover": {
      background: "transparent !important",
      backgroundColor: "#E8F3F4 !important",
      borderRadius: "50% !important",
    },
  },
  expandIconClicked: {
    padding: "4px",
    background: "transparent !important",
    backgroundColor: "#E8F3F4 !important",
    borderRadius: "50% !important",
    "&:hover": {
      background: "transparent !important",
      backgroundColor: "#E8F3F4 !important",
      borderRadius: "50% !important",
    },
  },
  dataLoader: {
    fontWeight: "600",
    padding: "10px 0",
    color: "#f00",
    textAlign: "center",
  },
});

// state less pure function component.
export default function NotificationDropdown(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  let notificationExpansionState = useSelector(
    (s) => s.notificationHeaderReducer.isExpanded,
  );

  const [tableData, setTableData] = useState([]);
  const [markasState, setMarkasState] = useState(false);
  const [viewButtonVisibility, setViewButtonVisibility] = useState(1);
  // data loader.
  const [dataLoader, setDataLoader] = useState(true);

  function dateTimeFormatter(timeString) {
    const today = new Date(timeString);

    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "-" + mm + "-" + yyyy;

    const time = today.toLocaleTimeString();
    return [formattedToday, time].join(" ");
  }

  function markAllAsReadFun(
    userID,
    requestPayload,
    successMarkAsReadFunCallBack,
    failureMarkAsReadFunCallBack,
    shouldReload,
  ) {
    const url = changeReadStatus(userID);
    const onSuccess = (response) => {
      const responsedata = response.message;
      if (shouldReload) {
        successMarkAsReadFunCallBack(responsedata);
      }
    };
    const onFailure = (response) => {
      failureMarkAsReadFunCallBack(response);
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
      if (token !== undefined && token !== null) {
        apiCall(POST, url, requestPayload, onSuccess, onFailure, token);
      }
    });
  }

  function successMarkAsReadFunCallBack() {
    window.location.reload();
  }

  function failureMarkAsReadFunCallBack() {
    console.log("failure");
  }

  function markAllAsRead(tableData, shouldReload = true) {
    _getStorageValue(USER_ID).then((userID) => {
      const requestPayload = [];
      tableData.map((data) => {
        let temp = {
          sno: data.sno,
          reference_id: data.reference_id,
          read_status: "true",
        };
        requestPayload.push(temp);
      });
      if (requestPayload.length != 0)
        markAllAsReadFun(
          userID,
          requestPayload,
          successMarkAsReadFunCallBack,
          failureMarkAsReadFunCallBack,
          shouldReload,
        );
    });
  }

  function getNotificationsData(
    userID,
    successNotificationDataCallBack,
    failureNotificationDataCallBack,
  ) {
    const url = getNotifications(userID);
    console.log("here");

    const onSuccess = (response) => {
      let notificationDataTable = response.data;

      successNotificationDataCallBack(notificationDataTable);
    };
    const onFailure = (response) => {
      failureNotificationDataCallBack(response);
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
      if (token !== undefined && token !== null) {
        apiCall(GET, url, "", onSuccess, onFailure, token);
      }
    });
  }

  const mapDataSet = (res) => {
    const dataObj = res.map((data) => {
      let error_status = null;
      let message1 = null;
      let message_bold = null;
      let message2 = null;

      if (data.notification_type === 2) {
        error_status = 1;
        message_bold = "Query raised";
        message2 = "for Reference id: " + data.reference_id;
      } else if (data.notification_type === 1) {
        switch (data.report_type) {
          case 1: // Satsource report
            if (data.report_status === 1) {
              message1 = "SatSource report";
              message_bold = "Generated";
              message2 = "for Reference id: " + data.reference_id;
            } else if (data.report_status === 2 || data.report_status === 3) {
              message1 = "SatSource report";
              message_bold = "Partially Generated";
              message2 = "for Reference id: " + data.reference_id;
            } else if (data.report_status === -2) {
              error_status = 1;
              message_bold = "Error detected";
              message2 =
                "in SatSource Report generation for Reference id: " +
                data.reference_id;
            } else if (data.report_status === -3) {
              message1 =
                "SatSource Report for Reference id: " + data.reference_id;
              message_bold = "is Not Available";
            }
            break;
          case 2: // Ownership report
            if (data.report_status === 1) {
              message1 = "Ownership report";
              message_bold = "Generated";
              message2 = "for Reference id: " + data.reference_id;
            } else if (data.report_status === -2) {
              message1 = "Ownership report";
              message_bold = "Partially generated";
              message2 = "for Reference id: " + data.reference_id;
            } else if (data.report_status === -3) {
              error_status = 2;
              message1 =
                "Ownership Report for Reference id: " + data.reference_id;
              message_bold = "is Delayed";
            } else if (data.report_status === -4) {
              error_status = 1;
              message1 =
                "Ownership Report for Reference id: " + data.reference_id;
              message_bold = "is Not Available";
            }
            break;
          default:
            break;
        }
      }

      return {
        sno: data.sno,
        user_id: data.user_id,
        request_id: data.request_id,
        reference_id: data.reference_id,
        query_serial: data.query_serial,
        notification_type: data.notification_type,
        update_time: data.update_time,
        read_status: data.read_status,
        delete_status: data.delete_status,
        query_title: data.query_title,
        report_status: data.report_status,
        error_status,
        message1,
        message_bold,
        message2,
      };
    });

    setTableData(dataObj);
    setStoreOriginalData(dataObj);
  };

  const successNotificationDataCallBack = (response) => {
    mapDataSet(response);
    setDataLoader(false);
    props.setShowNotificationDot(true);
  };

  const failureNotificationDataCallBack = (response) => {
    setDataLoader(false);
    // props.setShowNotificationDot(true);
  };

  const getNotificationData = () => {
    _getStorageValue(USER_ID).then((userID) => {
      getNotificationsData(
        userID,
        successNotificationDataCallBack,
        failureNotificationDataCallBack,
      );
    });
  };

  useEffect(() => {
    if (location.pathname == "/archive-report") {
      setViewButtonVisibility(0);
    }
    getNotificationData();
    if (
      location.pathname !== "/sageDashboardNotification" &&
      notificationExpansionState === true
    ) {
      dispatch({ type: "NOTIFICATION_EXPANDED" });
    }

    if (
      location.pathname === "/sageDashboardNotification" &&
      notificationExpansionState === false
    ) {
      dispatch({ type: "NOTIFICATION_EXPANDED" });
    }
  }, []);

  useEffect(() => {
    markAllAsRead(tableData);
  }, [markasState]);

  return (
    <Paper className={classes.dropdownRoot} elevation={2}>
      <Stack className={classes.dropdownHeader}>
        <Stack direction="row" justifyContent="space-between" mt={1}>
          <div className={classes.notificationHeaderDiv}>
            <p className={classes.notificationHeader}> NOTIFICATIONS</p>
          </div>
          <Stack spacing={1}>
            <Stack justifyContent="center" alignItems="flex-end">
              <label style={{ color: "#3342B5", cursor: "pointer" }}>
                <OpenInFullOutlinedIcon
                  className={
                    notificationExpansionState
                      ? classes.expandIconClicked
                      : classes.expandIcon
                  }
                  onClick={() => {
                    history.push({
                      pathname: "/sageDashboardNotification",
                      state: tableData,
                    });
                    dispatch({ type: "NOTIFICATION_EXPANDED" });
                    if (notificationExpansionState === true) {
                      history.push({ pathname: "/archive-report" });
                    }
                  }}
                />
              </label>
            </Stack>

            <Stack
              className={classes.markallasread}
              onClick={() => {
                setMarkasState(true);
              }}
              direction="row"
            >
              <DoneOutlinedIcon sx={{ fontSize: 14 }} />
              <p>Mark all as read</p>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Divider orientation="horizontal" flexItem />
      <Stack
        sx={{
          maxHeight: "100%",
          overflow: "auto",
          height: "452px",
          "&::-webkit-scrollbar": { width: 2, color: "grey" },
        }}
      >
        {dataLoader && <p className={classes.dataLoader}>Loading...</p>}
        <ul className={`${classes.notificationlistroot} listWrapper`}>
          {tableData.map((items, i) => {
            return (
              <li key={items.sno}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                  pt={2}
                  className={
                    items.read_status
                      ? classes.notificationListItemRead
                      : classes.notificationListItemUnread
                  }
                >
                  <Stack direction="row" justifyContent="flex-start">
                    <CircleIcon
                      className={
                        items.error_status === 1
                          ? classes.notificationStatusRed
                          : items.error_status === 2
                            ? classes.notificationStatusOrange
                            : classes.notificationStatusGreen
                      }
                      sx={{ fontSize: 10.32, paddingTop: "8px" }}
                    />
                    <Stack direction="column" sx={{ marginLeft: "9px" }}>
                      <p>
                        <span>{items.message1}</span>
                        <b style={{ padding: "0 5px 0 5px" }}>
                          {items.message_bold}
                        </b>
                        <span>{items.message2}</span>
                      </p>
                      <p className={classes.timeStamp}>
                        {dateTimeFormatter(items.update_time)}
                      </p>
                    </Stack>
                  </Stack>
                  <Stack alignItems="flex-end">
                    {viewButtonVisibility === 1 && (
                      <Button
                        disableripple
                        variant="text"
                        className={classes.viewbutton}
                        sx={{
                          visibility: viewButtonVisibility
                            ? "visible"
                            : "hidden",
                        }}
                        onClick={(e) => {
                          history.push({
                            pathname: "/archive-report",
                            state: {
                              refId: items.reference_id,
                              prevPath: "/sageDashboardNotification",
                            },
                          });
                          markAllAsRead(
                            [
                              {
                                sno: items.sno,
                                reference_id: items.reference_id,
                                read_status: "true",
                              },
                            ],
                            false,
                          );
                        }}
                      >
                        View
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </li>
            );
          })}
        </ul>
      </Stack>
    </Paper>
  );
}
