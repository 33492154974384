// parent component for notifications view page and side panel notifications child components. Manage all the state logic and funtionalities here.
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Layout
import HeaderLayoutComp from "../header.js";
import SidePanelNotification from "./sidePanel-notification.jsx";
import NotificationViewPage from "./notification-view-page.jsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Action
import { _getStorageValue } from "../../../common/localStorage";
import { USER_ID } from "../../../common/constants";
// api function import.
import { getNotificationsData, markAllAsReadFun } from "./notificationfun";

//stylings
import "./styles.css";

const SageDashboardNotification = (props) => {
  const [tabNav, setTabNav] = useState("");
  // to store the notifications.
  const [notificationList, setNotificationlist] = useState([]);
  // const [isAllSelected, setIsAllSelected] = useState(0);
  const [tableData, setTableData] = useState([]);
  // to store the filtered list default color
  const [defaultColor, setDefaultColor] = useState(true);
  // to set the active list color.
  const [activeColor, setActiveColor] = useState(false);
  // query list loader.
  const [queryListLoader, setQueryListLoader] = useState(true);
  //
  const [tabChange, setTabChange] = useState(false);
  //
  const [markAsState, setMarkAsState] = useState(false);
  //handle check of checkboxes
  const [storeCheckBoxLabels, setStoreCheckBoxLabels] = useState([]);
  // to show/hide the mark as read text.
  const [renderMarkAsReadText, setRenderMarkAsReadtText] = useState(false);
  // for checkboxes checked.
  const [storeCheckedStatus, setStoreCheckedStatus] = useState([]);
  const location = useLocation();

  useEffect(() => {
    _getStorageValue(USER_ID).then((userID) => {
      getNotificationsData(
        userID,
        successNotificationDataCallBack,
        failureNotificationDataCallBack,
      );
    });

    const successNotificationDataCallBack = (response) => {
      mapDataSet(response);
      setQueryListLoader(false);
    };

    const failureNotificationDataCallBack = (response) => {
      console.log(response, "error response");
      setQueryListLoader(false);
    };
  }, [tabChange]);

  useEffect(() => {
    let path = window.location.pathname;
    setTabNav(path);
    //setNotificationlist(location.state);
  }, [tabNav]);

  // use effect to set the location data inside a state.
  useEffect(() => {
    //setNotificationlist(location.state);
    setNotificationlist(tableData);
  }, [notificationList]);

  useEffect(() => {
    //setNotificationlist(location.state);
    _getStorageValue(USER_ID).then((userID) => {
      getNotificationsData(
        userID,
        successNotificationDataCallBack,
        failureNotificationDataCallBack,
      );
    });

    const successNotificationDataCallBack = (response) => {
      mapDataSet(response);
      setQueryListLoader(false);
    };

    const failureNotificationDataCallBack = (response) => {
      console.log(response, "error response");
      setQueryListLoader(false);
    };
  }, [markAsState]);

  // function to format the date and time.
  function dateTimeFormatter(timeString) {
    const today = new Date(timeString);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = dd + "-" + mm + "-" + yyyy;
    const time = today.toLocaleTimeString();
    return formattedToday + time;
  }

  const handleChange = (checkStatus, items) => {
    if (checkStatus === true) {
      setStoreCheckBoxLabels([...storeCheckBoxLabels, items]);
      setStoreCheckedStatus([...storeCheckedStatus, items.sno]);
    } else if (checkStatus === false) {
      setStoreCheckBoxLabels(
        [...storeCheckBoxLabels].filter(
          (filteredLabels) => filteredLabels !== items,
        ),
      );
      const checkedStatus = [...storeCheckedStatus].filter(
        (filteredLabels) => filteredLabels !== items.sno,
      );
      setStoreCheckedStatus(checkedStatus);
    }
  };

  // use effect to enable/disable the mark as read text based on the how many checkboxes are being checked.
  useEffect(() => {
    if (storeCheckBoxLabels.length === 0) {
      setRenderMarkAsReadtText(false);
    } else {
      setRenderMarkAsReadtText(true);
    }
  }, [storeCheckBoxLabels]);

  const mapDataSet = (res) => {
    let dataObj = [];
    res.map((data, index) => {
      let dummyObj = {
        sno: data.sno,
        user_id: data.user_id,
        request_id: data.request_id,
        reference_id: data.reference_id,
        query_serial: data.query_serial,
        notification_type: data.notification_type,
        update_time: data.update_time,
        read_status: data.read_status,
        delete_status: data.delete_status,
        query_title: data.query_title,
        query_title: data.query_title,
        report_status: data.report_status,
        error_status: null,
        message1: null,
        message_bold: null,
        message2: null,
        notificationIndex: index,
      };

      if (data.notification_type === 2) {
        dummyObj.error_status = 1;
        dummyObj.message_bold = "Query raised ";
        dummyObj.message2 = "for Reference id: " + data.reference_id;
        dummyObj.message1 = "";
      } else if (data.notification_type === 1) {
        if (data.report_type === 1) {
          //satsource report
          if (data.report_status === 1) {
            //generated
            dummyObj.error_status = 0;
            dummyObj.message1 = "SatSource report ";
            dummyObj.message_bold = "generated ";
            dummyObj.message2 = "for Reference id: " + data.reference_id;
          } else if (data.report_status === 2 || data.report_status === 3) {
            //partially generated
            dummyObj.error_status = 0;
            dummyObj.message1 = "SatSource report ";
            dummyObj.message_bold = "Partially generated ";
            dummyObj.message2 = "for Reference id: " + data.reference_id;
          } else if (data.report_status === -2) {
            //failed
            dummyObj.error_status = 1;
            dummyObj.message1 = "";
            dummyObj.message_bold = "Error detected ";
            dummyObj.message2 =
              "in SatSource Report generation for Reference id: " +
              data.reference_id;
          } else if (data.report_status === -3) {
            //not available
            dummyObj.error_status = 1;
            dummyObj.message1 =
              "SatSource Report for Reference id: " + data.reference_id;
            dummyObj.message_bold = " is Not Available";
            dummyObj.message2 = "";
          }
        } else if (data.report_type === 2) {
          //ownership report
          if (data.report_status === 1) {
            //generated
            dummyObj.error_status = 0;
            dummyObj.message1 = "Ownership report ";
            dummyObj.message_bold = "generated ";
            dummyObj.message2 = "for Reference id: " + data.reference_id;
          } else if (data.report_status === -2) {
            //partially generated
            dummyObj.error_status = 0;
            dummyObj.message1 = "Ownership report ";
            dummyObj.message_bold = "Partially generated ";
            dummyObj.message2 = "for Reference id: " + data.reference_id;
          } else if (data.report_status === -3) {
            //delayed
            dummyObj.error_status = 2;
            dummyObj.message1 =
              "Ownership Report for Reference id: " + data.reference_id;
            dummyObj.message_bold = " is Delayed";
            dummyObj.message2 = "";
          } else if (data.report_status === -4) {
            //not available
            dummyObj.error_status = 1;
            dummyObj.message1 =
              "Ownership Report for Reference id: " + data.reference_id;
            dummyObj.message_bold = " is Not Available";
            dummyObj.message2 = "";
          }
        }
      }
      dataObj.push(dummyObj);
    });
    setTableData(dataObj);
    setMarkAsState(false);

    setNotificationlist(dataObj);
  };

  useEffect(() => {
    _getStorageValue(USER_ID).then((userID) => {
      getNotificationsData(
        userID,
        successNotificationDataCallBack,
        failureNotificationDataCallBack,
      );
    });

    const successNotificationDataCallBack = (response) => {
      mapDataSet(response);
      setQueryListLoader(false);
    };

    const failureNotificationDataCallBack = (response) => {
      console.log(response, "error response");
      setQueryListLoader(false);
    };
  }, []);

  // this function will handle the mark as read text only for specific entries that are being checked.
  function markAsRead(shouldReload = true) {
    _getStorageValue(USER_ID).then((userID) => {
      const requestPayload = [];
      storeCheckBoxLabels.forEach((data) => {
        let temp = {
          sno: data.sno,
          reference_id: data.reference_id,
          read_status: "true",
        };
        requestPayload.push(temp);
      });
      markAllAsReadFun(
        userID,
        requestPayload,
        successMarkAsReadFunCallBack,
        failureMarkAsReadFunCallBack,
        shouldReload,
      );
    });
  }

  function successMarkAsReadFunCallBack() {
    //window.location.reload()
    setDefaultColor(true);
    setActiveColor(false);
    setMarkAsState(true);
    setTabChange(true);
    setRenderMarkAsReadtText(false);
    setStoreCheckedStatus([]);
  }

  function failureMarkAsReadFunCallBack() {
    console.log("failure");
  }

  // this function will handle the mark as read for all the entries.
  function markAllAsRead(tableData, shouldReload = true) {
    _getStorageValue(USER_ID).then((userID) => {
      const requestPayload = [];
      tableData.forEach((data) => {
        let temp = {
          sno: data.sno,
          reference_id: data.reference_id,
          read_status: "true",
        };
        requestPayload.push(temp);
      });
      markAllAsReadFun(
        userID,
        requestPayload,
        successMarkAllAsReadFunCallBack,
        failureMarkAllAsReadFunCallBack,
        shouldReload,
      );
    });
  }

  function successMarkAllAsReadFunCallBack() {
    //window.location.reload()
    setDefaultColor(true);
    setActiveColor(false);
    setMarkAsState(true);
    setTabChange(true);
    setRenderMarkAsReadtText(false);
    setStoreCheckedStatus([]);
  }

  function failureMarkAllAsReadFunCallBack() {
    console.log("failure");
  }

  // function to handle the notifications filteration.
  function handleFilteredItems(index) {
    setDefaultColor(false);
    const copyOfOriginalArray = [...notificationList];
    if (index === 0) {
      const storeAllNotifications = copyOfOriginalArray.filter(
        (allNotifications) =>
          allNotifications.notification_type === 1 ||
          allNotifications.notification_type === 2,
      );
      setActiveColor(index);
      setTableData(storeAllNotifications);
      setRenderMarkAsReadtText(false);
      setStoreCheckedStatus([]);
      setStoreCheckBoxLabels([]);
    } else if (index === 1) {
      const storeRaiseQueryNotifications = copyOfOriginalArray.filter(
        (raisedQueries) => raisedQueries.notification_type === 2,
      );
      setTableData(storeRaiseQueryNotifications);
      setActiveColor(index);
      setRenderMarkAsReadtText(false);
      setStoreCheckedStatus([]);
      setStoreCheckBoxLabels([]);
    } else if (index === 2) {
      const storeDelaysAndErrorsInNotifications = copyOfOriginalArray.filter(
        (delayAndError) =>
          delayAndError.notification_type === 1 &&
          (delayAndError.report_status === -3 ||
            delayAndError.report_status === -2),
      );
      setTableData(storeDelaysAndErrorsInNotifications);
      setActiveColor(index);
      setRenderMarkAsReadtText(false);
      setStoreCheckedStatus([]);
      setStoreCheckBoxLabels([]);
    }
    return null;
  }

  //usestyles code block
  const useStyles = makeStyles({
    notificationViewContainer: {
      height: "calc(100vh - 245px)",
      padding: "14px",
      overflowX: "hidden",
    },
    notificationViewHeader: {
      height: "36px",
      backgroundColor: "white",
      border: "0.5px solid #E0E0E0",
      borderRadius: "5px",
      paddingTop: "14px",
      paddingLeft: "15px",
      marginBottom: "15px",
      marginTop: "10px",
      paddingRight: "14px",
    },
    markallasread: {
      color: "#1B45AD",
      fontSize: "14px",
      fontWeight: "500",
      fontStyle: "underlined",
      "& p": {
        "&:hover": {
          backgroundColor: "transparent",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
    },
    notificationHeader: {
      fontSize: "14px",
      fontWeight: "600",
      color: "#3342B5",
    },
    notificationStatusGreen: {
      color: "#38A165",
    },
    notificationStatusRed: {
      color: "#D10000",
    },
    notificationStatusOrange: {
      color: "rgb(252, 121, 0)",
    },
    viewbutton: {
      fontSize: "12px",
      paddingRight: "10px",
      color: "#1B45AD",
      "&:hover": {
        backgroundColor: "transparent !important",
        textDecoration: "underline !important",
        cursor: "pointer",
      },
    },
    notificationListItemUnread: {
      background: "#E2E2E2 !important",
    },
    notificationListItemRead: {},
    timeStamp: {
      fontSize: "12px",
      fontWeight: "400",
      color: "#ABAAAA",
    },
    notificationItem: {
      fontSize: "15px",
      fontWeight: "500",
    },
  });

  // to store the filter list items for the side panel notification component.
  const storeItemLabels = [
    {
      items: "ALL",
      id: 0,
    },
    {
      items: "QUERIES RAISED",
      id: 1,
    },
    {
      items: "DELAYS AND ERRORS",
      id: 2,
    },
  ];

  return (
    <>
      <div className="sage-notification-segragation-sections">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <SidePanelNotification
                handleFilteredItems={handleFilteredItems}
                defaultColor={defaultColor}
                activeColor={activeColor}
                storeItemLabels={storeItemLabels}
              />
            </Grid>
            <Grid item xs={10}>
              <div className="sage-user-notification-wrapper">
                <HeaderLayoutComp tabNav={tabNav} />
                <div className="page-container">
                  <div className="slide-container">
                    <NotificationViewPage
                      notificationList={notificationList}
                      useStyles={useStyles}
                      dateTimeFormatter={dateTimeFormatter}
                      handleChange={handleChange}
                      tableData={tableData}
                      queryListLoader={queryListLoader}
                      markAsRead={markAsRead}
                      renderMarkAsReadText={renderMarkAsReadText}
                      storeCheckedStatus={storeCheckedStatus}
                      markAllAsRead={markAllAsRead}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};
export default SageDashboardNotification;
