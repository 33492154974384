import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Databasedashboard from "./Database";
import NewCommonHeaderComponent from "../common-header-component/NewCommonHeaderComponent";
import { _getStorageValue } from "../../common/localStorage";
import { USER_ID } from "../../common/constants";
import {
  fetchStateWiseData,
  fetchStateWiseProdata,
} from "./Internal-dashboard-API";
import ScrollToTop from "react-scroll-to-top";
import "./monitoringModuleStyles.css";

let countData;
let storeStateWiseResponse = [];
let storeStateWiseProdResponse = [];

export default function Database() {
  // show/hide the dropdown menu.
  const [shouldShowDropDownMenu, setShouldShowDropDownMenu] = useState(false);
  // store the dev data to render the states.
  const [storeStateData, setStoreStateData] = useState([]);
  // store the prod data to render the state.
  const [storeStateProdData, setStoreStateProdData] = useState([]);
  // for the loader.
  const [showDataLoader, setShowDataLoader] = useState(true);
  // Drop Down
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const history = useHistory();

  function prodCountData() {
    _getStorageValue(USER_ID).then(() => {
      fetchStateWiseProdata(
        successStateWiseProdDataCallBack,
        failureStateWiseProdDataCallBack,
      );
    });

    const successStateWiseProdDataCallBack = (response) => {
      setShowDataLoader(false);
      response.countData.forEach((data, i) => {
        let archiveObj = {
          state: data.state,
          totalD1Count: data.totalD1Count,
          totalD2Count: data.totalD2Count,
          matchedEntries: data.matchedEntries,
          hierarchyCount: data.hierarchyCount,
          villageCount: data.villageCount,
          d1Updation: data.d1Updation,
          d2Updation: data.d2Updation,
          stateRegionId: data.stateRegionId,
        };
        storeStateWiseProdResponse.push(archiveObj);
        countData = storeStateWiseProdResponse;
      });
      setStoreStateProdData(storeStateWiseProdResponse);
    };

    const failureStateWiseProdDataCallBack = (error) => {
      setShowDataLoader(false);
      console.log(error, "===== error statewise data");
    };
    return storeStateWiseProdResponse;
  }

  useEffect(() => {
    // for calling the prod api.
    prodCountData();

    // for calling the dev api.
    _getStorageValue(USER_ID).then(() => {
      fetchStateWiseData(
        successStateWiseDataCallBack,
        failureStateWiseDataCallBack,
      );
    });

    const successStateWiseDataCallBack = (response) => {
      setShowDataLoader(false);
      response.countData.forEach((data, i) => {
        let archiveObj = {
          state: data.state,
          totalD1Count: data.totalD1Count,
          totalD2Count: data.totalD2Count,
          matchedEntries: data.matchedEntries,
          hierarchyCount: data.hierarchyCount,
          villageCount: data.villageCount,
          d1Updation: data.d1Updation,
          d2Updation: data.d2Updation,
          stateRegionId: data.stateRegionId,
        };
        storeStateWiseResponse.push(archiveObj);
        countData = storeStateWiseResponse;
      });
      setStoreStateData(storeStateWiseResponse);
    };

    const failureStateWiseDataCallBack = (error) => {
      setShowDataLoader(false);
      console.log(error, "===== error statewise data");
    };
  }, []);

  const handleClick = useCallback(
    (event) => {
      setShouldShowDropDownMenu(!shouldShowDropDownMenu);
      setAnchorEl(event.currentTarget);
    },
    [shouldShowDropDownMenu, anchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);

  // on change functionality to handle the search input.
  const handleStateSearch = useCallback(
    (value) => {
      const storeTheInputValue = value.trim().toLowerCase();
      if (storeTheInputValue) {
        // Filter storeStateWiseResponse
        const filteredStateWiseResponse = storeStateWiseResponse.filter(
          (filteredData) => {
            return filteredData.state
              .toString()
              .toLowerCase()
              .includes(storeTheInputValue);
          },
        );
        // Filter storeStateWiseProdResponse
        const filteredStateWiseProdResponse = storeStateWiseProdResponse.filter(
          (filteredData) => {
            return filteredData.state
              .toString()
              .toLowerCase()
              .includes(storeTheInputValue);
          },
        );
        setStoreStateData(filteredStateWiseResponse);
        setStoreStateProdData(filteredStateWiseProdResponse);
      } else {
        setStoreStateData(storeStateWiseResponse);
        setStoreStateProdData(storeStateWiseProdResponse);
      }
    },
    [storeStateWiseResponse, storeStateWiseProdResponse],
  );

  return (
    <div className="sage-internal-dashboard-sections">
      <ScrollToTop smooth color="#111" top="100" />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className="internal-dashboard-wrapper">
              <NewCommonHeaderComponent
                handleClick={handleClick}
                handleClose={handleClose}
                anchorEl={anchorEl}
                shouldShowDropDownMenu={shouldShowDropDownMenu}
                setShouldShowDropDownMenu={setShouldShowDropDownMenu}
              />
              <Databasedashboard
                storeStateData={storeStateData}
                showDataLoader={showDataLoader}
                storeStateProdData={storeStateProdData}
                handleStateSearch={handleStateSearch}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
