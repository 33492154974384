import React from "react";
import tickBox from "../../../assets/images/tick-box.svg";
import "../styles.css";

export default function Surveydetails(props) {
  const levels = [
    "levelFive",
    "levelSix",
    "levelSeven",
    "levelEight",
    "levelNine",
    "levelTen",
    "levelEleven",
    "levelTwelve",
    "levelThirteen",
    "levelFourteen",
    "levelFifteen",
  ];

  const storeNewFilteredLevels = [];
  props.getSurveyDetails.map((item) => {
    const key = Object.keys(item).filter((keyItem) =>
      keyItem.includes("level"),
    );
    const keys = [];
    key.map((keyValue) => {
      if (levels.includes(keyValue)) {
        keys.push(item[keyValue]);
      }
      return item[keyValue];
    });
    storeNewFilteredLevels.push(keys);
  });

  // filtering out an array entry which consists of village label.
  const storeNewLevels = [];
  storeNewFilteredLevels.map((e) => {
    return e.map((fetchInnerData) => {
      if (fetchInnerData.label !== "village") {
        storeNewLevels.push(fetchInnerData);
      }
    });
  });

  return (
    <div className="ref-dropdown-wrapper">
      <div className="align-content">
        <h1>REFERENCE ID: {props.storeRefNo}</h1>
        <img
          src={tickBox}
          alt="Tick Box"
          onClick={() => props.handleDropDownToogle()}
          style={
            props.mapViewDropdown
              ? { transform: "rotate(180deg)", transition: "0.2s ease" }
              : { transform: "rotate(0deg)", transition: "0.2s ease" }
          }
        />
      </div>
      {props.mapViewDropdown && (
        <div className="ref-dropdown-content">
          <h1>Report Generate On:</h1>
          <span>{props.requestTime}</span>
          <h1 className="survey-details-heading">
            Survey Details ({props.getSurveyDetails.length}):
          </h1>
          <table className="ref-dropdown-table">
            {storeNewFilteredLevels.map((arrayItem, arrayItemIndex) => {
              return (
                <tbody>
                  <tr key={arrayItemIndex}>
                    {arrayItemIndex === 0 &&
                      arrayItem.map((surveyDetailsData, index) => {
                        {
                          return (
                            surveyDetailsData.label !== "village" && (
                              <th
                                key={index}
                                style={{ textTransform: "capitalize" }}
                              >
                                {surveyDetailsData.label}
                              </th>
                            )
                          );
                        }
                      })}
                  </tr>
                  <tr key={arrayItemIndex}>
                    {arrayItem.map((surveyDetailsData, index) => {
                      {
                        return (
                          surveyDetailsData.label !== "village" && (
                            <td
                              key={index}
                              style={{ textTransform: "capitalize" }}
                            >
                              {surveyDetailsData.value === null
                                ? "NA"
                                : surveyDetailsData.value}
                            </td>
                          )
                        );
                      }
                    })}
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      )}
    </div>
  );
}
