// react
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";

// plugin
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// style

// import "../../layouts-components/filter-component/filter.css";
import "../../../layouts-components/filter-component/filter.css";

// Images
// import sideLogo from "../../assets/images/Satsure-logo-1.svg";
import sideLogo from "../../../assets/images/Sage-new-logo.svg";

const useStyles = makeStyles({
  sideLabel: {
    fontSize: "17px",
    color: "#534F96",
    fontWeight: "500",
    paddingBottom: "0",
    cursor: "pointer",
    transition: "0.3s ease",
  },
});

const SidePanelNotification = (props) => {
  // let history = useHistory();
  const classes = useStyles();

  // functions to conditionally render the li elements color and dot based on id.
  const renderDynamicListItemsColor = (id) => {
    if (props.defaultColor === true && id === 0) {
      return "rgba(27, 69, 173, 1)";
    } else if (props.activeColor === id) {
      return "rgba(27, 69, 173, 1)";
    } else {
      return "#999999";
    }
  };

  const renderDynamicListItemsPointer = (id) => {
    if (props.defaultColor === true && id === 0) {
      return "1";
    } else if (props.activeColor === id) {
      return "1";
    } else {
      return "0";
    }
  };

  return (
    <div className="filter-sections" style={{ height: "100vh" }}>
      <div>
        <div className="filter-logo">
          <img src={sideLogo} alt="loginLogo" style={{ width: "100%" }} />
        </div>
      </div>
      <div className="filter-list-wrapper">
        <ul>
          {props.storeItemLabels.map((labels) => {
            return (
              <li
                key={labels.id}
                onClick={() => {
                  props.handleFilteredItems(labels.id);
                }}
                style={{ color: renderDynamicListItemsColor(labels.id) }}
              >
                <CircleIcon
                  className="small-icon"
                  style={{ opacity: renderDynamicListItemsPointer(labels.id) }}
                />
                <p
                  className={classes.sideLabel}
                  style={{ color: renderDynamicListItemsColor(labels.id) }}
                >
                  {labels.items}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SidePanelNotification;
