// react
import React from "react";
// Loyout
import LogInLayout from "../../layouts/login-layout/LoginLayout";
import SageLogo from "../../assets/images/Sage-new-logo.svg";
import BackGroundImage from "../../assets/images/LoginBackgroundImage.png";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GOOGLE_RECAPTCHA } from "../../action/actionConstants";

// Style
import "./Login.css";

const LogIn = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA}>
      <div className="login-page">
        <div className="logo-div">
          <div>
            <img src={SageLogo} alt="loginLogo" className="sage-logo" />
          </div>
        </div>
        <div className="login-layout">
          <LogInLayout />
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default LogIn;
