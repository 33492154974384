// react
import React, { useEffect, useState } from "react";
import Tablefiltercomponents from "../../../layouts-components/archive-table-component/Tablefiltercomponents";

// Action
import { _getStorageValue } from "../../../common/localStorage";

// style
import "../../../layouts-components/filter-component/filter.css";

// Images
import sideLogo from "../../../assets/images/Sage-new-logo.svg";
// use dispatch
import { useDispatch } from "react-redux";
import { FILTER_TABLE_COMPONENT } from "../../../action/actionConstants";
import { ALL_FILES } from "../../../action/actionConstants";
// lodash debouncing import
import { debounce } from "lodash";
import { USER_ID } from "../../../common/constants";
import { archiveTableData } from "./archivefun";

const ArchiveFilterComp = ({
  successResponse,
  failResponse,
  setTableData,
  storeOriginalData,
  buttonActive,
  setTableButtons,
  setStoreArchiveStatus,
  setStoreAllRowsSelected,
  setAddActive,
}) => {
  let dispatch = useDispatch();
  const copyOfOriginalArray = [...storeOriginalData];
  const [pageNumber, setPageNumber] = useState(0);
  const [qParam, setQParam] = useState("");

  // this use effect is used to set the default color and allFilesButtonActiveColor to true whenever the component mounts.
  useEffect(() => {
    dispatch({
      type: FILTER_TABLE_COMPONENT,
      payLoad: {
        defaultColor: true,
        allFilesButtonActiveColor: true,
      },
    });
  }, []);

  // function to handle table filter component list items.
  const handleListItems = (id) => {
    console.log("handleListItems====---->>>>", id);
    dispatch({
      type: FILTER_TABLE_COMPONENT,
      payLoad: {
        defaultColor: false,
        activeList: id,
        checkPendingStatus: id === 1 ? true : false,
        checkOnHoldStatus: id === 2 ? true : false,
        checkApprovedStatus: id === 3 ? true : false,
        checkDeclinedStatus: id === 4 ? true : false,
      },
    });
    // if (id === 0) {
    //   setTableData([...copyOfOriginalArray].filter((filteredData) => filteredData.archiveStatus === false));
    //   setStoreArchiveStatus(false);
    //   setStoreAllRowsSelected([]);
    //   dispatch({
    //     type: FILTER_TABLE_COMPONENT,
    //     payLoad: {
    //       defaultColor: true,
    //       allFilesButtonActiveColor: true
    //     },
    //   });
    // } else if (id === 1) {
    //   const storeDesicionPendingData = copyOfOriginalArray.filter((decisionPendingData) => decisionPendingData.dropDownStatus === "pending");
    //   setTableData(storeDesicionPendingData);
    //   setStoreArchiveStatus(false);
    // } else if (id === 2) {
    //   const storeOnHoldData = copyOfOriginalArray.filter((onHoldData) => onHoldData.dropDownStatus === "onhold");
    //   setTableData(storeOnHoldData);
    //   setStoreArchiveStatus(false);
    // } else if (id === 3) {
    //   const storeApprovedData = copyOfOriginalArray.filter((approvedData) => approvedData.dropDownStatus === "approved");
    //   setTableData(storeApprovedData);
    //   setStoreArchiveStatus(false);
    // } else if (id === 4) {
    //   const storeDeclinedData = copyOfOriginalArray.filter((declinedData) => declinedData.dropDownStatus === "declined");
    //   setTableData(storeDeclinedData);
    //   setStoreArchiveStatus(false);
    // } else {
    //   return null;
    // }
  };

  // handle all files button functionality
  const handleAllFiles = (id) => {
    dispatch({
      type: ALL_FILES,
      payLoad: {
        allFiles: id,
        allFilesButtonActiveColor: true,
      },
    });
    if (id === 0) {
      setTableData([
        ...copyOfOriginalArray.filter(
          (filteredData) => filteredData.archiveStatus === false,
        ),
      ]);
      setStoreArchiveStatus(false);
      setStoreAllRowsSelected([]);
    }
  };

  // add filter function to detarmine which data inside table data array has been starred.
  const handleStarredTableData = () => {
    const storeStarredData = copyOfOriginalArray.filter(
      (filteredItems) =>
        filteredItems.starredStatus === true &&
        filteredItems.archiveStatus === false,
    );
    dispatch({
      type: FILTER_TABLE_COMPONENT,
      payLoad: {
        starredButtonActiveColor: true,
        checkStarredStatus: true,
        // starredData: storeStarredData,
        // checkOnHoldStatus: false
      },
    });
    // if (copyOfOriginalArray[0]?.starredStatus === true && buttonActive === true) {
    //   setTableData(copyOfOriginalArray);
    // } else {
    //   setTableData(storeStarredData);
    //   setTableButtons(false);
    //   setStoreAllRowsSelected([]);
    // }
  };

  // on change handle function to trigger the search functionality for the table. Using debounce to prevent performance issues while searching.
  const handleTableSearch = debounce((e) => {
    const searchTerm = e.toLowerCase();
    _getStorageValue(USER_ID).then(() => {
      archiveTableData(
        successResponse,
        failResponse,
        pageNumber,
        qParam,
        searchTerm,
      );
    });
  }, 1000);

  // handle ArchiveData functionality.
  const handleArchiveData = () => {
    dispatch({
      type: FILTER_TABLE_COMPONENT,
      payLoad: {
        checkArchiveStatus: true,
        archiveButtonActiveColor: true,
      },
    });
    // const storeArchiveData = copyOfOriginalArray.filter((archiveData) => archiveData.archiveStatus === true);
    // setTableData(storeArchiveData);
    // setStoreAllRowsSelected([]);
    // setAddActive(false);
  };

  return (
    <div className="filter-sections" style={{ height: "100vh" }}>
      <div>
        <div className="filter-logo">
          <img src={sideLogo} alt="loginLogo" style={{ width: "100%" }} />
        </div>
        <Tablefiltercomponents
          handleListItems={handleListItems}
          handleStarredTableData={handleStarredTableData}
          handleAllFiles={handleAllFiles}
          handleTableSearch={handleTableSearch}
          storeOriginalData={storeOriginalData}
          handleArchiveData={handleArchiveData}
        />
      </div>
    </div>
  );
};

export default ArchiveFilterComp;
