import { NOTIFICATION_EXPANDED } from "../action/actionConstants";

const initialState = {
  isDisplaying: false,
  isExpanded: false,
};

const notificationHeader = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_EXPANDED:
      return {
        ...state,
        isDisplaying: true,
        isExpanded: !state.isExpanded,
      };

    default:
      return state;
  }
};

export default notificationHeader;
