import { FILTER_TABLE_COMPONENT } from "../action/actionConstants";
import { ALL_FILES } from "../action/actionConstants";

const initialState = {
  defaultColor: true,
  activeList: false,
  starredButtonActiveColor: false,
  checkStarredStatus: false,
  starredData: [],
  checkPendingStatus: false,
  checkOnHoldStatus: false,
  checkApprovedStatus: false,
  checkDeclinedStatus: false,
  allFiles: false,
  allFilesButtonActiveColor: false,
  storePendingCount: "",
  storeOnHoldCount: "",
  storeApprovedCount: "",
  storeDeclinedCount: "",
  checkArchiveStatus: false,
  archiveButtonActiveColor: false,
};

const filterTableComponentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_TABLE_COMPONENT:
      return action.payLoad;
    case ALL_FILES:
      return action.payLoad;
    default:
      return state;
  }
};

export default filterTableComponentReducer;
