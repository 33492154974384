// react
import React, { useState, useRef } from "react";

// plugin
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
// component

// Function

import { logout } from "../login-layout/authentication";

// style
import "./dashboard.css";

// Images
import loginLogo from "../../assets/images/satSure-Sage.png";
import logoutLogo from "../../assets/images/dashboard/logout.png";
import achiveReport from "../../assets/images/dashboard/achive-report.png";
import newReport from "../../assets/images/dashboard/new-report.png";
import summary from "../../assets/images/dashboard/summary.png";

const DashboardLayout = (props) => {
  let history = useHistory();
  const triggerLogout = () => {
    console.log("call");
    logout(successLogoutCallBack, failureLogoutCallBack);
  };
  const successLogoutCallBack = (response) => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
    console.log(response);
  };
  const failureLogoutCallBack = (response) => {
    console.log(response);
  };
  return (
    <div className="dashboard-sections">
      <div className="logout-sections">
        <div
          className="logout"
          onClick={() => {
            triggerLogout();
          }}
        >
          <label className="logout-img">
            <img src={logoutLogo} alt="logoutLogo" />
          </label>
          <h2>Log Out</h2>
        </div>
      </div>
      <div className="dashboard-content">
        {/* <div className="home-logo"> */}
        <div className="home-logo-img">
          <img src={loginLogo} alt="loginLogo" />
        </div>
        {/* </div> */}
        <ul className="dashboard-list">
          <li>
            <Link className="dash-card" to={"/generate-report"}>
              <label>
                <div className="list-icon">
                  <img src={newReport} alt="newReportLogo" />
                </div>
              </label>
              <span>GENERATOR</span>
            </Link>
          </li>
          <li>
            {" "}
            <Link className="dash-card" to={"/archive-report"}>
              <label>
                <div className="list-icon">
                  <img src={achiveReport} alt="achiveReportLogo" />
                </div>
              </label>
              <span>EXPLORER</span>
            </Link>
          </li>

          {/* <li>
            {" "}
            <Link className="dash-card" to={"/summary"}>
              <label>
                <div className="list-icon">
                  <img src={summary} alt="summaryLogo" />
                </div>
              </label>
              <span>SUMMARY</span>
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default DashboardLayout;
