import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Link } from "react-router-dom";
import { _getStorageValue, getCompId } from "../../common/localStorage";

// import admin and logout images.
import home_icon from "../../assets/images/home.svg";
import admin_icon from "../../assets/images/admin_icon.svg";
import logout_icon from "../../assets/images/Logout.svg";
// internal db img import for the logout dropdown.
import internal_db from "../../assets/images/Internal-dashboard/internal-db.svg";

//components
import NotificationDropdown from "./notification/notification-dropdown";
import "./header.css";
import { useSelector } from "react-redux";
export default function HeaderRightComponent({
  triggerLogout,
  notificationClicked,
  setNotificationClicked,
  notificationRef,
  showNotificationDot,
  handleClose,
  classes,
  location,
  handleClick,
  shouldShowDropDownMenu,
  setShouldShowDropDownMenu,
}) {
  const { isProperty } = useSelector(
    (state) => state.disableRadioButtonReducer,
  );

  const getDefaultRadioButtonSelection =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];
  let isPropEye = false;

  for (const item of getDefaultRadioButtonSelection) {
    if (
      (item.page_name === "property" ||
        item.page_name === "property & ownership") &&
      item.default_page
    ) {
      isPropEye = true;
      break;
    }
  }
  // extract the user access level from local storage.
  const getUserAccessLevel = parseInt(
    localStorage.getItem("user access level"),
  );
  //ref for logout menu
  const logoutMenu = useRef(null);
  // array to store the page nav links to check with the history location pathnmame property.
  const adminNavTabs = [
    "/adminMain",
    "/addUser",
    "/dataManagement",
    "/analytics",
  ];

  const history = useHistory();
  const closeOpenLogoutMenu = (e) => {
    if (
      logoutMenu.current &&
      shouldShowDropDownMenu &&
      !logoutMenu.current.contains(e.target)
    ) {
      setShouldShowDropDownMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenLogoutMenu);

  return (
    <Stack direction="row">
      <div ref={notificationRef}>
        <Button
          disableripple
          className="notification-bell"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={() => {
            if (notificationClicked == 0) {
              setNotificationClicked(1);
            } else {
              setNotificationClicked(0);
            }
          }}
        >
          <label style={{ color: "#1B45AD", cursor: "pointer" }}>
            <Badge
              color="secondary"
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  color: "rgba(27, 69, 173, 1)",
                  backgroundColor: "#27AE60",
                  opacity: showNotificationDot === false ? "0" : "1",
                },
              }}
            >
              <NotificationsNoneOutlinedIcon
                disableripple
                sx={{
                  "&:hover": {
                    backgroundColor: "#E8F3F4 !important",
                    borderRadius: "50% !important",
                  },
                }}
                className={
                  notificationClicked == 1
                    ? classes.notificationStateExpand
                    : location.pathname === "/sageDashboardNotification"
                      ? classes.notificationStateExpand
                      : null
                }
              />
            </Badge>
          </label>
        </Button>
        {notificationClicked === 1 && (
          <NotificationDropdown notificationClicked={notificationClicked} />
        )}
      </div>
      {isPropEye || isProperty ? (
        ""
      ) : (
        <div>
          <Button
            className="profile"
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={() => history.push("/sageDashboardManual")}
          >
            <label style={{ color: "#1B45AD", cursor: "pointer" }}>
              <HelpOutlineOutlinedIcon />
            </label>
          </Button>
        </div>
      )}
      <div>
        <Button
          className="profile"
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <label style={{ color: "#1B45AD", cursor: "pointer" }}>
            <AccountCircleOutlinedIcon />
          </label>
        </Button>

        {shouldShowDropDownMenu && (
          <div ref={logoutMenu}>
            <div className="clip-path-arrow"></div>
            <div className="custom-dropdown-wrapper">
              <ul>
                <li
                  style={
                    getUserAccessLevel === 1 || getUserAccessLevel === 3
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  onClick={() => {
                    adminNavTabs.includes(history.location.pathname)
                      ? history.push("/generate-report")
                      : history.push("/adminMain");
                  }}
                >
                  <p className="adminViewDropDown">
                    <img
                      src={
                        adminNavTabs.includes(history.location.pathname)
                          ? home_icon
                          : admin_icon
                      }
                    />
                    {adminNavTabs.includes(history.location.pathname)
                      ? "Home"
                      : "Admin View"}
                  </p>
                </li>
                {getCompId() === "1" && (
                  <li
                    onClick={() => {
                      history.push("/database");
                    }}
                  >
                    {" "}
                    <p className="adminViewDropDown">
                      <img src={internal_db} />
                      Internal DB
                    </p>
                  </li>
                )}
                <li
                  onClick={() => {
                    triggerLogout();
                    handleClose();
                  }}
                >
                  <p className="logoutDropDown">
                    <img src={logout_icon} />
                    Logout
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </Stack>
  );
}
