import { STORE_RADIO_BUTTON_SELECTION } from "../action/actionConstants";

const initialState = {
  storeRadioButtonSelection: "",
};

const radioButtonSelectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_RADIO_BUTTON_SELECTION:
      return action.payLoad;
    default:
      return state;
  }
};

export default radioButtonSelectionReducer;
