// react
import React, { useState } from "react";
// Loyout
import GenerateReportLayout from "../../layouts/generate-report-layout/GenerateReportLayout";

// Style

const GenerateReport = () => {
  const [key, setKey] = useState(0);
  return (
    <div className="home-sections">
      <GenerateReportLayout />
    </div>
  );
};

export default GenerateReport;
