import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import NewCommonHeaderComponent from "../../common-header-component/NewCommonHeaderComponent";
import { _getStorageValue } from "../../../common/localStorage";
import { USER_ID } from "../../../common/constants";
import {
  fetchDistrictWiseDevData,
  fetchDistrictWiseProdData,
} from "../Internal-dashboard-API";
import "../monitoringModuleStyles.css";
import Statewisedatadashboard from "./Statewisedata";

var stateWiseData;
export default function Statewisedata() {
  // show/hide the dropdown menu.
  const [shouldShowDropDownMenu, setShouldShowDropDownMenu] = useState(false);
  // for active buttons.
  const [activeButton, setActiveButton] = useState(1);
  // Drop Down
  const [anchorEl, setAnchorEl] = React.useState(null);
  // for the dev table loader.
  const [showDataLoader, setShowDataLoader] = useState(true);
  // for the prod table loader.
  const [showProdDataLoader, setShowProdDataLoader] = useState(true);
  // store the entire district level dev data.
  const [devDistrictLevelData, setDevDistrictLevelData] = useState([]);
  // store the entire district level prod data.
  const [prodDistrictLevelData, setProdDistrictLevelData] = useState([]);
  //store page no for pagination
  const [pageNo, setPageNo] = useState(0);
  //store total no of rows
  const [totalRows, setTotalRows] = useState(0);

  const history = useHistory();

  // Access the state Region Id from query parameters
  const stateRegionId = new URLSearchParams(location.search).get("stateId");
  // Access the string name district from query parameters
  const level = new URLSearchParams(location.search).get("level");

  const handleClick = useCallback(
    (event) => {
      setShouldShowDropDownMenu(!shouldShowDropDownMenu);
      setAnchorEl(event.currentTarget);
    },
    [shouldShowDropDownMenu, anchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);

  const handleCustomTabs = useCallback(
    (buttonIndex) => {
      setActiveButton(buttonIndex);
    },
    [activeButton],
  );

  useEffect(() => {
    if (activeButton === 1) {
      _getStorageValue(USER_ID).then(() => {
        fetchDistrictWiseProdData(
          stateRegionId,
          level,
          pageNo,
          successDistrictWiseProdDataCallBack,
          failureDistrictWiseProdDataCallBack,
        );
      });
      const successDistrictWiseProdDataCallBack = (response) => {
        setShowProdDataLoader(false);
        let storeProdDistrictData = [];
        response.countData.forEach((data, i) => {
          let archiveObj = {
            date: data.dataUpdation,
            districtId: data.districtId,
            districtName: data.districtName,
            hierarchyCount: data.hierarchyCount,
            matchedEntries: data.matchedEntries,
            villageLgdCode: data.villageLgdCode,
            villageName: data.villageName,
            d1Count: data.totalD1Count,
            d2Count: data.totalD2Count,
          };
          storeProdDistrictData.push(archiveObj);
          stateWiseData = storeProdDistrictData;
        });
        setProdDistrictLevelData(storeProdDistrictData);
        setTotalRows(response.totalRows);
      };

      const failureDistrictWiseProdDataCallBack = (error) => {
        setShowProdDataLoader(false);
        console.log(error, "===== error district level data");
      };
    } else {
      _getStorageValue(USER_ID).then(() => {
        fetchDistrictWiseDevData(
          stateRegionId,
          level,
          pageNo,
          successDistrictWiseDataCallBack,
          failureDistrictWiseDataCallBack,
        );
      });

      const successDistrictWiseDataCallBack = (response) => {
        setShowDataLoader(false);
        let storeDevDistrictData = [];
        response.countData.forEach((data, i) => {
          let archiveObj = {
            date: data.dataUpdation,
            districtId: data.districtId,
            districtName: data.districtName,
            hierarchyCount: data.hierarchyCount,
            matchedEntries: data.matchedEntries,
            villageLgdCode: data.villageLgdCode,
            villageName: data.villageName,
            d1Count: data.totalD1Count,
            d2Count: data.totalD2Count,
          };
          storeDevDistrictData.push(archiveObj);
          stateWiseData = storeDevDistrictData;
        });
        setDevDistrictLevelData(storeDevDistrictData);
        setTotalRows(response.totalRows);
      };

      const failureDistrictWiseDataCallBack = (error) => {
        setShowDataLoader(false);
        console.log(error, "===== error district level data");
      };
    }
  }, [activeButton, pageNo]);

  // to handle the search functionality for the statewise table data.
  const handleSearchTableData = useCallback(
    (inputValue) => {
      const storeInputValue = inputValue.toLowerCase();
      const result = [];
      if (storeInputValue) {
        if (activeButton === 1) {
          stateWiseData.filter((filteredData) => {
            if (
              filteredData.districtName
                .toString()
                .toLowerCase()
                .includes(storeInputValue) ||
              filteredData.villageName
                .toString()
                .toLowerCase()
                .includes(storeInputValue) ||
              filteredData.villageName
                .toString()
                .toLowerCase()
                .includes(storeInputValue)
            ) {
              return result.push(filteredData);
            }
          });
          setProdDistrictLevelData(result);
        } else {
          stateWiseData.filter((filteredData) => {
            if (
              filteredData.districtName
                .toString()
                .toLowerCase()
                .includes(storeInputValue) ||
              filteredData.villageName
                .toString()
                .toLowerCase()
                .includes(storeInputValue) ||
              filteredData.villageName
                .toString()
                .toLowerCase()
                .includes(storeInputValue)
            ) {
              return result.push(filteredData);
            }
          });
          setDevDistrictLevelData(result);
        }
      } else {
        if (activeButton === 1) {
          setProdDistrictLevelData(stateWiseData);
        } else {
          setDevDistrictLevelData(stateWiseData);
        }
      }
    },
    [activeButton],
  );

  return (
    <div className="sage-internal-dashboard-sections">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className="internal-dashboard-wrapper">
              <NewCommonHeaderComponent
                handleClick={handleClick}
                handleClose={handleClose}
                anchorEl={anchorEl}
                shouldShowDropDownMenu={shouldShowDropDownMenu}
                setShouldShowDropDownMenu={setShouldShowDropDownMenu}
              />
              <Statewisedatadashboard
                handleCustomTabs={handleCustomTabs}
                activeButton={activeButton}
                devDistrictLevelData={devDistrictLevelData}
                prodDistrictLevelData={prodDistrictLevelData}
                showDataLoader={showDataLoader}
                showProdDataLoader={showProdDataLoader}
                handleSearchTableData={handleSearchTableData}
                setPageNo={setPageNo}
                totalRows={totalRows}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
