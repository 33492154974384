// react
import React, { useState, useEffect, useRef } from "react";
// plugin
import { getPdfStatusData } from "../../layouts/archive-report-layout/layout-component/archivefun";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
// component
import { _getStorageValue } from "../../common/localStorage";
import { USER_ID } from "../../common/constants";
import { sendDataToDb } from "./generate-table-fun";
// style
import "./generatetable.css";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import Button from "react-bootstrap/Button";
// Images
// import loginLogo from "../../assets/images/satSure-Sage.png";

const GenerateTableLayout = (props) => {
  let history = useHistory();
  const [tableBodyHeight, setTableBodyHeight] = useState("calc(100vh - 254px)");
  const [tableData, setTableData] = useState(props.listData);
  const [rowsSelected, setRowsSelected] = useState([]);
  // const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const successPdfStatusResponse = (response) => {
    // setPdfStatus(response.message);
    console.log("PdfStatus", response.message);
  };
  const failPdfStatusResponse = (response) => {
    console.log("fail", response);
  };
  const exportPdfStatusTrigger = (surveyId, refNo) => {
    _getStorageValue(USER_ID).then((userID) => {
      getPdfStatusData(
        userID,
        surveyId,
        refNo,
        successPdfStatusResponse,
        failPdfStatusResponse,
      );
    });
    console.log("qwerty", surveyId, refNo);
  };
  const submitData = () => {
    _getStorageValue(USER_ID).then((userId) => {
      for (let i = 0; i < rowsSelected.length; i++) {
        sendDataToDb(
          userId,
          rowsSelected[i].villageId,
          rowsSelected[i].surveyId,
          rowsSelected[i].refNo,
          successCallBack,
          failureCallBack,
        );
      }
    });
  };

  const successCallBack = (response) => {
    history.push("/archive-report");
    for (let i = 0; i < rowsSelected.length; i++) {
      setTimeout(() => {
        setTableData(
          tableData.filter((item) => item.refNo !== rowsSelected[i].refNo),
        );
      }, 300);
    }
    props.resetTableData(tableData);
  };
  const failureCallBack = (response) => {
    console.log(response);
  };
  const deleteRow = (index) => {
    let updatedData;
    if (index > -1) {
      updatedData = tableData.splice(index, 1);
    }
    setTableData(updatedData);
  };
  const columns = [
    {
      label: "REFERENCE NO.",
      name: "refNo",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "#ffffff",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#DCE5E5",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 101,
          },
        }),
      },
    },
    {
      label: "STATE",
      name: "state",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "#ffffff",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#DCE5E5",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 101,
          },
        }),
      },
    },
    {
      label: "DISTRICT",
      name: "district",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "#ffffff",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#DCE5E5",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 101,
          },
        }),
      },
    },
    {
      label: "TEHSIL",
      name: "tehsil",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "#ffffff",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#DCE5E5",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 101,
          },
        }),
      },
    },
    {
      label: "VILLAGE",
      name: "village",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "#ffffff",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#DCE5E5",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 101,
          },
        }),
      },
    },
    {
      label: "SURVEY NO.",
      name: "khasara",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <div>
              {tableData[value].surveyno == null ? (
                <p>
                  {tableData[value].murabba + "//" + tableData[value].khasara}
                </p>
              ) : (
                <p>{tableData[value].surveyno}</p>
              )}
            </div>
          );
        },
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "#ffffff",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#DCE5E5",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: "MAP VIEW",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div onClick={() => props.showMapView()}>
              <PinDropOutlinedIcon style={{ cursor: "pointer" }} />
            </div>
          );
        },
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "#ffffff",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#DCE5E5",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: "DELETE",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              onClick={() => {
                deleteRow(dataIndex);
              }}
            >
              <DeleteOutlinedIcon style={{ color: "red", cursor: "pointer" }} />
            </div>
          );
        },
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "#ffffff",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#DCE5E5",
            textAlign: "center",
            borderRight: "2px solid #A9C4C5",
            zIndex: 101,
          },
        }),
      },
    },
  ];

  const options = {
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: true,
    responsive: "scroll",
    resizableColumns: false,
    filterType: "checkbox",
    tableBodyHeight,
    filter: false,
    download: false,
    print: false,
    // tableBodyMaxHeight,
    rowsPerPage: 5,
    rowsPerPageOptions: [5],
    onRowsSelect: (rowsSelect, allRows) => {
      let arr = [];
      const selected = allRows.map((item) => {
        arr.push(tableData[item.index]);
      });
      setRowsSelected(arr);
    },
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
    },
  };
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <div className="generatetable-sections">
      <ThemeProvider theme={theme}>
        <MUIDataTable
          // title={"Employee List"}
          data={tableData}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      <div className="submit-container">
        <Button
          variant="contained"
          onClick={rowsSelected.length > 0 ? submitData : null}
          className={rowsSelected.length > 0 ? "submit-btn" : "disabled-button"}
        >
          <p
            className={
              rowsSelected.length > 0 ? "submit-text" : "disabled-text"
            }
          >
            SUBMIT & GENERATE REPORT
          </p>
        </Button>
      </div>
    </div>
  );
};

export default GenerateTableLayout;
