import { decode } from "js-base64";
import crypto from "crypto";

export function rsaEnc(data, key) {
  var publicKeyValue = decode(key);

  const encryptedData = crypto.publicEncrypt(
    {
      key: publicKeyValue,
      padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
      oaepHash: "sha256",
    },
    // We convert the data string to a buffer using `Buffer.from`
    Buffer.from(data),
  );
  return encryptedData.toString("base64");
}
